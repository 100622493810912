import React, { useRef } from 'react'
// eslint-disable-next-line import/no-unresolved
import DatePicker from 'react-bootstrap-date-picker'
import Required from '../../../icons/Required'
import './DateInput.less'
/**
 * Component for selecting a date
 * @param   {function} onChange
 * @param   {function} onBlur
 * @param   {boolean} required mark as required
 * @param   {string} value
 * @param   {string} minDate iso minimum date
 * @param   {string} maxDate iso maximum date
 * @param   {string} error
 * @param   {string} label
 * @return  {Component}
 */
const DateInput = ({
  label, value, onChange, onBlur, required, error, minDate, maxDate,
}) => {
  const dateRef = useRef()
  return (
    <div className="date-input">
      <div className={`date-input-label ${error ? 'error' : ''}`}>
        {label}
        {required && <Required />}
      </div>
      <DatePicker
        className="date-input-box"
        ref={dateRef}
        minDate={minDate}
        maxDate={maxDate}
        onChange={onChange}
        onBlur={onBlur}
        showClearButton={false}
        value={value}
      />
    </div>
  )
}

export default DateInput
