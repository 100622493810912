import React from 'react';
import PropTypes from 'prop-types';
import './RemoveTemplateConfirmation.less';
import { Button, ButtonRow, Modal } from '@himarley/unity';

const RemoveTemplateConfirmation = ({
  setShow,
  show,
  cancel,
  remove,
  title,
}) => (
  <Modal
    show={show}
    toggleModal={setShow}
    size="sm"
    title="Delete Message Template"
    className="removeTemplateModal"
    type="positive"
  >
    <div>
      <p>
        Are you sure you want to delete
        {' '}
        <strong>{title}</strong>
        {' '}
        message
        template? This action cannot be undone.
      </p>
      <ButtonRow>
        <Button type="outline" onClick={cancel}>
          Cancel
        </Button>
        <Button type="positive-marley" onClick={remove}>
          Delete Template
        </Button>
      </ButtonRow>
    </div>
  </Modal>
);

RemoveTemplateConfirmation.propTypes = {
  cancel: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default RemoveTemplateConfirmation;
