/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react'
import _ from 'lodash'
import Modal from '../../../elements/Modal/Modal'
import GarbageIcon from '../../../icons/GarbageIcon'
import { formatDate } from '../../../../helpers/datetime'
import UserPhoto2 from '../../../UserPhoto2/UserPhoto2'
import IconButton from '../../../elements/buttons/IconButton/IconButtonWithPermissions'
import Card from '../../../elements/Card/Card'

/**
 * List Scheduled Messages
 * @param   {boolean} show show modal
 * @param   {function} cancelScheduledMessage function for cancelling a scheduled message
 * @param   {function} toggleShow function triggered with show is modified
 * @param   {array} participants list of all participants (for displaying user info)
 * @param   {array} scheduledMessages list of scheduled messages
* */
const ListScheduledMessages = ({
  participants, scheduledMessages, show, toggleShow, cancelScheduledMessage,
}) => {
  const noNotesFound = (scheduledMessages || []).length === 0

  return (
    <Modal
      title="Scheduled Messages"
      show={show}
      toggleModal={toggleShow}
    >
      {noNotesFound && (
        <div data-test="marley-note-none" className="marley-note-none">
          No messages scheduled.
        </div>
      )}
      {!noNotesFound && (scheduledMessages || []).map((m) => {
        const author = (participants || []).find((p) => p.id === _.get(m, 'userId._id'))
        const authorName = _.get(author, 'name', 'user')
        return (
          // eslint-disable-next-line react/jsx-key
          <Card
            header={(
              <>
                <div className="marley-note-author">
                  <UserPhoto2
                    type="thumb"
                    id={_.get(author, 'id')}
                    imageUrl={_.get(author, 'imageUrl')}
                    name={authorName}
                    isCustomer={false}
                  />
                  <div className="marley-note-author-name">{authorName}</div>
                </div>
                <div className="marley-note-createdAt">{formatDate(Date.parse(new Date(m.eventTime)))}</div>
                <div
                  data-test="delete-note-button"
                  className="marley-note-icons"
                >
                  <IconButton onClick={() => cancelScheduledMessage(m.id)}>
                    <GarbageIcon />
                  </IconButton>
                </div>
              </>
            )}
          >
            {m.messageBody}
          </Card>
        )
      })}
    </Modal>
  )
}

export default ListScheduledMessages
