import React from 'react';
import _ from 'lodash';
import { Tab, Tabs } from '@himarley/unity';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LookerDashboard from './LookerDashboard/LookerDashboard';
import DataDownload from './DataDownload/DataDownload';
import { loadReports } from '../../actions/analytics';
import './AnalyticsRoot.less';

const AnalyticsRoot = ({ loadDataDownload, currentPermissions }) => {
  // eslint-disable-next-line react/jsx-key
  const tabList = [<Tab eventKey="myorg" title="My Org"><LookerDashboard dashboardName="my_org" /></Tab>];
  if (currentPermissions.includes('MY_TEAM_DASHBOARD')) tabList.push(<Tab eventKey="myteam" title="My Team"><LookerDashboard dashboardName="my_team" /></Tab>);
  if (currentPermissions.includes('SERVICE_CENTER_DASHBOARD')) tabList.push(<Tab eventKey="inbound" title="Inbound Dashboard"><LookerDashboard dashboardName="service_center" /></Tab>);
  if (currentPermissions.includes('DATA_DOWNLOAD')) tabList.push(<Tab eventKey="download" onClick={loadDataDownload} title="Data Download"><DataDownload /></Tab>);

  return (
    <Tabs
      defaultActiveKey="myorg"
      onSelect={(id) => {
        if (id === 'download') loadDataDownload();
      }}
    >
      {tabList}
    </Tabs>
  );
};

AnalyticsRoot.propTypes = {
  loadDataDownload: PropTypes.func.isRequired,
  currentPermissions: PropTypes.instanceOf(Array).isRequired,
};

export default connect(
  (state) => ({
    currentPermissions: _.get(state, 'auth.permissions', []),
  }),
  { loadDataDownload: loadReports },
)(AnalyticsRoot);
