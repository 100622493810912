import React from 'react';
import PropTypes from 'prop-types';
import Caret from '../../../../../images/icons/large-caret.svg';
import LoBIcon from '../../../Settings/CompanyHierarchy/LoBIcon';
import { typeToUiText } from './helpers';

import styles from './LinesOfBusiness.module.less';

const LinesOfBusinessRows = ({ lobs, setSelectedLob }) => lobs?.map((lob, index) => (
  <div className={styles.lobRow} key={lob?._id}>
    <div className={styles.nameType}>
      <LoBIcon name={lob?.subType} />
      <div>
        <h3>{lob?.displayName}</h3>
        <span>{typeToUiText(lob?.type)}</span>
      </div>
    </div>
    <div>
      <button
        className={styles.edit}
        onClick={() => setSelectedLob(lob)}
        data-testid={`lobButton${index}`}
        type="button"
      >
        <span>
          Edit
          {' '}
          <Caret />
        </span>
      </button>
    </div>
  </div>
));

LinesOfBusinessRows.propTypes = {
  lobs: PropTypes.shape([]).isRequired,
  setSelectedLob: PropTypes.func.isRequired,
};

export default LinesOfBusinessRows;
