import { ChatsIcon } from '@himarley/unity';
import React from 'react';
import PinV2 from '../../../../../images/icons/pinV2.svg';

const isUnreadStateRefactorEnabled = process.env.UNREAD_STATE_REFACTOR_ENABLED;

const menuItems = isUnreadStateRefactorEnabled ? [
  {
    id: 1,
    label: 'Pin Chat',
    subIcon: <PinV2 />,
  },
  {
    id: 2,
    label: 'Mark Unread',
    subIcon: <ChatsIcon width="16" height="16" />,
  },
] : [
  {
    id: 1,
    label: 'Pin Chat',
    subIcon: <PinV2 />,
  },
]

const menuItemsUnpinned = isUnreadStateRefactorEnabled ? [
  {
    id: 1,
    label: 'Unpin Chat',
    subIcon: <PinV2 />,
  },
  {
    id: 2,
    label: 'Mark Unread',
    subIcon: <ChatsIcon width="16" height="16" />,
  },
] : [
  {
    id: 1,
    label: 'Pin Chat',
    subIcon: <PinV2 />,
  },
];

const ROW_TYPES = {
  PINNED_HEADER: 'pinnedHeader',
  UNPINNED_HEADER: 'unpinnedHeader',
  END_OF_LIST: 'endOfList',
};

export { menuItems, menuItemsUnpinned, ROW_TYPES };
