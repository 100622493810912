/* eslint-disable import/no-named-as-default */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-filename-extension */
import React, {
  useState, useCallback, useMemo, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import get from 'lodash/get';
import set from 'lodash/set';
import { Button } from '@himarley/unity';

import Panel from '../../../Panel/Panel';
import { groupType } from '../../../../models/marleyTypes';
import withLazyLoad from '../../../withLazyLoad/withLazyLoad';
import withLoading from '../../../HigherOrderComponents/withLoading';
import CreateGroup from './CreateGroup/CreateGroup';
import SearchBar from '../../../SearchBar/SearchBar';
import { lazyLoad } from '../../../../actions/common';
import GroupRow from './GroupRow';
import LoadingIcon from '../../../../../images/icons/loading.svg';
import BinocularsIcon from '../../../../../images/icons/binoculars.svg';

import './GroupMgmt.less';

const Row = ({ index, style, data }) => {
  const { groups, toggleEditModal, setItemSize } = data;
  return (
    <div style={style}>
      <GroupRow
        key={groups[index].id}
        group={groups[index]}
        toggleModal={toggleEditModal}
        setItemSize={setItemSize}
        index={index}
      />
    </div>
  );
};

Row.propTypes = {
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  style: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({
    groups: PropTypes.shape([]),
    toggleEditModal: PropTypes.func,
    setItemSize: PropTypes.func,
  }).isRequired,
};

export const createOtherGroups = (groups) => {
  const groupNamesById = {};
  groups.forEach((g) => {
    set(groupNamesById, g.id, g.name);
  });
  return groups
    .map((g) => ({
      ...g,
      members: g?.members?.map((m) => {
        const thisMemberOtherGroups = get(m, 'groups', []).filter(
          (groupId) => groupId !== g.id && get(groupNamesById, groupId),
        );
        const otherGroups = thisMemberOtherGroups.map((groupId) => get(groupNamesById, groupId));
        return {
          ...m,
          otherGroups,
        };
      }),
    }))
    .filter((g) => !g.archived)
    .sort((a, b) => a?.name.localeCompare(b?.name));
};

const GroupMgmt = ({ groups, modifyQuery, isLoading }) => {
  const [show, setShow] = useState(false);
  const [editGroup, setEditGroup] = useState({});
  const listRef = useRef();

  const groupsWithGroups = useMemo(() => createOtherGroups(groups), [groups]);

  const onSearchTextChange = useCallback(
    (evt) => {
      const searchText = evt.target.value;
      if (!searchText || searchText.length > 2) {
        modifyQuery({ searchText });
      }
    },
    [modifyQuery],
  );

  const toggleEditModal = (g) => {
    setEditGroup(g);
    setShow(true);
  };

  const groupsMap = useMemo(
    () => Object.assign({}, ...groups.map((group) => ({ [group.id]: group }))),
    [groups],
  );

  const sizeMap = useRef({});
  const setItemSize = useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef?.current?.resetAfterIndex(index);
  }, []);
  const getSize = (index) => sizeMap.current[index] || 110;

  return (
    <Panel
      className="group-mgmt-page"
      header={(
        <div className="group-mgmt-header">
          <Button
            type="positive-marley"
            data-test="show-create-group-modal-button"
            data-testid="create-edit-group"
            onClick={() => {
              setEditGroup({});
              setShow(true);
            }}
          >
            Create Group +
          </Button>
          <SearchBar
            placeholder="Search groups or members"
            onChange={onSearchTextChange}
          />
        </div>
      )}
      title="Group Management"
    >
      {show ? (
        <CreateGroup
          show={show}
          toggleModal={setShow}
          group={editGroup}
          groupsMap={groupsMap}
        />
      ) : null}
      {isLoading || groupsWithGroups?.length === 0 ? (
        isLoading ? (
          <div className="loading-notification">
            <div className="loading none-found-icon">
              <LoadingIcon />
            </div>
            <div>One sec, we&apos;re loading your groups...</div>
          </div>
        ) : (
          <div className="none-found-notification">
            <div className="none-found-icon">
              <BinocularsIcon />
            </div>
            <div>We&apos;re looking, but no groups found.</div>
          </div>
        )
      ) : (
        <AutoSizer>
          {({ height, width }) => (
            <List
              ref={listRef}
              itemCount={groupsWithGroups?.length}
              height={height - 100}
              itemSize={getSize}
              width={width}
              itemData={{
                groups: groupsWithGroups,
                toggleEditModal,
                setItemSize,
              }}
            >
              {Row}
            </List>
          )}
        </AutoSizer>
      )}
    </Panel>
  );
};

GroupMgmt.propTypes = {
  groups: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  modifyQuery: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

GroupMgmt.defaultProps = {
  isLoading: false,
};

export const mapStateToProps = (state) => ({
  groups: get(state, 'groups.list', []),
});

const GroupMgmtWithLoading = withLoading(GroupMgmt, { type: groupType });
const GroupMgmtWithFetch = withLazyLoad(GroupMgmtWithLoading, {
  type: groupType,
  listLocation: 'groups',
});

export default connect(mapStateToProps, { lazyLoad })(GroupMgmtWithFetch);
