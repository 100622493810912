import _ from 'lodash'
import {
  NOTIFICATION_SHOW,
  NOTIFICATION_CLOSE,
  SNACKBAR_SHOW,
  SNACKBAR_CLOSE,
  CANCEL_SCHEDULED_MESSAGE,
  UPDATED_USER_REQUEST_ERROR,
  UPDATE_USER,
} from '../constants/actions'
import { formatError } from '../constants/endpoints'

export const initialState = { notification: null, snackBar: null, linkID: null }

const snackBarShowState = (action, state) => {
  const notification = _.get(action, 'payload.notification')
  const { text, isError } = notification
  return { ...state, snackBar: { text, isError } }
}

const notification = (state = initialState, action) => {
  switch (action.type) {
    // add addition actions to show snackbar here using this template
    case UPDATED_USER_REQUEST_ERROR: {
      return {
        ...state, snackBar: { text: action.payload, isError: true }, linkID: null,
      }
    }
    case formatError(UPDATE_USER): {
      return {
        ...state,
        snackBar: {
          text: 'Error occurred while updating the user',
          isError: true,
        },
        linkID: null,
      };
    }
    case CANCEL_SCHEDULED_MESSAGE: {
      return snackBarShowState(action, state)
    }
    case 'SNACKBAR_CLICKED': {
      return { ...state, linkID: action.payload }
    }
    case SNACKBAR_SHOW: {
      return { ...state, snackBar: action.payload, linkID: null }
    }
    case SNACKBAR_CLOSE: {
      return { ...state, snackBar: null }
    }
    case NOTIFICATION_SHOW: {
      return { notification: { ...action.payload, show: true } }
    }
    case NOTIFICATION_CLOSE: {
      return initialState
    }
    default:
      return state
  }
}

export default notification
