import _ from 'lodash'
import { commonForm, commonLoading } from './common'

const selector = (templates) => {
  const list = _.get(templates, 'list')

  return {
    ...commonLoading(templates),
    ...commonForm(templates),
    list,
    tokens: _.get(templates, 'keys', []).map((item) => item.key),
  }
}

export default selector
