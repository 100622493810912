import React from 'react';
import { Divider } from '@himarley/unity';
import PropTypes from 'prop-types';
import IntegrationRule from './IntegrationRule';

const IntegrationRulesList = ({ toggleRuleEnabled, ruleSettings, toggleModal }) => {
  const rules = Object.keys(ruleSettings);
  return rules.map((key, index) => {
    const {
      id, enabled, messageTemplate, rule,
    } = ruleSettings[key];

    return (
      <div key={id} data-testid={`integration-rules-list-item-${index}`}>
        <IntegrationRule
          ruleName={messageTemplate.displayName}
          messageTemplateText={messageTemplate.body}
          messageTemplateTokens={messageTemplate.tokens}
          toggleRuleEnabled={toggleRuleEnabled}
          toggleModal={toggleModal}
          isEnabled={enabled}
          templateId={messageTemplate.id}
          ruleId={id}
          rule={rule}
        />
        {index < rules.length - 1 && <Divider />}
      </div>
    );
  });
};

IntegrationRulesList.propTypes = {
  ruleSettings: PropTypes.shape({}).isRequired,
  toggleRuleEnabled: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default IntegrationRulesList;
