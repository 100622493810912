/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useMemo } from 'react';
import {
  connect, shallowEqual, useDispatch, useSelector,
} from 'react-redux';
import PropTypes from 'prop-types';
import { ActionMenu, IconButton } from '@himarley/unity';
import classnames from 'classnames';
import { useQueryClient } from '@tanstack/react-query';
import { markMessageUnread } from '@app/actions/job';
import { isApplicableUnreadMessage } from '@app/helpers/inbox.helpers';
import UserPhoto2 from '../../../../UserPhoto2/UserPhoto2';
import IconBar from '../../../../elements/IconBar/IconBar';

import {
  copyTextToClipboard,
  displayNegativeSentiment,
  useCheckPermissions,
} from '../../../../../helpers/common';
import { markChatAsUnread } from '../../../../../actions/profile';
import ClipboardIcon from '../../../../../../images/icons/copy.svg';
import EnvelopeIcon from '../../../../../../images/icons/envelope.svg';
import GreyAlertIcon from '../../../../../../images/icons/GreyAlertIcon.svg';
import MsgFail from '../../../../../../images/icons/x-circle.svg';
import AlertIcon from '../../../../../../images/icons/alert.svg';
import Phone from '../../../../../../images/icons/phone.svg';
import EyeIcon from '../../../../../../images/icons/eye.svg';
import Badge from '../../../../elements/Badge/Badge';
import './MessageFormatter.less';
import {
  NEEDS_ATTENTION,
  NEEDS_ACTION,
} from '../../../../../constants/permissions';
import { handleMessageNeedsActionResponse } from '../../../../../actions/chat';
import { showSnackbar } from '../../../../../actions/notification';
import { useDisplayFailedMessageStatus } from '../../../../../helpers/featureFlags';
import { TagItem } from '../../../../elements/TagItem/TagItem';
import TagItemTypes from '../../../../../constants/tag-item-types';

const MessageFormatter = ({
  children,
  timestamp,
  authorName,
  authorId,
  authUserId,
  markChatAsUnread: markChatAsUnreadProp,
  isAI,
  imageUrl,
  id,
  messageId,
  authorNameMessage,
  branding,
  viewedChats,
  isRecipient,
  displayName,
  companyName,
  hasIconBar,
  displayMessage,
  allMessages,
  activeJob,
  markChatUnreadError,
  showSnackbar: showSnackbarProp,
  messageNeedsAttention,
  relationship,
  messageStatus,
  messageCreatedAt,
  hasNeedsAction,
  eventType,
  isRedactionMessage,
  toggleTextRedactionPopup,
  isUnreadMessage,
  isInbound,
}) => {
  const dispatch = useDispatch();
  const {
    userId,
  } = useSelector(
    (state) => ({
      userId: state.auth?.user?._id,
    }),
    shallowEqual,
  );
  const orgName = displayName || companyName;
  const jobHasFailedMessage = activeJob && activeJob.showFailedMessage
    ? activeJob.showFailedMessage
    : false;
  // eslint-disable-next-line max-len
  const showFailedMessage = useDisplayFailedMessageStatus(
    messageStatus,
    messageCreatedAt,
    jobHasFailedMessage,
  );
  const showNeedsAttention = useCheckPermissions([NEEDS_ATTENTION])
    ? displayNegativeSentiment(
      id,
      messageNeedsAttention,
      isRecipient,
      activeJob,
      allMessages,
    )
    : '';
  const showMessageIndicator = showFailedMessage
    ? 'message-failed'
    : showNeedsAttention;
  const displayNeedsAction = useCheckPermissions([NEEDS_ACTION]) && hasNeedsAction;

  useEffect(() => {
    if (markChatUnreadError) {
      showSnackbarProp({
        text: 'Mark Chat Unread was not successful',
        isError: true,
      });
    }
  }, [markChatUnreadError, showSnackbarProp]);

  const onClickCopyText = () => {
    copyTextToClipboard(displayMessage);
    showSnackbarProp({ text: 'Text copied to clipboard' });
  };

  const isUnreadStateRefactorEnabled = process.env.UNREAD_STATE_REFACTOR_ENABLED;
  const queryClient = useQueryClient();

  const onMarkUnreadClick = () => {
    if (isUnreadStateRefactorEnabled) {
      dispatch(markMessageUnread(queryClient, activeJob?._id, id));
    }
    if (viewedChats.has(activeJob._id)) {
      // only make request if chat is viewed
      markChatAsUnreadProp(authUserId, activeJob._id);
    }
    if (!isUnreadStateRefactorEnabled) {
      showSnackbarProp({ text: 'Message marked unread' });
    }
  };

  const iconBarClasses = classnames({
    'message-icon-bar': hasIconBar,
    'unread-message': isUnreadMessage,
  });

  const showMarkUnreadOption = useMemo(() => isApplicableUnreadMessage(
    { isInbound, authorId },
    userId,
  ), [isInbound, authorId, userId]);

  return (
    <IconBar
      className={iconBarClasses}
      disabled={!hasIconBar}
      icons={(
        <ActionMenu
          testId="text-toolbar-action-menu-testid"
          className="toolbar-action-menu"
          actions={[
            <IconButton
              title="Copy Text"
              onClick={onClickCopyText}
            >
              <ClipboardIcon />
            </IconButton>,
            showMarkUnreadOption && (
            <IconButton id="mark-unread" title="Mark Unread" onClick={onMarkUnreadClick}>
              <EnvelopeIcon />
            </IconButton>
            ),
            isRedactionMessage && (
              <IconButton id="view-redacted-text-btn" title="View Redacted Text" onClick={toggleTextRedactionPopup}>
                <EyeIcon />
              </IconButton>
            ),
          ]}
        />
      )}
    >
      <div
        className={`chat-message2 chat-message2-inbound ${showMessageIndicator}`}
        id={messageId}
      >
        <UserPhoto2
          name={authorName}
          id={authorId}
          isAI={isAI}
          imageUrl={imageUrl}
          className="message-photo"
          isRecipient={isRecipient}
        />
        <div className="message-box">
          <div className="message-header">
            <span className="message-timestamp">{timestamp}</span>
            <div className="message-header-row">
              {eventType === 'voice' ? (
                <span className="phone-ivr-icon">
                  <Phone />
                </span>
              ) : null}
              <span className="author-name">
                {authorName}
                {relationship && ` (${relationship})`}
                {authorNameMessage && (
                  <span className="author-name-message">
                    {authorNameMessage}
                  </span>
                )}
              </span>
              {branding && (!isRecipient || isAI) ? (
                <span className="message-branding">
                  -
                  {branding}
                  {' '}
                  @
                  {orgName}
                </span>
              ) : null}

              <div className="coaching-badges-container">
                <span>
                  {displayNeedsAction && (
                    <TagItem
                      testId={`message-has-question-${id}`}
                      text={TagItemTypes.Message.NeedsAction.text}
                      handleDismissFunction={() => dispatch(
                        handleMessageNeedsActionResponse(
                          id,
                          activeJob.id,
                          'dismissed',
                        ),
                      )}
                    />
                  )}
                </span>
                <span>
                  {showNeedsAttention === 'new-needs-attention' ? (
                    <Badge
                      jobId={`message-${activeJob.id}`}
                      type={showNeedsAttention}
                      tooltip="There's a high likelihood the customer has concerns and requires your attention."
                      icon={<AlertIcon />}
                    />
                  ) : showNeedsAttention === 'old-needs-attention' ? (
                    <Badge
                      type={showNeedsAttention}
                      tooltip="There's a high likelihood the customer has concerns and requires your attention."
                      icon={<GreyAlertIcon />}
                    />
                  ) : null}
                </span>
              </div>
            </div>
          </div>
          <div className="message-body">{children}</div>
          {showFailedMessage ? (
            <div data-testid="messageFailed" className="message-failed-notice">
              <MsgFail />
              Message failed to send. Please try to send this message again
              later.
            </div>
          ) : null}
        </div>
      </div>
    </IconBar>
  );
};

MessageFormatter.propTypes = {
  children: PropTypes.node.isRequired,
  timestamp: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired,
  authorNameMessage: PropTypes.string,
  authorId: PropTypes.string.isRequired,
  isAI: PropTypes.bool.isRequired,
  imageUrl: PropTypes.string,
  messageId: PropTypes.string,
  isRecipient: PropTypes.bool,
  relationship: PropTypes.string,
  messageStatus: PropTypes.string,
  messageCreatedAt: PropTypes.string,
  eventType: PropTypes.string,
  isRedactionMessage: PropTypes.bool.isRequired,
  toggleTextRedactionPopup: PropTypes.func.isRequired,
};

MessageFormatter.defaultProps = {
  imageUrl: '',
  messageId: '',
  authorNameMessage: '',
  isRecipient: false,
  relationship: null,
  messageStatus: 'delivered',
  messageCreatedAt: null,
  eventType: '',
};

const mapStateToProps = (state) => ({
  branding: state?.jobs?.activeJob?.branding,
  companyName: state?.auth?.user?.organization?.name,
  displayName: state?.auth?.user?.organization?.displayName,
  activeJob: state?.jobs?.activeJob,
  allMessages: state?.jobs?.activeChat?.messages,
  authUserId: state?.auth?.user?._id,
  viewedChats: state?.jobs?.viewedChats,
  markChatUnreadError: state?.jobs?.markChatUnreadError,
});

const mapDispatchToProps = { markChatAsUnread, showSnackbar };

export default connect(mapStateToProps, mapDispatchToProps)(MessageFormatter);
