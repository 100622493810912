/* eslint-disable import/no-named-as-default */
import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, useParams } from 'react-router';
import { clearNotification, setCustomParamsViaCookie } from '../actions/ui';
import { showNotification, checkedBrowser } from '../actions/notification';

import MyInsights from './MyInsights/MyInsights';
import Claims from './Claims/Claims';
import Policies from './Policies/Policies';
import Cases from './Cases/Cases';
import Settings from './Settings/settings';
import UserMgmtRoot from './panels/UserMgmt/UserMgmtRoot';
import Chats from './Chats/chats';
import AnalyticsRoot from './Analytics/AnalyticsRoot';
import MassNotificationsRoot from './MassNotifications/MassNotificationsRoot';
import ErrorModal from './ErrorModal/error-modal';
import { getBrowserCheckCookie } from '../helpers/cookies';
import {
  ANALYTICS_TAB,
  CLAIMS_TAB,
  POLICY_TAB,
  USER_MANAGEMENT_TAB,
  CASES_TAB,
  MASSNOTIFICATIONS_TAB,
  SYSTEM_ADMIN,
} from '../constants/permissions';

// Don't remove this line it has all the styling for the dashboard modal
import './ModalPage/ModalPage.less';
import usePermissionVerify from './elements/hooks/usePermissionVerify';
import ProfileConfig from './ProfileConfig/ProfileConfig';

export const URL_PANEL_HASH = {
  home: { Panel: MyInsights, neededPermissions: [] },
  insights: { Panel: AnalyticsRoot, neededPermissions: [ANALYTICS_TAB] },
  analytics: { Panel: AnalyticsRoot, neededPermissions: [ANALYTICS_TAB] },
  cases: { Panel: Cases, neededPermissions: [CASES_TAB] },
  chats: { Panel: Chats, neededPermissions: [] },
  claims: { Panel: Claims, neededPermissions: [CLAIMS_TAB] },
  massnotification: {
    Panel: MassNotificationsRoot,
    neededPermissions: [MASSNOTIFICATIONS_TAB],
  },
  policies: { Panel: Policies, neededPermissions: [POLICY_TAB] },
  usermgmt: { Panel: UserMgmtRoot, neededPermissions: [USER_MANAGEMENT_TAB] },
  manage: { Panel: UserMgmtRoot, neededPermissions: [USER_MANAGEMENT_TAB] },
  settings: { Panel: Settings, neededPermissions: [SYSTEM_ADMIN] },
  profile: { Panel: ProfileConfig },
};

const processQueryString = (queryString) => {
  const query = {};
  if (queryString.length > 0) {
    queryString.split('&').forEach((s) => {
      const split = s.split('=');
      set(query, split[0], split[1]);
    });
  }
  return query;
};

const Dashboard = (props) => {
  const { panelId, subNavId } = useParams();

  const isChrome = !!window.chrome; // eslint-disable-line
  const {
    checkedBrowser,
    setCustomParamsViaCookie,
    showNotification,
    ui,
    clearNotification,
    currentPermissions,
  } = props;
  const { audioNotification, muted } = ui || {};
  const [showBrowserCheckModal, setShowBrowserCheckModal] = useState(false);

  useEffect(() => {
    setCustomParamsViaCookie();
    if (!getBrowserCheckCookie() && !isChrome) setShowBrowserCheckModal(true);
    if (!isChrome) {
      showNotification({
        text: 'This browser is not supported. Please use Chrome.',
        persistent: true,
        canClose: false,
      });
    }
  }, [isChrome, setCustomParamsViaCookie, showNotification]);

  useEffect(() => {
    if (audioNotification) {
      // eslint-disable-next-line @typescript-eslint/no-implied-eval
      setTimeout(clearNotification, 2000);
    }
  }, [audioNotification, clearNotification]);

  const { Panel, neededPermissions } = get(URL_PANEL_HASH, panelId || 'home');

  const { hasAccess } = usePermissionVerify({ neededPermissions });
  const hasPermissions = currentPermissions.length > 0;

  const query = processQueryString(get(props, 'location.search', '').slice(1));
  return (
    !!Panel && (
      <main id="main" className="dashboard">
        {audioNotification && !muted && (
          <audio autoPlay>
            <source src="/sounds/notify.wav" />
            <track kind="captions" />
          </audio>
        )}
        {hasPermissions && hasAccess && (
          <Panel
            data-test="main-component"
            tab={query.tab}
            subNavId={subNavId}
          />
        )}
        {hasPermissions && !hasAccess && <Redirect to="/" />}
        {showBrowserCheckModal && (
          <ErrorModal
            checkedBrowser={checkedBrowser}
            show
            title="Your Browser Is Not Supported"
          >
            Please use
            {' '}
            <a href="https://www.google.com/chrome/">Chrome</a>
            .
          </ErrorModal>
        )}
      </main>
    )
  );
};

Dashboard.propTypes = {
  checkedBrowser: PropTypes.func.isRequired,
  clearNotification: PropTypes.func.isRequired,
  setCustomParamsViaCookie: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  ui: PropTypes.instanceOf(Object).isRequired,
  currentPermissions: PropTypes.instanceOf(Object).isRequired,
};

export { Dashboard };

const mapStateToProps = (state) => ({
  ui: state.ui,
  currentPermissions: get(state, 'auth.permissions', []),
});

const mapDispatchToProps = {
  clearNotification,
  checkedBrowser,
  showNotification,
  setCustomParamsViaCookie,
};

export const DashboardWithoutAuth = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
