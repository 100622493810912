import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Panel from '../Panel/Panel'
import SearchBar from '../SearchBarWrap/SearchBarWrap'

import Button from '../elements/buttons/Button/Button'
// This file uses commonjs
// eslint-disable-next-line import/named
import { subscribeToTopics, unsubscribeFromTopics } from '../../actions/socket';
import DisabledBanner from '../elements/Banner/DisabledBanner/DisabledBanner';

import { DISABLE_CREATE_CASE } from '../../constants/permissions'

import {
  setEditItem, setViewItem, lazyLoad, getItems,
} from '../../actions/common'
import { sortItems, openCreateForm, closeForm } from '../../actions/ui'
import { getAllOperatorsTopics } from '../../helpers/liveUpdates'

import EnterModal from '../modal/EnterModal/EnterModal'
import { userType, claimType, caseType } from '../../models/marleyTypes'

import CloseClaimConfirmation from '../confirmationModals/CloseClaimConfirmation/CloseClaimConfirmation'
import SendEmailConfirmation from '../confirmationModals/SendEmailConfirmation/SendEmailConfirmation' // eslint-disable-line
import SendSurveyConfirmation from '../confirmationModals/SendSurveyConfirmation/SendSurveyConfirmation'
import ClaimsForm from '../EnterClaims/ClaimsForm';

import ClaimsTable from './ClaimTable/ClaimTable'

import withLoading from '../HigherOrderComponents/withLoading'
import withLazyLoad from '../withLazyLoad/withLazyLoad'

import selector from '../../selectors/case'
import './Claims.less'
import useTopicHandler from '../elements/hooks/useTopicHandler'

export const modalTitle = (type, mode) => {
  const title = type.title()
  if (mode === 'create') {
    return `Create ${title}`
  } if (mode === 'edit') {
    return `Edit ${title}`
  }
  return `${title} Information`
}

const Claims = (props) => {
  const {
    setEditItem,
    form,
    openCreateForm,
    subscribeToTopics,
    unsubscribeFromTopics,
    organization,
    permissions,
  } = props;

  const { isEditing, isCreating, isViewing } = form

  const showModal = isEditing || isCreating || isViewing
  const isCreationDisabled = permissions.find((v) => v === DISABLE_CREATE_CASE)

  const memoizedTopicHandler = useTopicHandler();
  useEffect(() => {
    subscribeToTopics(getAllOperatorsTopics(organization._id), memoizedTopicHandler)
    return () => {
      unsubscribeFromTopics(getAllOperatorsTopics(organization._id), memoizedTopicHandler)
    }
  }, []);

  return (
    <Panel
      className="claims-page"
      header={(
        <SearchBar
          id="claims"
          left={isCreationDisabled
            ? (
              <div>
                <DisabledBanner
                  bannerText="Creating a claim is unavailable for your organization. Please go to your claims management system to create a new claim."
                />
              </div>
            )
            : (
              <Button
                type="positive-marley"
                className="add-claims-button"
                onClick={() => {
                  openCreateForm(caseType)
                }}
              >
                Create Claim +
              </Button>
            )}
          label="claims"
          type={caseType}
        />
)}
      title="Claims"
    >
      <EnterModal
        types={[caseType, userType]}
        title={modalTitle(claimType, form.mode)}
        showModal={showModal}
        dialogClassName="claims-page-modal"
      >
        <ClaimsForm />
      </EnterModal>

      <SendEmailConfirmation id="job" />
      <CloseClaimConfirmation id="claim" />
      <SendSurveyConfirmation id="job" />

      <ClaimsTable
        id="claim"
        label="claims"
        type={claimType}
        setEditItem={setEditItem}
        isFormOpen={showModal}
        {...props}
      />

    </Panel>
  )
}

Claims.propTypes = {
  assignClaim: PropTypes.func,
  form: PropTypes.instanceOf(Object),
  openCreateForm: PropTypes.func.isRequired,
  setEditItem: PropTypes.func.isRequired,
  setViewItem: PropTypes.func.isRequired,
  sortItems: PropTypes.func.isRequired,
  loadingItem: PropTypes.bool,
}

Claims.defaultProps = {
  form: { mode: 'isCreating' },
}

export { Claims }

export const mapDispatchToProps = {
  getItems,
  closeForm,
  openCreateForm,
  setEditItem,
  setViewItem,
  sortItems,
  lazyLoad,
  subscribeToTopics,
  unsubscribeFromTopics,
}

export const mapStateToProps = (state) => selector(state)

const ClaimsWithLoading = withLoading(Claims, { type: caseType })
const LoadedClaims = withLazyLoad(ClaimsWithLoading,
  {
    type: caseType,
    listLocation: 'jobs',
    defaultSort: {
      column: 'createdAt',
    },
    query: {
      caseTypeId: ['36ad685a-bc4b-5213-c3e1-8c28e4549c13', '75dd685b-b04b-4113-83e1-8c23f1149c11'],
    },
  })

export default connect(mapStateToProps, mapDispatchToProps)(LoadedClaims)
