import React from 'react'
import './FileUploadIndicator.less'
import LoadingIcon from '../../../../icons/LoadingIcon'
import CloseIcon from '../../../../../../images/icons/close.svg'
import { Image } from 'react-bootstrap'

const FileUploadIndicator = ({ previewFile, onCancel }) => (
  <div className="file-upload-indicator chat-input-textarea" data-test="file-upload-indicator">
    {previewFile ? 
      <Image src={previewFile} /> : 
      <div className="generic-doc" />
    }
    <div className="loading-overlay">
      <div className="loading-veil" />
      <LoadingIcon />
    </div>
    <div className="cancel-overlay" onClick={onCancel}>
      <div className="cancel-veil" />
      <CloseIcon />
    </div>
  </div>
)


export default FileUploadIndicator

