import React from 'react';
import PropTypes from 'prop-types';
import './Badge.less';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

/**
 * Badge
 * @param   {string} type badge display type
 * @param   {component} icon badge icon
 * @param   {component} children badge text
* */
const Badge = ({
  icon, type, children, tooltip, jobId,
}) => {
  const Tip = (
    <Tooltip id="tooltip">{tooltip}</Tooltip>
  );
  const ActualBadge = (
    <div className={`marley-badge marley-badge-${type}`} data-testid={`badge-${jobId}-${type}`}>
      {icon && <div className={`marley-badge-icon ${children && 'marley-badge-icon-with-text'}`}>{icon}</div>}
      {children}
    </div>
  );

  return (
    tooltip ? (
      <OverlayTrigger placement="top" overlay={Tip}>
        {ActualBadge}
      </OverlayTrigger>
    ) : { ActualBadge }
  );
};

Badge.propTypes = {
  type: PropTypes.string.isRequired,
  icon: PropTypes.node,
  tooltip: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  jobId: PropTypes.string,
};

Badge.defaultProps = {
  icon: undefined,
  jobId: undefined,
};

export default Badge;
