import React from 'react'
import PropTypes from 'prop-types'
import './ChatParticipantAddButton.less';

const ChatParticipantAddButton = ({ onClick }) => (
  <button
    type="button"
    className="chat-participant-add-button"
    onClick={onClick}
  >
    <span className="chat-participant-add-circle">+</span>
    <span className="chat-participant-add-text">Add</span>
  </button>
)

ChatParticipantAddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default ChatParticipantAddButton
