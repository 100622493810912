/* eslint-disable jsx-a11y/media-has-caption */
import {
  Button,
  TrayContainer,
  PhoneCallIcon,
  VoicemailIcon,
} from '@himarley/unity';
import React, { useState } from 'react';

import { formatTime } from '@app/helpers/datetimeWithoutMoment';
import useFileCache from '@app/hooks/file-loader';

import { VoiceRecordingType } from './types';
import styles from './voice-message.module.scss';
import VoiceTranscription from './voice-transcription';

interface VoiceMessageProps {
  voiceRecordingType: VoiceRecordingType;
  fileUrl: string;
  author: string;
  createdAt: string;
  messageBody?: string;
  messageId: string;
}

const VoiceMessage = ({
  voiceRecordingType,
  fileUrl,
  author,
  createdAt,
  messageBody,
  messageId,
}: VoiceMessageProps): JSX.Element => {
  const [isTrayContainerOpen, setIsTrayContainerOpen] = useState(false);
  const { localFileUrl } = useFileCache(fileUrl, false) || {};
  const isVoicemail = voiceRecordingType === VoiceRecordingType.VOICEMAIL;
  const callEvent = isVoicemail ? 'Voicemail' : 'Call Ended';
  const timestamp = formatTime(createdAt);
  const callEventDetails = isVoicemail
    ? `${author} left a voicemail at ${timestamp}`
    : `${author}'s call was forwarded the primary operator at ${timestamp}`;
  const grey = '#9AA5B1';
  const phoneIcon = isVoicemail
    ? <VoicemailIcon width={32} height={32} color={grey} />
    : <PhoneCallIcon width={32} height={32} color={grey} />;
  const trayTitle = <span className={styles.trayTitle}>Call Transcript</span>;

  return (
    <div className={styles.voiceMessage}>
      <div className={styles.messageInfo}>
        <span className={styles.callIcon}>{phoneIcon}</span>
        <div className={styles.callInfo}>
          <div className={styles.callType}>{callEvent}</div>
          <div className={styles.callDetails}>{callEventDetails}</div>
        </div>
        <div className={styles.transcriptButton}>
          {messageBody
            && (
              <Button
                onClick={() => {
                  setIsTrayContainerOpen((currState) => !currState);
                }}
                type="outline"
              >
                View Transcript
              </Button>
            )}
        </div>
      </div>
      {localFileUrl
      && (
        <div className={styles.messageAudio}>
          <audio
            controls
            src={localFileUrl}
            data-testid={`audio-player-${messageId}`}
          >
            Your browser does not support the audio element.
          </audio>
        </div>
      )}
      {isTrayContainerOpen
        && (
          <TrayContainer
            onCancel={() => { setIsTrayContainerOpen(false); }}
            showButtonRow={false}
            title={trayTitle}
          >
            <VoiceTranscription
              author={author}
              createdAt={createdAt}
              messageBody={messageBody}
            />
          </TrayContainer>
        )}
    </div>
  );
};

export default VoiceMessage;
