import React from 'react'
import PropTypes from 'prop-types'
import { Button, Card } from '@himarley/unity'
import { Translate } from 'react-redux-i18n';
import Title from '../../../elements/text/Title/Title';
import Row from '../../../elements/Row/Row';
import OpenWindow from '../../../../../images/icons/openWindow.svg';

const handleOpenLink = (link) => {
  window.open(
    link,
    '_blank',
  );
};

const IntegrationRow = ({
  Logo, title, description, link, id,
}) => (
  <Row>
    <Card className="app-integration" data-testid={id}>
      <Row type="justify">
        <Logo />
        <div>
          <Title type="subheader"><b>{title}</b></Title>
          <p><Translate value={description} /></p>
        </div>
        <Button
          className="learnMoreBtn"
          onClick={() => handleOpenLink(link)}
          type="outline"
          Icon={OpenWindow}
        >
          Learn More
        </Button>
      </Row>
    </Card>
  </Row>
)

IntegrationRow.propTypes = {
  Logo: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

export default IntegrationRow
