import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { userType, claimType, caseType } from '@app/models/marleyTypes';

import SortTable from '@app/components/elements/table/SortTable/SortTable' // eslint-disable-line
import TableHeader from '@app/components/elements/table/SortTable/TableHeader/TableHeader';
import { formatDate } from '@app//helpers/datetime';
import { formatPhoneNumber } from '@app//helpers/format';
import GarbageIcon from '@app/components/icons/GarbageIcon';

import SurveyIcon from '@images/icons/survey.svg';
import PencilIcon from '@images/icons/pencil.svg';
import ChatIcon from '@images/icons/welcome-message.svg';
import FolderIcon from '@app/components/icons/FolderIcon';
import { jobPutRequest } from '@app/actions/job';
import ClaimRow from '../ClaimRow/ClaimRow';

const columns = [
  {
    sort: false, label: 'Claim Number', class: 'class-number-head', location: 'metadata.f11560d8-0c09-11ea-8d71-362b9e155667',
  },
  {
    sort: false, id: 'claimant', label: 'Name', location: 'customer.nameTableFormat', sortLocation: 'customer.lastName',
  },
  {
    sort: false,
    label: 'Date Created',
    location: 'createdAt',
    numericSort: true,
    sortType: 'date',
    format: ({ value }) => (<div>{formatDate(value, 'just-date')}</div>), // eslint-disable-line
  },
  { sort: false, label: 'Policy Number', location: 'metadata.e969484e-0c0a-11ea-8d71-362b9e155667' },
  {
    label: 'Phone Number',
    location: ['customer.phoneNumber'],
    format: ({ value }) => (<div>{formatPhoneNumber(value)}</div>), // eslint-disable-line
    numericSort: true,
  },
  {
    sort: false, id: 'assigned', class: 'table-col-assigned', label: 'Assigned', location: 'assignedOperator.nameTableFormat', sortLocation: 'assignedOperator.lastName',
  },
  { label: 'Actions', id: 'actions', class: 'table-col-actions' },
];

class ClaimTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns,
    };
  }

  shouldComponentUpdate() {
    const { isFormOpen } = this.props;
    return !isFormOpen;
  }

  render() {
    const {
      operators, setEditItem, shouldSort, commonSort, sort, jobPutRequest,
    } = this.props;
    let { claims } = this.props;
    const { columns } = this.state;

    const closedActions = [
      {
        id: 'reopen',
        label: 'Open Claim',
        action: (id) => {
          let claim = claims.find((c) => c.id === id);
          claim = { ...claim, isOpen: true };
          jobPutRequest(claim);
        },
        icon: <FolderIcon />,
      },
    ];

    if (shouldSort) {
      claims = commonSort({ sort, items: claims, columns });
    }

    const claimRows = columns && columns.map((col) => (
      <TableHeader tableId="claims" key={col.id || col.label} {...col} />
    ));

    const rows = claims && claims.map((claim) => {
      const verified = _.get(claim, 'customerId.verified', false);
      const actions = [
        {
          id: 'edit',
          label: 'Edit Claim',
          action: (id) => {
            const { setEditItem: propsSetEditItem, claims: propsClaims } = this.props;

            propsSetEditItem(caseType, id);
            const filteredClaim = propsClaims.find((p) => p.id === id) || {};
            if (filteredClaim.customer) setEditItem(userType, filteredClaim.customer);
            propsSetEditItem(claimType, id);
          },
          icon: (<PencilIcon />),
        }, {
          id: 'survey',
          label: 'Send Survey',
          confirmation: true,
          icon: (<SurveyIcon />),
          disabled: !verified,
        }, {
          id: 'resendwelcome',
          label: 'Resend Opt-In Text',
          confirmation: true,
          icon: (<ChatIcon />),
          disabled: verified,
        }, {
          id: 'close',
          label: 'Archive Claim',
          confirmation: true,
          icon: (<GarbageIcon />),
        },
      ];
      const openActions = actions;
      return (
        <ClaimRow
          key={claim.id}
          claim={claim}
          operators={operators}
          actions={claim.isOpen ? openActions : closedActions}
          columns={columns}
        />
      );
    });

    const { increaseDataSet, isLoading } = this.props;

    return (
      <SortTable
        id="claim"
        label="claims"
        columns={claimRows}
        rows={rows}
        type={claimType}
        increaseDataSet={increaseDataSet}
        isLoading={isLoading}
      />
    );
  }
}

ClaimTable.propTypes = {
  claims: PropTypes.arrayOf(Object).isRequired,
  operators: PropTypes.arrayOf(Object).isRequired,
  setEditItem: PropTypes.func.isRequired,
  isFormOpen: PropTypes.bool,
  filter: PropTypes.string,
  sort: PropTypes.shape({}),
  shouldSort: PropTypes.string,
  commonSort: PropTypes.func,
  jobPutRequest: PropTypes.func,
  increaseDataSet: PropTypes.func,
  isLoading: PropTypes.bool,
};

ClaimTable.defaultProps = {
  isFormOpen: false,
  filter: '',
  sort: {},
  shouldSort: '',
  commonSort: () => {},
  jobPutRequest: () => {},
  increaseDataSet: () => {},
  isLoading: false,
};

export const mapStateToProps = () => ({});

export default connect(mapStateToProps, { jobPutRequest })(ClaimTable);
