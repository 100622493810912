import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Checkbox } from '@himarley/unity';
import LineDropdown from '../../elements/dropdowns/LineDropdown/LineDropdown';
import List from '../../elements/List/List';
import {
  PREFIX_CLAIM_NUMBER,
  PREFIX_PHONE_NUMBER,
  PREFIX_USER_TITLE,
} from '../../../constants/permissions';
import { constructUserPrefixPreview } from './constants';
import usePermissionVerify from '../../elements/hooks/usePermissionVerify';
import Preview from './Preview';
import AddIcon from '../../../../images/icons/add.svg';

const titleDropdownList = [
  {
    id: 'hide',
    label: "Don't Show Title",
    placeholderLabel: 'hide',
    value: false,
  },
  {
    id: 'show',
    label: 'Job Title',
    placeholderLabel: 'show',
    value: true,
  },
];

const UserMessagePrefix = ({
  userMessagePrefix,
  isTitleEnabled,
  isCaseIdEnabled,
  isPhoneNumberEnabled,
  setIsTitleEnabled,
  setIsCaseIdEnabled,
  setIsPhoneNumberEnabled,
}) => {
  const { hasAccess: hasCaseIdNumberPrefix } = usePermissionVerify({
    neededPermissions: [PREFIX_CLAIM_NUMBER],
  });
  const { hasAccess: hasPhoneNumberPrefix } = usePermissionVerify({
    neededPermissions: [PREFIX_PHONE_NUMBER],
  });
  const { hasAccess: hasUserTitlePrefix } = usePermissionVerify({
    neededPermissions: [PREFIX_USER_TITLE],
  });
  const [titleItem, setTitleItem] = useState(titleDropdownList[0]);

  // set user message prefix default values - check api object first -
  // if not present default to prefix flags
  useEffect(() => {
    if (!isEmpty(userMessagePrefix)) {
      setIsTitleEnabled(userMessagePrefix.isTitleEnabled);
      setIsCaseIdEnabled(userMessagePrefix.isCaseIdEnabled);
      setIsPhoneNumberEnabled(userMessagePrefix.isPhoneNumberEnabled);
    } else {
      setIsTitleEnabled(hasUserTitlePrefix);
      setIsCaseIdEnabled(hasCaseIdNumberPrefix);
      setIsPhoneNumberEnabled(hasPhoneNumberPrefix);
    }
  }, [
    hasCaseIdNumberPrefix,
    hasPhoneNumberPrefix,
    hasUserTitlePrefix,
    userMessagePrefix,
    setIsCaseIdEnabled,
    setIsPhoneNumberEnabled,
    setIsTitleEnabled,
  ]);

  useEffect(() => {
    setTitleItem(isTitleEnabled ? titleDropdownList[1] : titleDropdownList[0]);
  }, [isTitleEnabled]);

  const titleToggle = (id) => {
    const item = titleDropdownList.find((i) => i.id === id) || {};
    setTitleItem(item);
    setIsTitleEnabled(item.value);
  };

  return (
    <section className="usersMessageWrap">
      <section>
        <header>
          <h2>User Message Prefix</h2>
        </header>
        <p>
          Displays as the beginning of outgoing SMS text messages sent from
          users.
        </p>
        <div className="interactiveFieldsWrap">
          <div>
            <div className="labelText">Name Format</div>
            <div>First Name, Last Initial</div>
            <div className="labelText optionalBrandRow">Optional Fields</div>
            <div className="checkboxWrap">
              <Checkbox
                label="Case ID"
                checked={isCaseIdEnabled}
                onChange={() => setIsCaseIdEnabled(!isCaseIdEnabled)}
              />
              <Checkbox
                label="Phone"
                checked={isPhoneNumberEnabled}
                onChange={() => setIsPhoneNumberEnabled(!isPhoneNumberEnabled)}
              />
            </div>
          </div>
          <div>
            <br />
            <br />
            <AddIcon />
            <br />
            <br />
            <br />
            <br />
            <AddIcon />
          </div>
          <div>
            <div className="labelText">Title/Role</div>
            <LineDropdown label={titleItem?.label}>
              <List
                items={titleDropdownList}
                onToggle={titleToggle}
                selectedIds={titleItem?.id}
              />
            </LineDropdown>
            <div className="labelText optionalBrandRow">Org/Brand Name</div>
            <div>$BrandName</div>
          </div>
        </div>
        <div className="labelText">
          NOTE: Message prefixes will cut off (and truncate...) at 100
          characters.
        </div>
      </section>
      <Preview
        firstTextName={constructUserPrefixPreview(
          isTitleEnabled,
          isCaseIdEnabled,
          isPhoneNumberEnabled
        )}
        firstTextMessage="Hi Charlotte, I’m Justin your Lovable Insurance claims adjuster.
        I’ll be helping resolve your auto claim."
        secondTextMessage="Hi Justin"
      />
    </section>
  );
};

UserMessagePrefix.propTypes = {
  userMessagePrefix: PropTypes.shape({}),
  isTitleEnabled: PropTypes.bool,
  isCaseIdEnabled: PropTypes.bool,
  isPhoneNumberEnabled: PropTypes.bool,
  setIsTitleEnabled: PropTypes.func,
  setIsCaseIdEnabled: PropTypes.func,
  setIsPhoneNumberEnabled: PropTypes.func,
};

UserMessagePrefix.defaultProps = {
  userMessagePrefix: {},
  isTitleEnabled: false,
  isCaseIdEnabled: false,
  isPhoneNumberEnabled: false,
  setIsTitleEnabled: () => {},
  setIsCaseIdEnabled: () => {},
  setIsPhoneNumberEnabled: () => {},
};

export default UserMessagePrefix;
