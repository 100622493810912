/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { putChatParticipant as putChatParticipantAction, setChatRemoveUserStatus } from '../../../../actions/participants';
import { getAllChatParticipants } from '../../../../actions/chat';
import { setRelationship, setRelationshipFromList } from './relationships';
import ViewParticipantsModal from './ViewParticipantsModal';
import RemoveParticipantModal from './RemoveParticipantModal';
import ParticipantModal from './ParticipantModal';
import { setUpdateUserStatus } from '../../../../actions/users';

export const selectChatParticipants = (state) => state?.participants?.chatParticipants;

const ViewParticipantsContainer = ({
  handleCloseViewParticipantsModal,
  handleOpenParticipantModal,
  handleCloseParticipantModal,
  currentView,
  setCurrentView,
  handleCloseRemoveParticipantModal,
  selectedParticipantId,
  setSelectedParticipantId,
  enableAddParticipants,
}) => {
  const dispatch = useDispatch();
  const chatId = useSelector((state) => state?.jobs?.activeChat?.id);
  const jobId = useSelector((state) => state?.jobs?.activeJob?._id);
  const primaryEndUserId = useSelector((state) => state?.jobs?.activeJob?.customer?.id);
  const primaryOperatorId = useSelector((state) => state?.jobs?.activeJob?.assignedOperator?.id);
  const loggedInOperatorId = useSelector((state) => state?.auth?.user?._id);

  const {
    activeParticipants: active,
    pendingParticipants: pending,
    inactiveParticipants: inactive,
  } = useSelector(selectChatParticipants);

  const findActiveParticipant = (p) => p.id === selectedParticipantId;
  const participant = active?.find(findActiveParticipant)
    || pending?.find(findActiveParticipant)
    || inactive?.find(findActiveParticipant);

  const handleClickRemoveParticipant = (id) => {
    setSelectedParticipantId(id);
    setCurrentView('REMOVE_PARTICIPANT_VIEW');
  };

  const handleClickEditParticipant = (id) => {
    setSelectedParticipantId(id);
    setCurrentView('EDIT_PARTICIPANT_VIEW');
  };

  const handleClickReinviteParticipant = (id) => {
    setSelectedParticipantId(id);
    dispatch(putChatParticipantAction({
      chatId,
      endUserId: id,
    }));
    handleCloseViewParticipantsModal();
  };

  useEffect(() => {
    dispatch(setUpdateUserStatus(''));
    dispatch(setChatRemoveUserStatus(null));
    if (chatId) {
      dispatch(getAllChatParticipants(chatId));
    }
  }, [currentView, chatId, dispatch]);

  if (currentView === 'REMOVE_PARTICIPANT_VIEW') {
    if (participant) {
      const participantWithRelationship = setRelationship(
        participant,
        jobId,
        primaryEndUserId,
        primaryOperatorId,
      );
      return (
        <RemoveParticipantModal
          participant={participantWithRelationship}
          onClose={handleCloseRemoveParticipantModal}
        />
      );
    }
  } else if (['ADD_PARTICIPANT_VIEW', 'ADD_PARTICIPANT_FROM_TOOLBAR_VIEW'].includes(currentView)) {
    return <ParticipantModal onClose={handleCloseParticipantModal} />;
  } else if (currentView === 'EDIT_PARTICIPANT_VIEW') {
    if (participant) {
      const participantWithRelationship = setRelationship(
        participant,
        jobId,
        primaryEndUserId,
        primaryOperatorId,
      );
      if (participant?.id === primaryEndUserId) {
        participantWithRelationship.isPrimaryEndUser = true;
      }
      return (
        <ParticipantModal
          onClose={handleCloseParticipantModal}
          initialParticipantData={participantWithRelationship}
        />
      );
    }
  } else if (currentView === 'PARTICIPANTS_VIEW') {
    const activeParticipants = setRelationshipFromList(
      active,
      jobId,
      primaryEndUserId,
      primaryOperatorId,
    );
    const pendingParticipants = setRelationshipFromList(pending, jobId);
    const inactiveParticipants = setRelationshipFromList(inactive, jobId);
    return (
      <ViewParticipantsModal
        activeParticipants={activeParticipants}
        pendingParticipants={pendingParticipants}
        inactiveParticipants={inactiveParticipants}
        loggedInOperatorId={loggedInOperatorId}
        primaryEndUserId={primaryEndUserId}
        onClickRemoveParticipant={handleClickRemoveParticipant}
        onClickEditParticipant={handleClickEditParticipant}
        onClickReinviteParticipant={handleClickReinviteParticipant}
        onClose={handleCloseViewParticipantsModal}
        onClickAddParticipant={handleOpenParticipantModal}
        enableAddParticipants={enableAddParticipants}
      />
    );
  }

  return null;
};

ViewParticipantsContainer.propTypes = {
  handleCloseViewParticipantsModal: PropTypes.func.isRequired,
  handleOpenParticipantModal: PropTypes.func.isRequired,
  handleCloseParticipantModal: PropTypes.func.isRequired,
  currentView: PropTypes.string,
  setCurrentView: PropTypes.func.isRequired,
  selectedParticipantId: PropTypes.string.isRequired,
  setSelectedParticipantId: PropTypes.func.isRequired,
  handleCloseRemoveParticipantModal: PropTypes.func.isRequired,
  enableAddParticipants: PropTypes.bool.isRequired,
};

ViewParticipantsContainer.defaultProps = {
  currentView: null,
};

export default ViewParticipantsContainer;
