/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import FileIcon from '../../../../images/icons/icon-file-generic.svg';
import ContactCard from './MediaContactCard';
import PDFIcon from '../../../../images/icons/icon-file-pdf.svg';
import { mediaItemPropTypes } from '../media-helpers';

const ICON_WIDTH_THRESHOLD = 400;

const embeddedItem = ({
  height, width, fileUrl,
}) => (
  <embed
    src={fileUrl}
    width={width}
    height={height}
  />
);

const MediaGeneric = ({ mediaItem, containerDimensions, fileUrl }) => {
  const { width, height } = containerDimensions;
  const { contentType } = mediaItem.mediaInfo;
  const style = {
    width,
    height,
  };
  switch (contentType.toLowerCase()) {
    case 'text/x-vcard':
      return (
        <ContactCard
          fileUrl={fileUrl}
          mediaItem={mediaItem}
          containerDimensions={containerDimensions}
        />
      );
    case 'application/pdf': {
      return (
        width > ICON_WIDTH_THRESHOLD
          ? embeddedItem({ height, width, fileUrl })
          : (
            <PDFIcon
              title="pdf-file"
              style={style}
            />
          )
      );
    }
    default:
      return (
        <FileIcon
          title="generic-file"
          style={style}
        />
      );
  }
};

MediaGeneric.propTypes = {
  mediaItem: mediaItemPropTypes.isRequired,
  fileUrl: PropTypes.string.isRequired,
  containerDimensions: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
  }).isRequired,
};

export default MediaGeneric;
