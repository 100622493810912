import React from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import _ from 'lodash'
import PropTypes from 'prop-types'
import TableRow from '../../elements/table/TableRow/TableRow'
import TableCell from '../../elements/table/TableCell/TableCell'
import AssignUserDropdown from '../../AssignDropdown/AssignUserDropdown'
import { jobPutRequest } from '../../../actions/job'
import { useCheckForCaseAccess, handleUserAssignment } from '../../../helpers/cases';

const PolicyRow = ({
  columns, item, actions,
}) => {
  const assignedOperator = _.get(item, 'operator');
  const hasAccessToCase = useCheckForCaseAccess(item)
  const dispatch = useDispatch()
  const { operators } = useSelector((state) => ({
    operators: _.get(state, 'operators.listById', []),
  }), shallowEqual)
  const assignUser = handleUserAssignment(item, jobPutRequest, dispatch)
  const modifiedItem = { ...item }
  let contractTerm;
  if (!_.get(modifiedItem, 'contractTerm', false)) {
    contractTerm = {
      effectiveDate: _.get(modifiedItem.metadata, '8ae3a30a-0011-4b2d-abf4-d248b6d47b89', ''),
      expirationDate: _.get(modifiedItem.metadata, '5935bd29-ad61-41b9-bfa3-1fbe8f99c88c', ''),
    }
    modifiedItem.contractTerm = contractTerm
  }

  return (
    <TableRow
      id="policy"
      status="open"
      itemId={modifiedItem && modifiedItem.id}
      actions={actions}
      columns={columns.slice(0, columns.length - 2)}
      item={modifiedItem}
      hasAccessToCase={hasAccessToCase}
    >
      <TableCell id="assigned">
        { hasAccessToCase
          ? (
            <AssignUserDropdown
              id="policies"
              defaultLabel="Unassigned"
              selectedId={_.get(modifiedItem, 'assignedOperator.id') || _.get(modifiedItem, 'primaryContactId') || 'unassigned'}
              handleSelect={(e) => assignUser(e)}
              options={_.unionBy(operators,
                [{ id: _.get(modifiedItem, 'assignedOperator.id'), label: _.get(modifiedItem, 'assignedOperator.name') }],
                'id')}
            />
          )
          : <span>{assignedOperator}</span>}
      </TableCell>
    </TableRow>
  )
}

PolicyRow.propTypes = {
  columns: PropTypes.arrayOf(Object).isRequired,
  item: PropTypes.object,
  actions: PropTypes.arrayOf(Object).isRequired,
}

PolicyRow.defaultProps = {
  item: {},
}

export default PolicyRow
