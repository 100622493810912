/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useCloseBroadcastChannel from './Hooks/useCloseBroadcastChannel';
import SnackBar from './elements/SnackBar/SnackBar';
import Header from './Header/Header';
// eslint-disable-next-line import/no-named-as-default
import LeftBar from './LeftBar/LeftBar';
import InactivityAlert from './InactivityAlert/InactivityAlert';
import ProfileConfig from './ProfileConfig/ProfileConfig';
import withLoadingScreen from './HigherOrderComponents/withLoadingScreen';
import { closeNotification, closeSnackbar } from '../actions/notification';
import { getFullOrganizationData } from '../actions/organization';

import withAuth from './HigherOrderComponents/withAuth';
import { usePrevious } from './Hooks/usePrevious';
import { useQueryClient } from '@tanstack/react-query';

export const App = ({
  children,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(closeNotification());
    dispatch(getFullOrganizationData());
  }, [
    dispatch,
  ]);
  useCloseBroadcastChannel();

  const {
    showInactivityAlert,
    showProfileConfig,
    snackBarArgs,
    identityProvider,
    isNewInbox,
  } = useSelector((state) => ({
    showInactivityAlert: state.ui?.showInactivityAlert,
    showProfileConfig: state.ui?.showProfileConfig,
    snackBarArgs: state?.notification.snackBar,
    identityProvider: state.auth?.user?.organization?.identityProvider || 'MARLEY',
    isNewInbox: state.profile?.properties?.isNewInbox,
  }));

  const previousNewInboxFlag = usePrevious(isNewInbox);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isNewInbox === previousNewInboxFlag) {
      return;
    }
    queryClient.invalidateQueries();
  }, [previousNewInboxFlag, isNewInbox, queryClient]);

  return (
    <div className="app-container">
      <Header />
      <div className="center-box">
        <LeftBar />
        {children}
      </div>

      {snackBarArgs && (
        <SnackBar
          onToggle={snackBarArgs?.onToggle || closeSnackbar}
          text={snackBarArgs?.text}
          linkText={snackBarArgs?.linkText}
          isError={snackBarArgs?.isError}
          persist={snackBarArgs?.persist}
          linkID={snackBarArgs?.linkID}
          title={snackBarArgs?.title}
          isHyperLink={snackBarArgs?.isHyperLink}
          downloadFile={snackBarArgs?.downloadFile}
          downloadFileName={snackBarArgs?.downloadFileName}
          jobId={snackBarArgs?.jobId}
          externalLink={snackBarArgs?.externalLink}
          externalLinkText={snackBarArgs?.externalLinkText}
          uncloseableDelay={snackBarArgs?.uncloseableDelay}
          showProgress={snackBarArgs?.showProgress}
        />
      )}

      {/* Overlays */}
      {showInactivityAlert && <InactivityAlert />}
      {showProfileConfig && <ProfileConfig identityProvider={identityProvider} />}
    </div>
  );
};

App.propTypes = {
  children: PropTypes.instanceOf(Object),
};

App.defaultProps = {
  children: undefined,
};

export default withAuth(withLoadingScreen(App));
