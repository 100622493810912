/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react'
import Menu from '../../../../elements/menus/Menu'
import List from '../../../../elements/List/List'
import { languageList } from '../../../../../helpers/languages'

const LanguageMenu = ({ selectLanguage, className }) => (
  <Menu className={className}>
    <div>Translate</div>
    <List items={languageList} onToggle={selectLanguage} maxDisplay={5} />
  </Menu>
);

export default LanguageMenu
