import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import CasesListRow from './CasesListRow';

const CasesList = (props) => {
  const {
    authId,
    rows,
    increaseDataSet,
    handleSelectCase,
    updatePinnedChats,
    activeJob,
    useCheckForCaseAccess,
    currentPermissions,
    hasPinnedJobs,
    lastPinnedJobIndex,
  } = props;
  const infiniteLoadRef = useRef();
  const listRef = useRef();

  // add empty row as first row if there's any pinned jobs
  if (hasPinnedJobs) {
    rows.unshift({});
  }

  // force update index of last pinned row
  useEffect(() => {
    if (hasPinnedJobs) {
      infiniteLoadRef?.current?._listRef?.resetAfterIndex(lastPinnedJobIndex - 1);
    }
  }, [hasPinnedJobs, lastPinnedJobIndex]);

  // force update height of first row
  useEffect(() => {
    infiniteLoadRef?.current?._listRef?.resetAfterIndex(0);
  }, [hasPinnedJobs]);

  const itemCount = rows?.length + 1;
  const isItemLoaded = (index) => index < rows.length;
  const loadMoreData = () => {
    increaseDataSet && increaseDataSet();
  };

  const sizeMap = useRef({});
  const setSize = useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef?.current?.resetAfterIndex(index);
  }, []);
  const getSize = index => {
    if (hasPinnedJobs) {
      if (index === 0) {
        return 23;
      }
      if (index === lastPinnedJobIndex) {
        return (sizeMap?.current[index] || 110) + 10;
      }
    }
    return sizeMap.current[index] || 110;
  };

  const renderRow = (props) => (<CasesListRow  {...props} setSize={setSize} />)

  return (
    <AutoSizer>
      {({ height, width }) => (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={loadMoreData}
          ref={infiniteLoadRef}
        >
          {({ onItemsRendered, ref }) => (
            <List
              itemCount={rows?.length}
              height={height}
              itemSize={getSize}
              width={width}
              itemData={{
                rows,
                authId,
                handleSelectCase,
                updatePinnedChats,
                activeJob,
                useCheckForCaseAccess,
                currentPermissions,
                hasPinnedJobs,
                infiniteLoadRef,
                lastPinnedJobIndex,
              }}
              useIsScrolling
              ref={listRef}
              onItemsRendered={onItemsRendered}
            >
              {renderRow}
            </List>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
};

CasesList.propTypes = {
  hasPinnedJobs: PropTypes.bool.isRequired,
  rows: PropTypes.instanceOf(Object).isRequired,
  authId: PropTypes.string.isRequired,
  increaseDataSet: PropTypes.func.isRequired,
  handleSelectCase: PropTypes.func.isRequired,
  updatePinnedChats: PropTypes.func.isRequired,
  activeJob: PropTypes.instanceOf(Object).isRequired,
  useCheckForCaseAccess: PropTypes.func.isRequired,
  currentPermissions: PropTypes.func.isRequired,
  lastPinnedJobIndex: PropTypes.string.isRequired,
};

export default CasesList;
