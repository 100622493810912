import React from 'react';
import { useSelector } from 'react-redux';
import ParticipantCircles from './ParticipantCircles';

const ParticipantCirclesContainer = () => {
  const chatId = useSelector((state) => state?.jobs?.activeChat?.id);
  const chats = useSelector((state) => state?.jobs?.chats);
  const participants = chats?.[chatId]?.participants || [];

  // We only render circles for the first seven participants.
  const participantsToDisplay = participants.slice(0, 7);

  return (
    <ParticipantCircles participants={participantsToDisplay} />
  );
};

export default ParticipantCirclesContainer;
