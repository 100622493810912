import React from 'react'
import { Tab, Tabs } from '@himarley/unity';
import MassNotifications from './MassNotifications'
import LookerDashboard from '../Analytics/LookerDashboard/LookerDashboard'
import PermissionVerifier from '../PermissionVerifier/PermissionVerifier'

import './MassNotificationsRoot.less'
import { MASSNOTIFICATIONS_TAB } from '../../constants/permissions';

const MassNotificationsRoot = ({ tab }) => (
  <PermissionVerifier neededPermissions={[MASSNOTIFICATIONS_TAB]} redirect>
    <Tabs defaultActiveKey={tab || 'notifications'}>
      <Tab eventKey="notifications" title="Notifications">
        <div style={{ height: '100%' }}>
          <MassNotifications />
        </div>
      </Tab>
      {process.env.FEATURE_FLAG_MASS_NOTE_ANALYTICS === true && (
        <Tab eventKey="dashboard" title="Dashboard">
          <LookerDashboard dashboardName="mass_notification" />
        </Tab>
      )}
    </Tabs>
  </PermissionVerifier>
);

export default MassNotificationsRoot
