import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../elements/Modal/Modal';
import ParticipantFormContainer from './ParticipantFormContainer';

const ParticipantModal = ({
  onClose,
  initialParticipantData,
}) => {
  const title = initialParticipantData ? 'Edit Participant' : 'Add Participant';
  return (
    <Modal show title={title} toggleModal={onClose}>
      <ParticipantFormContainer
        onClose={onClose}
        initialParticipantData={initialParticipantData}
      />
    </Modal>
  );
};

ParticipantModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  initialParticipantData: PropTypes.shape({
    phoneNumber: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    relationship: PropTypes.string,
  }),
};

ParticipantModal.defaultProps = {
  initialParticipantData: null,
};

export default ParticipantModal;
