import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import TableRow from '../../elements/table/TableRow/TableRow' // eslint-disable-line

const MassNotificationRow = ({
  data,
  key,
  columns,
  actionOptions,
}) => {
  const id = _.get(data, 'id', 'id')
  return (
    <TableRow
      className="massNotifRow"
      columns={columns && columns.slice(0, columns.length - 1)}
      item={data}
      actions={actionOptions}
      key={key}
      itemId={id}
    />
  )
}

MassNotificationRow.propTypes = {
  data: PropTypes.shape({}).isRequired,
  key: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf({}).isRequired,
  actionOptions: PropTypes.shape({}).isRequired,
}

export default MassNotificationRow
