import React from 'react';
import AutoIcon from '../../../../images/icons/auto.svg';
import PropertyIcon from '../../../../images/icons/property.svg';
import HomeIcon from '../../../../images/icons/home.svg';
import OtherIcon from '../../../../images/icons/other.svg';
import HealthIcon from '../../../../images/icons/health.svg';
import TruckIcon from '../../../../images/icons/truck.svg';
import ShieldIcon from '../../../../images/icons/shield.svg';
import CropIcon from '../../../../images/icons/crop.svg';
import LifeIcon from '../../../../images/icons/life.svg';

const LOB_TYPE_TO_ICON = {
  home: <HomeIcon />,
  other: <OtherIcon />,
  health: <HealthIcon />,
  truck: <TruckIcon />,
  property: <PropertyIcon />,
  auto: <AutoIcon />,
  'general-liability': <ShieldIcon />,
  'workers-comp': <HealthIcon />,
  crop: <CropIcon />,
  life: <LifeIcon />,
};

const LoBIcon = ({ name }) => LOB_TYPE_TO_ICON[name?.toLowerCase()] || LOB_TYPE_TO_ICON.other;

export default LoBIcon;
