/* eslint-disable react/jsx-filename-extension */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './TranscribedAudioMessage.module.less';
import MarleyAi from '../../../../../../images/icons/ai-stars.svg';

const TranscribedAudioMessage = (props) => {
  const [showFullText, setShowFullText] = useState(false);
  const { messageBody, recordingUrl } = props;
  const truncateLength = 300;

  const shouldShowViewMore = messageBody.length > truncateLength && !showFullText;
  const threeDots = shouldShowViewMore ? '...' : '';
  const truncatedBody = showFullText ? messageBody : `${messageBody.slice(0, truncateLength)?.trim()}${threeDots} `;

  return (
    <div className={styles['transcribed-audio-chat-message']} data-testid="transcribed-audio-chat-message">
      <audio
        className={styles['audio-player']}
        src={recordingUrl}
        controls
      >
        Listen
      </audio>
      <div className={styles['audio-transcription']}>Audio Transcription</div>
      <div>
        {truncatedBody}
        {shouldShowViewMore
          ? (
            <button
              type="button"
              onClick={() => setShowFullText(true)}
              className={styles['view-more-button']}
            >
              view more
            </button>
          )
          : null}
      </div>
      <div className={styles['ai-container']}>
        <span><MarleyAi className={styles['ai-stars']} /></span>
        <span className={styles['ai-message']}>Generated by Marley AI</span>
      </div>
    </div>
  );
};

TranscribedAudioMessage.propTypes = {
  messageBody: PropTypes.string.isRequired,
  recordingUrl: PropTypes.string.isRequired,
};

export default TranscribedAudioMessage;
