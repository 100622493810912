import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { FileDrop } from 'react-file-drop';
import { Button } from '@himarley/unity';

import { queueMessage, sendFile } from '../../../../actions/job';
import ChatLog from '../ChatLog/chat-log';
import ChatInput from '../ChatInput/ChatInput';
import './ChatModule.less';
import MultiPartyToolbar from '../MultiPartyToolbar/MultiPartyToolbar';
import { isMultiPartyEnabled as shouldShowMultiPartyToolbar } from '../../../../helpers/featureFlags';

class ChatModule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewFile: null,
    };
  }

  handleFileSelectChange = (e) => {
    const { uploadFileIsInProgress } = this.props;
    if (uploadFileIsInProgress) {
      return;
    }
    if (e.preventDefault) e.preventDefault();
    let file;
    if (e.target.files) {
      [file] = e.target.files;

      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { sendFile } = this.props;
      sendFile(file);
      this.setState({
        previewFile:
          file.type.indexOf('image') > -1 && URL.createObjectURL(file),
      });
      e.target.value = '';
    }
  };

  render() {
    const {
      loadingTemplate,
      loadedTemplateError,
      disabled,
      chat,
      isInChatBotMode,
      queueMessage: queueMessageFn,
      userPermissions,
      isLocked,
      updateEndUser,
      isUnlockingUser,
      chatRef,
    } = this.props;

    const { previewFile } = this.state;

    const showMultiPartyToolbar = shouldShowMultiPartyToolbar(userPermissions);

    return (
      <div className="chat-module">
        <FileDrop
          onDrop={(files) => this.handleFileSelectChange({ target: { files } })}
        >
          <div className="file-drop-target-inner">Upload to Chat</div>
        </FileDrop>
        <ChatLog
          chat={chat}
          isInChatBotMode={isInChatBotMode}
          chatRef={chatRef}
        />
        {(loadingTemplate || loadedTemplateError) && (
          <div className="typing-notification-block">
            <div className="typing-notification">
              {loadingTemplate ? '...loading Template' : 'template load error'}
            </div>
          </div>
        )}
        {isLocked ? (
          <div className="lockedChatInputWrap">
            <p>
              This customer is locked out of texting into this case due to
              exceeding the maximum number of attempted messages without first
              opting-in.
            </p>
            <Button
              onClick={updateEndUser}
              type="outline"
              disabled={isUnlockingUser}
            >
              {isUnlockingUser ? 'Unlocking...' : 'Unlock Customer'}
            </Button>
          </div>
        ) : (
          <>
            {
              showMultiPartyToolbar
              && <MultiPartyToolbar enableAddParticipants={!disabled} />
            }
            <ChatInput
              handleFileSelectChange={this.handleFileSelectChange}
              previewFile={previewFile}
              chat={chat}
              queueMessage={queueMessageFn}
              disabled={disabled}
              loadingTemplate={loadingTemplate}
              loadedTemplateError={loadedTemplateError}
            />
          </>
        )}
      </div>
    );
  }
}

ChatModule.propTypes = {
  disabled: PropTypes.bool,
  isInChatBotMode: PropTypes.bool,
  loadingTemplate: PropTypes.bool,
  loadedTemplateError: PropTypes.bool,
  queueMessage: PropTypes.func,
  chat: PropTypes.shape({}).isRequired,
  sendFile: PropTypes.func.isRequired,
  userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  isLocked: PropTypes.bool,
  updateEndUser: PropTypes.func,
  isUnlockingUser: PropTypes.bool,
  uploadFileIsInProgress: PropTypes.bool,
  chatRef: PropTypes.instanceOf(Object).isRequired,
};

ChatModule.defaultProps = {
  disabled: false,
  isInChatBotMode: false,
  loadingTemplate: false,
  loadedTemplateError: false,
  queueMessage: undefined,
  isLocked: false,
  updateEndUser: () => {},
  isUnlockingUser: false,
  uploadFileIsInProgress: false,
};

const mapStateToProps = (state, ownProps) => ({
  loadingTemplate: get(state.templates, 'loadingTemplate', false),
  loadedTemplateError: get(state.templates, 'loadedTemplateError', false),
  chat: get(state.jobs, ownProps.chatLocation, {}),
  jobLanguagePref: get(state.jobs, 'activeJob.languagePreference'),
  userPermissions: get(state.auth, 'permissions', []),
  uploadFileIsInProgress: state?.jobs?.uploadFileIsInProgress,
});

export default connect(mapStateToProps, {
  sendFile,
  queueMessage,
})(ChatModule);
