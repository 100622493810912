/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
// TODO: address the liting  https://marley.atlassian.net/browse/HMB-5243
import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Tabs, Tab } from '@himarley/unity';
import _ from 'lodash';
import { withRouter, useParams, useHistory } from 'react-router-dom';

import selector from './selector';
import { lazyLoad } from '../../../actions/common';
import { updatePinnedChats } from '../../../actions/profile';
import {
  setActiveJob, pinJob, unpinJob, setCaseSelector, clearActiveJob, getFilteredOperatorIds,
} from '../../../actions/job';
import { filterItems } from '../../../actions/ui';
import SearchBar from '../../SearchBar/SearchBar';
import withLazyLoad from '../../withLazyLoad/withLazyLoad';
import withLoading from '../../HigherOrderComponents/withLoading';
import { caseType } from '../../../models/marleyTypes';
import CustomSelector from './ChatInboxSelector/CustomSelector';
import {
  // eslint-disable-next-line import/named
  subscribeToOrg,
  // eslint-disable-next-line import/named
  subscribeToTopics,
  // eslint-disable-next-line import/named
  unsubscribeFromTopics,
  // eslint-disable-next-line import/named
  subscribeToTopic,
} from '../../../actions/socket';
import { useCheckForCaseAccess, sortCases } from '../../../helpers/cases';
import {
  getAllOperatorsTopics,
  getCurrentChatTopic,
  getOperatorTopics,
  manageOperatorSubscriptions,
  filteredUnsubTopics,
} from '../../../helpers/liveUpdates';
import useTopicHandler from '../../elements/hooks/useTopicHandler';
import './ChatsList.less';
import usePersistentProperties from '../../elements/hooks/usePersistentProperties';
import { usePrevious } from '../../Hooks/usePrevious';
import WithDot from '../../WithDot/WithDot';
import LineDropdown from '../../elements/dropdowns/LineDropdown/LineDropdown';
import List from '../../elements/List/List';
import CasesList from './CasesList';
import { getCaseIdsFromLook } from '../../../actions/analytics';
import { LOOK_CASE_IDS_HASH } from '../../../reducers/jobs';
import usePermissionVerify from '../../elements/hooks/usePermissionVerify';
import { useCheckPermissions } from '../../../helpers/common';
import { FOCUSED_OPERATOR, NEEDS_ACTION, NEEDS_ATTENTION } from '../../../constants/permissions';
import useBroadcastChannel from '../../Hooks/useBroadcastChannel';

const NUMBER_DUMMY_ITEMS = 5;
const DEFAULT = 'DEFAULT';
const LoadingCaseItems = () => {
  const items = [];
  for (let i = 0; i < NUMBER_DUMMY_ITEMS; i += 1) {
    items.push(<div key={`case-item-dummmy-${i}`} data-jest="case-item-dummy" className="case-item case-item-dummy wave" />);
  }

  return <div className="case-item-dummy-list">{items}</div>;
};

const sortOrder = [
  {
    id: DEFAULT,
    label: 'Default',
    select: () => {},
  },
  {
    id: 'DESC',
    label: 'Newest First',
    select: () => {},
  },
  {
    id: 'ASC',
    label: 'Oldest First',
    select: () => {},
  },
];

const cleanUpProperties = (propertiesParam) => ({
  tabSelection: propertiesParam?.tabSelection,
  operators: propertiesParam?.operators,
  groups: propertiesParam?.groups,
});

const ChatTabsDefault = ({ handleSetCaseSelector, tabsList }) => (
  <ChatTabs
    handleSetCaseSelector={handleSetCaseSelector}
    tabsList={tabsList}
  />
);

const ChatTabs = ({ activeKey, handleSetCaseSelector, tabsList }) => (
  <Tabs
    defaultActiveKey={activeKey}
    justify
    type="content"
    onSelect={handleSetCaseSelector}
  >
    {tabsList}
  </Tabs>
);

const ChatsList = ({
  updatePinnedChats,
  pinnedJobs,
  activeJob,
  isLoading,
  authId,
  modifyQuery,
  presetFilterIds,
  filterItems,
  clearActiveJob,
  setActiveJob,
  auth,
  jobs,
  increaseDataSet,
  orgId,
  match,
  subscribeToTopics,
  unsubscribeFromTopics,
  subscribeToTopic,
  getFilteredOperatorIds,
  filteredOperatorIds,
}) => {
  const { filterId } = useParams();
  const dispatch = useDispatch();

  const [orderedJobs, setOrderedJobs] = useState([]);
  const [orderedPinnedJobs, setOrderedPinnedJobs] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const memoizedTopicHandler = useTopicHandler();
  const currentPermissions = _.get(auth, 'permissions');

  useBroadcastChannel();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const hasCoaching = useCheckPermissions([NEEDS_ACTION]) || useCheckPermissions([NEEDS_ATTENTION]);
  const isFocusedOperator = useCheckPermissions([FOCUSED_OPERATOR]);

  const defaultFilters = [{
    id: 'openCases',
    label: 'All Open Cases',
    select: () => {},
  },
  {
    id: 'onlyActive',
    label: 'All Active Cases',
    select: () => {},
  },
  {
    id: 'unansweredOptIns',
    label: 'Unanswered Opt Ins',
  },
  {
    id: 'waitingOnOptIn',
    label: 'Waiting on Opt In',
  },
  {
    id: 'mentions',
    label: 'Mentions',
  }];

  const presetFilters = hasCoaching ? [...defaultFilters, {
    id: 'responseRecommended',
    label: 'Response Recommended',
  }] : defaultFilters;

  const history = useHistory();

  // Clear query params when toggling to old inbox (ChatsList component is the old inbox)
  useEffect(() => {
    history.push({ search: null });
  }, [
    history,
  ]);

  // redirect/set active job to match url parameter
  useEffect(() => {
    clearActiveJob();
    if (match?.params?.subNavId) {
      setActiveJob(match?.params?.subNavId, isFocusedOperator);
    }
  }, [clearActiveJob, match, setActiveJob, isFocusedOperator]);

  const prevActiveJob = usePrevious(activeJob);
  useEffect(() => {
    // subscribe to active chat's topic
    if (activeJob.primaryChatId) {
      subscribeToTopics(
        getCurrentChatTopic(orgId, activeJob.primaryChatId),
        memoizedTopicHandler,
      );
    }
    // unsubscribe from previous active chat
    if (
      prevActiveJob?.primaryChatId
      && prevActiveJob?.primaryChatId !== activeJob?.primaryChatId
    ) {
      unsubscribeFromTopics(
        getCurrentChatTopic(orgId, prevActiveJob.primaryChatId),
      );
    }
  }, [
    activeJob,
    orgId,
    memoizedTopicHandler,
    subscribeToTopics,
    prevActiveJob,
    unsubscribeFromTopics,
    subscribeToTopic,
  ]);

  const presetIds = presetFilters.map((pf) => pf.id);

  useEffect(() => {
    if (authId && dispatch) {
      Object.keys(LOOK_CASE_IDS_HASH).forEach((lookId) => {
        dispatch(getCaseIdsFromLook(lookId));
      });
    }
  }, [authId, dispatch]);

  const mySortSelect = useCallback(
    (id, trigger = false) => {
      const newProperties = {
        ...properties,
        presetSort: id,
      };
      if (presetIds.includes(id)) newProperties.tabSelection = 'assigned';
      if (trigger) {
        newProperties.timestamp = Date.now();
      }
      setProperties(newProperties);
    },
    // eslint-disable-next-line no-use-before-define
    [presetIds, properties, setProperties],
  );

  const getPresetCaseIds = useCallback(
    (filterId) => {
      const filter = presetFilterIds.find((p) => p.id === filterId);
      return _.get(filter, 'caseIds', null);
    },
    [presetFilterIds],
  );

  const loadJobs = useCallback(
    ({
      operators,
      groups,
      tabSelection,
      presetFilter,
      presetSort,
      secondaryOperatorIds,
    }) => {
      const operatorIds = (operators || []).map((o) => o.id);
      const groupIds = (groups || []).length > 0 ? (groups || []).map((o) => o.id) : null;
      let query = {
        isOpen: true,
        view: 'oldinbox',
      };
      let onlyActive = null;
      let caseIds = null;
      let mentions = null;
      let sortOrder = presetSort === DEFAULT ? 'DESC' : presetSort;
      if (tabSelection) {
        if (tabSelection === 'assigned') {
          switch (presetFilter) {
            case 'openCases': {
              onlyActive = false;
              break;
            }
            case 'onlyActive': {
              onlyActive = true;
              break;
            }
            case 'mentions': {
              mentions = true;
              break;
            }
            default: {
              caseIds = getPresetCaseIds(presetFilter);
              break;
            }
          }
          query = {
            ...query,
            operatorIds: [authId],
            caseIds,
            groupIds,
            onlyActive: onlyActive || null, // has to be null or will hide active
            mentions,
            secondaryOperatorIds: [authId],
          };
        } else {
          sortOrder = 'DESC';
          query = {
            ...query,
            operatorIds:
              operatorIds && operatorIds.length > 0 ? operatorIds : null,
            groupIds,
            onlyActive: null,
            mentions: null,
            caseIds,
            secondaryOperatorIds,
          };
        }
        modifyQuery(query, sortOrder);
      }
    },
    [authId, modifyQuery, getPresetCaseIds],
  );
  const permissionVerification = usePermissionVerify({
    restrictedPersonas: [FOCUSED_OPERATOR],
  });
  const hasAllInboxAccess = permissionVerification?.hasAccess;
  const disallowedProperties = {};
  if (!hasAllInboxAccess) disallowedProperties.tabSelection = 'custom';

  const {
    properties, initialSet, setProperties, modifyProperty,
  } = usePersistentProperties({
    location: 'filters.inbox',
    disallowedProperties,
    defaults: {
      tabSelection: 'assigned',
      operators: null,
      onlyActive: false,
      mentions: null,
    },
    onPropertyChange: loadJobs,
  });

  const { tabSelection, presetFilter, presetSort } = properties || {};

  const setFilterId = (presetFilter) => {
    modifyProperty({ presetFilter, tabSelection: 'assigned' });
  };

  useEffect(() => {
    if (filterId !== properties?.presetFilter) setFilterId(filterId);
  }, [filterId]);

  useEffect(() => {
    if (initialSet && !hasLoaded) {
      loadJobs(properties || {});
      setHasLoaded(true);
    }
  }, [hasLoaded, initialSet, loadJobs, properties]);

  useEffect(() => {
    if (properties) {
      const { operators, groups } = properties;
      const selectedOperatorIds = (operators || []).length > 0
        ? (operators || []).map((o) => o.id)
        : null;
      const selectedGroupIds = (groups || []).length > 0
        ? (groups || []).map((o) => o.id)
        : null;

      getFilteredOperatorIds({ selectedOperatorIds, selectedGroupIds });
    }
  }, [properties]);

  const prevJobs = usePrevious(jobs);
  const prevPinnedJobs = usePrevious(pinnedJobs);
  const prevProperties = usePrevious(properties);

  const sortJobs = useCallback(
    (jobs) => sortCases(jobs, authId, currentPermissions, presetSort),
    [authId, presetSort, currentPermissions],
  );
  useEffect(() => {
    sortJobs(jobs);
  }, [authId, jobs]);

  useEffect(
    () => () => {
      const filteredOperators = properties?.operators
        ? getOperatorTopics(orgId, properties?.operators)
        : [];
      const unsubTopics = filteredUnsubTopics(
        [...filteredOperators, ...getAllOperatorsTopics(orgId)],
        authId,
      );
      unsubscribeFromTopics(unsubTopics);
    },
    [filteredUnsubTopics, memoizedTopicHandler, orgId,
      properties?.operators, unsubscribeFromTopics],
  );

  useEffect(() => {
    const newProps = cleanUpProperties(properties); // only use attributes needed
    const oldProps = cleanUpProperties(prevProperties);
    if (newProps.tabSelection !== 'assigned') {
      // on the All Chats tab
      const { subscribeList, unsubscribeList } = manageOperatorSubscriptions(
        orgId,
        oldProps,
        newProps,
      );
      const unsubTopics = filteredUnsubTopics(unsubscribeList, authId);
      unsubscribeFromTopics(unsubTopics);
      subscribeToTopics(subscribeList, memoizedTopicHandler);
    } else if (
      !_.isEqual(newProps, oldProps)
      && newProps.tabSelection === 'assigned'
    ) {
      // on the My Inbox tab
      unsubscribeFromTopics(getAllOperatorsTopics(orgId));
    }
  }, [
    authId,
    memoizedTopicHandler,
    orgId,
    prevProperties,
    properties,
    subscribeToTopics,
    unsubscribeFromTopics,
  ]);

  const maintainFilters = () => {
    if (
      !_.isEqual(prevPinnedJobs, pinnedJobs)
      || !_.isEqual(prevJobs, jobs)
      || !_.isEqual(properties, prevProperties)
    ) {
      const { operators, presetFilter } = properties || {};

      const operatorIds = tabSelection === 'assigned'
        ? [authId]
        : (operators || []).map((o) => o.id);

      let filteredJobs = jobs || [];

      const unassignedJobs = _.includes(operatorIds, 'unassigned')
        ? filteredJobs.filter((j) => j.operatorIds.length === 0)
        : []; // unassigned
      filteredJobs = _.union(filteredJobs, unassignedJobs);

      if (presetFilter === 'onlyActive' && tabSelection === 'assigned') {
        filteredJobs = filteredJobs.filter((j) => _.get(j, 'customer.activated', false));
      } // activated users

      if (presetFilter === 'waitingOnOptIn' && tabSelection === 'assigned') {
        filteredJobs = filteredJobs.filter((j) => !_.get(j, 'customer.activated', false));
      }

      if (presetFilter === 'unansweredOptIns' && tabSelection === 'assigned') {
        filteredJobs = filteredJobs.filter((j) => _.get(j, 'customer.activated', false) && _.get(j, 'isActive', false));
      }

      if (presetFilter === 'responseRecommended' && tabSelection === 'assigned') {
        filteredJobs = filteredJobs.filter((j) => (_.get(j, 'needsAttentionMessageCount', 0) > 0) || (_.get(j, 'needsAction.hasQuestionMessageCount', 0) > 0));
      }

      if (tabSelection === 'assigned') {
        filteredJobs = filteredJobs.filter((j) => {
          if ((operatorIds || []).length > 0) {
            return _.intersection(j.operatorIds, operatorIds).length > 0
              || _.intersection(j.secondaryOperatorIds, operatorIds).length > 0;
          }
          return true;
        });
      } else {
        filteredJobs = filteredJobs.filter((j) => {
          if ((filteredOperatorIds || []).length > 0) {
            return _.intersection(j.operatorIds, filteredOperatorIds).length > 0
              || _.intersection(j.secondaryOperatorIds, filteredOperatorIds).length > 0
              || _.intersection([j.operatorId], filteredOperatorIds).length > 0;
          }
          return true;
        });
      }

      const modifiedPinnedJobs = pinnedJobs?.map((obj) => ({
        ...obj,
        isPinned: true,
      }));

      setOrderedPinnedJobs(modifiedPinnedJobs);
      setOrderedJobs(filteredJobs);
    }
  };
  useEffect(maintainFilters, [jobs, pinnedJobs, properties]);

  const onSearchTextChange = (e) => {
    const searchMessages = e.target.value;
    if (modifyQuery) modifyQuery({ searchMessages });
    if (filterItems) filterItems('chats', searchMessages);
  };

  const handleSelectCase = (item) => () => {
    if (_.get(activeJob, 'id') !== item.id) {
      // No need to select if already selected, bad UI
      // remove active job from subscribed topics
      clearActiveJob();
      setActiveJob(item.id, isFocusedOperator);
      if (window && window.history) {
        // eslint-disable-line
        window.history.replaceState(null, null, `/chats/${item.id}`); // eslint-disable-line
      }
    }
  };

  const handleSetCaseSelector = (ts) => {
    modifyProperty({ tabSelection: ts });
  };
  const searchTerm = '';

  const showDot = !!(_.first(properties?.operators) || {}).id;
  const showAssignedDot = !!properties?.presetFilter && properties?.presetFilter !== 'openCases';
  const handleChangeCustomQuery = () => {};

  const tabsList = [(
    // eslint-disable-next-line react/jsx-key
    <Tab
      eventKey="assigned"
      title="My Inbox"
    >
      <div className="chatsList_filter_bar">
        <div className="chatsList__search">
          <SearchBar
            placeholder="Search my cases"
            value={searchTerm}
            onChange={onSearchTextChange}
          />
        </div>
        <div className="chatsList__inactive">
          <WithDot
            type="blue"
            show={properties?.presetSort !== DEFAULT}
            position="left"
          >
            <LineDropdown label="sort by" multiSelect position="left">
              <List
                items={sortOrder}
                onToggle={mySortSelect}
                selectedIds={[presetSort]}
              />
            </LineDropdown>
          </WithDot>
          <WithDot type="blue" show={showAssignedDot} position="left">
            <LineDropdown label="filter by" multiSelect>
              <List
                items={presetFilters}
                onToggle={(presetFilter) => modifyProperty({ presetFilter })}
                selectedIds={[presetFilter]}
              />
            </LineDropdown>
          </WithDot>
        </div>
      </div>
    </Tab>
  )];
  if (!isFocusedOperator) {
    tabsList.push(
      <Tab eventKey="custom" title="All">
        <div className="chatsList_filter_bar">
          <div className="chatsList__search">
            <SearchBar
              placeholder="Search all cases"
              value={searchTerm}
              onChange={onSearchTextChange}
            />
          </div>
          <div className="setup-all-inbox">
            <WithDot type="blue" show={showDot} position="left">
              <LineDropdown label="filter by" multiSelect dataTestId="all-tab-inbox-filter">
                <CustomSelector
                  properties={properties}
                  setProperties={setProperties}
                  onChange={handleChangeCustomQuery}
                />
              </LineDropdown>
            </WithDot>
          </div>
        </div>
      </Tab>,
    );
  }

  return (
    <div className="chatsList">
      {tabSelection ? <ChatTabs handleSetCaseSelector={handleSetCaseSelector} tabsList={tabsList} activeKey={tabSelection} /> : <ChatTabsDefault handleSetCaseSelector={handleSetCaseSelector} tabsList={tabsList} />}
      <div className="chatsList__body">
        {isLoading
          ? (
            <LoadingCaseItems />
          )
          : (
            <div
              data-jest="jobs-list"
              className="chatsList__wrapper"
              style={{ height: '100vh' }}
            >
              <CasesList
                rows={[...orderedPinnedJobs, ...orderedJobs]}
                lastPinnedJobIndex={orderedPinnedJobs.length}
                hasPinnedJobs={orderedPinnedJobs.length > 0}
                authId={authId}
                handleSelectCase={handleSelectCase}
                updatePinnedChats={updatePinnedChats}
                activeJob={activeJob}
                useCheckForCaseAccess={useCheckForCaseAccess}
                currentPermissions={currentPermissions}
                increaseDataSet={increaseDataSet}
              />
            </div>
          )}
      </div>
    </div>
  );
};

ChatsList.propTypes = {
  jobs: PropTypes.arrayOf(Object),
  pinnedJobs: PropTypes.arrayOf(Object),
  activeJob: PropTypes.instanceOf(Object),
  setActiveJob: PropTypes.func.isRequired,
  clearActiveJob: PropTypes.func.isRequired,
  pinJob: PropTypes.func.isRequired,
  unpinJob: PropTypes.func.isRequired,
  setCaseSelector: PropTypes.func.isRequired,
  caseSelectorId: PropTypes.string.isRequired,
  subscribeToTopics: PropTypes.func,
  unsubscribeFromTopics: PropTypes.func,
  subscribeToTopic: PropTypes.func,
  getFilteredOperatorIds: PropTypes.func,
  filteredOperatorIds: PropTypes.instanceOf(Array),
};

ChatsList.defaultProps = {
  activeJob: {},
  pinnedJobs: [],
  jobs: [],
  subscribeToTopics: () => {},
  unsubscribeFromTopics: () => {},
  subscribeToTopic: () => {},
  getFilteredOperatorIds: () => {},
  filteredOperatorIds: [],
};

const mapStateToProps = (state) => selector(state);
const mapDispatchToProps = {
  lazyLoad,
  filterItems,
  pinJob,
  setActiveJob,
  clearActiveJob,
  setCaseSelector,
  unpinJob,
  updatePinnedChats,
  getFilteredOperatorIds,
  subscribeToOrg,
  subscribeToTopics,
  unsubscribeFromTopics,
  subscribeToTopic,
};

export { ChatsList };

const ChatsWithLoading = withLoading(ChatsList, { type: caseType });
const LazyChatsList = withLazyLoad(
  ChatsWithLoading,
  {
    type: caseType,
    disableInitialFetch: true,
    listLocation: 'jobs',
  },
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LazyChatsList));
