import _ from 'lodash';
import {
  INSERT_TEMPLATE,
  LOAD_KEYS,
  CLEAR_LOADED_TEMPLATE,
  CREATE_NEW_TEMPLATE,
  EDIT_ONE_TEMPLATE,
  CANCEL_EDIT_TEMPLATE,
  REQUEST_EDIT_TEMPLATE_ERROR,
  REQUEST_ADD_TEMPLATE_ERROR,
  POPULATE_TEMPLATE,
} from '../constants/actions';

import * as commonReducer from './common';
import { formatSuccess, formatInit, formatError } from '../constants/endpoints';

export const initialState = {
  ...commonReducer.initialState,
  keys: [],
  loadedTemplate: false,
  loadingTemplate: false,
  loadedTemplateError: false,
  templateIndex: 0,
  showTemplateSelector: false,
  loadedTemplateText: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case formatInit(INSERT_TEMPLATE): {
      return { ...state, loadedTemplateError: false, loadingTemplate: true };
    }
    case formatSuccess(INSERT_TEMPLATE): {
      return { ...state, loadingTemplate: false };
    }
    case formatError(INSERT_TEMPLATE): {
      return {
        ...state, loadingTemplate: false, loadedTemplateError: true, loadedTemplate: '',
      };
    }
    case INSERT_TEMPLATE: {
      const loadedTemplate = _.get(JSON.parse(action.payload), 'parsedResult', '');
      let changes = { loadingTemplate: false };
      if (loadedTemplate) {
        changes = { loadedTemplate };
      }
      return { ...state, ...changes };
    }
    case POPULATE_TEMPLATE: {
      return { ...state, loadedTemplateText: action.payload.text };
    }
    case LOAD_KEYS: {
      const keys = action.payload;
      let changes = {};
      if (keys) {
        changes = {
          keys,
        };
      }
      return { ...state, ...changes };
    }
    case CLEAR_LOADED_TEMPLATE: {
      return { ...state, loadedTemplate: false, loadedTemplateText: '' };
    }
    case CREATE_NEW_TEMPLATE: {
      return { ...state, editObject: { new: true } };
    }
    case EDIT_ONE_TEMPLATE: {
      return { ...state, editObject: action.payload, httpRequestError: false };
    }
    case CANCEL_EDIT_TEMPLATE: {
      return { ...state, editObject: {}, httpRequestError: false };
    }
    case REQUEST_EDIT_TEMPLATE_ERROR: {
      return { ...state, httpRequestError: true };
    }
    case REQUEST_ADD_TEMPLATE_ERROR: {
      return { ...state, httpRequestError: true };
    }
    case 'LOAD_PINNED_TEMPLATES': {
      const pinnedTemplates = action.payload;
      return { ...state, pinnedTemplates };
    }
    case 'UPDATE_PINNED_TEMPLATES': {
      const { isPinned, templateId } = action.payload;
      const pinnedTemplates = isPinned ? [...state.pinnedTemplates, templateId] : _.filter(state.pinnedTemplates, (pinnedId) => pinnedId !== templateId);
      return { ...state, pinnedTemplates };
    }
    default:
      return state;
  }
};

export default commonReducer.reducer('TEMPLATE', reducer);
