import React from 'react'
import _ from 'lodash'
import PropType from 'prop-types'
import { useDispatch } from 'react-redux'
import TableRow from '../../elements/table/TableRow/TableRow' // eslint-disable-line
import TableCell from '../../elements/table/TableCell/TableCell'
import AssignUserDropdown from '../../AssignDropdown/AssignUserDropdown'
import { useCheckForCaseAccess, handleUserAssignment } from '../../../helpers/cases';
import { jobPutRequest } from '../../../actions/job'

const ClaimRow = ({
  claim, operators, actions, columns,
}) => {
  const operator = _.get(claim, 'assignedOperator.name');
  const dispatch = useDispatch()
  const hasAccessToCase = useCheckForCaseAccess(claim)
  const assignUser = handleUserAssignment(claim, jobPutRequest, dispatch)

  return (
    <TableRow
      id="claim"
      className={!claim.isOpen ? 'archived-claim' : ''}
      key={claim.id}
      itemId={claim.id}
      item={claim}
      status="open"
      actions={actions}
      columns={columns.slice(0, columns.length - 2)}
      hasAccessToCase={hasAccessToCase}
    >
      <TableCell id="assigned">
        {!claim.isOpen
          ? (
            <button disabled className="hymarley-button button-unassigned">
              {_.get(claim, 'assignedOperator.name', 'Unassigned')}
              <span className="caret" />
            </button>
          )
          : hasAccessToCase
            ? (
              <AssignUserDropdown
                id="claims"
                defaultLabel="Unassigned"
                rowId={`assign-${claim.id}`}
                selectedId={_.get(claim, 'assignedOperator.id') || _.get(claim, 'primaryContactId') || 'unassigned'}
                handleSelect={(e) => assignUser(e)}
                options={_.unionBy(operators,
                  [{ id: _.get(claim, 'assignedOperator.id'), label: _.get(claim, 'assignedOperator.name') }],
                  'id').filter((i) => i.id)}
              />
            )
            : <span>{operator}</span>}
      </TableCell>
    </TableRow>
  )
}

ClaimRow.propTypes = {
  claim: PropType.instanceOf(Object).isRequired,
  operators: PropType.instanceOf(Object).isRequired,
  actions: PropType.instanceOf(Object).isRequired,
  assignClaim: PropType.instanceOf(Object).isRequired,
  columns: PropType.instanceOf(Object).isRequired,
}

export default ClaimRow
