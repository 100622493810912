/* eslint-disable max-classes-per-file */
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { SUCCESS } from '../constants/general';

const fallbackCopyTextToClipboard = (text) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Fallback: Unable to copy:', err);
  }

  document.body.removeChild(textArea);
};

export const copyTextToClipboard = (text) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(() => {}, (err) => {
    console.error('Async: Could not copy text: ', err);
  });
};

export const pluralize = (item) => item && `${item.slice(0, item.length - 1)}${item.slice(-1) === 'y' ? 'ies' : `${item.slice(-1)}s`}`;

export class FiboInterval {
  constructor({ max }) {
    this.max = max;
    this.generateSequence();
  }

  generateSequence() {
    this.sequence = [];
    let prev = 0;
    let cur = 1;

    while (cur <= this.max) {
      this.sequence.push(cur);
      const current = cur;
      cur += prev;
      prev = current;
    }
  }

  getCurrentValue() {
    return this.sequence[0];
  }

  next() {
    return this.sequence.length === 1
      ? this.getCurrentValue()
      : this.sequence.shift();
  }

  reset() {
    this.generateSequence();
    return this.sequence.shift();
  }
}

export const stub = () => {};

export const titleCase = (text) => text
  .split(' ')
  .map((word) => `${word[0].toUpperCase()}${word.slice(1)}`)
  .join(' ');

export const capitalizeEachWord = (text = '') => text
  .trim()
  .toLowerCase()
  .split(' ')
  .filter(Boolean)
  .map((val) => val.charAt(0).toUpperCase() + val.substring(1))
  .join(' ');

export const ignoreSpecialCharacters = (text) => text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

/**
 * Modify requiredData with any paths in the redux state which are toggles to blocking the loading of the app.
 * The loading screen will display for ~10 seconds and error out if one of the values are false, preventing rendering
 * of the app.
 * @param {*} state
 */
export const checkInitDone = (state) => {
  const requiredData = [
    'auth.retrievedUserInfo',
    'socket.liveUpdateStrategySelected',
    'profile.retrievedProfileProperties',
  ];
  for (const currPath of requiredData) {
    const itemStatus = _.get(state, currPath);
    if (itemStatus !== SUCCESS) {
      return itemStatus;
    }
  }

  return SUCCESS;
};

/**
* Decides if a message should show the negative sentiment badge in the UI.
* @param {string} id - the current message id.
* @param {boolean} messageNeedsAttention - is true if the message passed the needs attention check.
* @param {boolean} isRecipient - tells us if the current message is an enduser/customer or not.
* @param {object} activeJob - used to identify who the enduser/customer is on the activeJob.
* @param {Array} allMessages - all messages in the activeChat
* @returns Boolean used to show or hide the message sentiment ui badge
*/
export const displayNegativeSentiment = (id, messageNeedsAttention, isRecipient, activeJob, allMessages) => {
  if (messageNeedsAttention && isRecipient) {
    const messageIndex = allMessages.findIndex((m) => m.id === id);
    // loop through all messages that come after the current message to check if the end user has received a reply from any operator
    for (let i = messageIndex + 1; i < allMessages.length; i++) {
      const activeJobCustomerId = activeJob?.customer?.id || activeJob?.customerId?._id;
      const currentMessageAuthorId = _.get(allMessages[i], 'author.id');
      // if the message is not from a customer or if it is not an AI message then we know it is an operator
      if (activeJobCustomerId !== currentMessageAuthorId && !allMessages[i].isAI) {
        return 'old-needs-attention';
      }
    }
    // if we reach this point in the code then we have not received a reply from an operator and therefore should display the negative sentiment
    return 'new-needs-attention';
  }
  return '';
};
/**
* Simple custom hook that compares an array or permissions with the permissions in redux
* @param {Array} neededPermissions - all needed permissions
* @returns Boolean of true when all needed permissions exist within redux store and false if not
*/
export const useCheckPermissions = (neededPermissions) => {
  const currentPermissions = useSelector((state) => _.get(state, 'auth.permissions', []));
  return _.intersection(neededPermissions, currentPermissions).length === neededPermissions.length;
};

export const isPasswordlessAuthSession = (authorizedUser) => {
  const passwordlessIdentityProviders = ['FEDERATED'];
  const userIdentityProvider = authorizedUser.user.organization.identityProvider;
  return passwordlessIdentityProviders.includes(userIdentityProvider);
};

/**
* Searches currentPermissions array to discover if it contains anything from the substrings array
* @param {Array} currentPermissions - all current permissions
* @param {Array} substrings - all valid substrings
* @returns Boolean of true when any substrings are found in the currentPermissions, false otherwise
*/
export const checkPermissionsContains = (currentPermissions, substrings) => {
  const booleans = substrings.map(
    (substring) => currentPermissions.some((p) => p.includes(substring)),
  );
  return booleans.includes(true);
};
/**
* Checks whether user is marley admin
* @param {Object} user - user to check
* @returns Boolean of true when first name is Marley and last name is Admin
*/
export const isMarleyAdmin = (user) => user?.firstName === 'Marley' && user?.lastName === 'Admin';

export const listToMap = (list) => list.reduce((acc, item) => {
  acc[item] = true;
  return acc;
}, {});

export const sortOperatorsByLabel = (array) => {
  array.sort((a, b) => {
    const labelA = a.label.toLowerCase();
    const labelB = b.label.toLowerCase();

    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });

  return array;
};

export const getShortenedEmail = (email) => {
  if (!email) return '';
  return email.slice(0, 30);
};

export const getObjectId = (obj = {}) => obj?.id || obj?._id;

export const isElementVisibleInContainer = (ele, container) => {
  const rect = ele?.getBoundingClientRect();
  const containerRect = container?.getBoundingClientRect();
  return (
    rect.top >= containerRect.top
        && rect.left >= containerRect.left
        && rect.bottom <= containerRect.bottom
        && rect.right <= containerRect.right
  );
};
