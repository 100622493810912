/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IconButton } from '@himarley/unity';
import {
  clearEditObject,
  handleFormInputChange,
  addFormError,
  clearAddFormErrors,
} from '../../../../../actions/common';
import { checkUser, clearMobileLookup } from '../../../../../actions/users';
import { UserFormValidation } from '../../../../../helpers/formValidation/userForm/userFormValidation';
import { languageList } from '../../../../../helpers/languages';
import { userType } from '../../../../../models/marleyTypes';
import FormInputField from '../../../../FormInput/FormInputField';
import SelectDropdown from '../../../../AssignDropdown/SelectDropdown/SelectDropdown';
import PhoneNumberSearchSelect from '../../../../SearchSelect/PhoneNumberSearchSelect';
import SwitchIcon from '../../../../../../images/icons/switch.svg';
import usePermissionCheck from '../../../../HigherOrderComponents/usePermissionCheck';
import { PHONE_TYPES } from '../../../../../constants/phoneTypes';

import './UserForm.less';
import secureRequest from '../../../../../helpers/secure-request';

/* For use with the phone lookup population feature */
const formatUsername = (user) => {
  const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  };

  // phoneUser comes in variety of formats, with comma it's lastname first
  if (!user) return null;
  let first;
  let last;
  const splitNameComma = user.split(',');
  const splitNameSpace = user.split(' ');

  if (splitNameComma.length > 1) {
    first = capitalize(splitNameComma[1]);
    last = capitalize(splitNameComma[0]);
  } else if (splitNameSpace.length > 1) {
    first = capitalize(splitNameSpace[0]);
    last = capitalize(splitNameSpace[1]);
  } else {
    first = user;
  }

  return {
    first,
    last,
    fullName: `${first} ${last}`,
  };
};

/* For use with the phone lookup population feature */
const UserNameClick = ({ onClick, fullName }) => {
  const tooltip = <Tooltip id="tooltip">Click to populate name fields</Tooltip>;

  return (
    <OverlayTrigger placement="bottom" overlay={tooltip}>
      <a
        onClick={onClick}
        onKeyDown={onClick}
        className="phone-lookup-populate"
        data-test="phone-lookup-populate"
        role="button"
        tabIndex="0"
      >
        {fullName}
      </a>
    </OverlayTrigger>
  );
};

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSwitch: false,
      phoneNumber: props.isEdit
        ? UserFormValidation.formatPhoneNumber(props.editObject.phoneNumber)
        : '',
      phoneNumberMetadata: '',
      phoneNumberIsValid: true,
      phoneNumberErrorMessage: 'Please enter a valid mobile number.',

      firstName: props.isEdit ? props.editObject.firstName : '',
      firstNameIsValid: true,
      firstNameErrorMessage: 'You must enter a first name',

      lastName: props.isEdit ? props.editObject.lastName : '',
      lastNameIsValid: true,
      lastNameErrorMessage: 'You must enter a last name',

      email: props.isEdit ? props.editObject.email : '',
      emailIsValid: true,
      emailErrorMessage: 'You must enter a valid email',

      languagePreference: props.isEdit
        ? props.editObject.languagePreference
        : 'en',
      languagePreferenceOptions: languageList,
      isLandlinePhoneNumber: false,
      isMobilePhoneNumber: false,
    };
  }

  componentDidMount() {
    this.props.clearMobileLookup();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const editObjectLen = Object.keys(nextProps.editObject).length;
    const {
      firstName, lastName, email, languagePreference,
    } = this.state;
    const isNew = (location) => {
      if (location === 'phoneNumber') {
        return (
          UserFormValidation.cleanPhone(_.get(this.state, location))
          !== _.get(nextProps.editObject, location)
        );
      }
      return (
        _.get(this.state, location) !== _.get(nextProps.editObject, location)
      );
    };

    if (
      (editObjectLen > 1 && isNew('phoneNumber'))
      || isNew('firstName')
      || isNew('lastName')
      || isNew('email')
      || isNew('languagePreference')
    ) {
      this.updateStateFields(nextProps.editObject);
    }

    if (
      editObjectLen < 2
      && (firstName || lastName || email || languagePreference)
    ) {
      this.setState({
        firstName: '',
        lastName: '',
        email: '',
        languagePreference: '',
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isEdit } = this.props;
    const currentPhone = this.state.phoneNumber;
    const previousPhone = prevState.phoneNumber;

    if (currentPhone) {
      if (previousPhone.length === 14 && currentPhone.length < 14 && !isEdit) {
        this.props.clearMobileLookup();
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ phoneNumberMetadata: '' });
      }
    }
  }

  componentWillUnmount() {
    this.props.clearAddFormErrors(userType.reducerCase());
    this.props.clearEditObject({ id: 'users', except: [] });
  }

  updateStateFields = (editObject) => {
    const fieldsToUpdate = Object.keys(editObject);

    fieldsToUpdate.forEach((field) => {
      if (field === 'phoneNumber') {
        this.setState({
          [field]: UserFormValidation.formatPhoneNumber(editObject[field]),
        });
      } else {
        this.setState({ [field]: editObject[field] });
      }
    });
  };

  switchNames = (e) => {
    if (e?.key === 'Tab') {
      return;
    }
    const firstChange = {
      target: {
        name: 'firstName',
        value: this.state.lastName,
      },
    };

    const lastChange = {
      target: {
        name: 'lastName',
        value: this.state.firstName,
      },
    };
    this.handleChange(firstChange);
    this.handleChange(lastChange);
  };

  handleChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    const { textContent } = e.target;
    const cleanedPhone = UserFormValidation.cleanPhone(value);
    const phoneNumberIsComplete = cleanedPhone.length === 10;
    const savedPos = e.target.selectionStart;
    this.props.toggleDisableParentFormSubmit(false);

    if (name === 'phoneNumber') {
      const formattedPhoneNumber = UserFormValidation.formatPhoneNumber(value);
      this.props.handleFormInputChange({
        value: formattedPhoneNumber,
        inputId: name,
        tableId: userType.id(),
      });
      this.setState({ [name]: formattedPhoneNumber });
      if (phoneNumberIsComplete) {
        Promise.all([
          this.props.checkUser(cleanedPhone),
          this.fetchCasesByPhoneNumberAndType(cleanedPhone)])
          .then((results) => {
            const [phoneMetadata] = results;
            const phoneType = phoneMetadata && phoneMetadata.type;

            // An example for phone number lookup with user data
            // -- (617) 445-4921 or (617) 445-3924 or (401) 225-8508
            const phoneUser = phoneMetadata && formatUsername(phoneMetadata.user);
            const { first, last, fullName } = phoneUser || {};
            const { firstName, lastName } = this.state;
            const changeName = () => {
              const firstChange = {
                target: {
                  name: 'firstName',
                  value: first,
                },
              };

              const lastChange = {
                target: {
                  name: 'lastName',
                  value: last,
                },
              };
              this.handleChange(firstChange);
              this.handleChange(lastChange);
            };

            const isLandlinePhoneNumber = phoneMetadata?.type === PHONE_TYPES.LANDLINE;
            const isMobilePhoneNumber = phoneMetadata?.type === PHONE_TYPES.MOBILE
            || phoneMetadata?.type === PHONE_TYPES.VOIP;
            const metadataText = phoneType && fullName ? (
              <span>
                {phoneType}
                {' '}
                for
                {' '}
                <UserNameClick fullName={fullName} onClick={changeName} />
              </span>
            ) : (
              phoneType
            );
            const namesMatch = `${firstName} ${lastName}` === fullName;

            this.setState({
              showSwitch: true,
              phoneNumberMetadata:
              isMobilePhoneNumber && !namesMatch ? (
                <span>{metadataText || ''}</span>
              ) : (
                ''
              ),
              isLandlinePhoneNumber,
              isMobilePhoneNumber,
            });
          });
      }
      if (savedPos < cleanedPhone.length) {
        setTimeout(() => {
          e.target.setSelectionRange(savedPos, savedPos);
        }, 0);
      }
    } else {
      this.props.handleFormInputChange({
        value: value || textContent,
        inputId: name,
        tableId: userType.id(),
      });
      this.setState({ [name]: value || textContent });
    }
  };

  handleBlur = (e) => {
    const { name } = e.target;
    const validation = UserFormValidation.userFormValidation({
      fieldName: name,
      userFormData: this.props.editObject,
    });
    this.setState({ [`${e.target.name}IsValid`]: validation.isValid });
  };

  fetchCasesByPhoneNumberAndType(phoneNumber) {
    const { view } = this.props;
    const baseUrl = `api/job/joblist?offset=0&limit=32&order=DESC&sort=createdAt&filter=${phoneNumber}`;
    const url = view === ('CLAIM' || view === 'POLICY') ? `${baseUrl}&type=${view}` : baseUrl;
    return secureRequest(
      url,
      { method: 'GET' },
    ).then((response) => response.json()).then((data) => {
      this.props.handleParentJobs(data.jobs);
      return data;
    });
  }

  render() {
    const {
      isEdit, parentIsEdit, userErrors, view, isVerified, hasAccess,
    } = this.props;

    const {
      phoneNumber,
      phoneNumberMetadata,
      phoneNumberIsValid,
      phoneNumberErrorMessage,
      firstName,
      firstNameIsValid,
      firstNameErrorMessage,
      lastName,
      lastNameIsValid,
      lastNameErrorMessage,
      email,
      emailIsValid,
      emailErrorMessage,
      languagePreference,
      languagePreferenceOptions,
      isLandlinePhoneNumber,
      isMobilePhoneNumber,
    } = this.state;

    return (
      <div className={`${view === 'case' ? 'case-view' : ''} user-form`}>
        <div className="phone-input-container">
          <PhoneNumberSearchSelect
            prefixAddOn="+1"
            formContext="users"
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            name="phoneNumber"
            value={phoneNumber}
            isValid={phoneNumberIsValid}
            errorMessage={phoneNumberErrorMessage}
            fieldSpecificMessage={phoneNumberMetadata}
            required
            isDisabled={isEdit && parentIsEdit}
            view={_.get(this.props, 'view', '')} // for case view specific styles
            isLandlinePhoneNumber={isLandlinePhoneNumber}
            isMobilePhoneNumber={isMobilePhoneNumber}
          />
        </div>
        {((phoneNumber && phoneNumber.length >= 14) || isEdit) && (
          <div className="user-input-fields-container">
            <div className="user-name-container">
              <FormInputField
                label="First Name"
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                name="firstName"
                type="text"
                value={firstName}
                isValid={
                  userErrors.indexOf('firstName') > -1
                    ? false
                    : firstNameIsValid
                }
                errorMessage={firstNameErrorMessage}
                isDisabled={isEdit}
                required
              />
              {this.state.showSwitch && (
                <IconButton className="switch-icon" data-jest="switchIcon" onClick={this.switchNames}>
                  <SwitchIcon />
                </IconButton>
              )}
              <FormInputField
                label="Last Name"
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                name="lastName"
                type="text"
                value={lastName}
                isValid={
                  userErrors.indexOf('lastName') > -1 ? false : lastNameIsValid
                }
                errorMessage={lastNameErrorMessage}
                isDisabled={isEdit}
                required
              />
            </div>
            <div className="user-email-language-container">
              <FormInputField
                label="Email"
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                name="email"
                type="email"
                value={email}
                isValid={
                  userErrors.indexOf('email') > -1 ? false : emailIsValid
                }
                errorMessage={emailErrorMessage}
                isDisabled={isEdit}
                required={false}
              />
              {hasAccess && (
                <SelectDropdown
                  label="Customer Language"
                  name="languagePreference"
                  isDisabled={!isVerified}
                  initialValue={
                    isEdit && languagePreference ? languagePreference : ''
                  }
                  options={languagePreferenceOptions}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

UserForm.propTypes = {
  toggleDisableParentFormSubmit: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  parentFormContext: PropTypes.string,
  view: PropTypes.string,
  handleParentJobs: PropTypes.func,
};

UserForm.defaultProps = {
  toggleDisableParentFormSubmit: () => {},
  handleParentJobs: () => {},
  parentFormContext: '',
  view: '',
};

const mapDispatchToProps = {
  handleFormInputChange,
  clearEditObject,
  addFormError,
  clearAddFormErrors,
  clearMobileLookup,
  checkUser,
};

const mapStateToProps = (state, props) => ({
  editObject: _.get(state.users, 'form.editObject', {}),
  mobileLookup: _.get(state, 'ui.mobileLookup'),
  isEdit: _.get(state.users, 'form.isEdit', false),
  isVerified: _.get(state.users, 'list[0].verified', true),
  parentIsEdit:
    props.parentIsEdit
    || _.get(state[props.parentFormContext], 'form.isEdit', false),
  userErrors: _.get(state, 'users.errors', []),
  neededPermissions: ['TRANSLATE_ACCESS'],
});

export { UserForm };
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // eslint-disable-next-line react-hooks/rules-of-hooks
)(usePermissionCheck(UserForm));
