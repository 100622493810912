import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextAreaInput, NumberInput, SlideToggle } from '@himarley/unity';
import TooltipInfo from '../../elements/Tootip/TooltipInfo';
import { MIN_MIN_VALUE, MAX_MIN_VALUE } from './constants';

const InactivityAutoReply = ({
  isAutoReplyEnabled,
  setIsAutoReplyEnabled,
  minutes,
  autoReplyMessage,
  setMinutes,
  setAutoReplyMessage,
  orgAutoReply,
}) => {
  const handleMinuteChange = (e) => {
    if (e.target.value > MAX_MIN_VALUE) {
      return;
    }
    setMinutes(e.target.value > 5 ? e.target.value : MIN_MIN_VALUE);
  };

  useEffect(() => {
    setMinutes(orgAutoReply?.sendCriteria?.minutesInactiveThreshold);
  }, [orgAutoReply?.sendCriteria?.minutesInactiveThreshold, setMinutes]);

  useEffect(() => {
    setAutoReplyMessage(orgAutoReply?.message?.during?.value);
  }, [orgAutoReply?.message?.during?.value, setAutoReplyMessage]);

  useEffect(() => {
    setIsAutoReplyEnabled(orgAutoReply?.active);
  }, [orgAutoReply?.active, setIsAutoReplyEnabled]);

  return (
    <main className="inactivityWrap">
      <header>
        <h2>Inactivity Auto Reply</h2>
        <div
          className={`${
            isAutoReplyEnabled ? 'autoReplyEnabled' : 'autoReplyDisabled'
          } minuteToggleWrap`}
        >
          {isAutoReplyEnabled && (
            <div>
              <NumberInput
                onChange={handleMinuteChange}
                value={minutes}
                label="Threshold"
              />
              <h3>Minutes</h3>
            </div>
          )}
          <SlideToggle
            onToggle={() => setIsAutoReplyEnabled(!isAutoReplyEnabled)}
            on={isAutoReplyEnabled}
            showLabels={false}
          />
        </div>
      </header>
      <p>
        Add an auto reply text for received messages on cases when users are
        inactive for a specified time.
      </p>

      {isAutoReplyEnabled && (
        <section>
          <TextAreaInput
            className="autoReplyInput"
            onKeyDown={() => {}}
            autoFocus
            label="Auto Reply Message"
            maxLength={1400}
            value={autoReplyMessage}
            onChange={(e) => setAutoReplyMessage(e.target.value)}
            labelTooltip={
              <TooltipInfo
                message="Individual users can override this org auto reply message with their own auto reply and away message settings."
                placement="top"
              />
            }
            showMaxLengthOnTopRight
          />
          <TextAreaInput
            value={autoReplyMessage}
            onChange={() => {}}
            onKeyDown={() => {}}
            autoFocus
            label="Preview"
            disabled
            showMaxLengthOnTopRight
          />
        </section>
      )}
    </main>
  );
};

InactivityAutoReply.propTypes = {
  organization: PropTypes.shape({ branding: PropTypes.shape([]) }),
  isAutoReplyEnabled: PropTypes.bool,
  setIsAutoReplyEnabled: PropTypes.func,
  orgAutoReply: PropTypes.shape({}),
  minutes: PropTypes.string,
  autoReplyMessage: PropTypes.string,
  setMinutes: PropTypes.func,
  setAutoReplyMessage: PropTypes.func,
};

InactivityAutoReply.defaultProps = {
  organization: { branding: [], _id: '' },
  isAutoReplyEnabled: false,
  setIsAutoReplyEnabled: () => {},
  orgAutoReply: {},
  minutes: MIN_MIN_VALUE,
  autoReplyMessage: '',
  setMinutes: () => {},
  setAutoReplyMessage: () => {},
};

export default InactivityAutoReply;
