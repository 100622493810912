import React from 'react';
import { Button } from '@himarley/unity';
import PropTypes from 'prop-types';
import styles from './ChatInbox.module.less';
import RefreshIcon from '../../../../images/icons/refresh.svg';

const InboxError = ({ isActiveChat, text }) => {
  const handleRefresh = () => {
    window?.location?.reload();
  };

  return (
    <section className={`${isActiveChat ? styles.activeWrap : styles.errorWrap}`}>
      <p>{text}</p>
      <Button
        className={styles.cancelSearchBtn}
        type="outline"
        testId="inboxRefreshBtn"
        onClick={handleRefresh}
      >
        <RefreshIcon />
        <span> Refresh</span>
      </Button>
    </section>
  );
};

InboxError.propTypes = {
  isActiveChat: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

InboxError.defaultProps = {
  isActiveChat: false,
  text: 'Unable to load chats',
};

export default InboxError;
