/* eslint-disable no-template-curly-in-string */
import DOMPurify from 'dompurify';
import React from 'react';

import {
  constructPreviewText,
  LEGAL_MESSAGE,
  TOKENS_MAP,
} from '@app/components/Settings/OptInMessages/constants';

import {
  KEYS, MOID_LEGAL_MESSAGE, LEGAL_MESSAGE_TOKEN, MOID_LEGAL_MESSAGE_TOKEN,
} from './constants';
import type {
  MessageTemplate,
  Condition,
  ParsedBody,
} from './types';

/**
 * Generates a preview message by splitting the message text at $legalMessage,
 * sanitizing each part, and constructing the preview text.
 * @param {string} messageText - The message text to be processed.
 * @param {{ [key: string]: string }} [tokenOverrideMap] - Optional map for token overrides.
 *
 * @returns {JSX.Element} - The preview message element.
 */
export const getPreviewMessage = (
  messageText: string,
  tokenOverrideMap?: { [key: string]: string },
): JSX.Element => {
  let legalMessageToken = LEGAL_MESSAGE_TOKEN;
  let legalMessage = LEGAL_MESSAGE;
  if (messageText.includes(MOID_LEGAL_MESSAGE_TOKEN)) {
    legalMessageToken = MOID_LEGAL_MESSAGE_TOKEN;
    legalMessage = MOID_LEGAL_MESSAGE;
  }

  const messageTextSplit = messageText.split(legalMessageToken);

  return (
    <>
      <span
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            constructPreviewText(messageTextSplit[0], tokenOverrideMap),
          ),
        }}
      />
      {messageTextSplit.length > 1 && (
        <>
          <span>
            {legalMessage}
          </span>
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                constructPreviewText(messageTextSplit[1], tokenOverrideMap),
              ),
            }}
          />
        </>
      )}
    </>
  );
};

/**
 * Replaces tokens in the message with ${TOKEN} and collects tokens.
 * @param {string} message - The message containing tokens.
 * @returns {[string, { token?: string }[]]} - The updated message and array of tokens.
 */
export const updateTokenStrings = (
  message: string,
): [newMessage: string, tokens: { token?: string }[]] => {
  const pattern = /\$(\w+)/g;
  const newTokens: { token: string }[] = [];

  const newMessage = message.replace(pattern, (_, token) => {
    newTokens.push({ token });
    return '${TOKEN}';
  });

  return [newMessage, newTokens];
};

/**
 * Finds the value of a specific condition fact.
 * @param {Condition[]} conditions - The list of conditions.
 * @param {string} fact - The fact to find the value for.
 *
 * @returns {string | boolean | undefined} - The value of the condition fact.
 */
const findConditionValue = (
  conditions: Condition[],
  fact: string,
): string | boolean | undefined => conditions.find((condition) => condition.fact === fact)?.value;

/**
 * Parses rules into message templates.
 * @param {ParsedBody} [data]
 * @param {string} [key=''] - The key to identify the message type.
 * @param {{ [key: string]: string }} [tokenOverrideMap] - Optional map for token overrides.
 *
 * @returns {MessageTemplate[]} - The parsed message templates.
 */
export const parseMessages = (
  data?: ParsedBody,
  key = '',
  tokenOverrideMap?: { [key: string]: string },
): MessageTemplate[] => data?.rules?.map((rule) => {
  const conditions = rule.rule.conditions.all;
  const ruleCaseType = findConditionValue(conditions, 'caseType');
  const ruleCaseAssignment = findConditionValue(conditions, 'caseAssignment');
  const disableMarleyOptIn = Boolean(findConditionValue(conditions, 'marleyOptInDisabled'));
  let ruleOptStatus = findConditionValue(conditions, 'optStatus');

  if (!ruleOptStatus && disableMarleyOptIn) {
    ruleOptStatus = KEYS.OPTED_IN;
  }

  let messageText = rule.messageTemplate?.body || '';
  rule.messageTemplate?.tokens?.forEach((token) => {
    messageText = messageText?.replace('${TOKEN}', `$${token.token}`);
  });

  return {
    ...rule.messageTemplate,
    enabled: typeof rule.enabled === 'boolean' ? rule.enabled : true,
    caseType: ruleCaseType,
    disableMarleyOptIn: disableMarleyOptIn || false,
    optStatus: (
      [KEYS.FOLLOWUP_MESSAGE, KEYS.CONFIRMATION_MESSAGE] as unknown as string
    ).includes(key)
      ? 'PENDING_OPT_IN'
      : ruleOptStatus,
    rule: rule.rule,
    [key]: true,
    previewMessage: getPreviewMessage(messageText, tokenOverrideMap),
    rawMessage: messageText,
    name: TOKENS_MAP.$companyName,
    isSent: true,
    caseAssignment: (() => {
      if (typeof ruleCaseAssignment === 'boolean') {
        return ruleCaseAssignment ? KEYS.ASSIGNED : KEYS.UNASSIGNED;
      }
      return KEYS.ANY;
    })(),
    ...(data.lineOfBusinessId && { lineOfBusinessId: data.lineOfBusinessId }),
  };
}) || [];

/**
 * Builds message templates from provided rules.
 * @param {Object} params - The parameters containing the rules and token override map.
 * @param {ParsedBody} [params.initialMessageData] - The initial message data.
 * @param {ParsedBody} [params.confirmationMessageData] - The confirmation message data.
 * @param {ParsedBody} [params.followupMessageData] - The follow-up message data.
 * @param {{ [key: string]: string }} [params.tokenOverrideMap] - Optional map for token overrides.
 *
 * @returns {MessageTemplate[]} - The built message templates.
 */
export const buildMessageTemplates = ({
  initialMessageData,
  confirmationMessageData,
  followupMessageData,
  tokenOverrideMap,
}: {
  initialMessageData?: ParsedBody;
  confirmationMessageData?: ParsedBody;
  followupMessageData?: ParsedBody;
  tokenOverrideMap?: { [key: string]: string };
}): MessageTemplate[] => [
  ...parseMessages(initialMessageData, KEYS.INITIAL_MESSAGE, tokenOverrideMap),
  ...parseMessages(
    confirmationMessageData,
    KEYS.CONFIRMATION_MESSAGE,
    tokenOverrideMap,
  ),
  ...parseMessages(
    followupMessageData,
    KEYS.FOLLOWUP_MESSAGE,
    tokenOverrideMap,
  ),
];

/**
 * Retrieves message templates filtered by case type and opt status.
 * @param {Object} params - The parameters containing message templates, case type, and opt status.
 * @param {MessageTemplate[]} params.messageTemplates - The list of message templates.
 * @param {string} params.caseType - The case type to filter by.
 * @param {string} params.optStatus - The opt status to filter by.
 *
 * @returns {MessageTemplate[]} - The filtered message templates.
 */
export const getMessages = ({
  messageTemplates,
  caseType,
  optStatus,
  isDisableMarleyOptIn,
}: {
  messageTemplates: MessageTemplate[];
  caseType: string;
  optStatus: string;
  isDisableMarleyOptIn: boolean;
}): MessageTemplate[] => {
  const currentTemplates = messageTemplates.filter(
    (messageTemplate) => messageTemplate.caseType === caseType
      && messageTemplate.optStatus === optStatus
      && isDisableMarleyOptIn === messageTemplate.disableMarleyOptIn,
  );

  return [
    ...currentTemplates.filter((template) => template[KEYS.INITIAL_MESSAGE]),
    ...(optStatus !== KEYS.OPTED_IN
      ? [
        {
          body: 'YES',
          displayName: 'Customer Not Opted In:YES',
          id: 'DEFAULT',
          previewMessage: 'YES',
          rawMessage: 'YES',
          name: TOKENS_MAP.$claimantFullName,
        },
      ]
      : []),
    ...currentTemplates.filter(
      (template) => template[KEYS.CONFIRMATION_MESSAGE],
    ),
    ...currentTemplates.filter((template) => template[KEYS.FOLLOWUP_MESSAGE]),
  ];
};
