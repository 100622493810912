import PropTypes from 'prop-types';

import { RELEASE_STATUS } from '../constants/copart-constants';

export const isReleaseIssueOpen = (issue) => issue.status === RELEASE_STATUS.ACTIVE;

export const isAnyReleaseIssueOpen = (issues) => issues.some((issue) => isReleaseIssueOpen(issue));

export const isReleaseIssueResolved = (issue) => issue.status === RELEASE_STATUS.RESOLVED;

export const areAllReleaseIssuesResolved = (
  issues,
) => issues.every((issue) => isReleaseIssueResolved(issue));

export const isReleaseIssueCompleted = (issue) => issue.status
&& issue.status === RELEASE_STATUS.COMPLETED;

export const isReleaseIssueRejected = (issue) => issue.rejected;

export const totalLossDataPropTypes = PropTypes.shape({
  vehicleLocation: PropTypes.shape({}),
  yardLocation: PropTypes.shape({}),
  ownerDetails: PropTypes.shape({}),
  vehicleDetails: PropTypes.shape({}),
  sellerCode: PropTypes.string,
});
