/* eslint-disable react/jsx-no-useless-fragment */
import { TabbedNavBar, Tab } from '@himarley/unity';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeApiRequest } from '@app/actions/api/api';
import usePermissionVerify from '@app/components/elements/hooks/usePermissionVerify';
import {
  ORG_SETTINGS_WELCOME_FLOW, ADMIN_NOTIFICATION_CONFIG,
  FEATURE_FLAG_TOTAL_LOSS_ASSIST,
} from '@app/constants/permissions';
import type { StateType } from '@app/types/reducer-state';

import AdminNotificationConfigurations from './AdminNotificationConfigurations/AdminNotificationConfigurations';
import CompanyHierarchy from './CompanyHierarchy/CompanyHierarchy';
import IntegrationSettingsContainer from './IntegrationSettings/IntegrationSettingsContainer';
import OptInMessages from './OptInMessages/OptInMessages';
import OrgSettings from './OrgSettings/OrgSettings';
import WelcomeFlowSettings from './WelcomeFlowSettings/welcome-flow-settings';
import './Settings.less';

const NAV_EVENT_KEYS: { [key: string]: string } = {
  orgSettings: 'orgSettings',
  optInMessages: 'optInMessages',
  welcomeMessages: 'welcomeMessages',
  companyHierarchy: 'companyHierarchy',
  adminNotificationConfigurations: 'adminNotificationConfigurations',
  integrationSettings: 'integrationSettings',
};

const Settings = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedTab, setSelectedTab] = React.useState(NAV_EVENT_KEYS.orgSettings);
  const organization = useSelector((state: StateType) => state?.organizations);
  const welcomeFlow = useSelector((state: StateType) => state?.organizations?.welcomeFlow || {});
  useEffect(() => {
    dispatch(makeApiRequest('GET_ORGANIZATIONS_AUTOREPLIES', [organization?.name?.toUpperCase()]));
  }, [dispatch, organization?.name]);

  const { hasAccess: canViewWelcomeFlow } = usePermissionVerify({
    neededPermissions: [ORG_SETTINGS_WELCOME_FLOW],
  });

  const { hasAccess: hasNotificationSettingsAccess } = usePermissionVerify({
    neededPermissions: [ADMIN_NOTIFICATION_CONFIG],
  });

  const { hasAccess: hasTotalLossEnabled } = usePermissionVerify({
    neededPermissions: [FEATURE_FLAG_TOTAL_LOSS_ASSIST],
  });

  const isWelcomeFlowRefactorEnabled = process.env.WELCOME_FLOW_REFACTOR_ENABLED;

  const handleTabSelect = (tab: string) => {
    if (tab) {
      history?.push(`/settings/${tab}`);
    }
  };

  useEffect(() => {
    const path = history?.location?.pathname.split('/');
    if (path && NAV_EVENT_KEYS[path[path.length - 1]]) {
      setSelectedTab(path[path.length - 1]);
    } else {
      setSelectedTab(NAV_EVENT_KEYS.orgSettings);
      history?.push(`/settings/${NAV_EVENT_KEYS.orgSettings}`);
    }
  }, [history?.location?.pathname, setSelectedTab, history]);

  const tabs = [
    <Tab eventKey={NAV_EVENT_KEYS.orgSettings} title="Org. Settings">
      <OrgSettings organization={organization} />
    </Tab>,
    <Tab eventKey={NAV_EVENT_KEYS.companyHierarchy} title="Company Hierarchy">
      <CompanyHierarchy organization={organization} />
    </Tab>,
  ];

  if (canViewWelcomeFlow) {
    if (isWelcomeFlowRefactorEnabled) {
      tabs.push(
        <Tab eventKey={NAV_EVENT_KEYS.welcomeMessages} title="Welcome Messages">
          <WelcomeFlowSettings />
        </Tab>,
      );
    } else {
      tabs.push(
        <Tab eventKey={NAV_EVENT_KEYS.optInMessages} title="Opt-In Messages">
          <OptInMessages dispatch={dispatch} welcomeFlow={welcomeFlow} />
        </Tab>,
      );
    }
  }

  if (hasNotificationSettingsAccess) {
    tabs.push(
      <Tab eventKey={NAV_EVENT_KEYS?.adminNotificationConfigurations} title="Notifications">
        <AdminNotificationConfigurations />
      </Tab>,
    );
  }

  if (hasTotalLossEnabled) {
    tabs.push(
      <Tab eventKey={NAV_EVENT_KEYS?.integrationSettings} title="Integration Settings">
        <IntegrationSettingsContainer />
      </Tab>,
    );
  }

  return (
    <main data-testid="settingsWrap" className="settingsWrap">
      <TabbedNavBar onSelect={handleTabSelect} defaultActiveKey={selectedTab} title="Org. Settings">
        {tabs}
      </TabbedNavBar>
    </main>
  );
};

Settings.propTypes = {
  organization: PropTypes.shape({}),
};

Settings.defaultProps = {
  organization: {},
};

export default Settings;
