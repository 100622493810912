import _ from 'lodash';

export const addUnreadMessageIndication = ({ viewedChats, jobs, isNewInbox }) => {
  const isUnreadStateRefactorEnabled = process.env.UNREAD_STATE_REFACTOR_ENABLED && isNewInbox;

  return _(jobs)
    .map((job) => {
      const hasUnreadMessages = isUnreadStateRefactorEnabled
        ? job.unreadMessageCount > 0
        : viewedChats && !viewedChats.has(job.id);
      return { ...job, hasUnreadMessages };
    })
    .value();
};

export const stub = () => {};
