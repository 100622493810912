import _ from 'lodash'
import axios from 'axios'
import {
  SEND_MASS_NOTIFICATION,
  GET_MASS_NOTIFICAITIONS,
  GET_UPLOAD_CSV_SIGNED_URL,
  GET_ERROR_CSV,
  DELETE_MASS_NOTIFICATION,
  GET_CSV_TEMPLATE,
  GET_MASS_NOTIFICATION_TRANSCRIPT,
  ASSIGN_MASS_NOTIIFICATION,
  GET_OPERATORS_BY_ORG,
} from '../constants/endpoints'
import {
  SET_MASSNOTIFICATION_FILENAME,
  SET_CREATE_BUTTON_STATUS,
  GET_MASS_NOTIFICATIONS_ACTION,
  OPEN_MASS_NOTIFICATION_MODAL,
  HIDE_MASSNOTIFICATION_MODAL,
  SET_MODAL_LOADING,
  SET_SELECTED_TYPE,
  SET_SELECTED_FILE,
  FAILED_TO_CREATE_NOTIFICATION,
  MASS_NOTIFICATION_UPDATE,
  GENERIC_MESSAGE,
  SET_MESSAGE_COPIED,
  MASS_NOTIFICATION_ASSIGNED,
  LOAD_ORG_OPERATORS,
  MASS_NOTIFICATION_PROGRESS_UPDATE,
  MASS_NOTIFICATION_CREATE_MESSAGE_UPDATE,
} from '../constants/actions'
import { doRequest, apiCall } from './api'

import { setLeftBarBlueDot } from './ui'
import { MASSNOTIFICATIONS_VIEW } from '../constants/dashboardState'

export const getMassNotifications = () => (dispatch, getState) => {
  // eslint-disable-next-line no-underscore-dangle
  const orgID = getState().auth?.user.organization._id

  doRequest({
    method: 'GET',
    endpoint: GET_MASS_NOTIFICAITIONS,
  })
    .then((response) => {
      dispatch({
        type: GET_MASS_NOTIFICATIONS_ACTION,
        payload: {
          data: response,
        },
      })
      return response
    })
    .catch((err) => console.error(err))

  doRequest({
    method: 'GET',
    endpoint: GET_OPERATORS_BY_ORG(orgID),
  })
    .then((operators) => {
      dispatch({
        type: LOAD_ORG_OPERATORS,
        payload: {
          data: operators,
        },
      })
      return operators
    })
    .catch((err) => console.error(err))
}

export const openNotificationModal = () => ({
  type: OPEN_MASS_NOTIFICATION_MODAL,
  payload: {},
})

export const hideMassNotificationModal = () => ({
  type: HIDE_MASSNOTIFICATION_MODAL,
  payload: {},
})

export const setFilename = (filename) => ({
  type: SET_MASSNOTIFICATION_FILENAME,
  payload: {
    filename,
  },
})

export const setCreateButton = ({ message, title }) => (dispatch, getState) => {
  const file = _.get(getState(), 'massNotifications.selectedFile')
  const status = message && title && message.length > 0 && title.length > 0 && file
  dispatch({
    type: SET_CREATE_BUTTON_STATUS,
    payload: {
      status: !!status,
    },
  })
}

export const setModalLoading = (status) => ({
  type: SET_MODAL_LOADING,
  payload: {
    status,
  },
})

export const sendMassNotification = (id) => {
  const args = {
    method: 'POST',
    endpoint: SEND_MASS_NOTIFICATION,
    action: 'MASS_NOTIFICATION_SENT',
    process: (body) => ({ body, id }),
    body: { id },
    passBodyOnError: true,
  }

  return apiCall({ args })
}

export const createMassNotification = (
  message,
  title,
  onHide,
  {
    scheduledDatetimeLocal,
    destinationTimezoneLocale,
    isScheduleEnabled,
    isThrottleEnabled,
    throttleRate,
  } = {},
) => (dispatch, getState) => {
  const type = getState().massNotifications.selectedType
  const { displayName, name } = _.get(getState(), 'auth.user.organization', {})
  const fileObj = _.get(getState(), 'massNotifications.selectedFile')
  const creator = `${_.get(getState(), 'auth.user.firstName', '')} ${_.get(getState(), 'auth.user.lastName', '')}`
  const creatorId = _.get(getState(), 'auth.user._id')
  const failureTimer = setTimeout(() => {
    dispatch({ type: FAILED_TO_CREATE_NOTIFICATION })
  }, 1000 * 40)

  const isScheduled = Boolean(isScheduleEnabled
    && scheduledDatetimeLocal
    && destinationTimezoneLocale)

  const bodyDefaults = {
    message,
    type,
    title,
    orgName: displayName || name,
    verify: true,
    creator,
    creatorId,
    isScheduled,
    scheduledDatetimeLocal,
    destinationTimezoneLocale,
  }

  const isThrottled = Boolean(isScheduleEnabled && isThrottleEnabled && throttleRate >= 1000)

  const body = isThrottled ? {
    ...bodyDefaults,
    isSeriesMain: true,
    isThrottled,
    throttleRate,
  } : bodyDefaults

  doRequest({
    method: 'POST',
    endpoint: GET_UPLOAD_CSV_SIGNED_URL,
    body,
  })
    .then((data) => axios.put(data.url, fileObj, {
      headers: {
        'Content-Type': fileObj.type,
      },
    }))
    .then((res) => {
      clearTimeout(failureTimer)
      getMassNotifications()(dispatch, getState)
      onHide()
      dispatch({
        type: HIDE_MASSNOTIFICATION_MODAL,
      })
      return res
    })
    .catch((err) => {
      console.error(err)
      clearTimeout(failureTimer)
      dispatch({ type: FAILED_TO_CREATE_NOTIFICATION, payload: err })
    })
    // hide modal if there is an issue
}

export const setSelectedType = (type) => ({
  type: SET_SELECTED_TYPE,
  payload: {
    type,
  },
})

export const downloadErrorCSV = (id) => () => {
  doRequest({
    method: 'GET',
    endpoint: `${GET_ERROR_CSV}?id=${id}`,
  })
    .then((res) => axios.get(res.url))
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${id}.csv`)
      document.body.appendChild(link)
      link.click()
      return res
    })
    .catch((err) => {
      console.error(err)
    })
}

export const downloadTranscript = (id) => () => {
  doRequest({
    method: 'GET',
    endpoint: `${GET_MASS_NOTIFICATION_TRANSCRIPT}?id=${id}`,
  })
    .then((res) => axios({
      url: res.url,
      method: 'GET',
      responseType: 'blob',
    }))
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${id}.pdf`)
      document.body.appendChild(link)
      link.click()
      return res
    })
    .catch((err) => {
      console.error(err)
    })
}

export const setSelectedFile = (file) => ({
  type: SET_SELECTED_FILE,
  payload: { file },
})

export const deleteNotification = (id) => apiCall({
  args: {
    method: 'DELETE',
    endpoint: DELETE_MASS_NOTIFICATION,
    action: 'DELETE_MASS_NOTIFICATION',
    body: {
      id,
    },
    initPayload: {
      id,
    },
  },
})

export const downloadCSVTemplate = () => {
  doRequest({
    endpoint: GET_CSV_TEMPLATE,
    responseType: 'blob',
    method: 'GET',
  }).then((res) => {
    const url = window.URL.createObjectURL(new Blob([res]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'MassNotificationTemplate.csv')
    document.body.appendChild(link)
    link.click()
    return res
  }).catch((err) => {
    console.error('failed to download csv')
    console.error(err)
  })
}

export const setMessageCopied = (set) => ({
  type: SET_MESSAGE_COPIED,
  payload: { set },
})

const handleMassNotificationUpdate = (newImage, dispatch, getState) => {
  if (newImage.Status === 'VALIDATION_COMPLETE') {
    setLeftBarBlueDot(MASSNOTIFICATIONS_VIEW)(dispatch, getState)
    dispatch({
      type: GENERIC_MESSAGE,
      payload: {
        name: 'Mass notification valdiation complete.',
        body: `"${newImage.Title}" is ready to be sent!`,
      },
    })
  } else if (newImage.Status.match(/VALIDATION_FAILED/gm)) {
    dispatch({
      type: GENERIC_MESSAGE,
      payload: {
        name: 'Mass notification validation failed.',
        body: `"${newImage.Title}" failed to validate!`,
      },
    })
  }
}

const backupPolling = (dispatch, getState) => {
  // backup on socket failure, do periodic polling
  getMassNotifications()(dispatch, getState)
}

let socketHasSubscribed = false
let retrySubTimeout = null
export const subscribeSocket = () => (dispatch, getState) => {
  clearTimeout(retrySubTimeout)
  if (socketHasSubscribed) { return }
  const socket = _.get(getState(), 'socket.socketObject')
  const orgId = _.get(getState(), 'auth.user.organization._id')

  if (!socket || !orgId) {
    // recall to wait out state loading
    clearTimeout(retrySubTimeout)
    retrySubTimeout = setTimeout(() => subscribeSocket()(dispatch, getState), 5000)
    return
  }
  const targetTopic = `mass-notifications-${orgId}`
  socket.subscribe([targetTopic])
  socket.on(targetTopic, (message) => {
    if (message.type === 'MASS_NOTIFICATION_PROGRESS_UPDATE') {
      dispatch({
        type: MASS_NOTIFICATION_PROGRESS_UPDATE,
        payload: message.data,
      })
    } else if (message.type === 'MASS_NOTIFICATION_CREATE_MESSAGE_UPDATE') {
      dispatch({
        payload: message.data,
        type: MASS_NOTIFICATION_CREATE_MESSAGE_UPDATE,
      })
    } else {
      dispatch({
        type: MASS_NOTIFICATION_UPDATE,
        payload: message.data,
      })
      handleMassNotificationUpdate(message.data, dispatch, getState)
    }
  })

  socketHasSubscribed = true
  backupPolling(dispatch, getState)
}

export const assignMassNotification = (noteId, opId) => {
  const args = {
    method: 'POST',
    endpoint: ASSIGN_MASS_NOTIIFICATION,
    action: MASS_NOTIFICATION_ASSIGNED,
    body: {
      operator: opId,
      id: noteId,
    },
    initPayload: {
      operator: opId,
      id: noteId,
    },
    passBodyOnError: true,
  }

  return apiCall({ args })
}
