/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */

import { PROFILE_UPDATE_ERROR } from '../constants/actions';
import { uploadMedia } from './media';
import { updateProfile } from './profile';

export const VALID_AVATAR_IMAGE_FILE_TYPES = [
  'image/jpeg',
];

export const AVATAR_IMAGE_MEGABYTE_LIMIT = 5;

export const uploadProfileImage = (file) => async (dispatch, getStore) => {
  dispatch({ type: 'REQUEST_UPLOAD_PROFILE_IMAGE_INIT' });
  try {
    const { key, url } = await uploadMedia(
      file,
      VALID_AVATAR_IMAGE_FILE_TYPES,
      AVATAR_IMAGE_MEGABYTE_LIMIT,
    )(dispatch);
    const imageUrl = `${url}${key}`;
    const store = getStore();
    const profile = {
      _id: store.profile?._id,
      email: store.profile?.email,
      oldEmail: store.profile?.email, // required to use route
      ...{
        firstName: store.profile?.firstName,
        lastName: store.profile?.lastName,
        phoneNumber: store.profile?.phoneNumber,
        contactNumber: store.profile?.contactNumber,
      },
      imageUrl,
    };
    await updateProfile(profile)(dispatch);

    dispatch({
      type: 'REQUEST_UPLOAD_PROFILE_IMAGE_SUCCESS',
      payload: {
        imageUrl,
      },
    });
  } catch (e) {
    dispatch({
      type: PROFILE_UPDATE_ERROR,
      payload: 'Cannot update profile photo',
    });
    dispatch({ type: 'REQUEST_UPLOAD_PROFILE_IMAGE_ERROR' });
  }
};
