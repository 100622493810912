import React from 'react';
import PropTypes from 'prop-types';
import UserPhoto2 from '../../../UserPhoto2/UserPhoto2';
import { ROLE_ENDUSER } from '../../../../constants/general';
import './ParticipantCircles.less';

const ParticipantCircles = ({ participants }) => (
  <div className="participant-circles">
    {participants.map((participant) => {
      const {
        imageUrl,
        name,
        role,
        id,
      } = participant;

      return (
        <UserPhoto2
          id={id}
          key={`participantCircle-${id}`}
          className="participant-circle"
          isRecipient={role === ROLE_ENDUSER}
          name={name}
          imageUrl={imageUrl}
        />
      );
    })}
  </div>
);

ParticipantCircles.propTypes = {
  participants: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    imageUrl: PropTypes.string,
    name: PropTypes.string,
    role: PropTypes.string,
  })).isRequired,
};

export default ParticipantCircles;
