/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/**
 * Author : Eric Widmann
 * Date : 11.7.2020
 * Wrapper for browser WebSocket class targeted toword WS via AWS-Gateway/live-update service.
 *
 * NOTE: IF IN THE FUTURE MISSION CRITICAL EVENTS ARE PUBLISHED DIRECTLY VIA THE `upstream-event` ACTION,
 * IT WILL BE NECESSARY TO IMPLEMENT AN OUTBOUND BUFFER FOR MESSAGES THAT IS FLUSHED ON THE ONOPEN (reconnect) EVENT.
 * ADDITIONALLY, THIS WILL NEED TO BE TOGGABLE AS NOT ALL UPSTREAM EVENTS ARE/WILL BE MISSION CRITICAL.
 */
import _ from 'lodash';
import isJSON from 'is-json';
import { serializeError } from 'serialize-error'; // if true, on connect socket will hard close after 2 seconds and remain closed for 30s
import { reportToCW } from './actions/cloudwatch';
// if subscribe or unsubscribe is called during DC, the func will be recalled after
// this amt of time
import { getAccessToken } from './helpers/localStorage';

const EventEmitter = require('events');

const PACKET_RECOVERY_TOPIC = 'packet-recovery';
const RECONNECT_BACKOFF_TIME_IN_MS = [2000, 4000, 8000, 16000, 32000];
const DISCONNECTED_RESUB_OR_UNSUB_BACKOFF_IN_MS = 1000;
const DEBUG_PACKET_RECOVERY = false;
const DEBUG_RECONNECT_FLOW = false;

/**
 * Event handler for socket opening. Request recovery packets from server if it is not the first time the
 * socket has connected to the server and the client has succesfully recieved at least one packet from the
 * the server
 * @param {*} client
 */
const onOpen = (client) => () => {
  // if there has been a successful connection and a packet from the server has been recieved, recover packets
  if (client.successFullyConnectedOnce && client.lastTimestampFromServer) {
    console.log(`SENDING RECOVERY FOR ${client.lastTimestampFromServer}`);
    client.send({
      action: 'recover-packets',
      lastRecordedDownstreamPacketTimestamp: client.lastTimestampFromServer,
      subscriptions: client.activeSubs,
    });
  }
  client.successFullyConnectedOnce = true;
  client.emit('open');
  // request timestampp from server for packet recovery
  client.send({
    action: 'ping-timestamp',
  });
  client.restoreSubs();
  client.backoffIndex = 0; // reset backoff on successful connection
  if (DEBUG_PACKET_RECOVERY) {
    console.log('SOCKET OPEN');
    // eslint-disable-next-line no-underscore-dangle
    setTimeout(() => client._close(), 3000);
  }
};
/**
 * Event handler for WS close
 * @param {*} client
 */
const onClose = (client) => () => {
  console.log('socket CLOSED');
  client.emit('close');
  client.reconnect();
};
/**
 * Event handler for downstream packet from server. Does some basic validation, stores lastTimestamp,
 * and bubble up to wrapper object EventEmitter instance
 * @param {*} client
 */
const onMessage = (client) => (msg) => {
  if (!isJSON(msg.data)) {
    console.error(new Error('INVALID DOWNSTREAM PACKET. DATA IS NOT FORMATTED AS JSON'));
    return;
  }
  const data = JSON.parse(msg.data);
  if (_.has(data, 'dateEmitted')) {
    const timestamp = new Date(data.dateEmitted).getTime();
    if (timestamp > client.lastTimestampFromServer || !client.lastTimestampFromServer) {
      client.lastTimestampFromServer = timestamp;
    }
  }
  if (_.has(data, 'topics')) { // WS msg data won't have topics field if FF is off
    // Only run the event handler once, don't loop through topics
    client.emit(data.topics[0], data.payload);
    return;
  }
  if (!_.has(data, 'topic') || !_.has(data, 'payload')) return;
  client.emit(data.topic, data.payload);
};

/**
 * Sends report to cloud watch log group for tracking
 * TODO: Disabling calls to this function since it currently attempts to
 *       use an accessToken that may not be present. The CW logger endpoint
 *       requires the authMiddleware for the user and org context
 * @param {*} err
 */
const reportError = (err, client) => {
  const payload = err instanceof Error ? serializeError(err) : err;
  reportToCW(payload, 'ERROW-WITH-WS-IN-WEB-APP', 'STAT')(client.dispatch, client.getState);
};

/**
 * Event handler for WS error
 * @param {*} client
 */
const onError = (client) => (err) => {
  reportError(err, client);
  client.reconnect();
  client.emit('error', err);
};

/** *
 * Event handler for downstream packet-recovery event from server, will re-emit all packets missed during
 * connection downtime
 */
const recoverPackets = (client) => ({ allMissedPackets = [] }) => {
  console.log(`PUBLISHING ${allMissedPackets.length} MISSED PACKETS`);
  allMissedPackets.forEach((currentPacket) => client.emit(_.get(currentPacket, 'Topic'), _.get(currentPacket, 'Payload')));
};

class SocketClient extends EventEmitter {
  constructor(endpoint, topic, getState, dispatch) {
    super();
    this.getState = getState;
    this.dispatch = dispatch;
    this.setupSocket(endpoint, topic);
    this.activeSubs = []; // list of subscription channel names this socket is connected too
    this.initalTopic = topic; // the inital topic (subscription) this socket is/was instantiated with
    this.endpoint = endpoint; // WS endpoint
    this.lastTimestampFromServer = null; // last timestamp from downstream packet
    this.successFullyConnectedOnce = false; // set to true on successful open
    this.on(PACKET_RECOVERY_TOPIC, recoverPackets(this));
    this.backoffIndex = 0;
  }

  /**
     * Checks if WebSocket instance is in proper state for reconnection
     * and determines backoff time.
     */
  reconnect() {
    const it = this;
    // if the socket is not closed, do not attempt reconnect
    if (it.socket.readyState !== WebSocket.CLOSED) return;

    // grab current backoff time or max backoff time if retries exceed 5
    const currBackoffIndex = it.backoffIndex <= RECONNECT_BACKOFF_TIME_IN_MS.length - 1
      ? it.backoffIndex
      : RECONNECT_BACKOFF_TIME_IN_MS.length - 1;

    const backoffTime = RECONNECT_BACKOFF_TIME_IN_MS[currBackoffIndex];
    console.log(`WS RECONNECTING WITH BACKOFF TIME ${backoffTime}`);
    it.backoffIndex += 1;

    clearTimeout(it.reconnectTimeout);
    it.reconnectTimeout = setTimeout(() => {
      if (DEBUG_RECONNECT_FLOW) {
        it.reconnect();
      } else {
        it.setupSocket(it.endpoint, it.initalTopic);
      }
    }, backoffTime);
  }

  /**
     * Inits browser WebSocket instance, resubs to clients topics (if a reconnection), and binds wrapper
     * to WebSocket events.
     * @param {string} endpoint WS addr
     * @param {string} topic The topic to sub to on handshake
     */
  async setupSocket(endpoint, topic) {
    const it = this;
    return getAccessToken()
      .then((tok) => {
        it.socket = new WebSocket(`${endpoint}?topic=${topic}&jwt=${tok}`);
        it.socket.onclose = onClose(it);
        it.socket.onopen = onOpen(it);
        it.socket.onmessage = onMessage(it);
        it.socket.onerror = onError(it);
        return true;
      });
  }

  /**
     * Subscribes WebSocket to topic in live-update service.
     * @param {string} topic
     */
  subscribe(topics) {
    const it = this;
    it.activeSubs = _.union(it.activeSubs, topics);
    return it.send({
      action: 'subscribe',
      topics,
    });
  }

  restoreSubs() {
    const it = this;
    if (it.socket.readyState !== WebSocket.OPEN) {
      clearTimeout(it.restoreSubsTimeout);
      it.restoreSubsTimeout = setTimeout(() => it.restoreSubs(), DISCONNECTED_RESUB_OR_UNSUB_BACKOFF_IN_MS);
      return;
    }
    // eslint-disable-next-line consistent-return
    return it.send({
      action: 'subscribe',
      topics: _.uniq(it.activeSubs),
    });
  }

  /**
     * Sends generic packet upstream to live-update service
     * VALID ACTIONS:
     * - 'upstream-event'
     * - 'ping-timestamp'
     * - 'recover-packets'
     * @param {*} payload
     */
  send(payload) {
    try {
      // attempt to send, if there is failure, mission critical upstream packets like subscriptions will be
      // recovered from the restoreSubs() func
      this.socket.send(JSON.stringify(payload));
    } catch (ex) {
      console.error(ex);
    }
  }

  /**
     * Unsubscribes WebSocket from topic in live-update service
     * @param {*} topic
     */
  unsubscribe(topics) {
    const it = this;
    it.activeSubs = it.activeSubs.filter((curr) => !topics.includes(curr));
    return it.send({
      action: 'unsubscribe',
      topics,
    });
  }

  // eslint-disable-next-line no-underscore-dangle
  _close() {
    this.socket.close();
  }
}

/**
 * Builder func to instantiate WS wrapper instance
 * @param {string} initalTopic the initial topic for socket to subscribe to on handshake
 * @returns {SocketClient} WS implementation wrapper targeted toward live-update service
 */
const buildSocketClient = async (initalTopic, getState, dispatch) => {
  const { endpoint } = process.env.WEBSOCKET_ENDPOINT;
  return new SocketClient(endpoint, initalTopic, getState, dispatch);
};

export default buildSocketClient;
