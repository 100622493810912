/* eslint-disable react/no-unescaped-entities */
import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Button, List } from '@himarley/unity';
import {
  createTemplate,
  getKeys,
  removeTemplate,
  updateTemplate,
} from '../../../../actions/templates';
import {
  updateOrgSetting,
  setIsUpdatingOrgSetting,
} from '../../../../actions/organization';
import { openCreateForm, closeForm } from '../../../../actions/ui';
import './MessageTemplates.less';
import selector from '../../../../selectors/template';
import RemoveTemplateConfirmation from './RemoveTemplateConfirmation/RemoveTemplateConfirmation';
import BinocularsIcon from '../../../../../images/icons/binoculars.svg';
import { capitalizeEachWord } from '../../../../helpers/format';
import { sortByDate, sortByAlphabetical } from '../../../../helpers/sorting';
import { constructHighlightedText } from '../../../../helpers/tokenTransformers';

import PermissionVerifier from '../../../PermissionVerifier/PermissionVerifier';
import CreateTemplate from './CreateTemplate';
import TemplateCard from './TemplateCard';
import withLoading from '../../../HigherOrderComponents/withLoading';
import { templateType } from '../../../../models/marleyTypes';
import { getItems } from '../../../../actions/common';
import GhostRows from '../../../elements/Ghosts/Ghosts';
import Panel from '../../../Panel/Panel';
import SearchBar from '../../../SearchBar/SearchBar';
import LineDropdown from '../../../elements/dropdowns/LineDropdown/LineDropdown';

const EmptyRows = ({
  searchTerm,
  setShow,
  hasNoMessages,
  lineOfBusinessName,
}) => (
  <div className="message-templates-empty">
    {hasNoMessages && (
      <div className="noTemplatesWrap">
        <p>
          {`There aren't any message templates for the ${
            lineOfBusinessName
              ? `${lineOfBusinessName} line of
          business`
              : 'Organization'
          }.`}
        </p>
        <Button onClick={() => setShow(true)} type="outline">
          + New Template
        </Button>
      </div>
    )}
    <div className="message-templates-empty-help">
      {searchTerm && !hasNoMessages ? (
        <>
          <div className="message-templates-empty-icon">
            <BinocularsIcon />
          </div>
          <div className="message-templates-empty-header">
            <Translate value="messageTemplates.noTemplateFound" />
          </div>
          {I18n.t('messageTemplates.coundNotFind')}
          {' '}
          "
          {searchTerm}
          "
        </>
      ) : (
        ''
      )}
    </div>
  </div>
);

EmptyRows.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  setShow: PropTypes.func.isRequired,
  hasNoMessages: PropTypes.bool.isRequired,
  lineOfBusinessName: PropTypes.string.isRequired,
};

const sortDropdownList = [
  {
    id: 'newest',
    label: 'Newest',
    placeholderLabel: 'Newest',
    query: true,
    sort: (brands) => brands.sort((a, b) => sortByDate(
      b?.raw?.createdAt || b?.createdAt,
      a?.raw?.createdAt || a?.createdAt,
    )),
  },
  {
    id: 'oldest',
    label: 'Oldest',
    placeholderLabel: 'Oldest',
    query: false,
    sort: (brands) => brands.sort((a, b) => sortByDate(
      a?.raw?.createdAt || a?.createdAt,
      b?.raw?.createdAt || b?.createdAt,
    )),
  },
  {
    id: 'lastUpdated',
    label: 'Last Updated',
    placeholderLabel: 'Last Updated',
    query: false,
    sort: (brands) => brands.sort((a, b) => sortByDate(
      b?.raw?.updatedAt || b?.updatedAt,
      a?.raw?.updatedAt || a?.updatedAt,
    )),
  },
  {
    id: 'az',
    label: 'A-Z',
    placeholderLabel: 'A-Z',
    query: false,
    sort: (brands) => brands.sort((a, b) => sortByAlphabetical(a?.token, b?.token)),
  },
  {
    id: 'za',
    label: 'Z-A',
    placeholderLabel: 'Z-A',
    query: false,
    sort: (brands) => brands.sort((a, b) => sortByAlphabetical(b?.token, a?.token)),
  },
];

/**
 * Message Templates Panel
 * @param {boolean} isLoading template list is loading
 * @param {function} removeTemplate funciton to delete template
 * @param {function} createTemplate create the template
 * @param {array} list list of message templates
 * @param {array} tokens list of tokens, e.g. $claimNumber
 * @param {function} updateTemplate function to edit template
 * */
const MessageTemplates = ({
  list,
  createTemplate,
  updateTemplate,
  tokens,
  getItems,
  getKeys,
  removeTemplate,
  isLoading,
  lineOfBusiness,
  updateOrgSetting,
  title,
  orgId,
}) => {
  const [templateToRemove, setTemplateToRemove] = useState(null);
  const [show, setShow] = useState(false);
  const [formObject, setFormObject] = useState();
  const [filter, setFilter] = useState('');
  const [filteredList, setFilteredList] = useState(list);
  const [sortType, setSortType] = useState(sortDropdownList[0]);
  useEffect(() => {
    if (!lineOfBusiness?._id) {
      getItems('TEMPLATE');
    }
    getKeys();
  }, [getItems, getKeys, lineOfBusiness?._id]);

  useEffect(() => {
    const filterText = filter.toLowerCase();
    const paredList = list.filter(
      (item) => item.token.toLowerCase()?.includes(filterText)
        || item?.messageTemplate?.toLowerCase()?.includes(filterText)
        || item?.template?.toLowerCase()?.includes(filterText),
    );
    setFilteredList(paredList);
  }, [filter, list]);

  useEffect(() => {
    setFilteredList(list);
  }, [list]);

  useEffect(() => {
    if (templateToRemove) setShow(false);
  }, [templateToRemove]);

  const handleRemoveTemplate = (template) => {
    if (lineOfBusiness?._id) {
      updateOrgSetting(
        {
          organizationId: orgId,
          lineOfBusinessId: lineOfBusiness?._id,
          linesOfBusinessTemplates: {
            id: template.id,
            category: ['CHAT'],
            action: 'DELETE',
          },
        },
        `Message template (${template?.templateKey}) deleted.`,
        'Error deleting message template. Please try again.',
      );
    } else {
      removeTemplate(template.id);
    }
    setTemplateToRemove(null);
  };

  const setEditItem = (formObject) => {
    setFormObject(formObject);
    setShow(true);
  };

  const statusToggle = (id) => {
    const item = sortDropdownList.find((i) => i.id === id) || {};
    setSortType(item);
  };

  const hasTemplates = filteredList.length > 0;

  const applyHighlightingToTokens = useCallback(
    (messageTemplates) => {
      const tokenSet = new Set(tokens?.map((token) => token));
      return messageTemplates?.map((message) => ({
        ...message,
        messageTemplate: constructHighlightedText(
          message?.messageTemplate || message?.template,
          tokenSet,
        ),
      }));
    },
    [tokens],
  );

  const filteredTemplates = useMemo(() => {
    const sortedMessageTemplates = sortType?.sort(filteredList);
    return applyHighlightingToTokens(sortedMessageTemplates);
  }, [applyHighlightingToTokens, filteredList, sortType]);

  const lineOfBusinessName = lineOfBusiness
    ? `${capitalizeEachWord(lineOfBusiness?.type)} ${capitalizeEachWord(
      lineOfBusiness?.displayName,
    )}`
    : '';

  return (
    <Panel
      className="template-mgmt-page"
      title={title}
      header={(
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <PermissionVerifier
            neededPermissions={['MESSAGE_TEMPLATE_ADD_AND_EDIT']}
          >
            <Button
              disabled={isLoading}
              type="positive-marley"
              onClick={() => setShow(true)}
            >
              + New Template
            </Button>
          </PermissionVerifier>
        </div>
      )}
    >
      <p>
        Create custom message templates available on
        {' '}
        {lineOfBusiness ? lineOfBusinessName : 'Organization'}
        {' '}
        cases.
      </p>
      <div className="message-templates">
        <CreateTemplate
          show={show}
          setShowForm={setShow}
          createTemplate={createTemplate}
          editTemplate={updateTemplate}
          setTemplateToRemove={setTemplateToRemove}
          editObject={formObject}
          tokens={tokens}
          lob={lineOfBusiness}
          updateOrgSetting={updateOrgSetting}
          orgId={orgId}
        />
        <RemoveTemplateConfirmation
          title={templateToRemove?.templateKey}
          show={!!templateToRemove}
          setShow={(s) => {
            if (!s) setTemplateToRemove(null);
          }}
          cancel={() => setTemplateToRemove(null)}
          remove={() => handleRemoveTemplate(templateToRemove)}
        />
        <div className="message-templates-list">
          {isLoading && <GhostRows type="card" />}
          {list?.length > 0 && !isLoading && (
            <section className="countSortSearchBar">
              <div>
                {filteredTemplates?.length === 1
                  ? `${filteredTemplates?.length} Template`
                  : `${filteredTemplates?.length} Templates`}
              </div>
              <div className="searchSort">
                <SearchBar
                  placeholder="Search templates..."
                  onChange={(e) => setFilter(e.target.value)}
                />
                <LineDropdown label={`Sort: ${sortType?.label}`}>
                  <List
                    items={sortDropdownList}
                    onToggle={statusToggle}
                    selectedIds={sortType?.id}
                  />
                </LineDropdown>
              </div>
            </section>
          )}
          <section className="scrollableTemplateList">
            {hasTemplates
              && !isLoading
              && filteredTemplates.map((template) => (
                <TemplateCard
                  template={template}
                  setEditItem={setEditItem}
                  setTemplateToRemove={setTemplateToRemove}
                  key={template?.id}
                />
              ))}
            {!hasTemplates && !isLoading && (
              <EmptyRows
                searchTerm={filter}
                setShow={setShow}
                hasNoMessages={list?.length === 0}
                lineOfBusinessName={lineOfBusinessName}
              />
            )}
          </section>
        </div>
      </div>
    </Panel>
  );
};

MessageTemplates.propTypes = {
  removeTemplate: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(Object),
  tokens: PropTypes.arrayOf(String),
  createTemplate: PropTypes.func.isRequired,
  updateTemplate: PropTypes.func.isRequired,
  getItems: PropTypes.func.isRequired,
  getKeys: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  lineOfBusiness: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string,
    displayName: PropTypes.string,
  }),
  updateOrgSetting: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  title: PropTypes.string,
};

MessageTemplates.defaultProps = {
  list: [],
  tokens: [],
  lineOfBusiness: null,
  title: '',
};

const mapDispatchToProps = {
  openCreateForm,
  closeForm,
  removeTemplate,
  createTemplate,
  updateTemplate,
  getItems,
  getKeys,
  updateOrgSetting,
  setIsUpdatingOrgSetting,
};
const mapStateToProps = (
  { templates, auth },
  { lineOfBusiness = {}, lobTemplateMessages = [] },
) => ({
  ...selector(templates),
  list: lineOfBusiness?._id ? lobTemplateMessages : selector(templates).list,
  orgId: auth?.user?.organizationId,
});
const MessageTemplatesWithLoading = withLoading(MessageTemplates, {
  type: templateType,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MessageTemplatesWithLoading);
