import _ from 'lodash';
import { addUnreadMessageIndication } from '../helpers';

const selector = (state) => {
  const pinnedJobsSelector = _.reduce(_.get(state.jobs, 'pinnedJobs', []), (acc, id) => {
    acc[id] = true;
    return acc;
  }, {});

  const jobList = addUnreadMessageIndication({
    jobs: _.get(state.jobs, 'list', []),
    viewedChats: _.get(state, 'jobs.viewedChats'),
    isNewInbox: _.get(state, 'profile.properties.isNewInbox', false),
  });

  const closedJobs = _.filter(jobList, (j) => j.isClosed);
  const allPinnedJobs = _.filter(jobList, (j) => pinnedJobsSelector[j.id]);
  const pinnedJobs = _.difference(allPinnedJobs, closedJobs);
  const unpinnedJobs = _.difference(jobList, _.concat(pinnedJobs, closedJobs));

  const caseSelectorId = _.get(state.jobs, 'caseSelectorId', 'all');
  return {
    presetFilterIds: _.get(state.jobs, 'presetFilterIds'),
    authId: _.get(state.auth, 'user._id'),
    activeJob: _.get(state.jobs, 'activeJob', {}),
    previousActiveChat: _.get(state.jobs, 'previousActiveChat.', ''),
    filteredOperatorIds: _.get(state, 'jobs.filteredOperatorIds', []),
    orgId: _.get(state.auth, 'user.organizationId'),
    pinnedJobs,
    jobs: unpinnedJobs,
    caseSelectorId,
  };
};

export default selector;
