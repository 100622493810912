import { ROLE_OPERATOR } from '../../../../constants/general'

export const defaultRelationships = {
  primaryEndUser: 'Primary Contact',
  primaryOperator: 'Primary Operator',
  secondaryOperator: 'Secondary Operator',
}

export const relationships = [
  {
    label: 'Parent',
    value: 'Parent',
  },
  {
    label: 'Spouse / Partner',
    value: 'Spouse / Partner',
  },
  {
    label: 'Child',
    value: 'Child',
  },
  {
    label: 'Other Family',
    value: 'Other Family',
  },
  {
    label: 'Friend',
    value: 'Friend',
  },
  {
    label: 'Agent',
    value: 'Agent',
  },
  {
    label: 'Contractor',
    value: 'Contractor',
  },
  {
    label: 'Vendor',
    value: 'Vendor',
  },
  {
    label: 'Witness',
    value: 'Witness',
  },
  {
    label: 'Payor',
    value: 'Payor',
  },
]

export const setRelationship = (participant, jobId, primaryEndUserId, primaryOperatorId) => {
  if (participant?.id === primaryOperatorId) {
    return {
      ...participant,
      relationship: defaultRelationships.primaryOperator,
    }
  }

  if (participant.role === ROLE_OPERATOR) {
    return {
      ...participant,
      relationship: defaultRelationships.secondaryOperator,
    }
  }

  if (participant?.id === primaryEndUserId) {
    return {
      ...participant,
      relationship: defaultRelationships.primaryEndUser,
    }
  }

  return {
    ...participant,
    relationship: participant?.relationships?.[jobId] || '',
  }
}

export const setRelationshipFromList = (
  participants = [],
  jobId,
  primaryEndUserId,
  primaryOperatorId,
) => participants.map(
  (participant) => setRelationship(participant, jobId, primaryEndUserId, primaryOperatorId),
)
