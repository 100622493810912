import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@himarley/unity';
import { setIsUpdatingOrgSetting } from '../../../actions/organization';
import BrandCard from './BrandCard';
import LineOfBusinessCard from './LineOfBusinessCard';
import LoBTrayContainer from './LoBTrayContainer';
import { useCheckPermissions } from '../../../helpers/common';
import { LINES_OF_BUSINESS_ENABLE } from '../../../constants/permissions';

const BrandLinesColumn = ({
  brandName,
  brandingMap,
  handleEditBrand,
  handleDeleteBrand,
  onEditBrand,
  linesOfBusiness,
  orgId,
}) => {
  const dispatch = useDispatch();
  const isUpdatingOrgSetting = useSelector(
    (state) => state?.organizations?.isUpdatingOrgSetting,
  );

  const [selectedBrand, setSelectedBrand] = useState(null);
  const showCreateLoBBtn = useCheckPermissions([LINES_OF_BUSINESS_ENABLE]);

  useEffect(() => {
    if (isUpdatingOrgSetting === false) {
      dispatch(setIsUpdatingOrgSetting(null));
      setSelectedBrand('');
    } else if (isUpdatingOrgSetting === 'error') {
      dispatch(setIsUpdatingOrgSetting(null));
    }
  }, [dispatch, isUpdatingOrgSetting]);

  return (
    <section className="brandLinesColWrap">
      <LoBTrayContainer
        className="lobTrayContainer"
        onCancel={() => setSelectedBrand('')}
        saveButtonText="Save Lines"
        title="Lines of Business"
        orgId={orgId}
        brandName={brandName}
        linesOfBusiness={linesOfBusiness}
        isOpen={selectedBrand}
      />
      <BrandCard
        name={brandName}
        index={brandingMap?.get(brandName)}
        handleEditBrand={handleEditBrand}
        handleDeleteBrand={handleDeleteBrand}
        brandingMap={brandingMap}
        hasLoB={linesOfBusiness.length > 0}
        isSelected={selectedBrand === brandName}
      />
      <div className="verticalDivider">
        <div className="verticalLeftOne" />
        <div />
      </div>
      <div
        data-testid={`${brandName?.replaceAll(' ', '')}LobColumn`}
        className={`${
          linesOfBusiness.length === 0 ? 'noLoBs' : ''
        } linesOfBusinessWrap ${
          selectedBrand === brandName ? 'selectedLos' : ''
        }`}
      >
        {linesOfBusiness.length === 0 && <p>No Lines Selected</p>}
        {linesOfBusiness.map((lob) => (
          <LineOfBusinessCard
            name={lob.displayName}
            handleEditBrand={onEditBrand}
            key={lob?._id || lob?.displayName}
            lob={lob}
          />
        ))}
        {showCreateLoBBtn && (
          <>
            <Button
              data-testid="new-brand-button"
              data-test="new-brand-button"
              className={`newBrandButton ${brandName?.replaceAll(
                ' ',
                '',
              )}ChooseLobBtn`}
              onClick={() => setSelectedBrand(brandName)}
              type="outline"
            >
              Choose Lines of Business
            </Button>
            <br />
          </>
        )}
      </div>
    </section>
  );
};

BrandLinesColumn.propTypes = {
  organization: PropTypes.shape({}),
  linesOfBusiness: PropTypes.shape([]),
  brandName: PropTypes.string.isRequired,
  handleEditBrand: PropTypes.func.isRequired,
  handleDeleteBrand: PropTypes.func.isRequired,
  onEditBrand: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  brandingMap: PropTypes.shape({ get: PropTypes.func }).isRequired,
};

BrandLinesColumn.defaultProps = {
  organization: {},
  linesOfBusiness: [],
};

export default BrandLinesColumn;
