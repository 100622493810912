import React, {
  useRef, useLayoutEffect, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import MediaItem from './MediaItem';
import useFileCache from '../../hooks/file-loader';
import { mediaItemPropTypes } from './media-helpers';

import './MediaContainer.less';

const fixedHeightItems = ['audio', 'video', 'text/x-vcard'];

const determineContainerHeight = (fixedHeight, isFixed, isThumbnail, contentType) => {
  const normalizedContentType = contentType?.toLowerCase() || '';
  const alwaysFixedHeight = fixedHeightItems.some(
    (fixedContentType) => normalizedContentType.includes(fixedContentType),
  );
  if (isThumbnail || (!alwaysFixedHeight && isFixed)) {
    return fixedHeight;
  }
  if (!alwaysFixedHeight || !isFixed) {
    return '100%';
  }
  return null;
};

const MediaContainer = ({
  configuration,
  onExpand,
  onLoad,
  mediaItem,
  isViewingRedactedImage,
}) => {
  const {
    mode, fixedHeight = null, fixedWidth = null, isThumbnail = false,
  } = configuration;
  const {
    fileUrl,
    isImageRedacted,
    providerId,
  } = mediaItem || {};
  const file = useFileCache(fileUrl, isImageRedacted);
  const ref = useRef(null);
  const [dimensions, setDimensions] = useState({ width: fixedWidth, height: fixedHeight });
  const isFixed = (mode === 'fixed');

  useEffect(() => {
    if (!onLoad || file.loadingState === 'loading') {
      return;
    }
    onLoad({ ...file });
  }, [file, onLoad]);

  useLayoutEffect(() => {
    if (isFixed) {
      return () => {};
    }

    // if mode is dynamic, setup dynamic sizing
    const updateDimensions = () => {
      if (ref.current) {
        setDimensions({
          width: ref.current.offsetWidth,
          height: ref.current.offsetHeight,
        });
      }
    };
    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, [mode, isFixed]);

  return (
    <div
      ref={ref}
      className={`media-item-boundary media-item-boundary-${mode}`}
      data-testid={`media-item-boundary-${providerId}`}
      style={{
        height: determineContainerHeight(
          fixedHeight,
          isFixed,
          isThumbnail,
          mediaItem.mediaInfo?.contentType,
        ),
        width: isFixed ? `${fixedWidth}px` : '100%',
      }}
    >
      <MediaItem
        containerDimensions={dimensions}
        file={file}
        onExpand={onExpand}
        mediaItem={mediaItem}
        isThumbnail={isThumbnail}
        isViewingRedactedImage={isViewingRedactedImage}
        isImageRedacted={isImageRedacted}
      />
    </div>
  );
};

MediaContainer.propTypes = {
  onExpand: PropTypes.func,
  onLoad: PropTypes.func,
  configuration: PropTypes.shape({
    mode: PropTypes.oneOf([
      'fixed', 'dynamic',
    ]),
    fixedHeight: PropTypes.number,
    fixedWidth: PropTypes.number,
    isThumbnail: PropTypes.bool,
  }),
  mediaItem: mediaItemPropTypes.isRequired,
  isViewingRedactedImage: PropTypes.bool,
};

MediaContainer.defaultProps = {
  onExpand: null,
  onLoad: null,
  configuration: {
    mode: 'dynamic',
    fixedWidth: null,
    fixedHeight: null,
    isThumbnail: false,
  },
  isViewingRedactedImage: false,
};

export default MediaContainer;
