/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-filename-extension */
import _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Check from '../../../../images/icons/check.svg';
import GroupIcon from '../../icons/GroupIcon';
import UserIcon from '../../icons/UserIcon';
import LoBIcon from '../../Settings/CompanyHierarchy/LoBIcon';
import Ghosts from '../Ghosts/Ghosts';
import FocusedList from './FocusedList';
import { capitalizeEachWord } from '../../../helpers/format';
import './List.less';

/**
 * List of items w/ toggle and selection
 * @param   {*} items items in the list in format { id, label, icon }
 * @param   {array} selectedIds
 *  ids of selected items. way to control from outside component. used also to set initial selection
 * @param   {function} onToggle triggered when item clicked in menu
 * @param   {boolean} multiSelect ability to select multiple items
 * @param   {boolean} isLoading for loading lists, ghost loading
 * @param   {string} className passthrough for className
 * @param   {number} maxDisplay max elements to display
 * @param   {boolean} showIcon Showing a Group icon if selected
 * @return  {Component}
 * testing up to date in ./list.test.js
 */

const List = ({
  id,
  isLoading,
  items,
  selectedIds,
  onToggle,
  multiSelect,
  className,
  maxDisplay,
  showIcon,
  focusedValue,
  adjustable,
  getItemData,
  customizedIcon,
  capitalizeLabels,
}) => {
  const itemRef = useRef();
  const [selectIds, setSelectIds] = useState([]);
  const [height, setHeight] = useState();

  useEffect(() => {
    if (selectedIds) setSelectIds(selectedIds);
  }, [selectedIds]);

  const toggle = (toggleId) => {
    if (onToggle) onToggle(toggleId);
    if (getItemData) {
      const itemData = items.filter((i) => i._id === toggleId);
      getItemData(itemData);
    }
    let newSelectedIds = [];
    const isRemoving = (selectIds || []).find((i) => i === toggleId);

    if (isRemoving) {
      newSelectedIds = multiSelect
        ? [...selectIds].filter((i) => i !== toggleId)
        : [];
    } else {
      newSelectedIds = multiSelect ? [...selectIds, toggleId] : [toggleId];
    }

    setSelectIds(newSelectedIds);
  };

  useEffect(() => {
    const max = maxDisplay;

    if (!isLoading) {
      const itemHeight = _.get(itemRef, 'current.clientHeight', 0);
      if (adjustable) { setHeight(max * ((itemHeight > 0 ? itemHeight : 24) + 4) + 8); }
      if (itemHeight > 0) setHeight(max * (itemHeight + 4) + 8);
    }
  }, [isLoading, maxDisplay, adjustable]);

  const slicedList
    = height && maxDisplay ? items : items.slice(0, maxDisplay || items?.length);

  return (
    <div
      style={height ? { height } : {}}
      data-test={`list-${id || '0'}`}
      className={`list ${className || ''}`}
    >
      {isLoading && <Ghosts numItems={maxDisplay} />}
      {!isLoading
        && (slicedList || []).map((i) => (
          <div
            key={i.id}
            ref={itemRef}
            onClick={() => {
              toggle(i.id || i._id);
            }}
            // TODO: this should probably be onMouseOver https://marley.atlassian.net/browse/HMB-4827
            // eslint-disable-next-line react/no-unknown-property
            onHover={() => {
              if (i.onHover) i.onHover(i.id);
            }}
            data-test={`list-item-${i.id}`}
            data-testid={`list-item-${i.id}`}
            className={`list-item ${
              selectIds && selectIds.includes(i.id) ? 'list-item-selected' : ''
            } list-item-${i.id}`}
          >
            {i.icon && <span className="list-item-icon">{i.icon}</span>}
            <span className="list-item-label">
              {showIcon && !i.isDefault && (
                <span className="list-item-identifier-icon">
                  {i.isGroup ? <GroupIcon /> : <UserIcon />}
                  <LoBIcon name={i?.subType} />
                </span>
              )}
              {customizedIcon && (
                <span className="list-item-identifier-icon">
                  <LoBIcon name={i?.subType} />
                </span>
              )}
              <span>
                {focusedValue
                  ? (
                    <FocusedList
                      key={i.label}
                      focusedValue={focusedValue}
                      label={i.label}
                      className="focus-bold"
                  />
                    )
                  : (
                      capitalizeLabels ? capitalizeEachWord(i.label) : i.label
                    )}
              </span>
            </span>
            <span className="list-item-check">
              <Check />
            </span>
          </div>
        ))}
    </div>
  );
};

List.propTypes = {
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  items: PropTypes.arrayOf(Object),
  selectedIds: PropTypes.arrayOf(String),
  onToggle: PropTypes.func,
  multiSelect: PropTypes.bool,
  className: PropTypes.string,
  maxDisplay: PropTypes.number,
  showIcon: PropTypes.bool,
  adjustable: PropTypes.bool,
  focusedValue: PropTypes.string,
  getItemData: PropTypes.func,
  customizedIcon: PropTypes.bool,
  capitalizeLabels: PropTypes.bool,
};

List.defaultProps = {
  id: '',
  isLoading: false,
  items: [],
  selectedIds: [],
  onToggle: () => {},
  multiSelect: false,
  className: '',
  maxDisplay: undefined,
  showIcon: false,
  adjustable: false,
  focusedValue: '',
  getItemData: () => {},
  customizedIcon: false,
  capitalizeLabels: false,
};

export default List;
