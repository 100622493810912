export const languageList = [
  {
    id: 'en',
    label: 'English (Default)',
  },
  {
    id: 'ar',
    label: 'Arabic',
  },
  {
    id: 'bs',
    label: 'Bosnian',
  },
  {
    id: 'zh',
    label: 'Chinese',
  },
  {
    id: 'fr',
    label: 'French',
  },
  {
    id: 'fr-ca',
    label: 'French Canadian',
  },
  {
    id: 'de',
    label: 'German',
  },
  {
    id: 'ht',
    label: 'Haitian Creole',
  },
  {
    id: 'hi',
    label: 'Hindi',
  },
  {
    id: 'ja',
    label: 'Japanese',
  },
  {
    id: 'ko',
    label: 'Korean',
  },
  {
    id: 'pl',
    label: 'Polish',
  },
  {
    id: 'pt',
    label: 'Portuguese',
  },
  {
    id: 'ru',
    label: 'Russian',
  },
  {
    id: 'so',
    label: 'Somali',
  },
  {
    id: 'es',
    label: 'Spanish',
  },
  {
    id: 'th',
    label: 'Thai',
  },
  {
    id: 'tl',
    label: 'Tagalog',
  },
  {
    id: 'ur',
    label: 'Urdu',
  },
  {
    id: 'vi',
    label: 'Vietnamese',
  },
];

export const languagesById = languageList.reduce((allLanguagesKeyedById, languageItem) => ({
  ...allLanguagesKeyedById,
  [languageItem.id]: languageItem.label,
}), {});
