/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import DropdownIconDown from '../../../../../images/icons/dropdown-arrow--down.svg';
import usePermissionCheck from '../../../HigherOrderComponents/usePermissionCheck';
import LanguageMenu from '../../../Chats/Chat/ChatInput/LanguageMenu/LanguageMenu';

import './DropdownWithMenu.less';

const DropdownWithMenu = ({
  Icon,
  disabled,
  style,
  hasAccess,
  value,
  selectLanguage,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const componentRef = useRef(null);

  const handleClick = (e) => {
    if (componentRef.current && componentRef.current.contains(e.target)) return;
    setMenuOpen(false); // click outside the component
  };

  // Adds listeners to detect clicks outside of the component
  useEffect(() => {
    if (menuOpen) {
      // eslint-disable-next-line no-undef
      document.addEventListener('click', handleClick, true);
    } else {
      // eslint-disable-next-line no-undef
      document.removeEventListener('click', handleClick, true);
    }
    return () => {
      // eslint-disable-next-line no-undef
      document.removeEventListener('click', handleClick, true);
    };
  }, [menuOpen]);

  const handleClickMenu = useCallback(() => {
    setMenuOpen(!menuOpen);
  }, [menuOpen]);

  const handleSelect = (val) => {
    selectLanguage(val);
    setMenuOpen(false);
  }

  return (
    <div style={style} ref={componentRef}>
      {hasAccess && (
        <button
          onClick={handleClickMenu}
          className="dropdownWrapper"
          disabled={disabled}
          type="button"
        >
          <div>
            <Icon
              className={`${
                menuOpen && 'marley-dropdown-open'
              } type-ahead-dropdown`}
            />
          </div>
          <div className="dropdownValue">{value}</div>
          <div className={`${menuOpen && 'marleyDropdownOpen'} dropdownIcon`}>
            <DropdownIconDown />
          </div>
        </button>
      )}
      {menuOpen && (
        <LanguageMenu
          className="dropdownMenu"
          selectLanguage={handleSelect}
        />
      )}
    </div>
  );
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export default usePermissionCheck(DropdownWithMenu);
