/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react';
import ChatIcon from '../../../../../images/icons/chats.svg';
import EmojiIcon from '../../../../../images/icons/emoji.svg';
import IconButton from '../../../elements/buttons/IconButton/IconButtonWithPermissions';
import PaperClipIcon from '../../../../../images/icons/paperclip.svg';
import InsurPayIcon from '../../../../../images/icons/insurpay.svg';
import ClockIcon from '../../../../../images/icons/clock.svg';
// eslint-disable-next-line import/named
import ScheduleMenu from './ScheduleMessage/ScheduleMenu';
import IconButtonWithMenu from '../../../elements/iconButtons/IconButtonWithMenu';
import ScheduleMessage from './ScheduleMessage';
import ListScheduledMessages from './ListScheduledMessages';

const ChatInputButtons = ({
  clearAndFocusTokenSelection,
  togglePanel,
  uploadFileIsInProgress,
  disabled,
  verifiedUser,
  makePayment,
  handleFileSelectChange,
  queueMessage,
  chatId,
  jobId,
  scheduledMessages,
  participants,
  cancelScheduledMessage,
}) => {
  const [showScheduleMessageModal, setShowScheduleMessageModal] = useState(false);
  const [showScheduleMessageListModal, setShowScheduleMessageListModal] = useState(false);
  const [scheduledDate, setScheduledDate] = useState('');
  const [scheduledTime, setScheduledTime] = useState('');

  let outBoundMediaRef;
  const outBoundMediaEle = (
    // eslint-disable-next-line react/jsx-filename-extension
    <input
      data-testid={`hidden-file-input-${jobId}`}
      type="file"
      className="hidden-file-input"
      onChange={handleFileSelectChange}
      disabled={disabled}
      ref={(el) => {
        outBoundMediaRef = el;
      }}
    />
  );

  const onTemplateButtonClick = useCallback(() => {
    clearAndFocusTokenSelection();
    togglePanel('TEMPLATE');
  }, [clearAndFocusTokenSelection, togglePanel]);

  const inputButtons = [
    {
      id: 'templates',
      Icon: ChatIcon,
      tooltip: 'Use Template',
      order: 2,
      props: { onClick: onTemplateButtonClick },
      className: 'icon-button-templates',
    },
    {
      id: 'imageUpload',
      tooltip: 'Send Image',
      order: 3,
      Icon: PaperClipIcon,
      props: {
        children: outBoundMediaEle,
        onClick: () => {
          outBoundMediaRef.click();
        },
      },
      className: 'icon-button-media',
    },
    {
      id: 'EMOJI',
      Icon: EmojiIcon,
      order: 4,
      className: 'icon-button-emoji',
    },
    {
      id: 'insurpay',
      props: { onClick: makePayment },
      tooltip: 'Make InsurPay Payment',
      Icon: InsurPayIcon,
      hide: true,
    },
  ];

  return (
    <div className="chat-input-buttons">
      {showScheduleMessageModal && (
        <ScheduleMessage
          queueMessage={queueMessage}
          chatId={chatId}
          jobId={jobId}
          show={showScheduleMessageModal}
          toggleShow={setShowScheduleMessageModal}
          scheduledDate={scheduledDate}
          setScheduledDate={setScheduledDate}
          scheduledTime={scheduledTime}
          setScheduledTime={setScheduledTime}
        />
      )}
      <ListScheduledMessages
        scheduledMessages={scheduledMessages}
        show={showScheduleMessageListModal}
        toggleShow={setShowScheduleMessageListModal}
        participants={participants}
        cancelScheduledMessage={cancelScheduledMessage}
      />
      {/* 'SCHEDULED_MESSAGES_ACCESS' */}
      <IconButtonWithMenu
        neededPermissions={[]}
        showDot={(scheduledMessages || []).length > 0}
        disabled={!verifiedUser}
        Icon={ClockIcon}
        style={{ order: 1, marginRight: '5px' }}
        data-testid="scheduled-message-button"
        // eslint-disable-next-line react/no-unstable-nested-components
        Menu={() => (
          <ScheduleMenu
            setShowScheduleMessageListModal={setShowScheduleMessageListModal}
            setShowScheduleMessageModal={setShowScheduleMessageModal}
          />
        )}
      />
      {inputButtons.filter((b) => !b.hide).map((b) => {
        const {
          Icon, props, order, neededPermissions = [],
        } = b;

        const disableIcon = uploadFileIsInProgress ? (disabled) : (disabled || !verifiedUser);

        return (
          <div key={b.id} style={{ order, flex: 0, marginRight: '5px' }}>
            <IconButton
                // eslint-disable-next-line no-nested-ternary
              className={`chat-input-icon-button ${b.className}`}
              data-testid={`chat-input-button-${b.id}`}
              onClick={() => {
                togglePanel(b.id);
                if (props && props.onClick) props.onClick();
              }}
                // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              disabled={disableIcon}
              neededpermissions={neededPermissions}
              key={b.id}
            >
              <Icon className="interactive-buttons" />
              {props && props.children}
            </IconButton>
          </div>
        );
      })}
    </div>
  );
};

export default ChatInputButtons;
