import React from 'react'
import Required from '../../../icons/Required'
import './TextAreaInput.less'

const TextAreaInput = (props, ref) => {
  const {
    inputRef,
    allowResize,
    disabled,
    style,
    className,
    required,
    error,
    label,
    help,
    value,
    maxLength,
    onChange,
    onBlur,
    placeholder,
    onKeyDown,
  } = props;

  const message = error

  return (
    <div className={`textarea-input ${className || ''}`} style={style} >
      {label && (
        <div className={`textarea-input-label ${error ? 'error' : ''}`}>
          {label}{required && <Required />}
        </div>
      )}
      {help && (
        <div className={`textarea-input-help ${error ? 'error' : ''}`}>
          {help}
        </div>
      )}
      <textarea
        ref={ref || inputRef}
        disabled={disabled}
        data-testid="textarea-input-box"
        className={`${allowResize ? 'textarea-input-box-resize' : 'textarea-input-box-noresize'} textarea-input-box`}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={(e) => {
          if (onKeyDown) onKeyDown(e)
        }}
        placeholder={placeholder}
        value={value}
        maxLength={maxLength}
      />
      {maxLength && <div data-test="textarea-input-char-count" className={`textarea-input-char-count ${value ? 'textarea-input-char-count-active' : ''}`}>{(value || '').length}/{maxLength}</div>}
      {message && <div className={`textarea-input-message ${error ? 'textarea-input-error-message' : ''}`}>
        {message}
      </div>}
    </div>
  )
}

const TextAreaInputRef = React.forwardRef(TextAreaInput);

export default TextAreaInputRef

