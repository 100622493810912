import {
  CalendarIcon,
  UserIcon,
} from '@himarley/unity';
import React from 'react';

import { formatDate } from '@app/helpers/datetimeWithoutMoment';

import styles from './voice-transcription.module.scss';

interface VoiceTranscriptionProps {
  author: string,
  createdAt: string,
  messageBody?: string,
}

const VoiceTranscription = ({
  author,
  createdAt,
  messageBody,
}: VoiceTranscriptionProps) => {
  const colorNevada = '#616E7C';
  return (
    <>
      <div className={styles.voiceTranscriptionHeader}>
        <span className={styles.headerSection}>
          <CalendarIcon color={colorNevada} className={styles.headerIcon} />
          {formatDate(createdAt, 'short', 'short')}
        </span>
        <span className={styles.headerSection}>
          <UserIcon color={colorNevada} className={styles.headerIcon} />
          {author}
        </span>
      </div>
      <div className={styles.voiceTranscriptionText}>
        {messageBody}
      </div>
    </>
  );
};

export default VoiceTranscription;
