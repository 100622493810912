import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@himarley/unity';
import { MentionsInput, Mention } from 'react-mentions';
import Card from '../../../elements/Card/Card';
import EditIcon from '../../../icons/EditIcon';
import GarbageIcon from '../../../icons/GarbageIcon';

const TemplateCard = ({ template, setEditItem, setTemplateToRemove }) => {
  const handleEditIconClick = () => setEditItem({
    id: template.id,
    template: template.messageTemplate || template?.template,
    templateKey: template.token,
  });

  const handleDeleteIconClick = () => setTemplateToRemove({
    id: template.id,
    template: template.messageTemplate,
    templateKey: template.token,
  });

  return (
    <Card
      key={template.id}
      id={template.id}
      header={(
        <>
          <strong className="templateTitle">{template.token}</strong>
          <div className="marley-card-editicons">
            <IconButton
              onClick={handleEditIconClick}
              className="editTemplateIcon"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={handleDeleteIconClick}
              className="deleteTemplateIcon"
            >
              <GarbageIcon />
            </IconButton>
          </div>
        </>
      )}
    >
      <MentionsInput
        value={template?.messageTemplate || template?.template}
        className="mentionsInput disabledMentionsInput"
      >
        <Mention className="noteSuggestion" />
      </MentionsInput>
    </Card>
  );
};

TemplateCard.propTypes = {
  setEditItem: PropTypes.func.isRequired,
  template: PropTypes.shape({
    id: PropTypes.string,
    messageTemplate: PropTypes.string,
    token: PropTypes.string,
    template: PropTypes.string,
  }).isRequired,
  setTemplateToRemove: PropTypes.func.isRequired,
};

export default TemplateCard;
