import React from 'react'
import PropTypes from 'prop-types'
import ButtonRow from '../../../elements/buttons/ButtonRow/ButtonRow'
import Button from '../../../elements/buttons/Button/Button'
import Divider from '../../../elements/Divider/Divider'
import './RemoveParticipant.less'

const createRemovePrompt = (name, relationship) => (relationship
  ? `Remove ${name} (${relationship}) from this conversation?` : `Remove ${name} from this conversation?`
)

const RemoveParticipant = ({
  participant,
  onClickRemoveParticipant,
  onClose,
  removeChatParticipantStatus,
}) => {
  const { name, relationship } = participant

  const isPending = removeChatParticipantStatus === 'PENDING'
  const hasError = removeChatParticipantStatus === 'ERROR'

  return (
    <div className="remove-participant-form">
      <div className="remove-participant-form-prompt">
        {createRemovePrompt(name, relationship)}
      </div>
      <Divider />
      {
        hasError ? (
          <p
            className="text-danger remove-participant-form-error"
            data-testid="removeParticipantFormError"
          >
            Unable to remove participant. Please try again later.
          </p>
        ) : null
      }
      <ButtonRow>
        <Button
          data-testid="removeParticipantCancelButton"
          className="remove-participant-form-cancel"
          disabled={isPending}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          data-testid="removeParticipantRemoveButton"
          className="remove-participant-form-remove"
          disabled={isPending}
          onClick={onClickRemoveParticipant}
          type="positive"
        >
          {isPending ? 'Removing...' : 'Remove Participant'}
        </Button>
      </ButtonRow>
    </div>
  )
}

RemoveParticipant.propTypes = {
  participant: PropTypes.shape({
    id: PropTypes.string,
    imageUrl: PropTypes.string,
    name: PropTypes.string,
    role: PropTypes.string,
    relationship: PropTypes.string,
  }).isRequired,
  onClickRemoveParticipant: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  removeChatParticipantStatus: PropTypes.string,
}

RemoveParticipant.defaultProps = {
  removeChatParticipantStatus: undefined,
}

export default RemoveParticipant
