/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, ButtonRow, Modal, TextInputLine,
} from '@himarley/unity';

const BrandModal = ({
  show,
  hideModal,
  handleAddBrand,
  isEditing,
  name,
  index,
  handleEditBrand,
  brandingMap,
  isUpdatingBranding,
}) => {
  const [brandName, setBrandName] = useState(name || '');
  const handleHideModal = () => {
    if (!isEditing) {
      setBrandName('');
    }
    hideModal();
  };

  useEffect(
    () => () => {
      if (!isEditing) {
        setBrandName('');
      }
    },
    [isEditing],
  );

  useEffect(() => {
    setBrandName(name);
  }, [name]);

  const isBrandNameUnique = () => {
    if (!isEditing || (isEditing && brandName !== name)) {
      return !brandingMap?.has(brandName?.trim());
    }
    return true;
  };

  const isSaveDisabled = () => {
    if (brandName?.trim()?.length < 1 || !isBrandNameUnique()) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      show={show}
      showCloseButton
      title={`${isEditing ? 'Edit' : 'Create'} Brand`}
      className="confirmation-modal"
      toggleModal={handleHideModal}
      backdrop
      size="lg"
    >
      <>
        <div className="requireFields">
          Required fields labeled with an asterisk.
        </div>
        <TextInputLine
          required
          label="Brand Name"
          value={brandName}
          onChange={(e) => setBrandName(e?.target?.value || '')}
          error={
            !isBrandNameUnique()
              ? 'Brand Name must be unique. That name already exists.'
              : ''
          }
        />
        <div className="confirmation-modal-footer">
          <ButtonRow>
            <Button
              onClick={handleHideModal}
              type="outline"
              className="cancelButton"
            >
              Cancel
            </Button>
            <Button
              data-testid="delete-note-button-confirm"
              data-test="delete-note-button-confirm"
              onClick={() => {
                isEditing
                  ? handleEditBrand(brandName, index)
                  : handleAddBrand(brandName);
              }}
              className={isEditing ? 'editBrandBtn' : 'createBrandBtn'}
              type="positive"
              disabled={isSaveDisabled() || isUpdatingBranding}
            >
              {isEditing ? 'Save Brand' : 'Create Brand'}
            </Button>
          </ButtonRow>
        </div>
      </>
    </Modal>
  );
};

BrandModal.propTypes = {
  isEditing: PropTypes.bool,
  handleAddBrand: PropTypes.func,
  show: PropTypes.bool,
  hideModal: PropTypes.func,
  name: PropTypes.string,
  index: PropTypes.string,
  handleEditBrand: PropTypes.func,
  brandingMap: PropTypes.shape({ has: PropTypes.func }),
  isUpdatingBranding: PropTypes.bool.isRequired,
};

BrandModal.defaultProps = {
  isEditing: false,
  handleAddBrand: () => {},
  show: false,
  hideModal: () => {},
  name: '',
  index: '',
  handleEditBrand: () => {},
  brandingMap: { has: () => {} },
};

export default BrandModal;
