import { useState, useEffect } from 'react';
import globalMediaCacheFactory from './global-file-cache';

const loadingStage = {
  loaded: 'loaded',
  error: 'error',
  loading: 'loading',
};

const useFileCache = (fileUrl, refetch) => {
  const [loadingState, setLoadingState] = useState(loadingStage.loading);
  const [localFileUrl, setLocalFileUrl] = useState(null);
  const [contentType, setContentType] = useState(null);
  useEffect(() => {
    const fetchImageWithAuthorization = async () => {
      if (!fileUrl) {
        console.warn('file url is not defined!');
        return;
      }
      const cache = await globalMediaCacheFactory();
      const cachedResponse = await cache.getItem(fileUrl);
      if (!cachedResponse || !cachedResponse.ok) {
        console.error(`error loading file at url: ${fileUrl} status: ${cachedResponse.statusText}`);
        setLoadingState(loadingStage.error);
        return;
      }
      const blob = await cachedResponse.blob();
      setLocalFileUrl(URL.createObjectURL(blob));
      setContentType(cachedResponse.headers.get('Content-Type'));
      setLoadingState(loadingStage.loaded);
    };
    fetchImageWithAuthorization();
  }, [fileUrl, loadingState, refetch]);
  return {
    loadingState,
    localFileUrl,
    contentType,
  };
};

export default useFileCache;
