export const TYPING_TEXT = 'user:typing_text';
export const MESSAGE_SEND = 'message:send';
export const CHAT_JOIN = 'chat:join';
export const NEW_JOB = 'job:new_created';
export const NEW_USER = 'user:new_created';
export const UPDATED_USER = 'user:updated';
export const NEW_BULK_USER = 'user:new_bulk_created';
export const REFRESH_MESSAGES_AI = 'chat:refresh_messages_ai';
export const NEW_MESSAGE = 'chat:refresh_messages';
export const NEW_TEMPLATE = 'msgtemplate:new_created';
export const MODIFIED_TEMPLATE = 'msgtemplate:update';
export const CREATE_NOTE = 'note:created';
export const DELETE_NOTE = 'note:deleted';
export const USER_MENTIONED = 'note:userMentioned';
export const USER_OKTA_INSERTION_FINISH = 'user:okta_insertion_finish';
export const UPDATE_GROUP = 'group:updated';
export const CREATE_GROUP = 'group:created';
export const ADD_REPLY = 'reply:added';
export const DELETE_REPLY = 'reply:deleted';
export const LOGOUT_USER = 'user:logout_user';
export const INLINE_MESSAGE_UPDATE = 'chat:inline_message_update';
export const USER_IS_TYPING = 'user:typing';
export const UPDATED_SURVEY_SCORE = 'job:update_survey_score';
export const USER_ASSIGNED = 'job:user_assigned';
export const ADD_TRANSLATION = 'chat:add_translation';
export const UPDATED_JOB = 'job:update';
export const UPDATED_NEEDS_ACTION = 'chat:needs_action_update';
export const UPDATE_MESSAGE = 'chat:update_message';
export const CHAT_END_USER_REMOVED = 'CHAT_END_USER_REMOVED';
export const CHAT_END_USER_JOINED = 'CHAT_END_USER_JOINED';
// export const CREATE_CASE = 'createCase';
export const CHAT_VIEW_TOPIC = (orgId, chatId) => `${orgId}#CHAT#${chatId}#web-v2`;

const TAG_MAP = {};
TAG_MAP[INLINE_MESSAGE_UPDATE] = 'needs-attention';
TAG_MAP[UPDATED_NEEDS_ACTION] = 'has-question';
TAG_MAP[UPDATE_MESSAGE] = 'failed-to-send';
export const EVENT_TAG_MAP = TAG_MAP;
