import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { parseParams } from '../../../services/helpers';
import { queryParameters } from '../../../helpers/urls';
import { SUCCESS } from '../../../constants/general';

/* eslint-disable import/prefer-default-export */
export const useSyncFiltersWithQueryParameters = () => {
  const {
    inboxFilters,
    search,
    checkedQueryParameters,
  } = useSelector((state) => ({
    inboxFilters: state.profile?.properties?.inboxFilters,
    search: state.jobs?.search,
    checkedQueryParameters: state?.profile?.checkedQueryParameters,
  }));

  const history = useHistory();
  const location = useLocation();

  const stateOperatorIds = useMemo(
    () => Object.keys(inboxFilters?.operatorIds || {}),
    [inboxFilters?.operatorIds],
  );
  const stateGroupIds = useMemo(
    () => Object.keys(inboxFilters?.groups || {}),
    [inboxFilters?.groups],
  );

  // Keep URL up to date with filter updated from Redux
  useEffect(() => {
    if (checkedQueryParameters !== SUCCESS) {
      return;
    }

    const queryParams = new URLSearchParams(location?.search);

    // Persistent
    const operatorIdsQueryParam = queryParams.getAll(queryParameters.operatorIds);
    const groupIdsQueryParam = queryParams.getAll(queryParameters.groupIds);
    const tagsQueryParam = queryParams.getAll(queryParameters.tags);
    const myChatsQueryParam = queryParams.get(queryParameters.myChats);
    const unassignedQueryParam = queryParams.get(queryParameters.unassigned);
    const secondaryChatsQueryParam = queryParams.get(queryParameters.secondaryOperatorIds);
    const refineByQueryParam = queryParams.get(queryParameters.refineBy);
    const sortQueryParam = queryParams.get(queryParameters.sort);
    const orderQueryParam = queryParams.get(queryParameters.order);

    // Not persistent
    const searchQueryParam = queryParams.get(queryParameters.search);

    if (JSON.stringify(stateOperatorIds) !== JSON.stringify(operatorIdsQueryParam)
      || JSON.stringify(stateGroupIds) !== JSON.stringify(groupIdsQueryParam)
      || JSON.stringify(inboxFilters?.tags) !== JSON.stringify(tagsQueryParam)
      || inboxFilters?.items?.myChats !== myChatsQueryParam
      || inboxFilters?.items?.unassigned !== unassignedQueryParam
      || search !== searchQueryParam
      || inboxFilters?.items?.secondaryOperatorIds !== secondaryChatsQueryParam
      || inboxFilters.refineBy !== refineByQueryParam
      || inboxFilters.sort !== sortQueryParam
      || inboxFilters.order !== orderQueryParam
    ) {
      const filter = {};
      if (stateOperatorIds?.length) {
        filter.operatorIds = stateOperatorIds;
      }
      if (stateGroupIds?.length) {
        filter.groupIds = stateGroupIds;
      }
      if (inboxFilters?.tags?.length) {
        filter.tags = inboxFilters?.tags;
      }
      if (inboxFilters?.items?.myChats) {
        filter.myChats = inboxFilters?.items?.myChats;
      }
      if (inboxFilters?.items?.unassigned) {
        filter.unassigned = inboxFilters?.items?.unassigned;
      }
      if (search) {
        filter.search = search;
      }
      if (inboxFilters?.items?.secondaryOperatorIds) {
        filter.secondaryOperatorIds = inboxFilters?.items?.secondaryOperatorIds;
      }
      if (inboxFilters?.refineBy) {
        filter.refineBy = inboxFilters?.refineBy;
      }
      if (inboxFilters?.sort) {
        filter.sort = inboxFilters?.sort;
      }
      if (inboxFilters?.order) {
        filter.order = inboxFilters?.order;
      }
      const newQueryParams = parseParams(filter);
      history.push({ search: `?${newQueryParams}` });
    }
  }, [
    history,
    inboxFilters,
    stateOperatorIds,
    stateGroupIds,
    search,
    location?.search,
    checkedQueryParameters,
  ]);
};
