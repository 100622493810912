/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { MentionsInput, Mention } from 'react-mentions';
import {
  Button, Modal, ButtonRow, TextInput,
} from '@himarley/unity';
import useForm from '../../../elements/hooks/useForm';
import IconButtonWithText from '../../../elements/buttons/IconButton/IconButtonWithText';
import LightBulb from '../../../../../images/icons/lightbulb.svg';
import GarbageIcon from '../../../icons/GarbageIcon';
import { capitalizeEachWord } from '../../../../helpers/format';

const MAX_CHAR_INPUT_LENGTH = 1400;

/**
 * Create Template
 * @param {boolean} show show this modal
 * @param {function} setShowForm trigger change in show
 * @param {function} createTemplate create the template
 * @param {function} editTemplate edit the template
 * @param {object} editObject template for editing
 * @param {array} tokens list of available tokens, e.g. $claimNumber
 * @param {function} setTemplateToRemove peg template for removal
 * */

// TODO: where used, replace with app/src/components/Form/MessageTemplate/MessageTemplateForm.jsx
const CreateTemplate = ({
  show,
  setShowForm,
  createTemplate,
  editTemplate,
  editObject,
  tokens,
  lob,
  setTemplateToRemove,
  updateOrgSetting,
  orgId,
}) => {
  const templateInput = useRef(null);

  // handling the basic form stuff
  const {
    formObject, handleFormChange, isEdit, canSubmit, resetForm,
  } = useForm({
    item: editObject || {},
    validation: [
      { id: 'templateKey', required: true },
      { id: 'template', required: true },
    ],
  });

  // handling the filter for the token popup
  const filteredTokens = tokens?.map((token) => ({
    id: token,
    display: token,
  }));

  // For Edit
  useEffect(() => {
    handleFormChange(editObject);
  }, [editObject]);

  useEffect(() => {
    if (!show) {
      resetForm({});
    }
  }, [show]);

  const closeForm = () => {
    setShowForm(false);
    handleFormChange({});
  };

  const handleSubmit = () => {
    const submitFunction = isEdit ? editTemplate : createTemplate;
    formObject.template = templateInput.current.textContent;
    if (lob?._id) {
      updateOrgSetting(
        {
          organizationId: orgId,
          lineOfBusinessId: lob?._id,
          linesOfBusinessTemplates: {
            ...formObject,
            category: ['CHAT'],
          },
        },
        isEdit
          ? `(${formObject?.templateKey}) template updated.`
          : `New message template (${formObject?.templateKey}) created.`,
        'Error saving message template. Please try again.',
      );
    } else {
      submitFunction(formObject);
    }
    closeForm();
  };

  const handleTextAreaChange = (e) => {
    if (e.target.value.length > MAX_CHAR_INPUT_LENGTH) {
      return;
    }
    const template = e.target.value;
    handleFormChange({ template });
  };

  const renderSuggestion = (suggestion) => (
    <div className="suggestionWrap">
      <div className="user-info">
        <div className="user-name">
          {' '}
          {suggestion?.display}
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      show={show}
      title={isEdit ? 'Edit Message Template' : 'New Message Template'}
      className={
        !isEdit
          ? 'createMessageEditorModal message-editor-modal'
          : 'editMessageEditorModal message-editor-modal'
      }
      toggleModal={closeForm}
      size="lg"
    >
      <p className="requiredWrap">Required fields labeled with an asterisk.</p>
      <>
        <div className="titleVisibleToWrap">
          <TextInput
            required
            label="Title"
            type="line"
            value={formObject.templateKey}
            onChange={(e) => handleFormChange({ templateKey: e.target.value })}
          />
          {lob && (
            <div className="lobVisibleTo">
              <div className="lobVisibleToLabel">Visible To</div>
              <div>
                {capitalizeEachWord(
                  `${lob?.type} ${lob?.displayName} (${lob?.branding})`,
                )}
              </div>
            </div>
          )}
        </div>
        <div className="text-input-label templatesLabelWrap">
          <div>
            Message Template
            <span className="text-danger">*</span>
          </div>
          <div
            className={`textarea-input-char-count ${
              formObject.template ? 'textarea-input-char-count-active' : ''
            }`}
            data-testid="textarea-input-char-count"
          >
            {formObject.template?.length ?? 0}
            /
            {MAX_CHAR_INPUT_LENGTH}
          </div>
        </div>
        <MentionsInput
          inputRef={templateInput}
          value={formObject.template ?? ''}
          onChange={handleTextAreaChange}
          className="mentionsInput"
          allowSpaceInQuery
        >
          <Mention
            renderSuggestion={renderSuggestion}
            className="noteSuggestion"
            trigger="$"
            data={filteredTokens}
          />
        </MentionsInput>
        <div className="tipWrap">
          <LightBulb />
          {' '}
          TIP: Type the '$' symbol to insert a message template
          variable.
        </div>

        <ButtonRow>
          {isEdit && (
            <IconButtonWithText
              onClick={() => setTemplateToRemove(editObject)}
              className="remove-template-icon-button"
              text="Delete Template"
            >
              <GarbageIcon />
            </IconButtonWithText>
          )}
          <div className="saveCreateButtons">
            <Button type="outline" onClick={closeForm}>
              Cancel
            </Button>
            <Button
              type="positive"
              data-test="create-group-button"
              disabled={!canSubmit}
              onClick={handleSubmit}
            >
              {isEdit ? 'Save' : 'Create'}
              {' '}
              Template
            </Button>
          </div>
        </ButtonRow>
      </>
    </Modal>
  );
};

CreateTemplate.propTypes = {
  updateOrgSetting: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  createTemplate: PropTypes.func.isRequired,
  editTemplate: PropTypes.func.isRequired,
  editObject: PropTypes.shape({}).isRequired,
  tokens: PropTypes.shape([]).isRequired,
  lob: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string,
    displayName: PropTypes.string,
    branding: PropTypes.string,
  }).isRequired,
  setTemplateToRemove: PropTypes.func.isRequired,
  setShowForm: PropTypes.func.isRequired,
};

export default CreateTemplate;
