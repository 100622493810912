import React from 'react'
import PropTypes from 'prop-types'

const ChatParticipantCount = ({ count }) => (
  <div>
    {count}
    {' '}
    Chat Participants
  </div>
)

ChatParticipantCount.propTypes = {
  count: PropTypes.number.isRequired,
}

export default ChatParticipantCount;
