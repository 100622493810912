import { TOTAL_LOSS } from '../../../constants/copart-constants';
import { CREATE_RULESET, UPDATE_RULESET } from '../../../constants/endpoints';

export const keyById = (arr) => arr.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {});

export const buildRequestOptions = (ruleSettings) => {
  const { rules, id, defaultVersion } = ruleSettings;
  const destructuredRules = Object.keys(rules).map((key) => rules[key]);
  const structuredBody = {
    id,
    rulesetType: TOTAL_LOSS,
    rules: destructuredRules,
    defaultVersion,
  };
  let method;
  let url;
  if (id === 'DEFAULT') {
    url = CREATE_RULESET;
    method = 'POST';
  } else {
    method = 'PUT';
    url = UPDATE_RULESET(id);
  }
  const options = {
    body: JSON.stringify(structuredBody),
    method,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return {
    url,
    options,
  };
};

export const deepEqual = (obj1, obj2) => JSON.stringify(obj1) !== JSON.stringify(obj2);

export const extractTokens = (text) => {
  const tokens = [];
  const body = text.replace(/@\[\$([^\]]+)\]\(([^)]+)\)/g, (match, token) => {
    tokens.push({ token });
    // eslint-disable-next-line @typescript-eslint/quotes
    return `\${TOKEN}`;
  });

  return { body, tokens };
};
