/* eslint-disable react/jsx-filename-extension */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import CloseIcon from '../../../../images/icons/close2.svg';
import SnackErrorIcon from '../../../../images/icons/snackfail.svg';
import './SnackBar.less';
import { closeSnackbar } from '../../../actions/notification';
import { setActiveJob } from '../../../actions/job';

const AUTO_CLOSE = 10000;

const SnackBar = ({
  onToggle,
  text,
  isError,
  linkText,
  persist,
  linkID,
  title,
  isHyperLink,
  downloadFile,
  downloadFileName,
  jobId,
  externalLinkText,
  externalLink,
  uncloseableDelay,
  showProgress,
}) => {
  const [displayCloseButton, setDisplayCloseButton] = useState(false);
  const timers = [];

  const clearTimers = useCallback(() => {
    timers.forEach((t) => clearTimeout(t));
  }, [timers]);
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      clearTimers();
    },
    [clearTimers],
  );

  const handleCloseSnackBar = useCallback(() => {
    dispatch(closeSnackbar());
  }, [dispatch]);

  useEffect(() => {
    if (!persist) {
      timers.push(
        setTimeout(() => setDisplayCloseButton(true), uncloseableDelay),
      );
      timers.push(
        setTimeout(
          () => {
            handleCloseSnackBar();
            onToggle();
          },
          AUTO_CLOSE,
        ),
      );
    }
    return () => {
      clearTimers();
    };
  }, [clearTimers, handleCloseSnackBar, onToggle, persist, text, timers]);

  const onLinkClick = () => {
    dispatch({ type: 'SNACKBAR_CLICKED', payload: linkID });
  };

  const handleHyperLinkClick = () => {
    if (jobId) {
      dispatch(setActiveJob(jobId));
    }
    onLinkClick();
    onToggle();
    handleCloseSnackBar();
  };

  const handleDownloadFile = () => {
    const hiddenElement = document.createElement('a');
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(
      downloadFile,
    )}`;
    hiddenElement.target = '_blank';
    hiddenElement.download = downloadFileName;
    document.body.appendChild(hiddenElement);
    hiddenElement.click();
    document.body.removeChild(hiddenElement);
    onToggle();
  };

  return (
    <div className="snack-bar">
      <div className="snack-bar-inner-container">
        {isError && (
          <div
            data-test="snackbar-error-icon"
            data-testid="snackbar-error-icon"
            className="snack-bar-error"
          >
            <SnackErrorIcon />
          </div>
        )}
        <div className="snack-bar-text">
          <div className="snackBarTopWrap">
            {title && (
              <>
                <h3>{title}</h3>
                <div
                  data-test="snackbar-close-button"
                  onClick={handleCloseSnackBar}
                  className="snack-bar-close-button"
                  role="button"
                  onKeyPress={handleCloseSnackBar}
                >
                  {(displayCloseButton || persist) && <CloseIcon />}
                </div>
              </>
            )}
          </div>
          <div className="snackBarBottomWrap">
            {text}
            {
              externalLinkText && externalLink && (
                <a
                  onClick={handleHyperLinkClick}
                  onKeyDown={handleCloseSnackBar}
                  role="button"
                  href={externalLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {externalLinkText}
                </a>
              )
            }
            <div data-testid="snackbar-link" className="snackBarActionsWrap">
              {linkText
                && (isHyperLink ? (
                  <Link
                    data-test="table-cell-link"
                    to={linkID}
                    className="table-cell-link"
                    onClick={() => (downloadFile && downloadFileName
                      ? handleDownloadFile()
                      : handleHyperLinkClick())}
                  >
                    {linkText}
                  </Link>
                ) : (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    onClick={handleHyperLinkClick}
                    onKeyDown={handleCloseSnackBar}
                    role="button"
                  >
                    {linkText}
                  </a>
                ))}
              {!title && (
                <div
                  data-test="snackbar-close-button"
                  data-testid="snackbar-close-button"
                  onClick={handleCloseSnackBar}
                  className="snack-bar-close-button"
                  role="button"
                  onKeyPress={handleCloseSnackBar}
                >
                  {(displayCloseButton || persist) && <CloseIcon />}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      { (!persist && showProgress) ? <div className="snack-bar-progress-bar" /> : null }
    </div>
  );
};

SnackBar.propTypes = {
  onToggle: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  isError: PropTypes.bool.isRequired,
  linkText: PropTypes.string.isRequired,
  persist: PropTypes.bool,
  linkID: PropTypes.string.isRequired,
  title: PropTypes.string,
  isHyperLink: PropTypes.bool,
  downloadFile: PropTypes.shape({}),
  downloadFileName: PropTypes.string,
  externalLinkText: PropTypes.string,
  externalLink: PropTypes.string,
  jobId: PropTypes.string,
  uncloseableDelay: PropTypes.number,
  showProgress: PropTypes.bool,
};

SnackBar.defaultProps = {
  isHyperLink: false,
  title: null,
  downloadFile: null,
  downloadFileName: null,
  jobId: '',
  persist: false,
  externalLinkText: null,
  externalLink: null,
  uncloseableDelay: 4000,
  showProgress: false,
};

export default SnackBar;
