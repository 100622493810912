/* eslint-disable @typescript-eslint/no-shadow */
import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { get, includes } from 'lodash';

import { Button } from '@himarley/unity';
import Download from '..././../../../../images/icons/download.svg';
import Modal from '../../../modal/Modal/Modal';
import { operatorType } from '../../../../models/marleyTypes';
import GarbageIcon from '../../../icons/GarbageIcon';
import Panel from '../../../Panel/Panel';
// Elements
import TableRow from '../../../elements/table/TableRow/TableRow'; // eslint-disable-line
import TableCell from '../../../elements/table/TableCell/TableCell';
import { SortTable } from '../../../elements/table/SortTable/SortTable';
import TableHeader from '../../../elements/table/SortTable/TableHeader/TableHeader';
import DisabledBanner from '../../../elements/Banner/DisabledBanner/DisabledBanner';

import UserPhoto2 from '../../../UserPhoto2/UserPhoto2';
import EnterOperator from '../../../EnterOperator/EnterOperator';
import SearchBar from '../../../SearchBar/SearchBar';
import ResetPassword from '../../../confirmationModals/ResetPassword/ResetPassword'; // eslint-disable-line
import DeleteUser from '../../../confirmationModals/DeleteUser/DeleteUser'; // eslint-disable-line
import selector from '../../../../selectors/usermgmt';
import { formatPhoneNumber } from '../../../../helpers/format';
import { isPasswordlessAuthSession } from '../../../../helpers/common';
import AssignRoleDropdown from '../../../AssignRoleDropdown/AssignRoleDropdown';
import {
  createUsers,
  updateOperatorsUniqueIds,
  getOrganizationRoles,
  downloadCSVTemplate,
  getUsersToExport,
} from '../../../../actions/users';
import { setConfigItem } from '../../../../actions/ui';
import { showSnackbar } from '../../../../actions/notification';
import { getUserProfile, getListOfTitles } from '../../../../actions/profile';
import withLazyLoad from '../../../withLazyLoad/withLazyLoad';
import withModal from '../../../HigherOrderComponents/withModal';
import withLoading from '../../../HigherOrderComponents/withLoading';
import './UserMgmt.less';
import BulkUsersUploadModal from './BulkUsersUpload/BulkUsersUploadModal';
import { CHANGE_UNIQUE_ID } from '../../../../constants/general';

const columns = [
  {
    id: 'user-photo',
    sort: false,
  },
  {
    id: 'name-table-format',
    label: 'Name',
    sort: false,
    location: 'nameTableFormat',
    secondaryLocation: 'title',
  },
  {
    id: 'last-login-time',
    label: 'Last Login',
    sort: true,
    location: 'lastLoginTime',
    numericSort: true,
  },
  {
    id: 'phone-number',
    label: 'Phone Number',
    sort: false,
    location: ['displayPhoneNumber', 'contactNumber'],
    format: ({ value }) => <div>{value ? formatPhoneNumber(value) : ''}</div>, // eslint-disable-line
    numericSort: true,
  },
  {
    id: 'okta-user-id',
    label: 'Unique Id',
    sort: false,
    location: 'oktaUserId',
    copyEnabled: true,
  },
  {
    id: 'email',
    label: 'Email',
    sort: false,
    location: 'email',
    copyEnabled: true,
  },
  {
    id: 'role',
    label: 'Role',
  },
  {
    id: 'actions',
    label: 'Actions',
  },
];

const UserMgmt = ({
  createUsers,
  updateOperatorsUniqueIds,
  isLoading,
  increaseDataSet,
  auth,
  getOrganizationRoles,
  getUserProfile,
  toggleModal,
  modifyQuery,
  loadedItems,
  requestCompleted,
  showSnackbar,
  getListOfTitles,
  getUsersToExport: getUsersToExportProp,
  setConfigItem,
}) => {
  const [isBulkUploadModalShown, setIsBulkUploadModalShown] = useState(false);
  const [modalType, setModalType] = useState();

  const history = useHistory();
  const handleOpenProfile = useCallback(
    () => history.push('/profile'),
    [history],
  );

  useEffect(() => {
    getOrganizationRoles();
  }, []);

  useEffect(() => {
    getListOfTitles();
  }, []);

  useEffect(() => {
    if (requestCompleted) {
      toggleModal(false);
    }
  }, [requestCompleted]);

  const onSearchTextChange = useCallback(
    (e) => {
      const searchText = get(e, 'target.value');
      modifyQuery({ searchText });
    },
    [modifyQuery],
  );
  const passwordlessAuthSession = isPasswordlessAuthSession(auth);
  const nonFederatedUserActions = [
    !passwordlessAuthSession && {
      id: 'resetpassword',
      label: 'Reset Password',
      confirmation: true,
    },
    {
      id: 'userdelete',
      label: 'Delete User',
      confirmation: true,
      icon: <GarbageIcon />,
    },
  ].filter(Boolean);

  const getActionsList = useMemo(() => {
    const authUserRoles = auth?.user?.roles;
    const actionList = includes(authUserRoles, 'ADMIN')
      ? [
        {
          id: 'editOperator',
          label: 'Edit Profile',
          confirmation: false,
          action: (userId) => {
            getUserProfile(userId);
            handleOpenProfile();
            setConfigItem('GENERAL_INFO');
          },
        },
        ...nonFederatedUserActions,
      ]
      : nonFederatedUserActions;

    return actionList;
  }, [
    auth?.user?.roles,
    nonFederatedUserActions,
    getUserProfile,
    handleOpenProfile,
  ]);

  const userColumns = columns?.map((col) => (
    <TableHeader key={col.id || col.label} {...col} tableId="user" />
  ));

  const jit = get(auth, 'user.organization.jit', false);

  const toggleImportCSVModal = useCallback(
    (val, type) => {
      setIsBulkUploadModalShown(val);
      setModalType(type);
    },
    [setIsBulkUploadModalShown],
  );

  const extendedItemSize = 72;

  const renderPanel = useMemo(
    () => (
      <Panel
        className="user-mgmt-page"
        title="User Management"
        header={(
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {
              jit
                && (
                <DisabledBanner
                  includesLink
                  linkRef="https://himarley.zendesk.com/hc/en-us/articles/1500009297302-Auto-provisioning-Capabilities-and-Limitations-"
                  linkText="Just-In-Time (JIT) Provisioning"
                  bannerText="has been setup for your organization."
                />
                )
            }
            <div className="importBannerLeft">
              <Button type="positive-marley" onClick={toggleModal}>
                Add New Operator +
              </Button>
              <Button
                type="positive-marley"
                onClick={() => toggleImportCSVModal(true)}
              >
                Import CSV
              </Button>
              <Button
                type="positive-marley"
                onClick={() => toggleImportCSVModal(true, CHANGE_UNIQUE_ID)}
              >
                Change Unique Ids
              </Button>
            </div>
            <div className="searchExportWrap">
              <SearchBar
                placeholder="Search operators"
                onChange={onSearchTextChange}
              />
              <Button
                className="downloadIcon"
                onClick={getUsersToExportProp}
                type="outline"
                Icon={Download}
              >
                Export
              </Button>
            </div>
          </div>
        )}
      >
        <ResetPassword />
        <DeleteUser />

        <SortTable
          label="users"
          columns={userColumns}
          increaseDataSet={increaseDataSet}
          isLoading={isLoading}
          extendedItemSize={extendedItemSize}
          rows={loadedItems.map((user) => (
            <TableRow
              id="user"
              key={user.id}
              itemId={user.id}
              actions={getActionsList}
              columns={columns.slice(1, columns.length - 2)}
              item={user}
              header={(
                <TableCell id="user-photo">
                  <UserPhoto2
                    id={user.id}
                    imageUrl={user.imageUrl}
                    name={user.name}
                  />
                </TableCell>
              )}
            >
              <TableCell id="role">
                <AssignRoleDropdown user={{ ...user }} />
              </TableCell>
            </TableRow>
          ))}
        />
      </Panel>
    ),
    [
      jit,
      toggleModal,
      auth?.user,
      toggleImportCSVModal,
      onSearchTextChange,
      userColumns,
      increaseDataSet,
      isLoading,
      loadedItems,
      getActionsList,
      getUsersToExportProp,
    ],
  );

  return (
    <>
      {isBulkUploadModalShown && (
        <BulkUsersUploadModal
          type={modalType}
          createUsers={createUsers}
          updateOperatorsUniqueIds={updateOperatorsUniqueIds}
          downloadCSVTemplate={downloadCSVTemplate}
          show={isBulkUploadModalShown}
          onClose={() => toggleImportCSVModal(false)}
          showSnackbar={showSnackbar}
          modifyQuery={modifyQuery}
        />
      )}
      {renderPanel}
    </>
  );
};

UserMgmt.propTypes = {
  getOrganizationRoles: PropTypes.func.isRequired,
  increaseDataSet: PropTypes.func.isRequired,
  loadedItems: PropTypes.arrayOf(Object),
  modifyQuery: PropTypes.func.isRequired,
  requestCompleted: PropTypes.bool.isRequired,
  modifiedUserID: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  getUserProfile: PropTypes.func.isRequired,
  createUsers: PropTypes.func.isRequired,
  updateOperatorsUniqueIds: PropTypes.func.isRequired,
  getListOfTitles: PropTypes.func,
  setConfigItem: PropTypes.func,
  isLoading: PropTypes.bool,
  auth: PropTypes.shape({
    user: PropTypes.shape({
      roles: PropTypes.string,
    }),
  }).isRequired,
  showSnackbar: PropTypes.bool,
  getUsersToExport: PropTypes.func,
};

UserMgmt.defaultProps = {
  loadedItems: [],
  getListOfTitles: () => {},
  setConfigItem: () => {},
  getUsersToExport: () => {},
  isLoading: false,
  showSnackbar: false,
};

export const mapStateToProps = (state) => ({
  ...selector(state, columns),
  type: operatorType,
  sort: get(state, 'ui.sort.user'),
  auth: get(state, 'auth'),
});

export const mapDispatchToProps = {
  createUsers,
  updateOperatorsUniqueIds,
  getOrganizationRoles,
  getUserProfile,
  showSnackbar,
  getUsersToExport,
  getListOfTitles,
  setConfigItem,
};

const UserMgmtWithLoading = withLoading(UserMgmt, { type: operatorType });
const UserMgmtWithLoadingModal = withModal({
  Modal: (
    <Modal title="Create Operator" className="create-operator">
      <EnterOperator />
    </Modal>
  ),
})(UserMgmtWithLoading);

const LoadedUsers = withLazyLoad(UserMgmtWithLoadingModal, {
  type: operatorType,
  listLocation: 'users',
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadedUsers);
