import React from 'react';
import PropTypes from 'prop-types';
import { NumberInput, SlideToggle } from '@himarley/unity';

const AutoCloseCases = ({ setAutoClose, orgAutoClose }) => {
  const { enabled: isAutoCloseEnabled, value: autoCloseValue } = orgAutoClose;

  const toggleSwitch = () => {
    if (!isAutoCloseEnabled && autoCloseValue === undefined) {
      setAutoClose({ value: 30, enabled: true });
    } else {
      setAutoClose({ ...orgAutoClose, enabled: !isAutoCloseEnabled });
    }
  };

  return (
    <div className="autocloseWrap">
      <section>
        <h2>Auto Close Cases</h2>
        <div
          className={`${
            isAutoCloseEnabled ? 'autoCloseEnabled' : 'autoCloseDisabled'
          } daysToggleWrap`}
        >
          {isAutoCloseEnabled && (
            <div
              data-testid="auto-close-section"
              className="auto-close-days-lbl"
            >
              <NumberInput
                onChange={(e) =>
                  setAutoClose({ ...orgAutoClose, value: e.target.value })
                }
                value={autoCloseValue}
                label="Close After"
              />
              <h3>Days</h3>
            </div>
          )}
          <SlideToggle
            onToggle={toggleSwitch}
            on={isAutoCloseEnabled}
            showLabels={false}
          />
        </div>
      </section>
      <p>
        Auto close cases that have not had any sent or received messages for a
        specific time.
      </p>
    </div>
  );
};

AutoCloseCases.propTypes = {
  setAutoClose: PropTypes.func,
  orgAutoClose: PropTypes.shape({
    enabled: PropTypes.bool,
    value: PropTypes.number,
  }),
};

AutoCloseCases.defaultProps = {
  orgAutoClose: {},
  setAutoClose: () => {},
};

export default AutoCloseCases;
