/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import './Menu.less'

/**
 * Generic menu
 * @param   {object} children what to display in the menu
**/
const Menu = ({ children, className }) => (
  <div className={`${className || ''} marley-menu`}>{children}</div>
);

export default Menu
