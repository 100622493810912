/* eslint-disable max-len */
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useState, useRef, useMemo } from 'react';
import {
  Badge, CountDisplay, DropdownMenu, IconButton,
} from '@himarley/unity';
import { flushSync } from 'react-dom';
import classNames from 'classnames/bind';
import CollapsibleSearchBar from './CollapsibleSearchBar/CollapsibleSearchBar';
import QuickFilter from './QuickFilter/quick-filter';
import FilterTray from './FilterTray/FilterTray';
import FilterIcon from '../../../../../images/icons/filter.svg';
import styles from './InboxHeader.module.less';
import { sortByAlphabetical } from '../../../../helpers/sorting';
import { persistUserProperties, setNewInboxCardDensity } from '../../../../actions/profile';
import { REFINE_BY } from '../../../../constants/inboxFilters';
import { sortMenuItems, DEFAULT_SELECTED_SORT_INDEX, CARD_DENSITY } from '../constants';
import CardDensityControl from './card-density-control';

const cx = classNames.bind(styles);

const InboxHeader = ({ totalChats }) => {
  const filterBtnRef = useRef(null);
  const [isFilterTrayOpen, setIsFilterTrayOpen] = useState(null);

  const {
    branding, linesOfBusiness, inboxFilters, newInboxCardDensity, hasUsedNewInboxFilters,
  } = useSelector((state) => ({
    branding:
        state?.organizations?.branding?.sort((a, b) => sortByAlphabetical(a?.name, b?.name)) || [],
    linesOfBusiness:
        state?.organizations?.linesOfBusiness?.sort((a, b) => sortByAlphabetical(a?.name, b?.name)) || [],
    inboxFilters: state?.profile?.properties?.inboxFilters || {},
    newInboxCardDensity:
        state?.profile?.properties?.newInboxCardDensity
        || CARD_DENSITY.EXPANDED,
    hasUsedNewInboxFilters:
        state?.profile?.properties?.hasUsedNewInboxFilters || false,
  }));

  const handleToggleTray = () => {
    setIsFilterTrayOpen(!isFilterTrayOpen);
  };

  const filterCount = useMemo(() => {
    const assigneeCount = Object.keys(inboxFilters?.items || {}).length
      + Object.keys(inboxFilters?.groups || {}).length
      + Object.keys(inboxFilters?.operatorIds || {}).length;
    let tagCount = 0;
    if (inboxFilters?.tags?.length > 0) {
      tagCount = inboxFilters?.tags?.includes('all')
        ? 0
        : inboxFilters?.tags?.length;
    }
    const secondaryOp = inboxFilters?.secondaryOperatorIds === true ? 1 : 0;
    const refineBy = inboxFilters?.refineBy === null
      || typeof inboxFilters?.refineBy === 'undefined'
      ? 0
      : 1;
    const onlyActive = inboxFilters?.onlyActive ? 1 : 0;
    return assigneeCount + secondaryOp + refineBy + onlyActive + tagCount;
  }, [
    inboxFilters?.groups,
    inboxFilters?.items,
    inboxFilters?.tags,
    inboxFilters?.onlyActive,
    inboxFilters?.operatorIds,
    inboxFilters?.refineBy,
    inboxFilters?.secondaryOperatorIds,
  ]);

  const selectedItemSortItemIndex = useMemo(() => {
    const menuItemIndex = sortMenuItems.findIndex(
      (item) => item.sort === inboxFilters?.sort && item.order === inboxFilters?.order,
    );
    return menuItemIndex === -1 ? DEFAULT_SELECTED_SORT_INDEX : menuItemIndex;
  }, [inboxFilters]); // dependencies: inboxFilters and menuItems

  const dispatch = useDispatch();

  const handleSort = (item) => {
    dispatch(
      persistUserProperties({
        hasUsedNewInboxFilters,
        inboxFilters: {
          ...inboxFilters,
          sort: item.sort,
          order: item.order,
          secondarySort: item?.secondarySort,
          secondaryOrder: item?.secondaryOrder,
        },
      }),
    );
  };

  const handleCardDensity = (density) => {
    if (document?.startViewTransition) {
      flushSync(() => {
        document.startViewTransition(() => {
          dispatch(
            setNewInboxCardDensity(density),
          );
        });
      });
    } else {
      dispatch(
        setNewInboxCardDensity(density),
      );
    }
  };

  const mentionsSelectedFilterCount = useMemo(() => {
    let count = 1;
    if (inboxFilters?.onlyActive) {
      count += 1;
    }
    return count;
  }, [inboxFilters?.onlyActive]);

  return (
    <header className={styles.inboxHeader} data-testid="inbox-header">
      <div className={styles.inboxSubHeader}>
        <h1 className={styles.h1}>Inbox</h1>
        <div className={styles.sortDropdownItemsContainer}>
          <DropdownMenu
            className={styles.sortDropdownItems}
            menuItems={sortMenuItems}
            onSelectItem={handleSort}
            selectedItemIndex={selectedItemSortItemIndex}
            testId="inbox-sort-btn"
          />
        </div>
      </div>
      <div className={styles.middle}>
        <QuickFilter />
        <div className={styles.filterIcon}>
          <span ref={filterBtnRef}>
            <IconButton id="filterTrayTrigger" onClick={handleToggleTray}>
              <FilterIcon />
            </IconButton>
          </span>
          <Badge
            className={cx({
              badge: true,
              filterIconWithBadge: hasUsedNewInboxFilters,
              filterIconWithoutBadge: !hasUsedNewInboxFilters,
            })}
            value={
                inboxFilters?.refineBy === REFINE_BY.MENTIONS
                  ? mentionsSelectedFilterCount
                  : filterCount || 2
              } // by default primary and secondary are select so 2
            testId="filtersCount"
          />
        </div>
        <FilterTray
          onCancel={() => setIsFilterTrayOpen(false)}
          isOpen={isFilterTrayOpen}
          brands={branding}
          lobs={linesOfBusiness}
          triggerRef={filterBtnRef}
        />
      </div>
      <div className={styles.footer}>
        <div className={styles.item}>
          <CountDisplay
            number={totalChats}
            displayPluralText="Cases"
            displaySingularText="Case"
            maxCount={50}
            testId="inbox-header-chat-count"
          />
        </div>
        <CardDensityControl density={newInboxCardDensity} onClick={handleCardDensity} />
        <CollapsibleSearchBar />
      </div>
    </header>
  );
};

InboxHeader.propTypes = {
  totalChats: PropTypes.number,
};

InboxHeader.defaultProps = {
  totalChats: 0,
};

export default InboxHeader;
