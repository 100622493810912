import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux';
import { removeUserFromChat } from '../../../../actions/chat';
import RemoveParticipant from './RemoveParticipant';

const RemoveParticipantContainer = ({ participant, onClose }) => {
  const chatId = useSelector((state) => state?.jobs?.activeChat?.id)
  const removeChatParticipantStatus = useSelector(
    (state) => state?.participants?.removeChatParticipantStatus,
  )

  const dispatch = useDispatch()
  const { id } = participant

  const handleClickRemoveParticipant = () => {
    dispatch(removeUserFromChat({ chatId, userId: id }))
  }

  useEffect(() => {
    if (removeChatParticipantStatus === 'SUCCESS') {
      onClose()
    }
  }, [removeChatParticipantStatus])

  return (
    <RemoveParticipant
      participant={participant}
      onClickRemoveParticipant={handleClickRemoveParticipant}
      onClose={onClose}
      removeChatParticipantStatus={removeChatParticipantStatus}
    />
  )
}

RemoveParticipantContainer.propTypes = {
  participant: PropTypes.shape({
    id: PropTypes.string,
    imageUrl: PropTypes.string,
    name: PropTypes.string,
    role: PropTypes.string,
    relationship: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default RemoveParticipantContainer
