import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CaseItem from './CaseItem/CaseItem';
import PinIcon from '../../../../images/icons/pin.svg';

const getLastPinnedRowClass = (lastPinnedJobIndex, currIndex) => {
  if (lastPinnedJobIndex === currIndex) {
    return 'lastPinnedRow';
  }
  if (lastPinnedJobIndex + 1 === currIndex) {
    return 'lastPinnedRowBuffer';
  }
  return '';
};

const CasesListRow = ({ index, style, data, setSize, windowWidth }) => {
  const rowRef = useRef();
  const {
    authId,
    rows,
    handleSelectCase,
    updatePinnedChats,
    activeJob,
    useCheckForCaseAccess,
    currentPermissions,
    hasPinnedJobs,
    lastPinnedJobIndex,
  } = data;
  const item = rows[index];

  useEffect(() => {
    setSize(index, rowRef.current.firstChild.getBoundingClientRect().height + 8);
  }, [setSize, index, windowWidth]);

  return (
    <div
      style={style}
      ref={rowRef}
      data-testid="tableRow"
      className={`chatListRow ${
        hasPinnedJobs ? getLastPinnedRowClass(lastPinnedJobIndex, index) : ''
      }`}
    >
      {hasPinnedJobs && index === 0 ? (
        <div className="pinned__title" data-testid="pinnedRow">
          <PinIcon />
          pinned
        </div>
      ) : (
        <CaseItem
          authId={authId}
          job={item}
          key={item?.id}
          select={handleSelectCase(item)}
          unpin={() => updatePinnedChats(authId, item.id, false)}
          pin={() => updatePinnedChats(authId, item.id, true)}
          selected={item.id === activeJob.id}
          hasAccessToCase={useCheckForCaseAccess(
            item,
            authId,
            currentPermissions
          )}
          isPinned={item?.isPinned}
        />
      )}
    </div>
  );
};

CasesListRow.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({
    authId: PropTypes.string.isRequired,
    rows: PropTypes.shape([]).isRequired,
    handleSelectCase: PropTypes.func.isRequired,
    updatePinnedChats: PropTypes.func.isRequired,
    activeJob: PropTypes.shape({}).isRequired,
    useCheckForCaseAccess: PropTypes.func.isRequired,
    currentPermissions: PropTypes.func.isRequired,
    hasPinnedJobs: PropTypes.bool.isRequired,
    lastPinnedJobIndex: PropTypes.string.isRequired,
  }).isRequired,
  setSize: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
};

export default CasesListRow;
