/* eslint-disable no-template-curly-in-string */
import {
  Button, Divider, Tab, Tabs,
} from '@himarley/unity';
import { useQueries } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';

import RadioButton from '@app/components/elements/RadioButton/RadioButton';
import {
  RADIO_BUTTON_VALUES,
  RADIO_BUTTON_VALUES_OPT_IN,
} from '@app/components/Settings/OptInMessages/constants';
import { CREATE_RULESET, GET_RULESET, UPDATE_RULESET } from '@app/constants/endpoints';
import { ORG_SETTINGS_GENERAL_WELCOME_FLOW, DISABLE_MARLEY_OPT_IN } from '@app/constants/permissions';
import { useCheckPermissions } from '@app/helpers/common';
import {
  fetchData,
  useDynamicMutation,
} from '@app/hooks/react-query-helpers';

import {
  ConfigOptInStatus, KEYS, WELCOME_FLOW_RULESETS, CASE, CLAIM,
} from './constants';
import {
  buildMessageTemplates,
  getMessages,
  updateTokenStrings,
} from './helpers';
import PreviewMessages from './preview-messages';
import styles from './styles/welcome-flow-settings.module.scss';
import type {
  MessageTemplate,
  Rule,
  MessageTemplateKey,
} from './types';
import WelcomeFlowSettingsModal from './welcome-flow-settings-modal';

interface WelcomeFlowSettingsProps {
  instructionText?: string;
  tokenOverrideMap?: { [key: string]: string };
  lineOfBusiness?: {
    _id: string,
    subType: string,
  };
}

/**
 * WelcomeFlowSettings component to manage and display settings for welcome flow messages.
 *
 * @param {Object} props - The props for the component.
 * @param {string} [props.instructionText] - The instructional text to display.
 * @param {{ [key: string]: string }} [props.tokenOverrideMap] - Optional map for token overrides.
 * @param {string} [props.lineOfBusiness] - The line of business.
 *
 * @returns {JSX.Element} - The rendered WelcomeFlowSettings component.
 */
const WelcomeFlowSettings: React.FC<WelcomeFlowSettingsProps> = ({
  instructionText = 'Configure text messages automatically sent to a customer when a new case is opened.',
  tokenOverrideMap,
  lineOfBusiness,
}) => {
  const ConfigCaseTypes: { [key: string]: string } = {
    CLAIM,
  };

  if (
    useCheckPermissions([ORG_SETTINGS_GENERAL_WELCOME_FLOW])
    && !lineOfBusiness?._id
  ) {
    ConfigCaseTypes.CASE = CASE;
  }
  const isDisableMarleyOptIn = useCheckPermissions([DISABLE_MARLEY_OPT_IN]);
  const caseTypes = Object.keys(ConfigCaseTypes);
  const optInStatuses = Object.keys(ConfigOptInStatus);

  if (isDisableMarleyOptIn) {
    optInStatuses.splice(optInStatuses.indexOf(KEYS.PENDING_OPT_IN), 1);
  }

  const getRulesetUrl = (rulesetType: string, params: URLSearchParams | null) => (params ? `${GET_RULESET(rulesetType)}?${params}` : GET_RULESET(rulesetType));
  const lineOfBusinessId = lineOfBusiness?._id;

  let params: URLSearchParams | null = null;
  if (lineOfBusinessId) {
    params = new URLSearchParams({ lobId: lineOfBusinessId });
  }

  const snackbarText = {
    success: 'Messages updated successfully',
    error: 'Error updating messages',
  };

  const queries = useQueries({
    queries: [
      {
        queryKey: [WELCOME_FLOW_RULESETS.INITIAL_MESSAGE, lineOfBusinessId],
        queryFn: () => fetchData(
          getRulesetUrl(WELCOME_FLOW_RULESETS.INITIAL_MESSAGE, params),
        ),
      },
      {
        queryKey: [
          WELCOME_FLOW_RULESETS.CONFIRMATION_MESSAGE,
          lineOfBusinessId,
        ],
        queryFn: () => fetchData(
          getRulesetUrl(WELCOME_FLOW_RULESETS.CONFIRMATION_MESSAGE, params),
        ),
      },
      {
        queryKey: [WELCOME_FLOW_RULESETS.FOLLOWUP_MESSAGE, lineOfBusinessId],
        queryFn: () => fetchData(
          getRulesetUrl(WELCOME_FLOW_RULESETS.FOLLOWUP_MESSAGE, params),
        ),
      },
    ],
  });

  const [
    initialMessageResponse,
    confirmationMessageResponse,
    followupMessageResponse,
  ] = queries;

  const { mutateAsync: updateInitialMessage } = useDynamicMutation(
    [WELCOME_FLOW_RULESETS.INITIAL_MESSAGE, lineOfBusinessId],
    snackbarText,
  );
  const { mutateAsync: updateConfirmationMessage } = useDynamicMutation(
    [WELCOME_FLOW_RULESETS.CONFIRMATION_MESSAGE, lineOfBusinessId],
    snackbarText,
  );
  const { mutateAsync: updateFollowupMessage } = useDynamicMutation(
    [WELCOME_FLOW_RULESETS.FOLLOWUP_MESSAGE, lineOfBusinessId],
    snackbarText,
  );

  const [messageTemplates, setMessageTemplates] = useState<MessageTemplate[]>(
    [],
  );
  const [radioPreviewOption, setRadioPreviewOption] = useState(
    RADIO_BUTTON_VALUES[0].id,
  );
  const [radioPreviewOptionOptIn, setRadioPreviewOptionOptIn] = useState(
    RADIO_BUTTON_VALUES_OPT_IN[0].id,
  );
  const [showModal, setShowModal] = useState(false);
  const [activeValues, setActiveValues] = useState<{
    caseType: string;
    optStatus: string;
    caseAssignment: string;
    messages: MessageTemplate[];
  }>({
    caseType: caseTypes[0],
    optStatus: optInStatuses[0],
    caseAssignment: '',
    messages: [],
  });

  useEffect(() => {
    if (
      !initialMessageResponse.isLoading
      && !confirmationMessageResponse.isLoading
      && !followupMessageResponse.isLoading
    ) {
      const initialMessageData = initialMessageResponse?.data;
      const confirmationMessageData = confirmationMessageResponse?.data;
      const followupMessageData = followupMessageResponse?.data;

      const templates = buildMessageTemplates({
        initialMessageData,
        confirmationMessageData,
        followupMessageData,
        tokenOverrideMap,
      });
      if (JSON.stringify(templates) !== JSON.stringify(messageTemplates)) {
        setMessageTemplates(templates);
      }
    }
  }, [
    initialMessageResponse,
    confirmationMessageResponse,
    followupMessageResponse,
    tokenOverrideMap,
    messageTemplates,
  ]);

  /**
   * Formats the rule set and saves the updated messages.
   * @param {MessageTemplate[]} messages - The array of message templates.
   * @param {boolean} showOptionalFollowUp - Flag to show the optional follow-up message.
   *
   * @returns {Promise<void>} - The promise to format the rule set and save the messages.
   */
  const formatRuleSetAndSave = async (
    messages: MessageTemplate[],
  ) => {
    const messageData = {
      [KEYS.INITIAL_MESSAGE]: {
        data: initialMessageResponse?.data,
        methodType: initialMessageResponse?.data?.id === 'DEFAULT' ? 'POST' : 'PUT' as 'POST' | 'PUT' | 'GET' | 'DELETE',
        mutation: updateInitialMessage,
      },
      [KEYS.CONFIRMATION_MESSAGE]: {
        data: confirmationMessageResponse?.data,
        methodType: confirmationMessageResponse?.data?.id === 'DEFAULT' ? 'POST' : 'PUT' as 'POST' | 'PUT' | 'GET' | 'DELETE',
        mutation: updateConfirmationMessage,
      },
      [KEYS.FOLLOWUP_MESSAGE]: {
        data: followupMessageResponse?.data,
        methodType: followupMessageResponse?.data?.id === 'DEFAULT' ? 'POST' : 'PUT' as 'POST' | 'PUT' | 'GET' | 'DELETE',
        mutation: updateFollowupMessage,
      },
    };

    messages.forEach((message: MessageTemplate) => {
      (
        Object.keys(messageData) as Array<
        keyof typeof messageData
        >
      ).forEach((key) => {
        if (message[key as MessageTemplateKey]) {
          const updatedMessage = message;

          if (key === KEYS.FOLLOWUP_MESSAGE && !message.enabled) {
            updatedMessage.rawMessage = '';
          }

          const [messageTemplate, tokens] = updateTokenStrings(
            message.rawMessage || '',
          );

          const rules = messageData[key].data.rules || [];
          let { methodType } = messageData[key];

          methodType = !messageData[key].data.id || messageData[key].data.id === 'DEFAULT' || (lineOfBusinessId && !messageData[key].data.lineOfBusinessId) ? 'POST' : 'PUT';

          const updatedRules = rules.map((rule: Rule) => {
            const ruleCopy = { ...rule };

            if (methodType === 'POST') {
              delete ruleCopy.id;
            }

            if (rule.messageTemplate.id === message.id) {
              if (key === KEYS.FOLLOWUP_MESSAGE && !message.enabled) {
                ruleCopy.enabled = false;
                ruleCopy.messageTemplate.body = '';
                ruleCopy.messageTemplate.tokens = [];
              } else if (key === KEYS.FOLLOWUP_MESSAGE) {
                ruleCopy.enabled = true;
              }

              return {
                ...ruleCopy,
                messageTemplate: {
                  ...ruleCopy.messageTemplate,
                  body: messageTemplate,
                  tokens,
                },
              };
            }
            return ruleCopy;
          });

          messageData[key].data.rules = updatedRules;
          messageData[key].methodType = methodType;
        }
      });
    });

    const mutationPromises = (
      Object.keys(messageData) as Array<
      keyof typeof messageData
      >
    ).map(async (key) => {
      const method = messageData[key].methodType;
      const endpoint = method === 'POST' || !messageData[key].data.id ? CREATE_RULESET : UPDATE_RULESET(messageData[key].data.id);

      if (method === 'POST') {
        messageData[key].data.rulesetType = WELCOME_FLOW_RULESETS[key];
        if (lineOfBusinessId) messageData[key].data.lineOfBusinessId = lineOfBusinessId;
        delete messageData[key].data.id;
      } else {
        delete messageData[key].data.createdAt;
      }

      const mutate = messageData[key].mutation;
      return mutate({
        endpoint,
        method,
        body: messageData[key].data,
      });
    });

    return Promise.all(mutationPromises);
  };

  const isLineOfBusinessMessaging = lineOfBusinessId
    && messageTemplates.some((message) => message.lineOfBusinessId === lineOfBusinessId);

  return (
    <section
      className={styles.optInMessages}
      style={{
      } as React.CSSProperties}
    >
      <header>
        <h1>Welcome Messages</h1>
      </header>
      <p>{instructionText}</p>
      {isDisableMarleyOptIn && (
        <p className={styles.disableOptInMessage}>
          Checking for opt-in has been turned off for your organization. This
          means your organization is collecting consent outside the Hi Marley
          platform. Please contact
          {' '}
          <strong>support@himarley.com</strong>
          {' '}
          if you
          wish to enable this feature for your organization.
        </p>
      )}
      <Tabs defaultActiveKey={caseTypes[0]} showTabBar>
        {caseTypes.map((caseType) => (
          <Tab
            eventKey={caseType.trim()}
            title={ConfigCaseTypes[caseType]}
            key={caseType}
          >
            <section
              data-testid={`${caseType.trim()}-section`}
              className={styles.messagesSection}
            >
              <div className={styles.containerOptIn}>
                {optInStatuses.map((optStatus) => {
                  const id = `${caseType}_${optStatus}`;
                  const optedIn = optStatus === KEYS.OPTED_IN;
                  const radioPreviewOptionValue = optedIn
                    ? radioPreviewOptionOptIn
                    : radioPreviewOption;
                  const messages = getMessages({
                    messageTemplates,
                    caseType,
                    optStatus,
                    isDisableMarleyOptIn,
                  });

                  let previewHeight = '475px';
                  if (isDisableMarleyOptIn && lineOfBusinessId) {
                    previewHeight = '325px';
                  } else if (isDisableMarleyOptIn || lineOfBusinessId) {
                    previewHeight = '400px';
                  }

                  return (
                    <div
                      className={styles.welcomeFlowCard}
                      key={id}
                      style={{
                        '--preview-height': previewHeight,
                      } as React.CSSProperties}
                    >
                      <header>
                        <h2>{isDisableMarleyOptIn ? 'Welcome Message' : `Customer ${ConfigOptInStatus[optStatus]}`}</h2>
                        <Button
                          testId={`${id}_Edit`}
                          onClick={() => {
                            setActiveValues({
                              caseType,
                              optStatus,
                              caseAssignment: radioPreviewOptionValue,
                              messages,
                            });
                            setShowModal(true);
                          }}
                          type="outline"
                          className="cancelButton not-opted-in-edit"
                        >
                          Edit
                        </Button>
                      </header>
                      <p className={styles.infoText}>
                        {`The following messages will send when a new
                        ${caseType.toLowerCase()} is opened${isDisableMarleyOptIn ? '' : ` and the customer has
                        ${ConfigOptInStatus[optStatus]}`}.`}
                      </p>
                      <PreviewMessages
                        previewBanner={lineOfBusinessId ? (
                          <div
                            className={
                              isLineOfBusinessMessaging
                                ? styles.customMessaging : styles.orgMessaging
                            }
                          >
                            {isLineOfBusinessMessaging ? (
                              <span data-testid="customBannerText">
                                Custom
                                {' '}
                                <span className={styles.capitalize}>{lineOfBusiness?.subType}</span>
                                {' '}
                                messaging
                              </span>
                            ) : (
                              'Default Org. Setting messaging'
                            )}
                          </div>
                        ) : null}
                        className={styles.previewMessagesBox}
                        messages={
                          messages.filter((message) => [radioPreviewOptionValue, KEYS.ANY].includes(
                            message.caseAssignment || KEYS.ANY,
                          ))
                        }
                        isLoading={initialMessageResponse.isLoading
                          || confirmationMessageResponse.isLoading
                          || followupMessageResponse.isLoading}
                        previewLabel={(
                          <RadioButton
                            disabled={false}
                            testId={optStatus}
                            currentValue={radioPreviewOptionValue}
                            values={
                              optedIn
                                ? RADIO_BUTTON_VALUES_OPT_IN
                                : RADIO_BUTTON_VALUES
                            }
                            setCheckedValue={
                              optedIn
                                ? setRadioPreviewOptionOptIn
                                : setRadioPreviewOption
                            }
                            isHorizontal
                          />
                        )}
                      />
                    </div>
                  );
                })}
              </div>
            </section>
          </Tab>
        ))}
      </Tabs>
      <Divider />
      {showModal && (
        <WelcomeFlowSettingsModal
          caseType={activeValues.caseType}
          optStatus={activeValues.optStatus}
          caseAssignment={activeValues.caseAssignment}
          messages={activeValues.messages}
          show={showModal}
          hideModal={() => setShowModal(false)}
          tokenOverrideMap={tokenOverrideMap}
          handleSave={formatRuleSetAndSave}
        />
      )}
    </section>
  );
};

export default WelcomeFlowSettings;
