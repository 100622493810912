/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import _ from 'lodash';
import qs from 'querystringify';
import {
  UPDATE_PROFILE,
  PROFILE_UPDATE_ERROR,
  UPDATE_PROFILE_AUTOREPLY,
  SET_PROFILE_EDIT_MODE,
  UPDATE_PROFILE_PROPERTIES,
  PROFILE_REQUEST_STARTED,
  SET_PROFILE,
  RESET_PROFILE,
  CLEAR_PROFILE_UPDATE,
  LOAD_CUSTOMER_FEEDBACK,
  UPDATE_EMAIL_CONFIG,
  SET_EMAIL_CONFIG,
  PUT_USER_AUTOASSIGN,
  FETCH_LIST_OF_TITLES,
  UPDATE_NEW_INBOX_CARD_DENSITY,
} from '../constants/actions';

import {
  GET_AVG_SCORE,
  PROFILE,
  PROFILE_AUTOREPLY,
  GET_USER_PROPERTIES,
  GET_CUSTOMER_FEEDBACK,
  GENERIC_USER_GET,
  GENERIC_USER_SET,
  PROFILE_BY_ID,
  GET_LIST_OF_TITLES,
} from '../constants/endpoints';
import User from '../models/user';
import * as apiErrors from '../constants/apierrors';
import { apiRequest, dispatchRequest, makeApiRequest } from './api/api';
import {
  apiCall,
  doRequest,
  validateAuthErrorAndDispatchDeauth,
} from './api';

function loadUserProfile(modifiedUserId) {
  return () => {
    const opts = {
      endpoint: modifiedUserId ? PROFILE_BY_ID(modifiedUserId) : PROFILE,
      method: 'GET',
    };
    return doRequest(opts);
  };
}

export const getViewedChats = (userId) => dispatchRequest(apiRequest.get.user.viewedChats(userId), {
  type: 'LOAD_VIEWED_CHATS',
});

export const getPinnedChats = (userId) => dispatchRequest(apiRequest.get.user.pinnedChats(userId), {
  type: 'LOAD_PINNED_CHATS',
});

export const getAutoReplies = (id) => (dispatch) => dispatch(makeApiRequest('GET_USERS_AUTOREPLIES', [id]));

export const updatePinnedChats = (userId, chatId, doPin = true) => {
  const updateFunction = doPin
    ? apiRequest.update.user.pinnedChats
    : apiRequest.delete.user.pinnedChats;
  return dispatchRequest(updateFunction(userId, chatId), {
    type: 'UPDATE_PINNED_CHATS',
    processPayload: () => ({
      isPinned: doPin,
      chatId,
    }),
  });
};

export const markChatAsRead = (userId, chatId) => dispatchRequest(apiRequest.update.user.viewedChats(userId, chatId), {
  type: 'MARK_CHAT_READ',
  processPayload: () => ({
    chatId,
  }),
});

export const markChatAsUnread = (userId, chatId) => dispatchRequest(apiRequest.delete.user.viewedChats(userId, chatId), {
  type: 'MARK_CHAT_UNREAD',
  processPayload: () => ({
    chatId,
  }),
});

export function updateProfile(profile) {
  return (dispatch) => {
    const opts = {
      endpoint: PROFILE,
      method: 'PUT',
      body: profile,
    };
    dispatch({ type: PROFILE_REQUEST_STARTED, payload: profile });
    return doRequest(opts)
      .then((response) => {
        const payload = new User(response);
        dispatch({ type: UPDATE_PROFILE, payload });
      })
      .catch((error) => {
        validateAuthErrorAndDispatchDeauth(error, dispatch);
        dispatch({
          type: PROFILE_UPDATE_ERROR,
          payload: 'Cannot update user profile',
        });
      });
  };
}

export function getUserScore({ userId }) {
  const args = {
    endpoint: GET_AVG_SCORE(userId),
    method: 'GET',
    action: 'LOAD_USER_SCORE',
    process: ({ body }) => body,
  };
  return apiCall({ args });
}

export function getUserProfile(modifiedUserId) {
  return (dispatch) => {
    dispatch({ type: RESET_PROFILE });
    return loadUserProfile(modifiedUserId)()
      .then((profile) => {
        dispatch({ type: SET_PROFILE, payload: profile });
      })
      .catch(() => {
        dispatch({
          type: PROFILE_UPDATE_ERROR,
          payload: apiErrors.PROFILE_GET_ERROR,
        });
      });
  };
}

export function updateAutoReply(autoReply) {
  return (dispatch) => {
    const opts = {
      endpoint: PROFILE_AUTOREPLY,
      method: 'PUT',
      body: autoReply,
    };
    dispatch({ type: PROFILE_REQUEST_STARTED });
    return doRequest(opts)
      .then((updated) => {
        dispatch({ type: UPDATE_PROFILE_AUTOREPLY, payload: updated });
      })
      .catch((error) => {
        validateAuthErrorAndDispatchDeauth(error, dispatch);
        dispatch({
          type: PROFILE_UPDATE_ERROR,
          payload: 'Cannot update auto-reply settings',
        });
      });
  };
}

export const clearProfileUpdate = () => ({ type: CLEAR_PROFILE_UPDATE });

export const setProfileEditMode = (mode) => ({
  type: SET_PROFILE_EDIT_MODE,
  payload: !mode,
});

export const setProfileError = (error) => ({
  type: PROFILE_UPDATE_ERROR,
  payload: error,
});

export const processProperties = (properties) => {
  const recentlyAssignedOperators = {
    claims: [],
    policies: [],
    cases: [],
  };
  if (properties.claimsLastOperators) {
    recentlyAssignedOperators.claims = properties.claimsLastOperators.map(
      (user) => new User(user),
    );
  }
  if (properties.policiesLastOperators) {
    recentlyAssignedOperators.policies = properties.policiesLastOperators.map(
      (user) => new User(user),
    );
  }
  if (properties.jobsLastOperators) {
    recentlyAssignedOperators.cases = properties.jobsLastOperators.map(
      (user) => new User(user),
    );
  }
  return recentlyAssignedOperators;
};

export const persistUserProperties = (body) => (dispatch) => {
  const opts = {
    endpoint: GET_USER_PROPERTIES,
    method: 'PUT',
    body,
  };

  return doRequest(opts)
    .then(() => dispatch({ type: 'UPDATE_PROFILE_FILTERS', payload: body }))
    .catch(() => {
      dispatch({
        type: PROFILE_UPDATE_ERROR,
        payload: 'Cannot get user profile',
      });
    });
};

export const updateUserProperties = (body) => (dispatch, getStore) => {
  const opts = {
    endpoint: GET_USER_PROPERTIES,
    method: 'PUT',
    body,
  };
  return doRequest(opts)
    .then((properties) => {
      const recentlyAssignedOperators = processProperties(
        properties,
        getStore().users.listById,
      );
      const payload = {
        ...properties,
        recentlyAssignedOperators,
      };
      dispatch({ type: UPDATE_PROFILE_PROPERTIES, payload });
      dispatch(getUserScore({ userId: payload.identityId }));
      return null;
    })
    .catch(() => {
      dispatch({
        type: PROFILE_UPDATE_ERROR,
        payload: 'Cannot get user profile',
      });
    });
};

export const viewMyOpenConversations = ({
  payload: body,
  history,
}) => (
  dispatch,
  getStore,
) => updateUserProperties(body)(dispatch, getStore).then(() => history.push('/chats'));

export const getUserProperties = () => (dispatch, getStore) => {
  const opts = {
    endpoint: GET_USER_PROPERTIES,
    method: 'GET',
  };
  return doRequest(opts)
    .then((properties) => {
      const recentlyAssignedOperators = processProperties(
        properties,
        getStore().users.listById,
      );
      const payload = {
        ...properties,
        recentlyAssignedOperators,
      };
      dispatch({ type: UPDATE_PROFILE_PROPERTIES, payload });
      dispatch(getUserScore({ userId: payload.identityId }));
      return null;
    })
    .catch(() => {
      dispatch({
        type: PROFILE_UPDATE_ERROR,
        payload: 'Cannot get user profile',
      });
    });
};

export const getEmailConfig = () => (dispatch, getStore) => {
  const query = qs.stringify({
    userId: _.get(getStore(), 'auth.user._id', null),
  });
  return doRequest({
    endpoint: `${GENERIC_USER_GET}?${query}&properties[]=profile.emailConfig`,
    method: 'GET',
  })
    .then((result) => {
      const smsSetting = _.get(
        result,
        'profile.emailConfig.smsResponseEmailEnabled',
        'false',
      );
      const offlineSetting = _.get(
        result,
        'profile.emailConfig.emailWhileOffline',
        'false',
      );
      dispatch({
        type: UPDATE_EMAIL_CONFIG,
        payload: {
          value: {
            smsResponseMailOn: smsSetting,
            emailWhileOffline: offlineSetting,
          },
        },
      });
      return null;
    })
    .catch((err) => console.error(err));
};

export const setSMSResponseEmail = () => (dispatch, getStore) => {
  const newSetting = !_.get(
    getStore(),
    'profile.emailConfig.smsResponseMailOn',
    false,
  );
  dispatch({
    type: SET_EMAIL_CONFIG,
    payload: {
      setting: 'smsResponseMailOn',
      value: newSetting,
    },
  });

  const userId = _.get(getStore(), 'auth.user._id', null);
  return doRequest({
    endpoint: `${GENERIC_USER_SET}`,
    method: 'POST',
    body: {
      userId,
      properties: {
        'profile.emailConfig.smsResponseEmailEnabled': newSetting,
      },
    },
  }).catch((err) => console.error(err));
};

export const setEmailWhileOffline = () => (dispatch, getStore) => {
  const newSetting = !_.get(
    getStore(),
    'profile.emailConfig.emailWhileOffline',
    false,
  );
  const userId = _.get(getStore(), 'auth.user._id', null);
  dispatch({
    type: SET_EMAIL_CONFIG,
    payload: {
      setting: 'emailWhileOffline',
      value: newSetting,
    },
  });
  return doRequest({
    endpoint: `${GENERIC_USER_SET}`,
    method: 'POST',
    body: {
      userId,
      properties: {
        'profile.emailConfig.emailWhileOffline': newSetting,
      },
    },
  }).catch((err) => console.error(err));
};

export const getCustomerFeedback = () => (dispatch, getStore) => {
  const opts = {
    endpoint: GET_CUSTOMER_FEEDBACK(getStore().profile._id),
    method: 'GET',
  };
  return doRequest(opts)
    .then((response) => {
      dispatch({ type: LOAD_CUSTOMER_FEEDBACK, payload: response });
    })
    .catch(() => {
      dispatch({
        type: PROFILE_UPDATE_ERROR,
        payload: 'Cannot get customer feedback',
      });
    });
};

export const toggleAutoAssign = () => (dispatch, getStore) => {
  const userId = _.get(getStore().auth.user, '_id');
  dispatch(
    makeApiRequest(
      PUT_USER_AUTOASSIGN,
      [userId],
      { isAutoAssign: !getStore().auth.user.isAutoAssign },
      false,
    ),
  );
};

export const toggleNewInbox = () => (dispatch, getStore) => {
  const { isNewInbox } = getStore().profile.properties;
  dispatch(updateUserProperties({ isNewInbox: !isNewInbox }));
};

export const setNewInboxCardDensity = (newInboxCardDensity) => (dispatch) => {
  dispatch({ type: UPDATE_NEW_INBOX_CARD_DENSITY, payload: { newInboxCardDensity } });
  dispatch(persistUserProperties({ newInboxCardDensity }));
};

export function getListOfTitles() {
  const args = {
    endpoint: GET_LIST_OF_TITLES,
    method: 'GET',
    process: (t) => t,
    action: FETCH_LIST_OF_TITLES,
  };
  return apiCall({ args });
}
