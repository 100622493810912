/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import _ from 'lodash';
import { Modal } from 'react-bootstrap';

import Panel from '../Panel/Panel';
import SortTable from '../elements/table/SortTable/SortTable';
import TableHeader from '../elements/table/SortTable/TableHeader/TableHeader';
import DisabledBanner from '../elements/Banner/DisabledBanner/DisabledBanner';
import SurveyIcon from '../../../images/icons/survey.svg';
import PencilIcon from '../../../images/icons/pencil.svg';
import ChatIcon from '../../../images/icons/welcome-message.svg';
import CloseClaimConfirmation from '../confirmationModals/CloseClaimConfirmation/CloseClaimConfirmation';
import SendEmailConfirmation from '../confirmationModals/SendEmailConfirmation/SendEmailConfirmation'  // eslint-disable-line
import SendSurveyConfirmation from '../confirmationModals/SendSurveyConfirmation/SendSurveyConfirmation';
import SearchBar from '../SearchBarWrap/SearchBarWrap';
import Button from '../elements/buttons/Button/Button';

import { DISABLE_CREATE_CASE } from '../../constants/permissions';

import { sortItems, openCreateForm, closeForm } from '../../actions/ui';
// eslint-disable-next-line import/named
import { subscribeToTopics, unsubscribeFromTopics } from '../../actions/socket';
import {
  setEditItem, setViewItem, lazyLoad, getItems,
} from '../../actions/common';
import withLoading from '../HigherOrderComponents/withLoading';
import withLazyLoad from '../withLazyLoad/withLazyLoad';
import PolicyForm from './EnterPolicy/PolicyForm';
import PolicyRow from './PolicyRow/PolicyRow' // eslint-disable-line
import selector from '../../selectors/case';
import { formatPhoneNumber } from '../../helpers/format';
import { formatDate } from '../../helpers/datetime';
import { policyType, userType, caseType } from '../../models/marleyTypes';
import './Policies.less';
import { getAllOperatorsTopics } from '../../helpers/liveUpdates';
import useTopicHandler from '../elements/hooks/useTopicHandler';

export const columns = [
  { label: 'Policy Number', sort: false, location: 'metadata.038d0c94-d346-11ea-87d0-0242ac130003' },
  {
    label: 'Name', sort: false, location: ['companyName', 'customer.nameTableFormat'], sortLocation: ['companyName', 'customer.nameTableFormat'],
  },
  {
    label: 'Phone Number',
    sort: false,
    location: 'customer.phoneNumber',
    format: ({ value }) => (<div>{formatPhoneNumber(value)}</div>), // eslint-disable-line
    numericSort: true,
  },
  {
    label: 'Deductible', sort: false, location: 'metadata.4c7a89a2-8ea1-4984-b5d4-43a05c1a4983', numericSort: true,
  },
  // find how the policy object is being traversed
  {
    label: 'Contract Term',
    sort: false,
    location: 'contractTerm',
    format: ({ value }) => (_.get(value, 'effectiveDate', false) && _.get(value, 'expirationDate', false) ? (
      <div>
        {formatDate(value.effectiveDate, 'just-date')}
        {' '}
        -
        {' '}
        {formatDate(value.expirationDate, 'just-date')}
      </div>
    ) : (<div />)), // correct but it doesn't understand how to process the array.
  },
  {
    label: 'Date Created',
    sort: false,
    location: 'createdAt',
    format: ({ value }) => (<div>{value && formatDate(value, 'just-date')}</div>), // eslint-disable-line
    numericSort: true,
    sortType: 'date',
  },
  {
    id: 'assigned', label: 'Assigned', class: 'table-col-assigned', sort: false, location: 'operator', sortLocation: 'operatorSortValue',
  },
  { label: 'Actions', id: 'actions' },
];

const modalTitle = (type, mode) => {
  const title = type.title();
  if (mode === 'create') {
    return `Create ${title}`;
  } if (mode === 'edit') {
    return `Edit ${title}`;
  }
  return `${title} Information`;
};

const Policies = ({
  isLoading,
  sortItems,
  policies,
  operators,
  setEditItem,
  increaseDataSet,
  openCreateForm,
  closeForm,
  form,
  subscribeToTopics,
  unsubscribeFromTopics,
  users,
  organization,
  permissions,
}) => {
  const memoizedTopicHandler = useTopicHandler();

  useEffect(() => {
    sortItems(caseType.stateLocation(), 'dateCreated', 'DESC');
    subscribeToTopics(getAllOperatorsTopics(organization._id), memoizedTopicHandler);
    return () => {
      unsubscribeFromTopics(getAllOperatorsTopics(organization._id), memoizedTopicHandler);
    };
  }, []);

  const policyRows = columns && columns.map((col) => (
    <TableHeader tableId="policies" key={col.id || col.label} {...col} />
  ));

  const isCreationDisabled = permissions.find((v) => v === DISABLE_CREATE_CASE);
  const showModal = form && (form.isEditing
      || form.isCreating
      || form.isViewing);

  return (
    <Panel
      className="policies-page"
      header={isCreationDisabled
        ? (
          <DisabledBanner
            bannerText="Creating a policy is unavailable for your organization. Please go to your claims management system to create a new policy."
          />
        )
        : (
          <SearchBar
            id="policies"
            left={(
              <Button
                type="positive-marley"
                onClick={() => openCreateForm(caseType)}
              >
                Create Policy +
              </Button>
            )}
            label="policies"
            type={caseType}
          />
        )}
      title="Policies"
    >
      <Modal
        show={showModal}
        dialogClassName="policies-page-modal"
        onHide={
            () => {
              closeForm(caseType);
              closeForm(userType);
            }
          }
      >
        <div className="header-modal">
          <div data-testid="create-policy-modal" className="header">{modalTitle(policyType, _.get(form, 'mode'))}</div>
          <button
            className="cls-btn"
            onClick={
                () => {
                  closeForm(caseType);
                  closeForm(userType);
                }
              }
          />
        </div>
        <div className="body-modal">
          <PolicyForm />
        </div>
      </Modal>

      <SendEmailConfirmation id="job" />
      <CloseClaimConfirmation />
      <SendSurveyConfirmation id="job" />

      <SortTable
        id="policy"
        label="policies"
        columns={policyRows}
        increaseDataSet={increaseDataSet}
        isLoading={isLoading}
        rows={policies && policies.map((policy) => {
          const insured = _.get(users, `listById.${policy.insuredId}`);
          const actions = [
            {
              id: 'edit',
              label: 'Edit Policy',
              action: (id) => {
                setEditItem(caseType, id);
                const policy = policies.find((p) => p.id === id) || {};
                if (policy.customer) setEditItem(userType, policy.customer);
                setEditItem(policyType, id);
              },
              icon: <PencilIcon />,
            }, {
              id: 'survey',
              label: 'Send Survey',
              confirmation: true,
              icon: <SurveyIcon />,
              disabled: !policy.customer.verified,
            }, {
              id: 'resendwelcome',
              label: 'Resend Opt-In Text',
              confirmation: true,
              icon: (<ChatIcon />),
              disabled: policy.customer.verified,
            },
          ];
          return (
            <PolicyRow
              key={policy.id}
              item={policy}
              operators={operators}
              insured={insured}
              actions={actions}
              columns={columns}
            />
          );
        })}
      />
    </Panel>
  );
};

Policies.propTypes = {
  closeForm: PropTypes.func.isRequired,
  form: PropTypes.instanceOf(Object),
  openCreateForm: PropTypes.func.isRequired,
  operators: PropTypes.arrayOf(Object).isRequired,
  policies: PropTypes.arrayOf(Object),
  setEditItem: PropTypes.func.isRequired,
  setViewItem: PropTypes.func.isRequired,
  sortItems: PropTypes.func.isRequired,
  users: PropTypes.instanceOf(Object),
};

Policies.defaultProps = {
  form: { mode: 'isCreating' },
  policies: [],
  users: [],
};

export { Policies };

export const mapDispatchToProps = {
  closeForm,
  openCreateForm,
  setEditItem,
  setViewItem,
  sortItems,
  lazyLoad,
  getItems,
  subscribeToTopics,
  unsubscribeFromTopics,
};

export const mapStateToProps = (state) => selector(state, columns);

const PoliciesWithLoading = withLoading(Policies, { type: caseType });
const LoadedPolicies = withLazyLoad(
  PoliciesWithLoading,
  {
    type: caseType,
    listLocation: 'jobs',
    defaultSort: {
      column: 'createdAt',
    },
    query: {
      caseTypeId: ['11f43fa7-df90-4da2-92b9-caae0d215323', 'badf9200-a0e0-48ae-ae7c-c51c97e9f14b'],
    },
  },
);

export default connect(mapStateToProps, mapDispatchToProps)(LoadedPolicies);
