/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import Twemoji from 'react-twemoji'
import './Emojis.less'
import SlideModal from '../../../../elements/Modal/SlideModal'

const EMOJI_PER_LINE = 15
const EMOJI_WIDTH = 100 / EMOJI_PER_LINE

const EmojiButton = ({
  item, index, currentIndex, selectEmoji,
}) => {
  const buttonRef = useRef()

  useEffect(() => {
    if (currentIndex === index && buttonRef) buttonRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [currentIndex])

  const { id, icon } = item

  return (
    <button
      key={id}
      type="button"
      style={{ width: `${EMOJI_WIDTH}%` }}
      className={index === currentIndex ? 'emoji-panel-button-active' : 'emoji-panel-button'}
      ref={buttonRef}
      onClick={() => selectEmoji(index)}
    >
      <Twemoji options={{ className: 'emoji' }}>
        {icon}
      </Twemoji>
    </button>
  )
}

/**
 * Emojis
 * Slide popup for selecting an emoji in chat
 * @param {boolean} showEmoji show modal
 * @param {function} setShowEmoji function to show/hide modal
 * @param {function} selectEmoji function for cancelling an emoji
 * @param {array} emojis list of emoji objects, { id, icon }
 * @param {number} currentIndex highlighted emoji index
* */
const Emojis = ({
  currentIndex, emojis, selectEmoji, showEmoji, setShowEmoji, className, style,
}) => {
  const componentRef = useRef()

  useEffect(() => {
    if (showEmoji && componentRef && componentRef.scrollTop) componentRef.scrollTop()
  }, [showEmoji])

  return (
    <SlideModal
      id="emoji-panel"
      forwardRef={componentRef}
      className={className}
      show={showEmoji}
      size="auto"
      style={style}
      toggleModal={setShowEmoji}
    >
      <>
        <div className="emoji-panel-header">
          <div className="emoji-panel-help-left">Emojis</div>
          <div className="emoji-panel-help">
            <Translate value="messageTemplates.fullNavigate" />
            <strong> tab</strong>
          </div>
          <div className="emoji-panel-help">
            <Translate value="messageTemplates.selectWith" />
            <strong> enter or click</strong>
          </div>
          <div className="emoji-panel-help">
            <Translate value="messageTemplates.dismissWith" />
            <strong> esc</strong>
          </div>
        </div>
        <div className="emoji-icon-container">
          {emojis.map((item, index) => (
            // eslint-disable-next-line react/jsx-key
            <EmojiButton
              item={item}
              index={index}
              currentIndex={currentIndex}
              selectEmoji={selectEmoji}
            />
          ))}
        </div>
      </>
    </SlideModal>
  )
}

Emojis.propTypes = {
  emojis: PropTypes.arrayOf(Object).isRequired,
  selectEmoji: PropTypes.func.isRequired,
  setShowEmoji: PropTypes.func.isRequired,
  showEmoji: PropTypes.bool.isRequired,
}

export default Emojis
