import React from 'react'
import PropTypes from 'prop-types'
import StarIcon from '../StarRate/StarIcon/StarIcon'
import './Score.less'

const Score = ({ score, onClick }) => {
  if (score) { 
    return (
      <div role="link" tabIndex="0" className="score-display" onClick={onClick || (() => {})} >
        <div className="icon">
          <StarIcon />
        </div>
        <div className="score">{score && score.toFixed(1)}</div>
      </div>
    )
  } else {
    return null
  }
}
Score.propTypes = {
  score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
}

Score.defaultProps = {
  score: null,
  onClick: () => {},
}

export default Score
