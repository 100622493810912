import React from 'react'
import PropTypes from 'prop-types'
import './FormInfo.less'

const FormInfo = ({ value, className, label, addOn }) => (
  <div className={`form-info ${className}`} >
    <div className="form-info-label">
      {label}
    </div>
    <div className="form-info-value-wrap">
      <div className="form-field-prefix">{addOn}</div>
      <div className={`form-info-value ${addOn ? 'with-addon' : ''}`}>{value}</div>
    </div>
  </div>
)

FormInfo.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  addOn: PropTypes.string,
}
FormInfo.defaultProps = {
  className: '',
  label: '',
  value: '',
  addOn: '',
}

export default FormInfo
