import {
  CLEAR_LOADED_TEMPLATE, SORT_TEMPLATES, SET_EDIT_TEMPLATE,
  MODIFIED_TEMPLATE, NEW_TEMPLATE, LOAD_KEYS, LOAD_TEMPLATES, INIT_TEMPLATE_REQUEST,
  ADD_TEMPLATE_ERROR, CLEAR_TEMPLATE_ERRORS, FILTER_TEMPLATES, CREATE_NEW_TEMPLATE,
  EDIT_ONE_TEMPLATE, CANCEL_EDIT_TEMPLATE, POPULATE_TEMPLATE,
} from '../constants/actions';
import {
  CREATE_TEMPLATE, EDIT_TEMPLATE, GET_TEMPLATES, DELETE_TEMPLATE, GET_KEYS,
} from '../constants/endpoints';
import { doRequest, apiCall } from './api';

import Template from '../models/template';
import Key from '../models/key';

export const addTemplateError = (error) => ({
  type: ADD_TEMPLATE_ERROR,
  payload: error,
});

export const filterTemplates = (filter) => ({
  type: FILTER_TEMPLATES,
  payload: filter,
});

export const selectTemplate = (text) => ({
  type: POPULATE_TEMPLATE,
  payload: {
    text,
  },
});

export const clearTemplateErrors = () => ({ type: CLEAR_TEMPLATE_ERRORS });
export const initTemplateRequest = () => ({ type: INIT_TEMPLATE_REQUEST });
export const editTemplate = (id) => ({ type: SET_EDIT_TEMPLATE, payload: id });
export const clearLoadedTemplate = () => ({ type: CLEAR_LOADED_TEMPLATE });
export const createNewTemplate = () => ({ type: CREATE_NEW_TEMPLATE });
export const editOneTemplate = (template) => ({ type: EDIT_ONE_TEMPLATE, payload: template });
export const cancelEditor = () => ({ type: CANCEL_EDIT_TEMPLATE });

export const addTemplate = (template) => ({ type: NEW_TEMPLATE, payload: new Template(template) });
export const modifyTemplate = (template) => ({
  type: MODIFIED_TEMPLATE, payload: new Template(template),
});

export const sortTemplates = (column, order) => ({
  type: SORT_TEMPLATES,
  payload: { column, order },
});
export function createTemplate(template) {
  const data = { ...template };

  const args = {
    endpoint: CREATE_TEMPLATE,
    method: 'POST',
    process: (t) => t,
    body: data,
    action: 'ADD_TEMPLATE',
    callback: ({ body }) => addTemplate(body),
  };
  return apiCall({ args });
}

export function updateTemplate(template) {
  const data = { ...template };

  const args = {
    endpoint: EDIT_TEMPLATE,
    method: 'PUT',
    process: (t) => t,
    body: data,
    action: 'EDIT_TEMPLATE',
    callback: ({ body }) => modifyTemplate(body),
  };
  return apiCall({ args });
}

export function getKeys() {
  return (dispatch) => {
    const args = {
      endpoint: GET_KEYS,
    };

    return doRequest(args)
      .then((response) => {
        dispatch({ type: LOAD_KEYS, payload: response.map((t) => new Key(t)) });
      })
      .catch(() => { });
  };
}

export function getTemplates() {
  return (dispatch) => {
    const args = {
      endpoint: GET_TEMPLATES,
    };

    return doRequest(args)
      .then((response) => {
        dispatch({
          type: LOAD_TEMPLATES,
          payload: {
            items: response.map((t) => new Template(t)),
            refresh: true,
          },
        });
      })
      .catch(() => { });
  };
}

export function removeTemplate(id) {
  return (dispatch) => {
    const args = {
      endpoint: DELETE_TEMPLATE(id),
      method: 'DELETE',
    };
    return doRequest(args)
      .then(() => {
        dispatch(getTemplates());
      })
      .catch(() => { });
  };
}
