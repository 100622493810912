import _ from 'lodash';
import { localToRemoteUrl } from '../helpers/urls';
import {
  DEAUTH_USER,
  RESET_PROFILE,
  UPDATE_PROFILE,
  UPDATE_PROFILE_AUTOREPLY,
  SET_PROFILE_EDIT_MODE,
  UPDATE_PROFILE_PROPERTIES,
  PROFILE_REQUEST_STARTED,
  SET_PROFILE,
  PROFILE_UPDATE_ERROR,
  CLEAR_PROFILE_UPDATE,
  LOAD_CUSTOMER_FEEDBACK,
  UPDATED_USER,
  UPDATE_EMAIL_CONFIG,
  SET_EMAIL_CONFIG,
  RESET_PROFILE_REQUESTS,
  REQUEST_GET_USERS_AUTOREPLIES_INIT,
  REQUEST_GET_USERS_AUTOREPLIES_SUCCESS,
  REQUEST_GET_USERS_AUTOREPLIES_ERROR,
  GET_USERS_AUTOREPLIES,
  REQUEST_POST_USERS_AUTOREPLIES_INIT,
  REQUEST_POST_USERS_AUTOREPLIES_ERROR,
  REQUEST_POST_USERS_AUTOREPLIES_SUCCESS,
  POST_USERS_AUTOREPLIES,
  REQUEST_PUT_USERS_AUTOREPLIES_INIT,
  REQUEST_PUT_USERS_AUTOREPLIES_ERROR,
  REQUEST_PUT_USERS_AUTOREPLIES_SUCCESS,
  PUT_USERS_AUTOREPLIES,
  REQUEST_DELETE_USERS_AUTOREPLIES_INIT,
  REQUEST_DELETE_USERS_AUTOREPLIES_ERROR,
  REQUEST_DELETE_USERS_AUTOREPLIES_SUCCESS,
  DELETE_USERS_AUTOREPLIES,
  DISABLE_NEXT_BTN,
  UPDATE_CHECKED_QUERY_PARAMETERS,
  UPDATE_NEW_INBOX_CARD_DENSITY,
} from '../constants/actions';
import { SUCCESS } from '../constants/general';

export const initialState = {
  stub: true,
  readonly: true,
  pendingRequest: false,
  profileIsUpdate: false,
  error: '',
  success: '',
  score: '',
  firstName: '',
  lastName: '',
  properties: {
    inboxFilters: {},
  },
  customerFeedback: [],
  initializeAutoReply: false,
  isAutoAssign: false,
  userAutoReply: {
    generalAutoReplies: {},
    oooReplies: {},
  },
  userStatus: false,
  permissions: [],
  emailConfig: {
    smsResponseMailOn: false,
    emailWhileOffline: false,
  },
  nextButtonDisabled: false,
  retrievedProfileProperties: null,
  checkedQueryParameters: null,
  userProfileFetchCount: 0,
};

const updateImageUrl = (user) => {
  const imageUrl = user?.imageUrl || user?.profile?.imageUrl || '';
  _.set(user, 'imageUrl', imageUrl ? localToRemoteUrl(imageUrl) : null);
  return user;
};

const whatStatus = (autoReply) => (_.get(autoReply, 'active') ? 'away' : 'active');

const profile = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_USER_SCORE': {
      const score = _.get(action, 'payload.averageScore', '');
      return { ...state, score };
    }
    case 'GET_USER_BY_OKTA_USER_ID': {
      updateImageUrl(action.payload);
      return { ...state, ...action.payload, readonly: true };
    }
    case 'REQUEST_UPLOAD_PROFILE_IMAGE_SUCCESS': {
      updateImageUrl(action.payload);
      return { ...state, ...action.payload };
    }
    case DEAUTH_USER:
    case RESET_PROFILE: {
      return {
        ...initialState,
        retrievedProfileProperties: state.retrievedProfileProperties,
        // do not reset new inbox values as we never want to manually do that
        properties: {
          isNewInbox: state.properties.isNewInbox || false,
          inboxFilters: state.properties.inboxFilters || {},
        },
      };
    }
    case 'UPDATE_LAST_ASSIGNED': {
      const properties = { ...state.properties };
      if (!properties.recentlyAssignedOperators) properties.recentlyAssignedOperators = {};

      const {
        jobsLastOperators,
        claimsLastOperators,
        policiesLastOperators,
      } = action.payload;
      const changes = {};
      if (jobsLastOperators) {
        changes.cases = jobsLastOperators;
      }
      if (claimsLastOperators) {
        changes.claims = claimsLastOperators;
      }
      if (policiesLastOperators) {
        changes.policies = policiesLastOperators;
      }

      properties.recentlyAssignedOperators = {
        ...properties.recentlyAssignedOperators,
        ...changes,
      };

      return { ...state, properties: { ...properties } };
    }
    case 'UPDATE_PROFILE_FILTERS': {
      const { payload } = action;
      const { filters, inboxFilters, hasUsedNewInboxFilters } = payload || {};
      return {
        ...state,
        properties: {
          ...state.properties || {},
          hasUsedNewInboxFilters,
          filters,
          ...(inboxFilters && { inboxFilters }),
        },
      };
    }
    case UPDATE_PROFILE_PROPERTIES: {
      return {
        ...state,
        properties: action.payload,
        retrievedProfileProperties: SUCCESS,
        userProfileFetchCount: state.userProfileFetchCount + 1,
      };
    }
    case UPDATE_CHECKED_QUERY_PARAMETERS: {
      return { ...state, checkedQueryParameters: SUCCESS };
    }
    case UPDATE_PROFILE: {
      updateImageUrl(action.payload);
      return {
        ...state,
        ...action.payload,
        pendingRequest: false,
        error: '',
        profileIsUpdate: true,
        readonly: true,
      };
    }
    case UPDATE_PROFILE_AUTOREPLY: {
      return {
        ...state,
        replies: action.payload,
        pendingRequest: false,
        profileIsUpdate: true,
      };
    }
    case CLEAR_PROFILE_UPDATE: {
      return { ...state, profileIsUpdate: false };
    }
    case SET_PROFILE_EDIT_MODE: {
      return {
        ...state,
        readonly: action.payload,
        profileIsUpdate: false,
        error: '',
      };
    }
    case PROFILE_REQUEST_STARTED: {
      return {
        ...state,
        ...action.payload,
        pendingRequest: true,
        profileIsUpdate: false,
        error: '',
      };
    }
    case SET_PROFILE: {
      updateImageUrl(action.payload);
      return {
        ...state,
        ...action.payload,
        pendingRequest: false,
        error: '',
        profileIsUpdate: false,
      };
    }
    case PROFILE_UPDATE_ERROR: {
      return { ...state, error: action.payload, pendingRequest: false };
    }
    case LOAD_CUSTOMER_FEEDBACK: {
      return { ...state, customerFeedback: action.payload };
    }
    case UPDATED_USER: {
      if (action.payload.id === state.id) {
        return { ...state, ...action.payload };
      }
      return state;
    }
    case UPDATE_EMAIL_CONFIG: {
      return {
        ...state,
        emailConfig: {
          ...action.payload.value,
        },
      };
    }
    case SET_EMAIL_CONFIG: {
      const stateClone = _.cloneDeep(state);
      _.set(
        stateClone,
        `emailConfig.${action.payload.setting}`,
        action.payload.value,
      );
      return stateClone;
    }
    case RESET_PROFILE_REQUESTS: {
      return {
        ...state,
        pendingRequest: false,
        error: '',
        success: '',
      };
    }
    case REQUEST_PUT_USERS_AUTOREPLIES_INIT: {
      return {
        ...state,
        pendingRequest: true,
      };
    }
    case REQUEST_POST_USERS_AUTOREPLIES_INIT: {
      return {
        ...state,
        pendingRequest: true,
      };
    }
    case REQUEST_DELETE_USERS_AUTOREPLIES_INIT: {
      return {
        ...state,
        pendingRequest: true,
      };
    }
    case REQUEST_PUT_USERS_AUTOREPLIES_ERROR: {
      const errors = _.get(action, 'payload.response.data.errors');
      const errorMessage = errors && errors.length > 0 ? errors[0].detail : 'Sorry, system error.';
      return {
        ...state,
        error: errorMessage,
        pendingRequest: false,
      };
    }
    case REQUEST_POST_USERS_AUTOREPLIES_ERROR: {
      const errors = _.get(action, 'payload.response.data.errors');
      const errorMessage = errors && errors.length > 0 ? errors[0].detail : 'Sorry, system error.';
      return {
        ...state,
        error: errorMessage,
        pendingRequest: false,
      };
    }
    case REQUEST_DELETE_USERS_AUTOREPLIES_ERROR: {
      const errors = _.get(action, 'payload.response.data.errors');
      const errorMessage = errors && errors.length > 0 ? errors[0].detail : 'Sorry, system error.';
      return {
        ...state,
        error: errorMessage,
        pendingRequest: false,
      };
    }
    case REQUEST_PUT_USERS_AUTOREPLIES_SUCCESS: {
      return {
        ...state,
        success: 'success',
        pendingRequest: false,
      };
    }
    case REQUEST_POST_USERS_AUTOREPLIES_SUCCESS: {
      return {
        ...state,
        success: 'success',
        pendingRequest: false,
      };
    }
    case REQUEST_DELETE_USERS_AUTOREPLIES_SUCCESS: {
      return {
        ...state,
        success: 'success',
        pendingRequest: false,
      };
    }
    case REQUEST_GET_USERS_AUTOREPLIES_INIT: {
      return { ...state, initializeAutoReply: true };
    }
    case REQUEST_GET_USERS_AUTOREPLIES_SUCCESS: {
      return { ...state, initializeAutoReply: false };
    }
    case REQUEST_GET_USERS_AUTOREPLIES_ERROR: {
      return {
        ...state,
        error: 'get error',
        initializeAutoReply: false,
      };
    }
    case DELETE_USERS_AUTOREPLIES: {
      const stateClone = { ...state };
      const hasGar = stateClone.userAutoReply.generalAutoReplies.list.find(
        (reply) => reply.id === action.payload.id,
      );
      const hasOoo = stateClone.userAutoReply.oooReplies.list.find(
        (reply) => reply.id === action.payload.id,
      );
      if (hasGar) {
        _.set(
          stateClone,
          'userAutoReply.generalAutoReplies.list',
          stateClone.userAutoReply.generalAutoReplies.list.filter(
            (reply) => reply.id !== action.payload.id,
          ),
        );
        stateClone.userStatus = whatStatus(action.payload);
      } else if (hasOoo) {
        _.set(
          stateClone,
          'userAutoReply.oooReplies.list',
          stateClone.userAutoReply.oooReplies.list.filter(
            (reply) => reply.id !== action.payload.id,
          ),
        );
      }
      return stateClone;
    }
    case PUT_USERS_AUTOREPLIES: {
      const stateClone = { ...state };
      if (action.payload.type === 'GENERAL-AUTO-REPLY') {
        _.set(
          stateClone,
          'userAutoReply.generalAutoReplies.list',
          stateClone?.userAutoReply?.generalAutoReplies?.list?.map((reply) => (reply.id === action.payload.id ? action.payload : reply)),
        );
        stateClone.userStatus = whatStatus(action.payload);
      } else if (action.payload.type === 'OUT-OF-OFFICE') {
        _.set(
          stateClone,
          'userAutoReply.oooReplies.list',
          stateClone.userAutoReply.oooReplies.list.map((reply) => (reply.id === action.payload.id ? action.payload : reply)),
        );
      }
      return stateClone;
    }
    case POST_USERS_AUTOREPLIES: {
      const stateClone = { ...state };
      if (action.payload.type === 'GENERAL-AUTO-REPLY') {
        _.set(stateClone, 'userAutoReply.generalAutoReplies.list', [
          action.payload,
          ...stateClone.userAutoReply.generalAutoReplies.list,
        ]);
        stateClone.userStatus = whatStatus(action.payload);
      } else if (action.payload.type === 'OUT-OF-OFFICE') {
        _.set(stateClone, 'userAutoReply.oooReplies.list', [
          action.payload,
          ...stateClone.userAutoReply.oooReplies.list,
        ]);
      }
      return stateClone;
    }
    case GET_USERS_AUTOREPLIES: {
      const list = action.payload;
      const generalAutoRepliesList = _.filter(list, {
        type: 'GENERAL-AUTO-REPLY',
      });
      const oooRepliesList = _.filter(list, { type: 'OUT-OF-OFFICE' });
      return {
        ...state,
        userStatus: whatStatus(_.first(generalAutoRepliesList)),
        userAutoReply: {
          generalAutoReplies: { list: [...generalAutoRepliesList] },
          oooReplies: { list: [...oooRepliesList] },
        },
      };
    }
    case DISABLE_NEXT_BTN: {
      return {
        ...state,
        nextButtonDisabled: action.payload,
      };
    }
    case UPDATE_NEW_INBOX_CARD_DENSITY: {
      const { payload } = action;
      const { newInboxCardDensity } = payload || {};
      return {
        ...state,
        properties: {
          ...state.properties || {},
          newInboxCardDensity,
        },
      };
    }
    default:
      return state;
  }
};

export default profile;
