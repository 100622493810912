/* eslint-disable react/no-unescaped-entities */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MentionsInput, Mention } from 'react-mentions';
import {
  Button, Modal, ButtonRow, TextInputLine,
} from '@himarley/unity';
import { useSelector } from 'react-redux';
import IconButtonWithText from '../../elements/buttons/IconButton/IconButtonWithText';
import LightBulb from '../../../../images/icons/lightbulb.svg';
import GarbageIcon from '../../icons/GarbageIcon';
import { capitalizeEachWord } from '../../../helpers/format';
import { generateReadableBody } from './helpers';
import './MessageTemplateForm.less';
import { messageTemplatePropTypes } from '../../modal/TemplatesModal/template-helpers';

const MAX_CHAR_TITLE_LENGTH = 250;
const MAX_CHAR_INPUT_LENGTH = 1400;

/**
 * Create Template
 * @param {boolean} show show this modal
 * @param {function} toggleModal trigger change in show
 * @param {function} handleSubmit called when submitting the form
 * @param {boolean} isEdit is this an edit or create
 * @param {object} templateData template for editing
 * @param {boolean} showDeleteButton show delete button
 * */

const MessageTemplateForm = ({
  show,
  lob,
  handleSubmit,
  showDeleteButton,
  toggleModal,
  isEdit,
  templateData,
}) => {
  const [formData, setFormData] = useState({
    displayName: '',
    body: '',
  });
  const [canSubmit, setCanSubmit] = useState(false);

  const handleFormFieldChange = (name, maxLength) => (e) => {
    const { value } = e.target;
    const newValue = value.slice(0, maxLength);
    setCanSubmit(value.length > 0);
    setFormData({ ...formData, [name]: newValue });
  };

  const handleCancel = () => {
    toggleModal(false);
    setCanSubmit(false);
  };

  useEffect(() => {
    if (!show) {
      setFormData({});
    }
  }, [show]);

  const tokens = useSelector(({ templates }) => templates.keys);

  useEffect(() => {
    if (templateData && Object.keys(templateData).length) {
      // parse template tokens and replace with readable text
      const readableBody = generateReadableBody(tokens, templateData);
      setFormData({
        body: readableBody,
        displayName: templateData.displayName,
      });
    }
  }, [templateData, tokens]);

  // passed into Mentions to render dropdown of available tokens
  // updated when text changes
  const filteredTokens = tokens?.map(({ key }, index) => ({
    id: `${index}`,
    display: key,
  }));

  const templateInput = useRef(null);

  const renderSuggestion = (suggestion) => (
    <div className="suggestionWrap">
      <div className="user-info">
        <div className="user-name">
          {' '}
          {suggestion?.display}
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      show={show}
      title={isEdit ? 'Edit Message Template' : 'New Message Template'}
      className={
        !isEdit
          ? 'createMessageEditorModal message-editor-modal'
          : 'editMessageEditorModal message-editor-modal'
      }
      toggleModal={handleCancel}
      size="lg"
    >
      <p className="requiredWrap">Required fields labeled with an asterisk.</p>
      <div className="titleVisibleToWrap">
        <TextInputLine
          label="Title"
          type="line"
          value={formData?.displayName}
          onChange={handleFormFieldChange('displayName', MAX_CHAR_TITLE_LENGTH)}
        />
        {lob && (
        <div className="lobVisibleTo">
          <div className="lobVisibleToLabel">Visible To</div>
          <div>
            {capitalizeEachWord(
              `${lob?.type} ${lob?.displayName} (${lob?.branding})`,
            )}
          </div>
        </div>
        )}
      </div>
      <div className="text-input-label templatesLabelWrap">
        <div>
          Message Template
          <span className="text-danger">*</span>
        </div>
        <div
          className={`textarea-input-char-count ${
            formData?.body ? 'textarea-input-char-count-active' : ''
          }`}
          data-testid="textarea-input-char-count"
        >
          {formData?.body?.length ?? 0}
          /
          {MAX_CHAR_INPUT_LENGTH}
        </div>
      </div>
      <MentionsInput
        inputRef={templateInput}
        value={formData?.body ?? ''}
        onChange={handleFormFieldChange('body', MAX_CHAR_INPUT_LENGTH)}
        className="mentionsInput integrationTemplateModal"
        allowSpaceInQuery
      >
        <Mention
          renderSuggestion={renderSuggestion}
          className="noteSuggestion"
          trigger="$"
          data={filteredTokens}
        />
      </MentionsInput>
      <div className="tipWrap">
        <LightBulb />
        {' '}
        TIP: Type the '$' symbol to insert a message template
        variable.
      </div>

      <ButtonRow>
        {isEdit && showDeleteButton && (
        <IconButtonWithText
          onClick={() => null}
          className="remove-template-icon-button"
          text="Delete Template"
        >
          <GarbageIcon />
        </IconButtonWithText>
        )}
        <Button
          type="outline"
          onClick={handleCancel}
          testId="template-cancel-button"
        >
          Cancel
        </Button>
        <Button
          type="positive"
          testId="template-submit-button"
          disabled={!canSubmit}
          onClick={() => handleSubmit(formData)}
        >
          {isEdit ? 'Save' : 'Create'}
          {' '}
          Template
        </Button>
      </ButtonRow>
    </Modal>
  );
};

MessageTemplateForm.propTypes = {
  show: PropTypes.bool,
  isEdit: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showDeleteButton: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  templateData: messageTemplatePropTypes.isRequired,
  lob: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string,
    displayName: PropTypes.string,
    branding: PropTypes.string,
  }),
};

MessageTemplateForm.defaultProps = {
  show: false,
  lob: null,
};

export default MessageTemplateForm;
