import React from 'react';
import PropTypes from 'prop-types';
import './ViewParticipantsButton.less';

const ViewParticipantsButton = ({ onClick }) => (
  <button
    type="button"
    className="view-participants-button"
    onClick={onClick}
    data-testid="view-participants"
  >
    View
  </button>
);

ViewParticipantsButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ViewParticipantsButton;
