import React from 'react'
import { useSelector } from 'react-redux'
import ChatParticipantCount from './ChatParticipantCount'

const ChatParticipantCountContainer = () => {
  const chatId = useSelector((state) => state?.jobs?.activeChat?.id)
  const chats = useSelector((state) => state?.jobs?.chats)
  const numberOfActiveParticipants = chats?.[chatId]?.participants?.length || 0
  return (
    <ChatParticipantCount count={numberOfActiveParticipants} />
  )
}

export default ChatParticipantCountContainer;
