/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import EditIcon from '../../icons/EditIcon';
import FormField from '../../Form/FormField/FormField';
import IconButton from '../buttons/IconButton/IconButton';
import AIStars from '../../../../images/icons/ai-stars.svg';
import './EditableLine.less';

const EDIT = 'edit';
const DISPLAY = 'display';

const EditField = ({
  children, onChange, onBlur, value, testId,
}) => children || (
<FormField
  onChange={(e) => {
    if (onChange) onChange(e);
  }}
  onBlur={(e) => {
    if (onBlur) onBlur(e);
  }}
  defaultValue={value}
  data-testid={testId}
/>
);

const EditableLine = ({
  subtext,
  error,
  show,
  children,
  disableEdit,
  className,
  label,
  placeholder,
  value,
  onChange,
  onBlur,
  highlightMarleyNumberInRed,
  testId,
  source,
}) => {
  const [mode, setMode] = useState(DISPLAY);

  const aiGeneratedValue = source === 'chatGPT';
  const marleyAIHoverMessage = 'Identified by Marley AI';

  const showLabel = (mode !== EDIT && !show) || disableEdit; // i.e. don't show the editable text

  const isAIGeneratedClass = aiGeneratedValue ? 'ai-generated-value' : '';
  const isEditableContainerClass = !disableEdit ? `editable-line-value-container ${isAIGeneratedClass}` : 'editable-line-value-disabled';
  const isPlaceholderValueClass = !value && placeholder ? 'editable-line-placeholder' : '';
  const highlightMarleyNumberInRedClass = highlightMarleyNumberInRed ? 'text-red' : '';

  const renderEditableContainer = () => (
    <div
      className={isEditableContainerClass}
      data-testId={`editable-line-value-container-ai-generated-${aiGeneratedValue}`}
    >
      {showLabel ? (
        <div
          className={`editable-line-display-value ${isPlaceholderValueClass} ${highlightMarleyNumberInRedClass}`}
        >
          {value || placeholder}
        </div>
      ) : (
        <EditField
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          testId={testId}
        >
          {children}
        </EditField>
      )}
      { aiGeneratedValue
      && <AIStars className="ai-stars" />}
      <IconButton
        data-testid="editable-line-icon-button"
        className={
        mode === EDIT
          ? 'editable-line-edit-button-always'
          : 'editable-line-edit-button'
      }
        onClick={() => setMode(mode === EDIT ? DISPLAY : EDIT)}
      >
        <EditIcon className="" />
      </IconButton>
    </div>
  );
  return (
    <div
      className={`editable-line ${className || ''} ${error ? 'has-error' : ''}`}
    >
      {label && (
        <div className="editable-line-display-label data-label">{label}</div>
      )}
      { aiGeneratedValue ? (
        <OverlayTrigger overlay={<Tooltip id="popover-contained">{marleyAIHoverMessage}</Tooltip>} placement="top">
          {renderEditableContainer()}
        </OverlayTrigger>
      ) : (
        renderEditableContainer()
      )}
      {subtext && <div className="editable-line-help-text">{subtext}</div>}
    </div>
  );
};

export default EditableLine;
