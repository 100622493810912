import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, List } from '@himarley/unity';
import Title from '../../elements/text/Title/Title';
import Row from '../../elements/Row/Row';
import Dropdown from '../../elements/dropdowns/Dropdown/Dropdown';
import LoBIcon from './LoBIcon';
import EditIcon from '../../../../images/icons/edit.svg';
import ActionIcon from '../../../../images/icons/action.svg';
import { typeToLine } from './helpers';
import { capitalizeEachWord } from '../../../helpers/format';
import EditLineOfBusinessModal from './EditLineOfBusinessModal';
import RemoveLineOfBusinessModal from './RemoveLineOfBusinessModal';
import RemoveIcon from '../../../../images/icons/close2.svg';

const LineOfBusinessCard = ({
  name,
  index,
  handleEditBrand,
  handleDeleteBrand,
  brandingMap,
  lob,
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const menuItems = [
    {
      id: 'edit',
      onClick: () => setShowEditModal(true),
      icon: <EditIcon />,
      label: 'Edit',
    },
    {
      id: 'remove',
      onClick: () => setShowDeleteModal(true),
      icon: <RemoveIcon />,
      label: 'Remove',
    },
  ];

  return (
    <>
      <EditLineOfBusinessModal
        show={showEditModal}
        isEditing
        hideModal={() => setShowEditModal(false)}
        handleEditBrand={handleEditBrand}
        name={name}
        index={index}
        brandingMap={brandingMap}
        lob={lob}
      />
      <RemoveLineOfBusinessModal
        show={showDeleteModal}
        hideModal={() => setShowDeleteModal(false)}
        handleDeleteBrand={handleDeleteBrand}
        name={name}
        index={index}
        lob={lob}
      />
      <Row type="lob">
        <Card className="brandList">
          <Row>
            <div>
              <LoBIcon name={lob?.subType} />
            </div>
            <div className="lobName">
              <Title type="subheader">
                <b>
                  {capitalizeEachWord(lob?.displayName || lob?.subType)}
                </b>
              </Title>
              <p>{typeToLine[lob?.type]}</p>
            </div>
            <div className="iconRow">
              <Dropdown
                className="actionDropdown"
                id={`${lob?.type}${lob?.subType}LoBActionDropdown`}
                DropItem={<ActionIcon />}
                showArrowIcon={false}
              >
                <List
                  onToggle={(toggledId) => {
                    const item = menuItems.find((b) => b.id === toggledId);
                    if (item) item.onClick();
                  }}
                  items={menuItems}
                />
              </Dropdown>
            </div>
          </Row>
        </Card>
      </Row>
    </>
  );
};

LineOfBusinessCard.propTypes = {
  name: PropTypes.string,
  handleEditBrand: PropTypes.func,
  handleDeleteBrand: PropTypes.func,
  index: PropTypes.string,
  brandingMap: PropTypes.shape({ has: PropTypes.func }),
  lob: PropTypes.shape({
    subType: PropTypes.string,
    type: PropTypes.string,
    displayName: PropTypes.string,
  }).isRequired,
};

LineOfBusinessCard.defaultProps = {
  name: '',
  handleEditBrand: () => {},
  handleDeleteBrand: () => {},
  index: '',
  brandingMap: { has: () => {} },
};

export default LineOfBusinessCard;
