import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import invert from 'lodash/invert';
import { getMassNotifications } from '../../actions/massNotifications';
import {
  closeForm,
  closeProfileConfig,
  unsetLeftBarBlueDot,
} from '../../actions/ui';
import { loadReports } from '../../actions/analytics';
import { fetchCaseTypes } from '../../actions/job';
import {
  caseType,
  claimType,
  policyType,
  massNotificationType,
} from '../../models/marleyTypes';
import {
  ANALYTICS_VIEW,
  CLAIMS_VIEW,
  CASES_VIEW,
  POLICIES_VIEW,
  USER_MGMT_VIEW,
  CHATS_VIEW,
  MASSNOTIFICATIONS_VIEW,
  SETTINGS_VIEW,
} from '../../constants/dashboardState';
import {
  CLAIMS_TAB,
  POLICY_TAB,
  USER_MANAGEMENT_TAB,
  ANALYTICS_TAB,
  CASES_TAB,
  CHATS_TAB,
  MASSNOTIFICATIONS_TAB,
  SYSTEM_ADMIN,
} from '../../constants/permissions';
import './LeftBar.less';
import ChatIcon from '../../../images/icons/chat-bubbles.svg';
import CaseIcon from '../../../images/icons/briefcase.svg';
import ClaimIcon from '../../../images/icons/clipboard.svg';
import PolicyIcon from '../../../images/icons/policy.svg';
import BullHornIcon from '../../../images/icons/bullhorn.svg';
import UserIcon from '../../../images/icons/user-management.svg';
import AnalyticsIcon from '../../../images/icons/analytics.svg';
import SettingsIcon from '../../../images/icons/cog.svg';
import { getUserProfile } from '../../actions/profile';
import NavItem from './NavItem';

const URL_VIEW_HASH = {
  chats: CHATS_VIEW,
  massnotification: MASSNOTIFICATIONS_VIEW,
  cases: CASES_VIEW,
  claims: CLAIMS_VIEW,
  policies: POLICIES_VIEW,
  usermgmt: USER_MGMT_VIEW,
  insights: ANALYTICS_VIEW,
  analytics: ANALYTICS_VIEW,
  settings: SETTINGS_VIEW,
};

export const menuItems = [
  {
    id: CHATS_VIEW,
    Icon: ChatIcon,
    label: 'Inbox',
    neededPermissions: [CHATS_TAB],
  },
  {
    id: MASSNOTIFICATIONS_VIEW,
    Icon: BullHornIcon,
    label: 'Outreach',
    neededPermissions: [MASSNOTIFICATIONS_TAB],
  },
  {
    id: CASES_VIEW,
    Icon: CaseIcon,
    label: 'Cases',
    neededPermissions: [CASES_TAB],
  },
  {
    id: CLAIMS_VIEW,
    Icon: ClaimIcon,
    label: 'Claims',
    neededPermissions: [CLAIMS_TAB],
  },
  {
    id: POLICIES_VIEW,
    Icon: PolicyIcon,
    label: 'Policies',
    neededPermissions: [POLICY_TAB],
  },
  {
    id: USER_MGMT_VIEW,
    Icon: UserIcon,
    label: 'Manage',
    neededPermissions: [USER_MANAGEMENT_TAB],
  },
  {
    id: ANALYTICS_VIEW,
    Icon: AnalyticsIcon,
    label: 'Insights',
    neededPermissions: [ANALYTICS_TAB],
  },
  {
    id: SETTINGS_VIEW,
    Icon: SettingsIcon,
    label: 'Settings',
    neededPermissions: [SYSTEM_ADMIN],
  },
];

const LeftBar = (props) => {
  const { panelId } = useParams();

  useEffect(() => {
    const { fetchCaseTypes } = props;
    fetchCaseTypes();
  }, []);

  const {
    closeForm,
    getMassNotifications,
    unsetLeftBarBlueDot,
    blueDotTarget,
    closeProfileConfig,
    authUserId,
    profileUserId,
    getUserProfile,
    showProfileConfig,
  } = props;

  const onNavClick = (navId) => {
    if (navId !== panelId) {
      closeForm(caseType);
      const viewId = URL_VIEW_HASH[navId];

      switch (viewId) {
        case 'CLAIMS_VIEW': {
          closeForm(claimType);
          break;
        }
        case 'POLICIES_VIEW': {
          closeForm(policyType);
          break;
        }
        case MASSNOTIFICATIONS_VIEW: {
          getMassNotifications();
          closeForm(massNotificationType);
          break;
        }
        case 'ANALYTICS_VIEW': {
          loadReports();
          break;
        }
        default:
          break;
      }

      if (blueDotTarget.includes(viewId)) {
        unsetLeftBarBlueDot(viewId);
      }
    }
    if (authUserId !== profileUserId) {
      getUserProfile(authUserId);
    }
    closeProfileConfig();
  };

  return (
    <nav className="left-bar">
      {menuItems.map(({
        id, label, Icon, neededPermissions,
      }) => {
        const urlId = invert(URL_VIEW_HASH)[id];
        return (
          <NavItem
            id={urlId}
            label={label}
            Icon={Icon}
            isActive={(urlId === panelId && !showProfileConfig)
              || (urlId === 'chats' && panelId === 'inbox')} // TO DO: remove this conditional when we go to ALPHA with NEW_INBOX
            onClick={() => onNavClick(urlId)}
            showDot={blueDotTarget.includes(id)}
            neededPermissions={neededPermissions}
          />
        );
      })}
    </nav>
  );
};

LeftBar.propTypes = {
  blueDotTarget: PropTypes.arrayOf(Object),
  authUserId: PropTypes.string,
  closeForm: PropTypes.func.isRequired,
  closeProfileConfig: PropTypes.func,
  getMassNotifications: PropTypes.func.isRequired,
  unsetLeftBarBlueDot: PropTypes.func.isRequired,
  fetchCaseTypes: PropTypes.func.isRequired,
  profileUserId: PropTypes.string,
  getUserProfile: PropTypes.func,
  showProfileConfig: PropTypes.bool,
};

LeftBar.defaultProps = {
  blueDotTarget: [{}],
  authUserId: '',
  closeProfileConfig: () => {},
  profileUserId: '',
  getUserProfile: () => {},
  showProfileConfig: false,
};

const mapStateToProps = (state) => ({
  blueDotTarget: get(state, 'ui.leftBarBlueDot'),
  showProfileConfig: get(state, 'ui.showProfileConfig'),
  authUserId: get(state, 'auth.user._id'),
  profileUserId: get(state, 'profile._id', ''),
});

const mapDispatchToProps = {
  loadReports,
  closeForm,
  getMassNotifications,
  unsetLeftBarBlueDot,
  closeProfileConfig,
  getUserProfile,
  fetchCaseTypes,
};

const connectedLeftBar = connect(mapStateToProps, mapDispatchToProps)(LeftBar);
export { connectedLeftBar, LeftBar };

export default withRouter(connectedLeftBar);
