/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-console */
import isJSON from 'is-json';
import flatten from 'lodash/flatten';
import { QUERY_KEYS } from '@app/services/constants';
import { buildFilters } from '@app/cache/caseCacheHelpers';
import * as constants from '../constants/websocket';
import {
  addTranslation,
  endUserJoinedChat,
  setChatParticipantsFromEvent,
  newMessage,
  inlineMessageUpdate,
  updateMessage,
  needsActionUpdate,
} from '../actions/chat';
import { handleCaseCacheCustomerNames, handleCaseCacheItem } from '../cache/caseCache';
import { handleBulkUploadOktaComplete } from '../middlewares/snackBarAlerts';
import { showSnackbar } from '../actions/notification';
import { addBulkUser, addUser, modifiedUser } from '../actions/users';
import { addTemplate, modifyTemplate } from '../actions/templates';
import { assignUser, updateJob } from '../actions/job';
import { addItem, modifyItem } from '../actions/common';
import { getCustomerFeedback, getUserScore } from '../actions/profile';
import {
  CREATE_NOTE,
  DELETE_NOTE,
  DELETE_REPLY,
  DEAUTH_USER,
  ADD_REPLY,
  REDACT_IMAGE,
} from '../constants/actions';
import { handleUserIsTypingEvent } from '../actions/socket';
import { NEEDS_ATTENTION, NEEDS_ACTION } from '../constants/permissions';
import { s3toPipedUrl } from './urls';
import globalMediaCacheFactory from '../hooks/global-file-cache';
import { REDACTED_IMAGE } from '../constants/general';
import { isApplicableUnreadMessage } from './inbox.helpers';

const updateSurveyEvent = () => (dispatch) => {
  dispatch(getCustomerFeedback());
  return dispatch(getUserScore());
};
const jobAssignedEvent = (data) => assignUser(data);
const newNoteEvent = (data) => (dispatch) => dispatch({ type: CREATE_NOTE, payload: data });
const updateGroupEvent = (data) => modifyItem('GROUP')(data);
const createGroupEvent = (data) => addItem('GROUP')(data);
const deleteNoteEvent = (data) => (dispatch) => dispatch({ type: DELETE_NOTE, payload: data });
const newReplyEvent = (data) => (dispatch) => dispatch({ type: ADD_REPLY, payload: data });
const deleteReplyEvent = (data) => (dispatch) => dispatch({ type: DELETE_REPLY, payload: data });

const newUserMentionedEvent = (data) => showSnackbar({
  text: data?.noteText,
  linkText: 'View',
  isError: false,
  linkID: `/chats/${data?.caseId}`,
  title: 'You were mentioned in a note',
  isHyperLink: true,
  persist: true,
  jobId: data?.caseId,
});

export const newJobEvent = (data, queryClient, eventName) => (dispatch, getStore) => {
  dispatch(addItem('CASE')(data));
  const store = getStore();
  handleCaseCacheItem(data, eventName, queryClient, store);
};

const updateJobEvent = (data, queryClient, eventName) => (dispatch, getStore) => {
  dispatch(updateJob(data));
  const store = getStore();
  handleCaseCacheItem(data, eventName, queryClient, store);
};

const useLastViewedMessageDataFromCache = (job, message, store, queryClient) => {
  // Fetch the related cached job
  const filters = buildFilters(store);
  const cachedJobList = queryClient?.getQueryData([QUERY_KEYS.CASES, filters]);
  const cachedJob = cachedJobList?.pages?.flat().find((c) => c._id === job?._id) || {};
  const bumpUnreadMessageCount = isApplicableUnreadMessage(
    message,
    store?.auth?.user?._id,
    !cachedJob?.lastViewedMessage?.messageId,
  );

  let unreadMessageCount = cachedJob?.unreadMessageCount || 0;
  if (bumpUnreadMessageCount) {
    unreadMessageCount += 1;
  }

  const updatedJob = {
    ...job,
    lastViewedMessage: cachedJob?.lastViewedMessage,
    unreadMessageCount,
  };
  return updatedJob;
};

const newMessageEvent = (data, queryClient, eventName) => (dispatch, getStore) => {
  dispatch(newMessage(data));
  const { job } = data;
  const store = getStore();
  const updatedJob = useLastViewedMessageDataFromCache(job, data, store, queryClient);
  handleCaseCacheItem(updatedJob, eventName, queryClient, store);
};

/**
 * Function to check if we should redact the live update message event
 * @param {*} messageEvent message object coming in from event
 * @param {*} localMessage message object in redux store
 * @param {*} authId id of logged in user from redux
 * @returns {Boolean} on whether the image should be redacted
 */
export const redactImageMessageEvent = (messageEvent, localMessage, authId) => {
  if (messageEvent?.isImage && localMessage?.redactionData?.length) return false;
  const redactionDataItem = messageEvent?.redactionData?.find((r) => r.Type === REDACTED_IMAGE);
  const createdBy = redactionDataItem?.createdBy;
  if (!authId || !redactionDataItem || !createdBy) return false;
  return createdBy !== authId;
};

export const updateMessageEvent = (data, queryClient, eventName) => async (dispatch, getStore) => {
  const store = getStore();
  const authId = store.auth.user._id || store.auth.user.id;
  const localMessage = store.jobs?.activeChat?.messages?.find(
    (m) => m.id === data?.newMessage?._id,
  );
  const redactImageForUser = redactImageMessageEvent(data.newMessage, localMessage, authId);
  if (redactImageForUser) {
    const { _id, fileUrl, redactionData } = data.newMessage;
    const pipedUrl = s3toPipedUrl(fileUrl);
    const globalFileCache = await globalMediaCacheFactory();
    await globalFileCache?.deleteItem(pipedUrl);
    dispatch({ type: REDACT_IMAGE, payload: { fileUrl: pipedUrl, messageId: _id, redactionData } });
  }
  dispatch(updateMessage(data));
  const { job } = data;
  handleCaseCacheItem(job, eventName, queryClient, store);
};

export const inlineMessageUpdateEvent = (data, queryClient, eventName) => (dispatch, getStore) => {
  const store = getStore();
  const userHasAccess = store?.auth?.permissions.includes(NEEDS_ATTENTION);
  if (userHasAccess) {
    dispatch(inlineMessageUpdate(data));
    const { job } = data;
    handleCaseCacheItem(job, eventName, queryClient, store);
  }
};

export const updatedNeedsActionEvent = (data, queryClient, eventName) => (dispatch, getStore) => {
  const store = getStore();
  const userHasAccess = store?.auth?.permissions.includes(NEEDS_ACTION);
  if (userHasAccess) {
    dispatch(needsActionUpdate(data));
    const { job } = data;
    handleCaseCacheItem(job, eventName, queryClient, store);
  }
};

const handleLogoutUser = () => (dispatch) => {
  dispatch({ type: DEAUTH_USER });
};

const handleModifiedUser = (data, queryClient) => (dispatch, getStore) => {
  dispatch(modifyItem('OPERATOR')(data));
  dispatch(modifiedUser(data));
  const store = getStore();
  handleCaseCacheCustomerNames(data, queryClient, store);
};

/**
 * Get user permissions
 * @param {*} organizationName Name of users organzation
 * @param {*} userId Id of user
 * @returns {Array[string]} array of a users permissions
 */
const eventMap = {};

eventMap[constants.NEW_USER] = addUser;
eventMap[constants.NEW_BULK_USER] = addBulkUser;
eventMap[constants.UPDATED_USER] = handleModifiedUser;
eventMap[constants.NEW_TEMPLATE] = addTemplate;
eventMap[constants.MODIFIED_TEMPLATE] = modifyTemplate;
eventMap[constants.NEW_MESSAGE] = newMessageEvent;
eventMap[constants.UPDATED_SURVEY_SCORE] = updateSurveyEvent;
eventMap[constants.USER_ASSIGNED] = jobAssignedEvent;
eventMap[constants.ADD_TRANSLATION] = addTranslation;
eventMap[constants.NEW_JOB] = newJobEvent;
eventMap[constants.UPDATED_JOB] = updateJobEvent;
eventMap[constants.CREATE_NOTE] = newNoteEvent;
eventMap[constants.UPDATE_GROUP] = updateGroupEvent;
eventMap[constants.CREATE_GROUP] = createGroupEvent;
eventMap[constants.USER_MENTIONED] = newUserMentionedEvent;
eventMap[constants.USER_OKTA_INSERTION_FINISH] = handleBulkUploadOktaComplete;
eventMap[constants.DELETE_NOTE] = deleteNoteEvent;
eventMap[constants.INLINE_MESSAGE_UPDATE] = inlineMessageUpdateEvent;
eventMap[constants.UPDATED_NEEDS_ACTION] = updatedNeedsActionEvent;
eventMap[constants.UPDATE_MESSAGE] = updateMessageEvent;
eventMap[constants.CHAT_END_USER_REMOVED] = setChatParticipantsFromEvent;
eventMap[constants.CHAT_END_USER_JOINED] = endUserJoinedChat;
eventMap[constants.ADD_REPLY] = newReplyEvent;
eventMap[constants.DELETE_REPLY] = deleteReplyEvent;
eventMap[constants.LOGOUT_USER] = handleLogoutUser;
eventMap[constants.USER_IS_TYPING] = handleUserIsTypingEvent;

const eventProcessor = (dispatch, queryClient, events) => {
  events.forEach((event) => {
    const eventHandler = eventMap[event.name];
    if (eventHandler) {
      try {
        const data = isJSON(event.payload) ? JSON.parse(event.payload) : event.payload;
        dispatch(eventHandler(data, queryClient, event.name));
      } catch (err) {
        console.log('cannot parse payload: ', event.payload);
        console.error(err);
      }
    } else {
      console.log('cannot find event handler for: ', event);
    }
  });
};

export const topicHandler = (
  dispatch,
  queryClient,
) => (
  body,
) => eventProcessor(
  dispatch,
  queryClient,
  flatten(body),
);
