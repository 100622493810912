/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unescaped-entities */

import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Modal, Button as UnityButton } from '@himarley/unity';
import FormField from '../../Form/FormField/FormField';
import FormPhoneNumberField from '../../Form/FormPhoneNumberField/FormPhoneNumberField';
import TypeAheadDropdown from '../../elements/form/TypeAheadDropdown/TypeAheadDropdown';
import usePermissionVerify from '../../elements/hooks/usePermissionVerify';
import FormInfo from '../../Form/FormInfo/FormInfo';
import UserPhoto2 from '../../UserPhoto2/UserPhoto2';
import TrashIcon from '../../../../images/icons/trash.svg';
import { formatPhoneNumber, cleanPhone } from '../../../helpers/format';
import {
  updateProfile,
  getUserProfile,
  setProfileEditMode,
  setProfileError,
  getListOfTitles,
} from '../../../actions/profile';
import { uploadProfileImage } from '../../../actions/profileImage';
import { validatePhone, validateEmail } from '../../../helpers/validator';
import Button from '../../Form/Button/Button';
import { TITLE_ENABLED, TITLE_EDIT_ONLY_ADMIN } from '../../../constants/permissions';

import './GeneralInfo.less';

const trim = (value) => (typeof value === 'string' ? value.trim() : value);

const GeneralInfo = (props) => {
  const {
    uploadProfileImage: uploadProfileImageProp,
    setProfileError: setProfileErrorProp,
    readonly,
    isAdmin,
    listTitles,
    getListOfTitles,
    organization,
  } = props;
  const [userErrors, setUserErrors] = useState({});
  const [firstName, setFirstName] = useState(props?.firstName);
  const [lastName, setLastName] = useState(props?.lastName);
  const [phoneNumber, setPhoneNumber] = useState(
    cleanPhone(props?.phoneNumber),
  );
  const [contactNumber, setContactNumber] = useState(props?.contactNumber);
  const [showModal, setShowModal] = useState(false);
  const [imageUrl] = useState(props?.imageUrl);
  const [email, setEmail] = useState(props?.email);
  const [title, setTitle] = useState(props?.title);
  useEffect(
    () => () => {
      setProfileEditMode(false);
    },
    [],
  );
  useEffect(() => {
    getListOfTitles();
  }, [readonly]);

  useEffect(() => {
    setFirstName(props.firstName);
    setLastName(props.lastName);
    setPhoneNumber(props.phoneNumber);
    setContactNumber(props.contactNumber);
    setEmail(props.email);
    setTitle(props.title);
  }, [
    props.contactNumber,
    props.email,
    props.firstName,
    props.lastName,
    props.phoneNumber,
    props.title,
    readonly,
  ]);

  const getSaveProfileButton = () => {
    if (props.pendingRequest) {
      return (
        <Button className="user-save-button" type="pending">
          <Translate value="common.saving" />
        </Button>
      );
    }
    return (
      <Button
        data-testid="profile-save-button"
        className="user-save-button"
        onClick={saveProfile}
      >
        <Translate value="common.save" />
      </Button>
    );
  };

  const getSaveProfileMessage = () => {
    if (props.profileIsUpdate) {
      return (
        <div className="sucsess-profile-saved">
          <Translate value="profile.saved" />
        </div>
      );
    }
    if (props.error) {
      return (
        <div className="error-profile-saved">
          <Translate value={props.error} />
        </div>
      );
    }
    return null;
  };

  const isUserValid = (
    firstNameParam,
    lastNameParam,
    emailParam,
    phoneNumberParam,
  ) => {
    const FIRST_NAME_MIN_LENGTH = 1;
    const LAST_NAME_MIN_LENGTH = 1;

    let firstNameError = true;
    if (firstNameParam && firstNameParam.length >= FIRST_NAME_MIN_LENGTH) {
      firstNameError = false;
    }

    let lastNameError = true;
    if (lastNameParam && lastNameParam.length >= LAST_NAME_MIN_LENGTH) {
      lastNameError = false;
    }

    let emailError = true;
    if (emailParam && validateEmail(emailParam)) {
      emailError = false;
    }

    let phoneError = true;
    if (!phoneNumberParam) {
      phoneError = false;
    } else if (validatePhone(phoneNumberParam)) {
      phoneError = false;
    }

    const hasError = firstNameError || lastNameError || emailError || phoneError;

    const newUserErrors = {
      hasError,
      firstNameError,
      lastNameError,
      phoneError,
      emailError,
    };
    setUserErrors(newUserErrors);
    return hasError;
  };
  const handleTitleChanges = (val) => {
    setTitle(val);
  };
  const handleFileSelectChange = useCallback(
    (e) => {
      e.preventDefault();
      const file = _.last(e.target.files);
      uploadProfileImageProp(file);
    },
    [setProfileErrorProp, uploadProfileImageProp],
  );

  const saveProfile = () => {
    const { _id, organizationId } = props;
    setUserErrors({});
    if (!isUserValid(firstName, lastName, email, phoneNumber)) {
      const profile = {
        _id,
        firstName: trim(firstName),
        lastName: trim(lastName),
        email: trim(email),
        phoneNumber: trim(phoneNumber),
        contactNumber: trim(contactNumber),
        title,
        organizationId,
        imageUrl,
        oldEmail: props?.email,
      };
      props.updateProfile(profile);
    }
  };

  const removePhoto = useCallback(() => {
    const profile = {
      _id: props._id,
      email: _.get(props, 'email'),
      oldEmail: _.get(props, 'email'), // Required to not have chat server auto change the oktaUserId from bulk upload easter egg logic
      ...{
        firstName,
        lastName,
        phoneNumber,
        contactNumber,
      },
      imageUrl: null,
    };
    props.updateProfile(profile);
    setShowModal(false);
  }, [contactNumber, firstName, lastName, phoneNumber, props]);

  const userName = _.get(props, 'name') || `${props.firstName} ${props.lastName}`;

  const { request } = props;
  const isFinishedLoading = !_.get(request, 'requesting') && _.get(request, 'success');

  const renderGeneralInfo = useMemo(
    () => (
      <>
        <div className="general-header">General Information</div>
        <UserPhoto2
          imageUrl={props.imageUrl}
          isFinishedLoading={isFinishedLoading}
          id={props._id}
          name={userName}
          className="user-photo-preview"
          testId="profile-page"
        />
        <div className="change-photo-panel">
          <div className="changePhotoWrap">
            <button
              type="button"
              className="file-input-wrapper"
            >
              Change photo
              <input
                type="file"
                className="hidden-file-input"
                onChange={handleFileSelectChange}
                onClick={
                  // Allows onChange to run when exact same file is uploaded again
                  (event) => {
                  // eslint-disable-next-line no-param-reassign
                    event.target.value = null;
                  }
                }
                data-testId="change-avatar-button"
              />
            </button>
            <UnityButton
              className={`${
                !props?.imageUrl ? 'iconDeleteBtnDisabled' : 'iconDeleteBtn'
              }`}
              disabled={!props?.imageUrl}
              onClick={() => setShowModal(true)}
              left
              Icon={TrashIcon}
              type="outline"
              testId="remove-avatar-image"
            />
          </div>
          <div className="help">Image must be a jpg under 5mb</div>
        </div>
      </>
    ),
    [
      handleFileSelectChange,
      isFinishedLoading,
      props._id,
      props.imageUrl,
      userName,
    ],
  );

  const renderModal = useMemo(
    () => (
      <Modal
        show={showModal}
        title="Are you sure you want to delete your image?"
        showCloseButton
        toggleModal={() => setShowModal(false)}
      >
        <div className="deletePhotoWrap">
          <div>
            You cannot undo this action. You may upload a new image by
            <br />
            clicking on the "Change photo" link.
          </div>
          <UnityButton
            className="deletePhotBtn"
            type="neutral"
            onClick={removePhoto}
            testId="remove-avatar-image-confirmation"
          >
            Delete Photo
          </UnityButton>
        </div>
      </Modal>
    ),
    [removePhoto, showModal],
  );

  const isAbleToEditEmail = useMemo(
    () => {
      const isSSOUser = organization?.identityProvider === 'FEDERATED';
      const isJITUser = organization?.jit;
      const { EDIT_EMAIL_ENABLED } = process.env;
      return isAdmin && ((isSSOUser && !isJITUser && EDIT_EMAIL_ENABLED) || !isSSOUser);
    },
    [organization?.identityProvider, organization?.jit, isAdmin],
  );

  const isTitleEnabled = usePermissionVerify({ neededPermissions: [TITLE_ENABLED] });
  const isTitleEditAdminOnly = usePermissionVerify({
    neededPermissions: [TITLE_EDIT_ONLY_ADMIN],
  });

  const hasAccessToEditTitles = useMemo(() => {
    const { hasAccess: adminOnly } = isTitleEditAdminOnly;
    return adminOnly ? isAdmin : true;
  }, [isTitleEditAdminOnly, isAdmin]);

  const canEditTitle = useMemo(
    () => isTitleEnabled.hasAccess && hasAccessToEditTitles,
    [isTitleEnabled, hasAccessToEditTitles],
  );

  return (
    <>
      {renderModal}
      {readonly ? (
        <div className="general-info">
          {renderGeneralInfo}
          <div className="general-row">
            <FormInfo label="First Name" value={props.firstName} />
            <FormInfo label="Last Name" value={props.lastName} />
          </div>
          <div className="general-row">
            <FormInfo label="Email" value={props.email} />
            {
              isTitleEnabled.hasAccess && (
                <FormInfo label="Title" value={title} />
              )
            }
          </div>
          <div className="general-row">
            <FormInfo
              label="Phone"
              value={props.phoneNumber && formatPhoneNumber(props.phoneNumber)}
            />
            <FormInfo label="Office Line" value={props.contactNumber} />
          </div>
          <div className="general-row-button">
            <Button
              data-testid="profile-edit-button"
              className="user-save-button"
              type="secondary"
              onClick={() => props.setProfileEditMode(true)}
            >
              Edit
            </Button>
          </div>
        </div>
      ) : (
        <div className="general-info">
          {renderGeneralInfo}
          <div className="general-row">
            <FormField
              data-testid="profile-edit-first-name"
              id="first-name"
              label="First Name"
              value={firstName}
              required
              onChange={(e) => setFirstName(e.target.value)}
              error={userErrors.firstNameError}
              help={I18n.t('userForm.enterValidFirstName')}
            />
            <FormField
              id="last-name"
              data-testid="profile-edit-last-name"
              label="Last Name"
              value={lastName}
              required
              onChange={(e) => setLastName(e.target.value)}
              error={userErrors.lastNameError}
              help={I18n.t('userForm.enterValidLastName')}
            />
          </div>
          <div className="general-row">
            <FormField
              label="Email"
              data-testid="profile-edit-email"
              value={email}
              required
              disabled={!isAbleToEditEmail}
              onChange={(e) => setEmail(e.target.value)}
              error={userErrors.emailError}
              help={I18n.t('userForm.enterValidEmail')}
            />
            {
              isTitleEnabled.hasAccess && (
                <TypeAheadDropdown
                  type="title"
                  placeholder="Search or add a title"
                  testId="operators-title"
                  allowEdit={canEditTitle}
                  existingValue={title}
                  list={listTitles}
                  onSelect={handleTitleChanges}
                />
              )
            }
          </div>
          <div className="general-row">
            <FormPhoneNumberField
              id="phone"
              data-testid="profile-edit-phone-number"
              label="Phone"
              value={cleanPhone(phoneNumber)}
              onChange={(e) => setPhoneNumber(e.target.value)}
              help={I18n.t('userForm.enterValidPhone')}
              error={userErrors.phoneError}
            />
            <FormField
              id="contactNumber"
              data-testid="profile-edit-contact-number"
              label="Office Line"
              value={contactNumber}
              onChange={(e) => setContactNumber(e.target.value)}
            />
          </div>
          {!readonly && isAbleToEditEmail && (
            <div className="changeEmailInfo">
              Changing a user email will log them out when you click Save
            </div>
          )}
          <div className="general-row-button">
            {getSaveProfileMessage()}
            {getSaveProfileButton()}
          </div>
        </div>
      )}
    </>
  );
};

GeneralInfo.propTypes = {
  getUserProfile: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  updateProfile: PropTypes.func.isRequired,
  uploadProfileImage: PropTypes.func.isRequired,
  setProfileEditMode: PropTypes.func.isRequired,
  setProfileError: PropTypes.func.isRequired,
  _id: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  title: PropTypes.string,
  organizationId: PropTypes.string,
  contactNumber: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  readonly: PropTypes.bool.isRequired,
  pendingRequest: PropTypes.bool.isRequired,
  profileIsUpdate: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool,
  listTitles: PropTypes.arrayOf(Object),
  getListOfTitles: PropTypes.func,
  request: PropTypes.instanceOf(Object),
  organization: PropTypes.shape({
    identityProvider: PropTypes.string,
    jit: PropTypes.bool,
  }),
};

GeneralInfo.defaultProps = {
  isAdmin: false,
  listTitles: [],
  title: '',
  getListOfTitles: () => {},
  organization: {},
  request: {},
  organizationId: '',
};

const mapDispatchToProps = {
  getUserProfile,
  updateProfile,
  uploadProfileImage,
  setProfileEditMode,
  setProfileError,
  getListOfTitles,
};
const mapStateToProps = ({
  profile, ui, auth, operators,
}) => ({
  ...profile,
  request: _.get(ui, 'requests.profileImage', {}),
  authUserId: _.get(auth, 'user._id'),
  isAdmin: auth?.user?.roles?.includes('ADMIN') || false,
  listTitles: operators?.listOfTitles,
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfo);
