import React from 'react'
import PropTypes from 'prop-types'
import Modal from '../../../elements/Modal/Modal'
import RemoveParticipantContainer from './RemoveParticipantContainer'

const RemoveParticipantModal = ({
  participant,
  onClose,
}) => (
  <Modal show title="Remove Participant" toggleModal={onClose}>
    <RemoveParticipantContainer
      participant={participant}
      onClose={onClose}
    />
  </Modal>
)

RemoveParticipantModal.propTypes = {
  participant: PropTypes.shape({
    id: PropTypes.string,
    imageUrl: PropTypes.string,
    name: PropTypes.string,
    role: PropTypes.string,
    relationship: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default RemoveParticipantModal
