import React from 'react';
import { Accordion } from '@himarley/unity';
import PropTypes from 'prop-types';
import IntegrationRulesList from './IntegrationRulesList';
import Title from '../../elements/text/Title/Title';
import CopartLogo from '../../../../images/logos/logoCopart.png';
import styles from './IntegrationSettingsContainer.module.less';

const IntegrationSettings = ({ toggleRuleEnabled, ruleSettings, toggleModal }) => (
  <div>
    <Accordion
      leftContent={(
        <div className={styles.leftAccordionContent}>
          <img src={CopartLogo} alt="Copart" className={styles.copartLogo} />
          <Title>Copart Integration</Title>
        </div>
)}
      rightContent="Rules"
    >
      <div data-testid="rules-accordion-content">
        <IntegrationRulesList
          toggleRuleEnabled={toggleRuleEnabled}
          ruleSettings={ruleSettings}
          toggleModal={toggleModal}
        />
      </div>
    </Accordion>
  </div>
);

IntegrationSettings.propTypes = {
  ruleSettings: PropTypes.shape({}).isRequired,
  toggleRuleEnabled: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default IntegrationSettings;
