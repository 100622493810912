/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Dropdown, Button, MenuItem } from 'react-bootstrap'
import { addUserRole, removeUserRole } from '../../actions/users'
import { useCheckPermissions } from '../../helpers/common'
import { SYSTEM_ADMIN } from '../../constants/permissions'
import { SYS_ADMIN } from '../../constants/roles'
import CheckmarkIcon from '../icons/CheckmarkIcon'

import './AssignRoleDropdown.less'
// maintain state not prop

const AssignRoleDropdown = ({
  addUserRole: addUserRoleProp,
  removeUserRole,
  user,
  orgRoles,
}) => {
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const componentRef = useRef(null);

  const handleClickOutsideComponent = (e) => {
    if (componentRef.current && !componentRef.current.contains(e.target)) {
      setDropDownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideComponent, true);

    return () => document.removeEventListener('click', handleClickOutsideComponent, true);
  }, []);

  const hasRole = (roleName) => _.indexOf(user.roles, roleName) !== -1;

  const updateGroup = (roleName) => {
    const isAdd = !hasRole(roleName);

    if (isAdd) {
      addUserRoleProp(user, roleName);
    } else {
      removeUserRole(user, roleName);
    }
  };

  const buildButtonLabel = (userRoles = [], orgRolesParam = []) => {
    // had a bug where roles would still display after deleted, so we need to filter here by possible roles
    const orgRoleNames = orgRolesParam.map((role) => _.get(role, 'groupName'));
    const actualUserRoles = _.intersection(userRoles, orgRoleNames);

    const NO_ROLES = 'No Roles';
    const rolesCount = actualUserRoles ? actualUserRoles.length : 0;
    const hasMultipleRoles = rolesCount > 1;
    return hasMultipleRoles
      ? `${rolesCount} Roles`
      : _.first(actualUserRoles) || NO_ROLES;
  };

  const userRoles = user.roles || [];

  const buttonLabel = buildButtonLabel(userRoles, orgRoles);

  const isUserSysAdmin = useCheckPermissions([SYSTEM_ADMIN])
  const canNotAssignSysAdmin = (roleName) => !isUserSysAdmin && roleName === SYS_ADMIN
  const handleRoleSelect = (groupName) => {
    updateGroup(groupName);
    setDropDownOpen(false);
  }
  return (
    <div ref={componentRef} data-testid="assign-user-dropdown-button">
      <Dropdown id={user.id} open={dropdownOpen} pullRight>
        <Button
          bsRole="toggle"
          className="assign-user-dropdown-button has-error btn-dropdown disabled"
          onClick={() => setDropDownOpen(!dropdownOpen)}
        >
          <div className="assign-user-dropdown-button-selected">
            {buttonLabel}
          </div>
          <span className="caret" />
        </Button>

        <Dropdown.Menu>
          {orgRoles.map((role, index) => {
            const { groupName } = role;
            return (
              <MenuItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                eventKey={index}
                className="select-list-item-menu"
                disabled={canNotAssignSysAdmin(groupName)}
                onClick={() => {
                  if (!canNotAssignSysAdmin(groupName)) {
                    handleRoleSelect(groupName)
                  }
                }}
              >
                {' '}
                {groupName}
                {' '}
                {hasRole(groupName) ? <CheckmarkIcon /> : ''}
              </MenuItem>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export const mapStateToProps = (state) => {
  const orgRoles = _.get(state, 'users.groups', []) // TODO: this is called "groups" in this reducer which is mad confusing, need to go back through chain and change name
  return { orgRoles }
}

export default connect(mapStateToProps, { addUserRole, removeUserRole })(AssignRoleDropdown)
