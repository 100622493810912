import _ from 'lodash'
import { commonLoading, commonForm, commonFilter } from './common'

function userFilter(filter, users) {
  return commonFilter({
    items: users,
    searches: { text: ['name', 'email'] },
    filter,
  })
}

const selector = (state) => {
  const users = _.get(state, 'operators.list', [])
  userFilter(_.get(state, 'ui.filter.user', ''), users)
  _.get(state, 'ui.sort.user')
  const requestCompleted = _.get(state, 'users.requestCompleted')

  return {
    ...commonLoading(state.users),
    ...commonForm(state.users),
    requestCompleted,
  }
}

export default selector
