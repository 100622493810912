import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonRow, Button, Divider } from '@himarley/unity';
import { updateOrgSetting } from '../../../actions/organization';
import {
  ORG_INACTIVITY_AUTO_REPLY,
  ORG_SETTINGS_USER_MESSAGE_PREFIX,
  ORG_SETTINGS_AUTO_MESSAGE_PREFIX,
  AUTO_CLOSE_ENABLED,
} from '../../../constants/permissions';
import usePermissionVerify from '../../elements/hooks/usePermissionVerify';
import { makeApiRequest } from '../../../actions/api/api';
import InactivityAutoReply from './InactivityAutoReply';
import UserMessagePrefix from './UserMessagePrefix';
import { MIN_MIN_VALUE, snackBarSuccessMessage } from './constants';
import SystemMessagePrefix from './SystemMessagePrefix';
import './OrgSettings.less';
import AutoCloseCases from './AutoCloseCases';

const OrgSettings = ({ organization }) => {
  const [isAutoReplyEnabled, setIsAutoReplyEnabled] = useState(
    organization?.active || false,
  );
  // inactivity auto reply state - minutes and message
  const [minutes, setMinutes] = useState(MIN_MIN_VALUE);
  const [autoReplyMessage, setAutoReplyMessage] = useState('');

  // user message prefix state - phone, caseId, title
  const [isTitleEnabled, setIsTitleEnabled] = useState(false);
  const [isPhoneNumberEnabled, setIsPhoneNumberEnabled] = useState(false);
  const [isCaseIdEnabled, setIsCaseIdEnabled] = useState(false);
  const { organizationSettings } = organization;
  const [autoClose, setAutoClose] = useState(
    organizationSettings?.autoCloseAfterDays || {},
  );
  const dispatch = useDispatch();

  const [autoMessagePrefixText, setAutoMessagePrefixText] = useState('');

  const orgAutoReply = useSelector(
    (state) => state.organizations?.orgAutoReply || {},
  );

  const { hasAccess: canViewInactivityAutoReply } = usePermissionVerify({
    neededPermissions: [ORG_INACTIVITY_AUTO_REPLY],
  });

  const { hasAccess: canViewUserMessagePrefix } = usePermissionVerify({
    neededPermissions: [ORG_SETTINGS_USER_MESSAGE_PREFIX],
  });

  const { hasAccess: canAutoCloseCases } = usePermissionVerify({
    neededPermissions: [AUTO_CLOSE_ENABLED],
  });

  const isAutoReplyChanged = orgAutoReply?.pk
    && (isAutoReplyEnabled !== orgAutoReply?.active
      || parseInt(minutes, 10)
        !== orgAutoReply?.sendCriteria?.minutesInactiveThreshold
      || autoReplyMessage !== orgAutoReply?.message?.during?.value);

  const isUserMessagePrefixChanged = isCaseIdEnabled
      !== (organization?.organizationSettings?.userMessagePrefix?.isCaseIdEnabled
        || false)
    || isTitleEnabled
      !== (organization?.organizationSettings?.userMessagePrefix?.isTitleEnabled
        || false)
    || isPhoneNumberEnabled
      !== (organization?.organizationSettings?.userMessagePrefix
        ?.isPhoneNumberEnabled || false);

  const savedMessagePrefix = organizationSettings?.autoMessagePrefix;
  const savedAutoClose = organizationSettings?.autoCloseAfterDays;
  useEffect(() => {
    setAutoMessagePrefixText(
      savedMessagePrefix && savedMessagePrefix.prefix !== undefined
        ? savedMessagePrefix.prefix
        : 'Marley',
    );
  }, [savedMessagePrefix]);

  const autoPrefixPermission = usePermissionVerify({
    neededPermissions: [ORG_SETTINGS_AUTO_MESSAGE_PREFIX],
  });
  const canViewAutoMessagePrefix = autoPrefixPermission?.hasAccess || false;

  const saveAutoReply = () => {
    if (!orgAutoReply?.pk || !isAutoReplyChanged) {
      return;
    }

    const reply = { ...orgAutoReply };
    reply.active = isAutoReplyEnabled;
    reply.sendCriteria.minutesInactiveThreshold = parseInt(minutes, 10);
    reply.message.during.value = autoReplyMessage;
    const orgName = organization.name.toUpperCase();

    if (orgAutoReply.pk === 'ORG#DEFAULT') {
      reply.pk = `ORG#${orgName}`;
      dispatch(
        makeApiRequest(
          'POST_ORGANIZATIONS_AUTOREPLIES',
          [orgName],
          reply,
          undefined,
          snackBarSuccessMessage,
        ),
      );
    } else {
      dispatch(
        makeApiRequest(
          'PUT_ORGANIZATIONS_AUTOREPLIES',
          [orgName, orgAutoReply.id],
          reply,
          undefined,
          snackBarSuccessMessage,
        ),
      );
    }
  };

  const onSaveOrg = () => {
    const autoCloseIntValue = parseInt(autoClose?.value, 10) || null;
    dispatch(
      updateOrgSetting(
        {
          organizationId: organization._id,
          organizationSettings: {
            autoMessagePrefix: {
              prefix: autoMessagePrefixText,
            },
            userMessagePrefix: {
              isTitleEnabled,
              isCaseIdEnabled,
              isPhoneNumberEnabled,
            },
            autoCloseAfterDays: {
              enabled: autoClose?.enabled,
              value: autoCloseIntValue,
            },
          },
        },
        snackBarSuccessMessage,
      ),
    );
    saveAutoReply();
    setAutoClose({ ...autoClose, value: autoCloseIntValue });
  };

  const isEditingOrgAutoReply = orgAutoReply?.pk
    && (isAutoReplyEnabled !== orgAutoReply?.active
      || parseInt(minutes, 10)
        !== orgAutoReply?.sendCriteria?.minutesInactiveThreshold
      || autoReplyMessage !== orgAutoReply?.message?.during?.value);
  const compareMessagePrefix = savedMessagePrefix?.prefix !== undefined
    ? savedMessagePrefix?.prefix
    : 'Marley';

  const isValidAutoCloseValue = (value) => {
    const parsedInt = Number.parseInt(value, 10);
    return !Number.isNaN(parsedInt) && parsedInt > 0;
  };

  const isEditingAutoClose = (autoClose?.value !== savedAutoClose?.value
      || autoClose?.enabled !== savedAutoClose?.enabled);

  const isEditingAutoMessagePrefix = autoMessagePrefixText !== compareMessagePrefix;

  const isSaveButtonEnabled = (isEditingOrgAutoReply
      || isEditingAutoMessagePrefix
      || isEditingAutoClose
      || isUserMessagePrefixChanged)
    && (!autoClose?.enabled || isValidAutoCloseValue(autoClose?.value));

  const handleCancel = () => {
    setMinutes(orgAutoReply?.sendCriteria?.minutesInactiveThreshold);
    setAutoReplyMessage(orgAutoReply?.message?.during?.value);
    setIsAutoReplyEnabled(orgAutoReply?.active);

    // reset user message prefix
    setIsCaseIdEnabled(
      organization?.organizationSettings?.userMessagePrefix?.isCaseIdEnabled
        || false,
    );
    setIsTitleEnabled(
      organization?.organizationSettings?.userMessagePrefix?.isTitleEnabled
        || false,
    );
    setIsPhoneNumberEnabled(
      organization?.organizationSettings?.userMessagePrefix
        ?.isPhoneNumberEnabled || false,
    );

    setAutoMessagePrefixText(
      savedMessagePrefix && savedMessagePrefix.prefix !== undefined
        ? savedMessagePrefix.prefix
        : 'Marley',
    );
    setAutoClose(savedAutoClose);
  };

  return (
    <section className="brandsWrap">
      <header>
        <h1>Org. Settings</h1>
      </header>
      <p className="orgSettingsInfo">
        Org. Settings apply to your entire organization unless overridden per
        brand, line of business, role, or by individual user settings.
      </p>
      {canAutoCloseCases && (
        <>
          <Divider />
          <AutoCloseCases setAutoClose={setAutoClose} orgAutoClose={autoClose} />
        </>
      )}
      {canViewInactivityAutoReply && (
        <>
          <Divider />
          <InactivityAutoReply
            isAutoReplyEnabled={isAutoReplyEnabled}
            setIsAutoReplyEnabled={setIsAutoReplyEnabled}
            minutes={minutes}
            autoReplyMessage={autoReplyMessage}
            setMinutes={setMinutes}
            setAutoReplyMessage={setAutoReplyMessage}
            orgAutoReply={orgAutoReply}
          />
        </>
      )}
      {canViewAutoMessagePrefix && (
        <>
          <Divider />
          <SystemMessagePrefix
            autoMessagePrefixText={autoMessagePrefixText}
            setAutoMessagePrefixText={setAutoMessagePrefixText}
          />
        </>
      )}
      {canViewUserMessagePrefix && (
        <>
          <Divider />
          <UserMessagePrefix
            isTitleEnabled={isTitleEnabled}
            isCaseIdEnabled={isCaseIdEnabled}
            isPhoneNumberEnabled={isPhoneNumberEnabled}
            setIsTitleEnabled={setIsTitleEnabled}
            setIsCaseIdEnabled={setIsCaseIdEnabled}
            setIsPhoneNumberEnabled={setIsPhoneNumberEnabled}
            userMessagePrefix={
              organization?.organizationSettings?.userMessagePrefix
            }
          />
        </>
      )}
      <div className="orgSettingsButtonRow">
        <Divider />
        <ButtonRow type="positive">
          <Button onClick={handleCancel} type="outline">
            Cancel
          </Button>
          <Button
            onClick={onSaveOrg}
            type="neutral"
            disabled={!isSaveButtonEnabled}
          >
            Save Org. Settings
          </Button>
        </ButtonRow>
      </div>
    </section>
  );
};

OrgSettings.propTypes = {
  organization: {
    _id: '',
    branding: PropTypes.shape([]),
    name: PropTypes.string,
    organizationSettings: PropTypes.shape({
      autoCloseAfterDays: PropTypes.shape({
        enabled: PropTypes.bool,
        value: PropTypes.number,
      }),
      userMessagePrefix: PropTypes.shape({}),
      autoMessagePrefix: PropTypes.shape({
        prefix: PropTypes.string,
      }),
    }),
  },
  organizationSettings: PropTypes.instanceOf(Object),
};

OrgSettings.defaultProps = {
  organization: { branding: [], _id: '' },
  organizationSettings: {},
};

export default OrgSettings;
