/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Tabs, Tab, Button } from '@himarley/unity';
import UserDetails from './UserDetails/UserDetails';
import InCurrentConversation from './InCurrentConversation/InCurrentConversation';
import {
  USER_MANAGE_TAB,
  USER_DETAILS_TAB,
} from '../../../constants/rightBarState';
import {
  setRightSidebarTab,
  openCreateForm,
  closeForm,
} from '../../../actions/ui';
import {
  updateUser,
  cleanUserRequest,
  clearFormErrors,
  resetEditObj,
} from '../../../actions/users';
import {
  setEditItem,
  setViewItem,
  clearAddFormErrors,
} from '../../../actions/common';
import { useCheckPermissions } from '../../../helpers/common';
import {
  DISABLE_CREATE_CASE,
  CREATE_CASE_FROM_CHATS_PAGE,
} from '../../../constants/permissions';
import {
  jobPutRequest,
  createNote as createNoteAction,
  getNotes as getNotesAction,
  deleteNote as deleteNoteAction,
} from '../../../actions/job';
import { caseType, userType } from '../../../models/marleyTypes';
import selector from './selector';

import './RightBar.less';

const RightBar = ({
  authId,
  hasNotesAccess,
  rightBar,
  activeCase,
  customer,
  createNote,
  getNotes,
  deleteNote,
  notes,
  error,
  requestComplete,
  caseTypes,
  jobPutRequest,
  mediaMessages,
  caseData,
  setRightSidebarTab,
  updateUser,
  openCreateForm,
  clearAddFormErrors,
}) => {
  const tabIndex = rightBar !== USER_MANAGE_TAB && rightBar !== 'SIDEBAR'
    ? USER_DETAILS_TAB
    : rightBar;
  const isCreateCaseEnabled = !useCheckPermissions([DISABLE_CREATE_CASE]);
  const isCreateCaseButtonOnChatsEnabled = useCheckPermissions([
    CREATE_CASE_FROM_CHATS_PAGE,
  ]);

  return (
    <div className="right-bar">
      <div className="right-bar-filler">
        <div className="button-row">
          {isCreateCaseEnabled && isCreateCaseButtonOnChatsEnabled && (
            <Button
              type="positive-marley"
              data-test="add-cases-button"
              data-testid="add-cases-button"
              className="add-cases-button"
              onClick={() => {
                openCreateForm(caseType);
                clearAddFormErrors(userType.reducerCase());
              }}
            >
              Create Case +
            </Button>
          )}
        </div>
      </div>
      {activeCase.id
        ? (
          <Tabs
            defaultActiveKey={tabIndex}
            justify
            type="content"
            onSelect={setRightSidebarTab}
          >
            <Tab title="Details" eventKey={USER_DETAILS_TAB}>
              <UserDetails
                hasNotesAccess={hasNotesAccess}
                authId={authId}
                createNote={createNote}
                getNotes={getNotes}
                deleteNote={deleteNote}
                notes={notes}
                jobPutRequest={jobPutRequest}
                activeCase={activeCase}
                customer={customer}
                error={error}
                requestComplete={requestComplete}
                updateUser={updateUser}
                caseTypes={caseTypes}
                mediaMessages={mediaMessages}
                caseData={caseData.entities}
              />
            </Tab>
            <Tab title="Manage" eventKey={USER_MANAGE_TAB}>
              <InCurrentConversation activeCase={activeCase} />
            </Tab>
          </Tabs>
        )
        : null}
    </div>
  );
};

RightBar.propTypes = {
  setRightSidebarTab: PropTypes.func.isRequired,
  rightBar: PropTypes.string,
  activeCase: PropTypes.instanceOf(Object),
  customer: PropTypes.instanceOf(Object),
  requestComplete: PropTypes.bool,
  error: PropTypes.string,
  updateUser: PropTypes.func.isRequired,
  caseTypes: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  mediaMessages: PropTypes.arrayOf(PropTypes.shape({})), // TODO: define shape later
  caseData: PropTypes.shape({ entities: PropTypes.shape({}) }),
  jobPutRequest: PropTypes.func.isRequired,
  openCreateForm: PropTypes.func.isRequired,
  clearAddFormErrors: PropTypes.func.isRequired,
  createNote: PropTypes.func.isRequired,
  deleteNote: PropTypes.func.isRequired,
  getNotes: PropTypes.func.isRequired,
  hasNotesAccess: PropTypes.func.isRequired,
  authId: PropTypes.string.isRequired,
  notes: PropTypes.instanceOf(Object).isRequired,
};
RightBar.defaultProps = {
  rightBar: USER_MANAGE_TAB,
  activeCase: {},
  customer: {},
  mediaMessages: [],
  requestComplete: false,
  error: '',
  caseData: {},
};

const mapStateToProps = (state) => selector(state);

const mapDispatchToProps = {
  updateUser,
  cleanUserRequest,
  setRightSidebarTab,
  setEditItem,
  setViewItem,
  resetEditObj,
  clearFormErrors,
  jobPutRequest,
  createNote: createNoteAction,
  getNotes: getNotesAction,
  deleteNote: deleteNoteAction,
  openCreateForm,
  closeForm,
  clearAddFormErrors,
};

export { RightBar as RightBarDisconnected };

export default connect(mapStateToProps, mapDispatchToProps)(RightBar);
