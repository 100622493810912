/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react'
import { isEnter } from '../../../../helpers/keyboard'
import IconButton from './IconButton'

/**
 * IconButtonWithText
 * @param {function} onClick it's the onClick
 * @param {string} className
 * @param {component} children
 * @param {string} text
 * */

const IconButtonWithText = ({
  onClick, className, children, text,
}) => (
  <div
    onClick={onClick}
    className={`icon-button-with-text ${className || ''}`}
    role="link"
    tabIndex={0}
    onKeyDown={(e) => {
      if (isEnter(e)) {
        onClick()
      }
    }}
  >
    {text}
    <IconButton
      onClick={onClick}
    >
      {children}
    </IconButton>
  </div>
)

export default IconButtonWithText
