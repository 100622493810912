import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../FormField/FormField';
import '../FormField/FormField.less';
import { formatPhoneNumber } from '../../../helpers/format';

class FormPhoneNumberField extends React.Component {
  render() {
    const {
      error, onChange, onFocus, onKeyUp, onBlur, value, disabled, htmlRef, ...other
    } = this.props;
    return (
      <FormField
        error={error}
        disabled={disabled}
        textInput={this.textInput}
        data-testid="edit-phone-number"
        value={formatPhoneNumber(value)}
        onChange={onChange}
        {...other}
      />
    );
  }
}

FormPhoneNumberField.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  htmlRef: PropTypes.func,
};
FormPhoneNumberField.defaultProps = {
  disabled: false,
  onChange: null,
  value: '',
  error: null,
  onBlur: null,
  htmlRef: null,
  onKeyUp: null,
  onFocus: null,
};

export default FormPhoneNumberField;
