import React from 'react';
import PropTypes from 'prop-types';
import { ROLE_OPERATOR, ROLE_ENDUSER } from '../../../../constants/general';
import ViewParticipantItem from './ViewParticipantItem';
import ButtonRow from '../../../elements/buttons/ButtonRow/ButtonRow';
import Button from '../../../elements/buttons/Button/Button';
import Divider from '../../../elements/Divider/Divider';
import { defaultRelationships } from './relationships';

import './ViewParticipantList.less';

export const participantPropType = PropTypes.shape({
  id: PropTypes.string,
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  role: PropTypes.string,
  relationship: PropTypes.string,
});

const sortedActive = (participants) => {
  // Primary Contact
  // primary operator
  // secondary operators
  // other active users
  const primaryEndUser = participants.filter((p) => (
    p.relationship === defaultRelationships.primaryEndUser
  ));
  const primaryOperator = participants.filter((p) => (
    p.relationship === defaultRelationships.primaryOperator
  ));
  const operators = participants.filter((p) => (
    p.role === ROLE_OPERATOR && p.relationship !== defaultRelationships.primaryOperator
  ));
  const endUsers = participants.filter((p) => (
    p.role === ROLE_ENDUSER && p.relationship !== defaultRelationships.primaryEndUser
  ));

  return [
    ...primaryEndUser,
    ...primaryOperator,
    ...operators,
    ...endUsers,
  ];
};

const ViewParticipantList = ({
  activeParticipants,
  pendingParticipants,
  inactiveParticipants,
  loggedInOperatorId,
  primaryEndUserId,
  onClickRemoveParticipant,
  onClose,
  onClickAddParticipant,
  onClickEditParticipant,
  onClickReinviteParticipant,
  enableAddParticipants,
}) => (
  <div className="view-participant-list">
    <div className="view-participant-header">
      <div className="view-participant-header-participant">
        Participant
      </div>
      <div className="view-participant-header-phone-number">
        Phone Number
      </div>
      <div className="view-participant-header-participant-status">
        Participant Status
      </div>
      <div className="view-participant-header-actions">
        Actions
      </div>
    </div>
    <div className="view-participant-list-body">
      {
        sortedActive(activeParticipants).map((participant) => (
          <ViewParticipantItem
            key={participant.id}
            participant={participant}
            isLoggedInOperator={loggedInOperatorId === participant.id}
            isPrimaryEndUser={primaryEndUserId === participant.id}
            status="Active"
            onClickRemoveParticipant={onClickRemoveParticipant}
            onClickEditParticipant={onClickEditParticipant}
            onClickReinviteParticipant={onClickReinviteParticipant}
          />
        ))
      }
      {
        pendingParticipants.map((participant) => (
          <ViewParticipantItem
            key={participant.id}
            participant={participant}
            isPrimaryEndUser={primaryEndUserId === participant.id}
            status="Invited"
            onClickRemoveParticipant={onClickRemoveParticipant}
            onClickEditParticipant={onClickEditParticipant}
            onClickReinviteParticipant={onClickReinviteParticipant}
          />
        ))
      }
      {
        inactiveParticipants.map((participant) => (
          <ViewParticipantItem
            key={participant.id}
            participant={participant}
            status="Removed"
            onClickRemoveParticipant={onClickRemoveParticipant}
            onClickEditParticipant={onClickEditParticipant}
            onClickReinviteParticipant={onClickReinviteParticipant}
          />
        ))
      }
    </div>
    <div className="view-participant-list-footer">
      <Divider />
      <ButtonRow>
        <Button
          data-testid="view-participant-list-close"
          onClick={onClose}
          className="view-participant-list-close-button"
        >
          Close
        </Button>
        {enableAddParticipants && (
        <Button
          data-testid="view-participant-list-add"
          type="positive"
          onClick={onClickAddParticipant}
          className="view-participant-list-add-button"
        >
          + Add Participant
        </Button>
        )}
      </ButtonRow>
    </div>
  </div>
)

ViewParticipantList.propTypes = {
  activeParticipants: PropTypes.arrayOf(participantPropType).isRequired,
  pendingParticipants: PropTypes.arrayOf(participantPropType).isRequired,
  inactiveParticipants: PropTypes.arrayOf(participantPropType).isRequired,
  loggedInOperatorId: PropTypes.string.isRequired,
  primaryEndUserId: PropTypes.string.isRequired,
  onClickRemoveParticipant: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onClickAddParticipant: PropTypes.func.isRequired,
  onClickEditParticipant: PropTypes.func.isRequired,
  onClickReinviteParticipant: PropTypes.func.isRequired,
  enableAddParticipants: PropTypes.bool.isRequired,
};

export default ViewParticipantList;
