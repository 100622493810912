import { QUICK_FILTER_MENU_LABELS } from '../ChatsContainer/ChatInbox/InboxHeader/QuickFilter/constants';

export const LookNumber = {
  OpenConversations: '171',
  WaitingOnOptIns: '172',
  UnansweredOptIns: '174',
  ResponseRecommended: '405',
};

export const buildNewInboxFilters = (lookId) => {
  const filters = {
    items: {
      myChats: QUICK_FILTER_MENU_LABELS.primaryOpLabel,
      secondaryOperatorIds: QUICK_FILTER_MENU_LABELS.secondaryOpsLabel,
    },
  };
  switch (lookId) {
    case LookNumber.OpenConversations:
      filters.tags = ['all'];
      break;
    case LookNumber.UnansweredOptIns:
      filters.tags = ['all'];
      filters.refineBy = 'unansweredOptIns';
      break;
    case LookNumber.WaitingOnOptIns:
      filters.tags = ['not-opted-in'];
      break;
    case LookNumber.ResponseRecommended:
      filters.tags = ['has-question', 'needs-attention'];
      break;
    default:
      break;
  }
  return filters;
};
