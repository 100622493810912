import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import BoldPlus from '../../../../images/icons/BoldPlus.svg';
import Preview from './Preview';

const SystemMessagePrefix = ({
  autoMessagePrefixText,
  setAutoMessagePrefixText,
}) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(autoMessagePrefixText.length);
  }, [autoMessagePrefixText]);

  const handlePrefixChange = (e) => {
    setAutoMessagePrefixText(e.target.value);
  };
  const firstPreviewText = `${autoMessagePrefixText}${autoMessagePrefixText && ' | '}Lovable Insurance:`.trim();
  return (
    <div className="system-prefix">
      <section className="system-prefix-body">
        <h2>System Message Prefix</h2>
        <p>
          Displays as the beginning of outgoing SMS text messages sent
          automatically, such as the opt-In welcome message.
        </p>
        <div className="system-prefix-input">
          <section>
            <div>
              <div className="sysPrefixLabels">
                <p className="system-prefix-label">From</p>
                <p className="system-prefix-counter">
                  {count}
                  /50
                </p>
              </div>
              <input
                data-testid="system-prefix-input"
                value={autoMessagePrefixText}
                maxLength={50}
                onChange={handlePrefixChange}
              />
            </div>
            <div className="system-prefix-plus">
              <BoldPlus />
            </div>
            <div>
              <p className="system-prefix-label">Org/Brand Name</p>
              <span>$BrandName</span>
            </div>
          </section>
          <p>Ex: System Message, Network Message, Automated, etc.</p>
        </div>
      </section>
      <section
        className="system-prefix-preview"
        data-testid="system-prefix-preview"
      >
        <Preview
          firstTextName={firstPreviewText}
          firstTextMessage="Please reply YES to agree to the terms and privacy policy and
                begin messaging."
          secondTextMessage="YES"
        />
      </section>
    </div>
  );
};

SystemMessagePrefix.propTypes = {
  autoMessagePrefixText: PropTypes.string,
  setAutoMessagePrefixText: PropTypes.func.isRequired,
};

SystemMessagePrefix.defaultProps = {
  autoMessagePrefixText: '',
};

export default SystemMessagePrefix;
