import React, { useEffect, useMemo, useState } from 'react';
import { Button, ButtonRow } from '@himarley/unity';
import { useDispatch } from 'react-redux';
import IntegrationSettings from './IntegrationSettings';
import styles from './IntegrationSettingsContainer.module.less';
import MessageTemplateForm from '../../Form/MessageTemplate/MessageTemplateForm';
import { getKeys } from '../../../actions/templates';
import { keyById, buildRequestOptions, extractTokens } from './utils';
import useSecureRequest from '../../../hooks/secure-request';
import secureRequest from '../../../helpers/secure-request';
import { TOTAL_LOSS, defaultActiveMessageTemplateState, defaultRuleSettingsState } from '../../../constants/copart-constants';
import { showSnackbar } from '../../../actions/notification';
import { GET_RULESET } from '../../../constants/endpoints';

const IntegrationSettingsContainer = () => {
  const { requestState, parsedBody } = useSecureRequest(GET_RULESET(TOTAL_LOSS), { method: 'GET' });
  const [ruleSettings, setRuleSettings] = useState(defaultRuleSettingsState);
  const [activeMessageTemplate, setActiveMessageTemplate] = useState(
    defaultActiveMessageTemplateState,
  );
  const [initialRuleSettings, setInitialRuleSettings] = useState(defaultRuleSettingsState);
  const isRuleSettingsChanged = useMemo(
    () => JSON.stringify(initialRuleSettings.rules) !== JSON.stringify(ruleSettings.rules),
    [ruleSettings, initialRuleSettings],
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getKeys());
  }, [dispatch]);

  useEffect(() => {
    if (requestState === 'success') {
      const { rules, id, defaultVersion } = parsedBody;
      setRuleSettings({ id, defaultVersion, rules: { ...keyById(rules, id) } }); // initial state set
      setInitialRuleSettings({ id, defaultVersion, rules: { ...keyById(rules, id) } });
    }
  }, [requestState, parsedBody]);

  const toggleModal = ({
    templateId: id,
    ruleId,
    messageTemplateTitle: displayName,
    messageTemplateText: body,
    messageTemplateTokens: tokens,
  }) => {
    const activeMessageTemplateData = id ? {
      id,
      ruleId,
      showModal: true,
      body,
      displayName,
      tokens,
    } : defaultActiveMessageTemplateState;
    setActiveMessageTemplate(activeMessageTemplateData);
  };

  const toggleRuleEnabled = (ruleId) => {
    setRuleSettings({
      ...ruleSettings,
      rules: {
        ...ruleSettings.rules,
        [ruleId]: {
          ...ruleSettings.rules[ruleId],
          enabled: !ruleSettings.rules[ruleId].enabled,
        },
      },
    });
  };

  const handleSaveMessageTemplate = (formData) => {
    const { ruleId } = activeMessageTemplate;
    const { body, tokens } = extractTokens(formData.body); // format readable tokens as ${TOKEN}
    const formattedFormData = {
      displayName: formData.displayName,
      body,
      tokens,
    };
    setRuleSettings({
      ...ruleSettings,
      rules: {
        ...ruleSettings.rules,
        [ruleId]: {
          ...ruleSettings.rules[ruleId],
          messageTemplate: {
            ...ruleSettings.rules[ruleId].messageTemplate,
            ...formattedFormData,
          },
        },
      },
    });
    setActiveMessageTemplate(defaultActiveMessageTemplateState);
  };
  const handleSubmitRules = async () => {
    // initial state set
    const { url, options } = buildRequestOptions(ruleSettings);
    const response = await secureRequest(url, options);
    if (response.ok) {
      const { rules, id } = await response.json();
      setRuleSettings({ id, rules: { ...keyById(rules, id) } }); // initial state set
      setInitialRuleSettings({ id, rules: { ...keyById(rules, id) } });
      dispatch(showSnackbar({ text: 'Rules and Settings saved', isError: false }));
    } else {
      // Some Error handling here
      setRuleSettings(initialRuleSettings);
      dispatch(showSnackbar({ text: 'Error submitting rules', isError: true }));
    }
  };

  const handleCancelButtonClick = () => {
    setRuleSettings(initialRuleSettings);
  };

  return (
    <div data-testid="integration-settings-container">
      <header>
        <h1>Integration Settings</h1>
      </header>
      <p>Configure rules and settings per software integration</p>
      <MessageTemplateForm
        show={activeMessageTemplate.showModal}
        isEdit
        showDeleteButton={false}
        toggleModal={toggleModal}
        handleSubmit={handleSaveMessageTemplate}
        templateData={activeMessageTemplate}
      />
      <IntegrationSettings
        className={styles.accordionContent}
        ruleSettings={ruleSettings.rules}
        toggleRuleEnabled={toggleRuleEnabled}
        toggleModal={toggleModal}
      />
      <div className={styles.buttonContainer}>
        <ButtonRow>
          <Button testId="rules-cancel-button" type="outline" disabled={!isRuleSettingsChanged} onClick={handleCancelButtonClick}>Cancel</Button>
          <Button testId="rules-submit-button" type="positive" disabled={!isRuleSettingsChanged} onClick={handleSubmitRules}>Save</Button>
        </ButtonRow>
      </div>
    </div>
  );
};

export default IntegrationSettingsContainer;
