import React from 'react';
import { Checkbox, SlideToggle, IconButton } from '@himarley/unity';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { MentionsInput, Mention } from 'react-mentions';
import styles from './IntegrationSettingsContainer.module.less';
import EditIcon from '../../../../images/icons/edit.svg';
import { ruleSettingsCopy, TRUNCATE_TEXT_LENGTH } from '../../../constants/copart-constants';
import { generateReadableBody } from '../../Form/MessageTemplate/helpers';

const IntegrationRule = ({
  ruleId,
  toggleRuleEnabled,
  ruleName,
  templateId,
  isEnabled,
  messageTemplateText,
  messageTemplateTokens,
  toggleModal,
  rule,
}) => {
  const { conditions, event } = rule;
  const tokens = useSelector(({ templates }) => templates.keys);
  const textData = generateReadableBody(
    tokens,
    { body: messageTemplateText, tokens: messageTemplateTokens, truncateAt: TRUNCATE_TEXT_LENGTH },
  );
  return (
    <div
      data-testid={`ruleset-rule-container-${ruleId}`}
      className={styles.integrationRuleContainer}
    >
      <div className={styles.flexRow}>
        <h1 data-testid="integration-rule-name">{ruleName}</h1>
        <SlideToggle
          onToggle={(event) => toggleRuleEnabled(ruleId, event)}
          on={isEnabled}
          testId={`enable-rule-toggle-${isEnabled ? 'on' : 'off'}-${ruleId}`}
        />
      </div>
      {isEnabled && (
      <div data-testid="enabled-rule-content">
        <p>When enabled, the following rule will apply for your organization.</p>
        <div className={styles.flexRow}>
          <div className={styles.conditions}>
            {conditions.all.map((condition) => {
              // condition values are either a string or an array of strings
              if (typeof condition.value === 'string') {
                return (
                  <div key={condition.value} className={styles.condition}>
                    <Checkbox
                      disabled
                      checked
                    />
                    {ruleSettingsCopy[condition.value]}
                  </div>
                );
              }
              return condition.value.map((conditionValue) => (
                <div key={conditionValue} className={styles.condition}>
                  <Checkbox
                    disabled
                    checked
                  />
                  {ruleSettingsCopy[conditionValue]}
                </div>
              ));
            })}
          </div>
          <div className={styles.condition}>
            <Checkbox disabled checked />
            {ruleSettingsCopy[event.type]}
          </div>
          <div>
            <IconButton
              id={`edit-message-template-button-${ruleId}`}
              onClick={
                () => toggleModal({
                  templateId,
                  ruleId,
                  messageTemplateTitle: ruleName,
                  messageTemplateText,
                  messageTemplateTokens,
                })
              }
            >
              Edit Template
              <EditIcon />
            </IconButton>
          </div>
        </div>
        <div className={styles.previewMode}>
          <MentionsInput
            value={textData}
            className="mentionsInput disabledMentionsInput"
          >
            <Mention className="noteSuggestion" />
          </MentionsInput>
        </div>
      </div>
      )}
    </div>
  );
};

IntegrationRule.propTypes = {
  ruleId: PropTypes.string.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  messageTemplateText: PropTypes.string.isRequired,
  ruleName: PropTypes.string.isRequired,
  toggleRuleEnabled: PropTypes.func.isRequired,
  templateId: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  messageTemplateTokens: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rule: PropTypes.shape({
    conditions: PropTypes.shape({
      all: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.arrayOf(PropTypes.string),
        ]),
      })),
    }),
    event: PropTypes.shape({
      type: PropTypes.string,
    }),
  }).isRequired,
};

export default IntegrationRule;
