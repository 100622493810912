/* eslint-disable class-methods-use-this */
/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { getLookResult, getiFrameLookUrl } from '../../../../actions/analytics';
import { getQuoteLookNumber } from '../../../../helpers/analytics';
import './LookContent.less';

const LookContent = ({
  lookNumber, onClick, className, dataTestId,
}) => {
  const {
    lookResults = '',
    lookUrls = '',
  } = useSelector((state) => ({
    lookResults: state?.analytics?.lookResults || '',
    lookUrls: state?.analytics?.lookUrls || '',
  })) || {};
  const dispatch = useDispatch();
  useEffect(() => {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    timezone = timezone.replace(/\//ig, '-');
    const quoteLookNumber = getQuoteLookNumber();

    if (lookNumber === quoteLookNumber) {
      // only get iframe for quote look
      dispatch(getiFrameLookUrl(null, quoteLookNumber, timezone));
    } else {
      dispatch(getLookResult(lookNumber));
    }
  }, [dispatch, lookNumber]);

  const pickTitle = (lookNumberParam) => {
    switch (lookNumberParam) {
      // These are the look numbers from looker staging/prod
      case '171':
        return 'Open Conversations';
      case '172':
        return 'Waiting on Opt-In';
      case '405':
        return 'Response Recommended';
      case '174':
        return 'Unanswered Opt-Ins';
      case '362':
      case '367':
      case '580':
      case '681':
        return 'Quote of the Day';
      default:
        return null;
    }
  };

  const quoteLookNumber = getQuoteLookNumber();

  return (
    <div className={className} onKeyDown={onClick} onClick={onClick}>
      {lookNumber === quoteLookNumber
        ? (
          <div className="look-quote-container" data-testid={dataTestId}>
            <div data-testid="quoteLookTitle" className="quote-look-title">{pickTitle(lookNumber)}</div>
            {/* styling for the below needs to be inline */}
            <iframe
              title="look"
              data-testid="quoteiFrame"
              src={lookUrls[quoteLookNumber]}
              frameBorder="0"
              scrolling="no"
              style={{
                cursor: 'none', pointerEvents: 'none', width: '550px', height: '240px', overflow: 'hidden', paddingTop: '20px',
              }}
            />
          </div>
        )
        : (
          <div className="look-content-container" data-testid={dataTestId}>
            <div data-testid="lookResult" className="look-result">{lookResults[lookNumber]}</div>
            <div data-testid="lookTitle" className="look-title">{pickTitle(lookNumber)}</div>
          </div>
        )}
    </div>
  );
};

LookContent.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  lookNumber: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

LookContent.defaultProps = {
  className: '',
  dataTestId: '',
};

export default LookContent;
