const DEFAULT_CACHE_ITEM_COUNT_LIMIT = 100;

const lruLocalStorage = (cacheName) => {
  const metaName = `cache-meta-${cacheName}`;
  return (
    {
      get: () => {
        const raw = localStorage.getItem(metaName);
        return JSON.parse(raw)?.byAccess || [];
      },
      set: (lru) => {
        const value = JSON.stringify({ byAccess: lru });
        localStorage.setItem(
          metaName,
          value,
        );
        return lru;
      },
    }
  );
};

let thisCache;

export default async (cacheName, fetchItem, opts = {}) => {
  const {
    maxRequestCount = DEFAULT_CACHE_ITEM_COUNT_LIMIT,
  } = opts;

  const initCache = async (cacheName) => {
    try {
      thisCache = await caches.open(cacheName);
      return await thisCache.keys();
    } catch (err) {
      thisCache = undefined;
      console.error(`unexpected error trying to open the cache: ${err.stack}`);
    }
    return [];
  };

  const lruLS = lruLocalStorage(cacheName);
  const cachedRequests = await initCache(cacheName);
  // if there are no keys in the cache, storage may have been evicted from the browser
  // https://developer.mozilla.org/en-US/docs/Web/API/Storage_API/Storage_quotas_and_eviction_criteria#what_happens_when_an_origin_fills_its_quota
  let lru = (cachedRequests.length === 0)
    ? []
    : lruLS.get();
  const accessItem = (url) => {
    const currentIndex = lru.indexOf(url);
    lru.splice(currentIndex, 1);
    // insert as the most recently used item (front of the array)
    lru.unshift(url);
    lruLS.set(lru);
    return thisCache.match(url);
  };

  const purgeOldestItem = () => {
    // remove the least recently used item (end of the array)
    const url = lru.pop();
    return thisCache.delete(url);
  };

  const addItem = async (url, response) => {
    // remove the url from its position in the cache
    lru.unshift(url);

    if (lru.length > maxRequestCount) {
      await purgeOldestItem();
    }
    let success = false;
    const responseClone = response.clone();
    while (!success) {
      try {
        // eslint-disable-next-line no-await-in-loop
        await thisCache.put(url, responseClone);
        success = true;
      } catch (err) {
        // if the storage quota is exceeded, remove older items until we can add to the cache
        // https://developer.mozilla.org/en-US/docs/Web/API/Storage_API/Storage_quotas_and_eviction_criteria#what_happens_when_an_origin_fills_its_quota
        if (err.name === 'QuotaExceededError') {
          // Handle QuotaExceededError
          console.warn('storage quota exceeded:', err.message);
          // eslint-disable-next-line no-await-in-loop
          await purgeOldestItem();
        } else {
          // really not sure what other errors could happen here
          // but if something weird happens would rather surface
          // the issue
          console.error(`unexpected error ${err.name} putting item in the cache: \n${err.stack}`);
          return;
        }
      }
    }

    lruLS.set(lru);
  };

  return {
    getItem: async (url) => {
      // if the cache failed to initialize
      // bypass it and directly fetch
      if (!thisCache) {
        return fetchItem(url);
      }
      const cacheHasItem = lru.includes(url);
      if (cacheHasItem && !fetchItem) return null;
      const response = (cacheHasItem
        ? await accessItem(url)
        : await fetchItem(url));
      if (!cacheHasItem && response.ok) {
        await addItem(url, response);
      }
      return response;
    },
    deleteItem: async (url) => {
      lru = lru.filter((item) => item !== url);
      lruLS.set(lru);
      await thisCache.delete(url);
    },
  };
};
