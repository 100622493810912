/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect, useRef, useCallback } from 'react'
import IconButtonWithPermissions from '../../elements/buttons/IconButton/IconButtonWithPermissions'

import './IconButtonWithMenu.less'

const IconButtonWithMenu = ({
  Icon, Menu, showDot, neededPermissions, disabled, style, buttonId, 'data-testid': dataTestId,
}) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const componentRef = useRef(null)

  const handleClick = (e) => {
    if (componentRef.current && componentRef.current.contains(e.target)) return
    setMenuOpen(false) // click outside the component
  }

  // Adds listeners to detect clicks outside of the component
  useEffect(() => {
    if (menuOpen) {
      // eslint-disable-next-line no-undef
      document.addEventListener('click', handleClick, true);
    } else {
      // eslint-disable-next-line no-undef
      document.removeEventListener('click', handleClick, true);
    }
    return () => {
      // eslint-disable-next-line no-undef
      document.removeEventListener('click', handleClick, true);
    };
  }, [menuOpen]);

  const handleClickMenu = useCallback(() => {
    !disabled && setMenuOpen(!menuOpen)
  }, [disabled, menuOpen]);

  return (
    <div style={style} ref={componentRef}>
      <IconButtonWithPermissions id={buttonId} neededPermissions={neededPermissions} className="icon-button-with-menu" disabled={disabled} showDot={showDot} onClick={handleClickMenu} data-testid={dataTestId}>
        {menuOpen && <Menu />}
        <Icon />
      </IconButtonWithPermissions>
    </div>
  )
}

export default IconButtonWithMenu
