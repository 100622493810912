/* eslint-disable react/jsx-filename-extension */
import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { jobPutRequest } from '../../../actions/job'
import TableRowComponent from '../../elements/table/TableRow/TableRow'
import TableCell from '../../elements/table/TableCell/TableCell'
import AssignUserDropdown from '../../AssignDropdown/AssignUserDropdown'
import { useCheckForCaseAccess, handleUserAssignment } from '../../../helpers/cases';
import CheckBoxInput from '../../elements/form/CheckBoxInput/CheckBoxInput'

const CaseRow = ({
  operators, columns, c, actions, assignedOperator,
  setCaseSelected, isChecked,
}) => {
  const operator = _.get(assignedOperator, 'name');
  const hasAccessToCase = useCheckForCaseAccess(c)
  const dispatch = useDispatch()
  const memoizedHandleUserAssignment = useMemo(() => handleUserAssignment(c, jobPutRequest, dispatch),
    [c, dispatch])
  const memoizedSelect = useCallback((e) => memoizedHandleUserAssignment(e),
    [memoizedHandleUserAssignment])
  const renderTableCell = () => {
    if (c.isClosed) {
      return (
        // eslint-disable-next-line react/button-has-type
        <button disabled className="hymarley-button disabled-assign-user-dropdown">
          <div className="disabled-assign-user-dropdown-text">
            {_.get(assignedOperator, 'name', 'Unassigned')}
          </div>
          <span className="caret" />
        </button>
      )
    }
    if (hasAccessToCase) {
      return (
        <AssignUserDropdown
          id="cases"
          defaultLabel="Unassigned"
          rowId={`assign-${c.id}`}
          selectedId={_.get(assignedOperator, 'id', 'unassigned')}
          handleSelect={memoizedSelect}
          options={operators}
        />
      )
    }
    return (<span>{operator}</span>)
  }

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <span data-test="case-table-row">
      <TableRowComponent
        id="case"
        actions={actions}
        className={c.isClosed ? 'closed-case' : ''}
        columns={columns}
        item={c}
        itemId={c.id}
        header={(
          <>
            <span className="row-checkbox">
              <CheckBoxInput
                onChange={setCaseSelected}
                uniqueKey={c.id}
                value={c.id}
                isChecked={isChecked}
              />
            </span>
            <TableCell link={hasAccessToCase ? `/chats/${c.id}` : ''} id="case-number">
              {c.displayId}
              {' '}
            </TableCell>
          </>
        )}
        hasAccessToCase={hasAccessToCase}
      >
        <TableCell id="assigned">
          {renderTableCell()}
        </TableCell>
      </TableRowComponent>
    </span>
  )
}

CaseRow.propTypes = {
  assignJob: PropTypes.func.isRequired,
  operators: PropTypes.instanceOf(Object).isRequired,
  columns: PropTypes.instanceOf(Object).isRequired,
  c: PropTypes.instanceOf(Object).isRequired,
  actions: PropTypes.instanceOf(Object).isRequired,
  assignedOperator: PropTypes.instanceOf(Object).isRequired,
  isChecked: PropTypes.bool.isRequired,
  setCaseSelected: PropTypes.func.isRequired,
}

export default CaseRow
