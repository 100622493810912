import React from 'react'
import IconButton from './IconButton'
import usePermissionCheck from '../../../HigherOrderComponents/usePermissionCheck'

const IconButtonWithPermissions = ({ hasAccess, disabled, onClick, ...other }) => {
  const disabledIcon = disabled || !hasAccess
  return (
    hasAccess ? <IconButton disabled={disabledIcon} onClick={onClick} {...other} /> : null
  )
}

// eslint-disable-next-line react-hooks/rules-of-hooks
export default usePermissionCheck(IconButtonWithPermissions)
