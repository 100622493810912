import React from 'react';
import PropTypes from 'prop-types';
import MediaImage from './MediaTypes/MediaImage';
import MediaVideo from './MediaTypes/MediaVideo';
import MediaAudio from './MediaTypes/MediaAudio';
import MediaGeneric from './MediaTypes/MediaGeneric';
import InfoIcon from '../../../images/icons/info.png';
import FileNotLoaded from '../../../images/icons/fileNotLoaded.svg';

import './MediaItem.less';
import { mediaItemPropTypes } from './media-helpers';

const MediaItem = ({
  className,
  file,
  onExpand,
  isThumbnail,
  mediaItem,
  containerDimensions,
  isImageRedacted,
}) => {
  const {
    loadingState,
    localFileUrl,
    contentType,
  } = file;
  const {
    rotation,
    providerId,
    mediaInfo,
  } = mediaItem;
  const isVertical = rotation === 90 || rotation === 270;
  const adjustedWidth = isVertical ? containerDimensions.height : containerDimensions.width;
  const adjustedHeight = isVertical ? containerDimensions.width : containerDimensions.height;

  const renderMediaItem = () => {
    const type = contentType.split('/')[0];
    switch (type) {
      case 'image':
        return (
          <>
            { mediaInfo?.exif && <img src={InfoIcon} alt="media-item-info-icon" className="media-item-info-icon" /> }
            <MediaImage
              fileUrl={localFileUrl}
              rotation={rotation}
              providerId={providerId}
              isThumbnail={!!onExpand}
            />
          </>
        );
      case 'video':
        return (
          <MediaVideo
            fileUrl={localFileUrl}
            providerId={providerId}
            isThumbnail={isThumbnail}
            controls={!isThumbnail}
          />
        );
      case 'audio':
        return (
          <MediaAudio
            fileUrl={localFileUrl}
            providerId={providerId}
            isThumbnail={isThumbnail}
            controls={!isThumbnail}
          />
        );
      default:
        return (
          <MediaGeneric
            mediaItem={mediaItem}
            fileUrl={localFileUrl}
            isThumbnail={isThumbnail}
            containerDimensions={containerDimensions}
          />
        );
    }
  };

  const wrapMediaInButton = () => (
    onExpand && !isImageRedacted
      ? (
        <button
          type="button"
          onClick={onExpand}
          className="media-expand-button"
          aria-label="expand media item"
          data-testid={`media-expand-button-${providerId}-${adjustedWidth}`}
        >
          {renderMediaItem()}
        </button>
      )
      : (renderMediaItem())
  );

  const contentToRender = () => {
    let mediaContent;
    if (loadingState === 'loading') {
      mediaContent = <div className="media-loading" data-testid={`media-loading-${providerId}`} />;
    } else if (loadingState === 'loaded') {
      mediaContent = wrapMediaInButton();
    } else {
      mediaContent = (
        <FileNotLoaded />
      );
    }
    return mediaContent;
  };
  return (
    <div
      className={`media-item-container ${className} rotate${rotation}`}
      data-testid={`media-item-container-${providerId}`}
      style={{
        maxWidth: `${adjustedWidth}px`,
        maxHeight: `${adjustedHeight}px`,
      }}
    >
      {
        contentToRender()
      }
    </div>
  );
};

MediaItem.propTypes = {
  className: PropTypes.string,
  file: PropTypes.shape({
    loadingState: PropTypes.string.isRequired,
    localFileUrl: PropTypes.string,
    contentType: PropTypes.string,
  }).isRequired,
  onExpand: PropTypes.func,
  mediaItem: mediaItemPropTypes.isRequired,
  isThumbnail: PropTypes.bool.isRequired,
  containerDimensions: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
  }).isRequired,
  isImageRedacted: PropTypes.bool.isRequired,
};

MediaItem.defaultProps = {
  onExpand: null,
  className: '',
};

export default MediaItem;
