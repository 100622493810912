import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  ButtonRow,
  Modal,
  TextInputLine,
} from '@himarley/unity';
import Title from '../../elements/text/Title/Title';
import InfoIcon from '../../../../images/icons/info.svg';

const DeleteBrandModal = ({
  show,
  hideModal,
  handleDeleteBrand,
  name,
  index,
  hasLoB,
  isUpdatingBranding,
}) => {
  const [brandName, setBrandName] = useState('');
  const handleHideModal = () => {
    setBrandName('');
    hideModal();
  };

  useEffect(
    () => () => {
      setBrandName('');
    },
    [],
  );

  return (
    <Modal
      show={show}
      showCloseButton
      title={hasLoB ? 'Unable to Delete Brand' : 'Delete Brand'}
      className="deleteModal"
      toggleModal={handleHideModal}
      backdrop
      size="lg"
    >
      <>
        {hasLoB
          ? (
            <div className="hasLobWrap">
              <div className="avatarNameWrap">
                <div className="brandName">
                  <Avatar name={name} isCustomer className="brandAvatar" />
                </div>
                <div className="nameTitle">
                  <Title type="subheader">
                    <b>{name}</b>
                  </Title>
                </div>
              </div>
              <p>
                You must remove lines of business from this brand before you can
                delete the brand.
              </p>
            </div>
            )
          : (
            <>
              <div>
                {`Are you sure you want to delete the brand ${name}? This cannot be undone.`}
              </div>
              <div className="brandInputWrap">
                <TextInputLine
                  required
                  label="Type in Brand Name"
                  value={brandName}
                  onChange={(e) => setBrandName(e?.target?.value || '')}
              />
                <div className="brandName">
                  Brand Name
                  <br />
                  {name}
                </div>
              </div>
            </>
            )}
        <div className="deleteModalFooter">
          {hasLoB
            ? (
              <div />
              )
            : (
              <div className="deleteModalInfo">
                <InfoIcon />
                <span>
                  Type the name of this
                  <br />
                  brand and click Delete.
                </span>
              </div>
              )}
          <div className="confirmation-modal-buttons">
            <ButtonRow>
              <Button
                onClick={handleHideModal}
                type="outline"
                className="cancelButton"
              >
                {hasLoB ? 'Close' : 'Cancel'}
              </Button>
              {!hasLoB && (
                <Button
                  data-testid="delete-note-button-confirm"
                  data-test="delete-note-button-confirm"
                  onClick={() => {
                    handleDeleteBrand(index, brandName);
                  }}
                  type="positive"
                  disabled={name !== brandName || isUpdatingBranding}
                >
                  Delete Brand
                </Button>
              )}
            </ButtonRow>
          </div>
        </div>
      </>
    </Modal>
  );
};

DeleteBrandModal.propTypes = {
  handleDeleteBrand: PropTypes.func,
  show: PropTypes.bool,
  hideModal: PropTypes.func,
  name: PropTypes.string,
  index: PropTypes.string,
  hasLoB: PropTypes.bool.isRequired,
  isUpdatingBranding: PropTypes.bool.isRequired,
};

DeleteBrandModal.defaultProps = {
  handleDeleteBrand: () => {},
  show: false,
  hideModal: () => {},
  name: '',
  index: '',
};

export default DeleteBrandModal;
