/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import useTopicHandler from '../elements/hooks/useTopicHandler';
import { setupSocketClient, getUserByOktaUserId } from '../../actions/auth';
import { useQueryParameters } from './useQueryParameters';
import { usePrefetchQueries } from '../../services/case';
import { getUserProperties, toggleNewInbox, getPinnedChats } from '../../actions/profile';
import { logoutConfig } from '../../constants/okta';
import styles from './withAuth.module.less';

/**
 * HOC to wrap the entire App component and fetch user information for the UI
 * using the email from the Okta id token. This separates flooding App.js with
 * extra logic
 * @param {*} WrappedComponent
 */
const withAuth = (WrappedComponent) => {
  const Auth = (props) => {
    const { oktaAuth } = useOktaAuth();
    const dispatch = useDispatch();
    const memoizedTopicHandler = useTopicHandler();

    const {
      logout,
      userProfileFetchCount,
      isNewInbox,
      authId,
    } = useSelector((state) => ({
      logout: state?.auth?.logout,
      userProfileFetchCount: state?.profile?.userProfileFetchCount,
      isNewInbox: state?.profile?.properties?.isNewInbox,
      authId: state?.auth?.user?._id,
    }));

    useEffect(() => {
      dispatch(setupSocketClient(memoizedTopicHandler));
      const idToken = oktaAuth.getIdToken();
      const decodedIdToken = idToken ? jwt_decode(idToken) : null;
      const oktaUserId = decodedIdToken?.preferred_username;
      dispatch(getUserByOktaUserId(oktaUserId));
      dispatch(getUserProperties());
    }, []);

    useEffect(() => {
      if (!isNewInbox && authId) {
        dispatch(getPinnedChats(authId));
      }
    }, [authId, dispatch, isNewInbox]);

    useEffect(() => {
      if (userProfileFetchCount === 1 && typeof isNewInbox === 'undefined') {
        dispatch(toggleNewInbox());
      }
    }, [dispatch, userProfileFetchCount, isNewInbox]);

    useEffect(() => {
      if (!logout) {
        return;
      }
      const oktaLogout = async () => oktaAuth.signOut(logoutConfig);
      oktaLogout();
    }, [
      oktaAuth,
      logout,
    ]);

    usePrefetchQueries();
    useQueryParameters();

    return (
      <div className={styles.withAuth}>
        {!logout && <WrappedComponent {...props} />}
      </div>
    );
  };

  return Auth;
};

export default withAuth;
