import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './EventMessage.less';
import _ from 'lodash';
import { formatDate } from '../../../../../helpers/datetime';
import CopartAlertIcon from '../../../../../../images/icons/copart-chat-alert.svg';
import AlertIcon from '../../../../../../images/icons/alert.svg';
import { TagItem } from '../../../../elements/TagItem/TagItem';
import TagItemTypes from '../../../../../constants/tag-item-types';
import { setTemplateTrayIsOpen } from '../../../../../actions/ui';

const createEvent = (text, className) => (
  <div className={className} data-test="gray-event">
    {text}
  </div>
);

const setTemplateTray = (templateTrayIsOpen, setTemplateTrayIsOpen) => {
  if (!templateTrayIsOpen) {
    setTemplateTrayIsOpen(true, 'CHAT_INPUT');
  }
};

const createCopartReleaseIssue = ({
  releaseIssueText,
  releaseIssueDescription,
  timestamp,
  resolved,
  templateTrayIsOpen,
  showTagItem,
  setTemplateTrayIsOpen,
}) => (
  <div className="copart-event-container" data-testid="copart-event-container">
    <div className="left-side">
      <div className="copart-icon">
        <CopartAlertIcon />
      </div>
      <div className={!releaseIssueDescription && 'copart-single-line'}>
        <div className="copart-release-issue-text">
          <div className="text" data-test="release-issue">
            <b>{releaseIssueText}</b>
          </div>
          <div className="timestamp" data-test="timestamp">
            {timestamp}
          </div>
        </div>
        {
          releaseIssueDescription && (
            <div className="copart-release-issue-description" data-test={`release-description-${resolved ? 'resolved' : 'open'}`}>
              {releaseIssueDescription}
            </div>
          )
        }
      </div>
    </div>
    <div className="right-side">
      {
        showTagItem && (
          <TagItem
            text={TagItemTypes.Case.ReleaseIssue.text}
            textColor="#000"
            backgroundColor={
              resolved ? TagItemTypes.Case.ReleaseIssue.resolvedColor : TagItemTypes.Case.ReleaseIssue.unresolvedColor
            }
            icon={<AlertIcon />}
          />
        )
      }
      {!resolved && (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div className="template-link" data-test="template-link" onClick={() => setTemplateTray(templateTrayIsOpen, setTemplateTrayIsOpen)}>
        View Suggested Responses
      </div>
      )}
    </div>
  </div>
);

const EventMessage = ({
  text, message, templateTrayIsOpen, setTemplateTrayIsOpen,
}) => {
  const displayText = text;
  const eventType = _.get(message, 'additionalInfo.eventType', 'default');
  let className = 'has-event-message';
  if (eventType === 'mass-notification-initial-event') {
    className = 'has-event-message-mass-note';
  }
  if (eventType === 'copart-gray-event') {
    const [releaseIssueText, releaseIssueDescription] = text.split(': ');
    const timestamp = formatDate(message.createdAt, 'date-time');
    const resolved = releaseIssueText.includes('Resolved');
    const showTagItem = !!releaseIssueDescription;
    return createCopartReleaseIssue({
      releaseIssueText,
      releaseIssueDescription,
      timestamp,
      resolved,
      showTagItem,
      templateTrayIsOpen,
      setTemplateTrayIsOpen,
    });
  }
  return createEvent(displayText, className);
};

EventMessage.propTypes = {
  text: PropTypes.string.isRequired,
  message: PropTypes.instanceOf(Object),
  setTemplateTrayIsOpen: PropTypes.func.isRequired,
  templateTrayIsOpen: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  setTemplateTrayIsOpen,
};

const mapStateToProps = (state) => ({
  templateTrayIsOpen: state.ui.templateTrayIsOpen,
});

export default connect(mapStateToProps, mapDispatchToProps)(EventMessage);
