import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  ControlLabel,
  FormControl,
  Col,
  Row,
  HelpBlock,
} from 'react-bootstrap';
import ButtonRow from '../../../elements/buttons/ButtonRow/ButtonRow';
import Button from '../../../elements/buttons/Button/Button';
import Divider from '../../../elements/Divider/Divider';
import './ParticipantForm.less';
import { buildOptedInWelcomeMessage, buildNonOptedInWelcomeMessage } from './welcomeMessagePrompts';

const ParticipantForm = ({
  onClose,
  mobileLookupMessage,
  handlePhoneNumberChange,
  handleFirstNameChange,
  handleLastNameChange,
  handleRelationshipChange,
  participantForm,
  requestStatus,
  onSubmit,
  relationships,
  isExistingUser,
  orgName,
  verifiedUser,
  isInEditMode,
  isPrimaryEndUser,
}) => {
  const handleOnClose = (event) => {
    event.preventDefault();
    onClose();
  };

  const isParticipantSelected = (
    participantForm.phoneNumber
    && participantForm.firstName
    && participantForm.lastName
  );

  const validationState = mobileLookupMessage === 'SMS Supported' ? 'success' : 'error';
  const isPhoneNumberAlreadyAdded = mobileLookupMessage === 'This phone number is already active on the conversation';
  const isSubmitDisabled = !isParticipantSelected || isPhoneNumberAlreadyAdded;

  // This is a temp fix for Farmers Alpha. Should be replaced with templating at some point.
  const marleyTermsOfService = 'himarley.com/tp';
  const farmersTermsOfService = `farmers.com/privacy-center/ and ${marleyTermsOfService}`;
  const termsOfService = orgName?.trim()?.toLowerCase() === 'farmers insurance' ? farmersTermsOfService : marleyTermsOfService;

  const welcomeMessageTemplate = verifiedUser
    ? buildOptedInWelcomeMessage(participantForm, orgName, termsOfService)
    : buildNonOptedInWelcomeMessage(participantForm, orgName, termsOfService);

  // only show welcome message preview if participant is selected and we are not in edit user mode
  const shouldShowWelcomeMessage = !isInEditMode && !!isParticipantSelected;

  const isLoading = requestStatus === 'PENDING';
  const isError = requestStatus === 'ERROR';

  let errorMessage
  if (isInEditMode) {
    errorMessage = 'Unable to edit participant. Please try again later.';
  } else {
    errorMessage = 'Unable to invite participant. Please try again later.';
    if (participantForm.error) {
      errorMessage = participantForm.error;
    }
  }
  const submitButtonNotLoadingText = isInEditMode ? 'Save Participant' : 'Invite Participant';
  const submitButtonText = isLoading ? 'Saving...' : submitButtonNotLoadingText;

  const editingNameEnabled = isInEditMode || (!isExistingUser && !isLoading);

  return (
    <form onSubmit={onSubmit}>
      <FormGroup
        className="mb-3"
        controlId="mobilePhoneNumber"
        validationState={isInEditMode ? null : validationState}
      >
        <ControlLabel>Mobile Phone (Required)</ControlLabel>
        <FormControl
          type="tel"
          placeholder="Enter Phone Number (ex: 5555551234)"
          onChange={handlePhoneNumberChange}
          value={participantForm.phoneNumber}
          pattern="^\d{10}$"
          required
          disabled={isLoading || isInEditMode}
          data-testid="addParticipantMobilePhoneInput"
        />
        <FormControl.Feedback />
        <HelpBlock>{mobileLookupMessage}</HelpBlock>
      </FormGroup>
      <Row>
        <Col xs={6}>
          <FormGroup className="mb-3" controlId="firstName">
            <ControlLabel>First Name (Required)</ControlLabel>
            <FormControl
              className="participant-form-first-name"
              type="text"
              maxLength={50}
              onChange={handleFirstNameChange}
              value={participantForm.firstName}
              disabled={!editingNameEnabled}
              required
              data-testid="firstNameInput"
            />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup className="mb-3" controlId="lastName">
            <ControlLabel>Last Name (Required)</ControlLabel>
            <FormControl
              className="participant-form-last-name"
              type="text"
              maxLength={50}
              onChange={handleLastNameChange}
              value={participantForm.lastName}
              disabled={!editingNameEnabled}
              required
              data-testid="lastNameInput"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <FormGroup controlId="formControlsSelect">
            <ControlLabel>Relationship</ControlLabel>
            <FormControl
              componentClass="select"
              placeholder="Choose Relationship"
              onChange={handleRelationshipChange}
              value={participantForm.relationship}
              disabled={isLoading || isPrimaryEndUser}
              data-testid="relationshipInput"
            >
              <optgroup>
                <option disabled value="">Choose Relationship</option>
              </optgroup>
              {relationships.map((option) => (
                <option key={`${option.label}-${option.value}`} value={option.value}>
                  {option.label}
                </option>
              ))}
            </FormControl>
          </FormGroup>
        </Col>
      </Row>
      { shouldShowWelcomeMessage ? welcomeMessageTemplate : null }
      <Divider />
      {isError ? (
        <p
          className="text-danger participant-form-submit-error"
          data-testid="errorText"
        >
          {errorMessage}
        </p>
      ) : null}
      <ButtonRow>
        <Button
          className="participant-form-cancel"
          onClick={handleOnClose}
          disabled={isLoading}
          data-testid="inviteParticipantCancelButton"
        >
          Cancel
        </Button>
        <Button
          type="positive"
          disabled={isSubmitDisabled || isLoading}
          data-testid="inviteParticipantSubmitButton"
        >
          {submitButtonText}
        </Button>
      </ButtonRow>
    </form>
  );
};

ParticipantForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  handlePhoneNumberChange: PropTypes.func.isRequired,
  handleFirstNameChange: PropTypes.func.isRequired,
  handleLastNameChange: PropTypes.func.isRequired,
  handleRelationshipChange: PropTypes.func.isRequired,
  mobileLookupMessage: PropTypes.string,
  participantForm: PropTypes.shape({
    phoneNumber: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    relationship: PropTypes.string,
    error: PropTypes.string,
  }),
  requestStatus: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  relationships: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  isExistingUser: PropTypes.bool,
  orgName: PropTypes.string.isRequired,
  verifiedUser: PropTypes.bool,
  isInEditMode: PropTypes.bool.isRequired,
  isPrimaryEndUser: PropTypes.bool.isRequired,
};

ParticipantForm.defaultProps = {
  requestStatus: undefined,
  participantForm: {
    phoneNumber: '',
    firstName: '',
    lastName: '',
    relationship: '',
  },
  isExistingUser: false,
  verifiedUser: false,
  mobileLookupMessage: '',
};

export default ParticipantForm;
