/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import PinIcon from '../../../../../images/icons/pin.svg';
import UnpinIcon from '../../../../../images/icons/unpin.svg';
import IconButton from '../../../elements/buttons/IconButton/IconButton';
import WithDot from '../../../WithDot/WithDot';
import AlertIcon from '../../../../../images/icons/alert.svg';
import ExclamationPoint from '../../../../../images/icons/exclamationPoint.svg';
import NoAccessIcon from '../../../../../images/icons/noAccessIcon.svg';
import CloseCircle from '../../../../../images/icons/close-circle.svg';
import MultipleNeedsAttention from '../../../../../images/icons/MultipleNeedsAttention.svg';
import { handleJobNeedsActionResponse } from '../../../../actions/job';
import { TagList, TagItem } from '../../../elements/TagItem/TagItem';
import LoBIcon from '../../../Settings/CompanyHierarchy/LoBIcon';
import { capitalizeEachWord } from '../../../../helpers/format';
import Case from '../../../../../images/icons/case.svg';

import './CaseItem.less';
import Badge from '../../../elements/Badge/Badge';
import {
  NEEDS_ATTENTION,
  DISPLAY_MESSAGE_STATUS,
  NEEDS_ACTION,
} from '../../../../constants/permissions';
import { useCheckPermissions } from '../../../../helpers/common';
import { CONFIRMED_TOTAL_LOSS_STATUS, DENIED_TOTAL_LOSS_STATUS } from '../../../../constants/jobState';
import TagItemTypes from '../../../../constants/tag-item-types';
import { FNOL_CAPTURE_LOSS_DATA } from '../../../../constants/workflows';

const CaseItem = ({
  job,
  selected,
  select,
  pin,
  unpin,
  hasAccessToCase,
  authId,
  isPinned,
}) => {
  const dispatch = useDispatch();
  const customerName = hasAccessToCase
    ? get(job, 'customer.name')
    : '[ Private ]';
  const latestActivity = get(job, 'latestActivity.formatted');
  const customerActivated = get(job, 'customer.activated');
  const customerId = job?.customer?.id;
  const customerVerified = get(job, 'customer.verified');
  const customerLocked = get(job, 'customer.locked');
  const customerBlocked = get(job, 'customer.blocked');
  const showFailedMessage = get(job, 'showFailedMessage');
  const displayNeedsAction = useCheckPermissions([NEEDS_ACTION]) && get(job, 'needsAction.hasQuestionMessageCount', 0) > 0;
  const hasDisplayMessageStatusPermission = useCheckPermissions([DISPLAY_MESSAGE_STATUS]);
  const hasNeedsActionPermission = useCheckPermissions([NEEDS_ACTION]);
  const workflowName = get(job, 'fnolData.workflowName');
  const airbagsDeployed = get(job, 'fnolData.entities.airbagsDeployed.value');
  const fnolAirbagsDeployed = airbagsDeployed && airbagsDeployed !== 'Not Reported' && workflowName === FNOL_CAPTURE_LOSS_DATA;
  const linesOfBusiness = useSelector(
    (state) => (state?.organizations?.linesOfBusiness || []),
  );

  const displayNeedsAttention = (needsAttentionMessageCount) => {
    if (!needsAttentionMessageCount || needsAttentionMessageCount === 0) {
      return null;
    }

    if (needsAttentionMessageCount === 1) {
      return (
        <Badge
          jobId={`chat-${job.id}`}
          type="tangerine"
          tooltip={
            "There's a high likelihood the customer has concerns and requires your attention."
          }
          icon={<AlertIcon />}
        />
      );
    }
    if (needsAttentionMessageCount >= 2) {
      return (
        <Badge
          jobId={`chat-${job.id}`}
          type="needs-attention-multiple"
          tooltip={
            "There's a high likelihood the customer has concerns and requires your attention."
          }
          icon={<MultipleNeedsAttention />}
        />
      );
    }
    return null;
  };

  const displayShowFailedMessage = (jobId) => {
    const { text, textColor, color } = TagItemTypes.Case.FailedMessage;
    if (hasDisplayMessageStatusPermission && showFailedMessage) {
      return <TagItem testId={`case-failed-message-badge-${jobId}`} icon={<ExclamationPoint />} text={text} textColor={textColor} backgroundColor={color} />;
    }
    return null;
  };

  const displayShowHasQuestion = () => {
    const { text } = TagItemTypes.Case.NeedsAction;
    if (hasNeedsActionPermission && displayNeedsAction) {
      return <TagItem testId={`case-has-question-${job.id || job._id}`} text={text} handleDismissFunction={() => dispatch(handleJobNeedsActionResponse(job.id, job.primaryChatId, 'dismissed'))} />;
    }
    return null;
  };

  const displayShowTotalLoss = () => {
    const { text, color } = TagItemTypes.Case.TotalLoss;
    if (job.isTotalLoss) {
      return <TagItem testId={`case-total-loss-${job.id || job._id}`} text={text} backgroundColor={color} />;
    }
    return null;
  };

  const displayReleaseIssue = () => {
    const { text, color } = TagItemTypes.Case.ReleaseIssue;
    const releaseIssues = job.releaseIssues || [];
    const openReleaseIssues = releaseIssues.filter((i) => i.status === 'ACTIVE');
    if (openReleaseIssues.length > 0) {
      return <TagItem testId={`case-release-issue-${job.id || job._id}`} text={text} backgroundColor={color} icon={<ExclamationPoint />} />;
    }
    return null;
  };

  const displayFNOLBadge = (fnolAirbagsDeployedStatus, totalLossStatus) => {
    const { text: confirmedFNOLText, color: confirmedFNOLColor } = TagItemTypes.Case.ConfirmedFNOL;
    const { text: possibleFNOLText, color: possibleFNOLColor } = TagItemTypes.Case.PossibleFNOL;
    if (totalLossStatus === CONFIRMED_TOTAL_LOSS_STATUS) {
      return (
        <TagItem
          backgroundColor={confirmedFNOLColor}
          text={confirmedFNOLText}
          testId="confirmed-total-loss"
        />
      );
    }

    if (fnolAirbagsDeployedStatus && fnolAirbagsDeployedStatus !== 'Not Reported'
      && totalLossStatus !== DENIED_TOTAL_LOSS_STATUS) {
      return (
        <TagItem
          backgroundColor={possibleFNOLColor}
          text={possibleFNOLText}
          testId="possible-total-loss"
        />
      );
    }

    return null;
  };

  const displayShowNotOptInOrLockedOut = (jobId) => {
    const {
      text: lockedOutText,
      color: lockedOutColor,
      textColor: lockedOutTextColor,
    } = TagItemTypes.Case.LockedOut;
    const {
      text: notOptedInText,
      color: notOptedInColor,
      textColor: notOptedInTextColor,
    } = TagItemTypes.Case.NotOptedIn;
    if (hasAccessToCase
      && (!customerVerified || !customerActivated || customerLocked) && !customerBlocked) {
      return (
        <TagItem
          testId={`case-not-opt-in-or-locked-out-badge-${jobId}`}
          icon={customerLocked ? <NoAccessIcon /> : null}
          text={customerLocked ? lockedOutText : notOptedInText}
          backgroundColor={customerLocked ? lockedOutColor : notOptedInColor}
          textColor={customerLocked ? lockedOutTextColor : notOptedInTextColor}
        />
      );
    }
    return null;
  };

  const displayShowOptOut = (jobId) => {
    const { text, color, textColor } = TagItemTypes.Case.OptedOut;
    if (hasAccessToCase && customerVerified && customerBlocked && !customerLocked) {
      return <TagItem icon={<CloseCircle />} testId={`case-opt-out-badge-${jobId}`} text={text} backgroundColor={color} textColor={textColor} />;
    }
    return null;
  };

  const displayChatTileIcon = (lobType) => {
    if (!linesOfBusiness || linesOfBusiness.length === 0) {
      return null;
    }

    return (
      <div className="case_type_icon" data-testid="case-lob-icon-container">
        {lobType === 'case' ? <Case /> : <LoBIcon name={lobType} />}
      </div>
    );
  };

  const displayChatId = () => {
    if (job?.lineOfBusiness?.subType) {
      const displayName = job.lineOfBusiness?.displayName || job.lineOfBusiness.subType;
      return `${capitalizeEachWord(displayName)}: ${job.displayId}`;
    }
    return `ID: ${job.displayId}`;
  };

  return (
    <WithDot
      position="top-left"
      show={job.hasUnreadMessages && hasAccessToCase}
      isBackgroundLight
      className={`case-item ${hasAccessToCase ? 'with-access' : ''} ${
        isPinned && hasAccessToCase ? 'unpinned' : ''
      }`}
      type={
        get(job, 'type', '').toUpperCase() === 'MASSNOTIFICATION'
          ? 'red'
          : 'blue'
      }
      key={job.id}
      jobId={job.id}
    >
      <button
        type="button"
        className={`chat__inner__button ${!hasAccessToCase ? 'redacted' : ''} ${
          selected ? 'selected' : ''
        }`}
        data-jest="set-active-button"
        data-testid={`case-item-${job._id || job.id}`}
        onClick={hasAccessToCase ? select : null}
        id={`case-item-${job.id}`}
      >
        <div className="chat_column_parent">
          {displayChatTileIcon(job.lineOfBusiness?.subType || 'case')}
          <div
            data-jest={`case-item${isPinned ? '' : '-pinned'}`}
            className="chat__inner"
          >
            <div className="chat_inner_row">
              <div className="chat__text chat__text--name" data-testId={`customer-name-${customerId || 'private'}`}>{customerName}</div>
              {useCheckPermissions([NEEDS_ATTENTION])
                ? displayNeedsAttention(job.needsAttentionMessageCount)
                : null}
              <div data-testid={`latest-activity-timestamp-${job.id}`} className="chat__text chat__text--date">{latestActivity}</div>
            </div>
            <div className="chat_inner_row">
              <div className="chat__text chat__text--id">
                {displayChatId()}
              </div>
              <IconButton
                data-testid={`${!isPinned ? 'unpin' : 'pin'}-button-${job?._id || job?.id}`}
                className={
                isPinned
                  ? 'chat-item-pin-button show-pinned-icon'
                  : 'chat-item-pin-button-hover'
              }
                id={`chat-item-pin-button-${job.id}`}
                onClick={isPinned ? unpin : pin}
              >
                {!isPinned ? <PinIcon /> : <UnpinIcon />}
              </IconButton>
            </div>

            <TagList displayHorizontalLine>
              {displayShowHasQuestion()}
              {displayShowFailedMessage(job?._id)}
              {displayShowNotOptInOrLockedOut(job?._id)}
              {displayShowOptOut(job?._id)}
              {displayFNOLBadge(fnolAirbagsDeployed, job?.totalLossStatus)}
              {displayShowTotalLoss()}
              {displayReleaseIssue()}
              {(job?.unreadNoteMentions?.[authId] !== undefined)
                ? <TagItem testId={`unread-note-mention-badge-${job?._id}`} text={TagItemTypes.Case.NoteMention.text} backgroundColor={TagItemTypes.Case.NoteMention.color} textColor={TagItemTypes.Case.NoteMention.textColor} />
                : null}
            </TagList>

          </div>
        </div>
      </button>
    </WithDot>
  );
};

CaseItem.propTypes = {
  job: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.bool.isRequired,
  select: PropTypes.func.isRequired,
  pin: PropTypes.func.isRequired,
  unpin: PropTypes.func.isRequired,
  hasAccessToCase: PropTypes.bool.isRequired,
  isPinned: PropTypes.bool.isRequired,
  authId: PropTypes.string.isRequired,
};

export default CaseItem;
