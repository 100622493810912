import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Button from '../../../../Form/Button/Button';
import IconButton from '../../../../elements/buttons/IconButton/IconButton';
import CloseIcon from '../../../../../../images/icons/close.svg';
import './SurveyModal.less';

const SurveyModal = (props) => {
  const {
    showSurvey, closeSendSurvey, isAssignedJob, sendSurvey,
  } = props;
  const handleSubmit = () => {
    if (isAssignedJob) {
      sendSurvey();
    }
    closeSendSurvey();
  };

  return (
    <Modal
      show={showSurvey}
      onHide={closeSendSurvey}
      dialogClassName="chat-input-survey-modal"
    >
      <IconButton
        onClick={closeSendSurvey}
        left
        className="chat-input-survey-button-close"
        id="chat-input-survey-close"
        data-testid="chat-input-survey-close"
      >
        <CloseIcon />
      </IconButton>
      <div className="chat-input-survey-header">Send Survey?</div>
      <div className="chat-input-survey-helper">
        This will send the user a Hi Marley satisfaction survey.
      </div>
      <div className="chat-input-survey-buttons">
        <Button
          onClick={handleSubmit}
          className="chat-input-survey-button-send"
          type="primary"
          data-testid="chat-input-survey-send"
        >
          Send
        </Button>
        <Button
          onClick={closeSendSurvey}
          className="chat-input-survey-button-cancel"
          type="default"
          data-testid="chat-input-survey-cancel"
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

SurveyModal.propTypes = {
  closeSendSurvey: PropTypes.func.isRequired,
  sendSurvey: PropTypes.func.isRequired,
  isAssignedJob: PropTypes.bool.isRequired,
  showSurvey: PropTypes.bool.isRequired,
};

export default SurveyModal;
