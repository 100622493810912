import {
  Avatar,
  Button,
  ButtonRow,
  Modal,
} from '@himarley/unity';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import LoBIcon from './LoBIcon';
import { updateLineOfBusiness } from '../../../actions/organization';
import { typeToLine } from './helpers';
import { useDispatch, useSelector } from 'react-redux';
import { capitalizeEachWord } from '../../../helpers/format';
import FormInputField from '../../FormInput/FormInputField';

const EditLineOfBusinessModal = ({
  show,
  hideModal,
  isEditing,
  name,
  index,
  brandingMap,
  lob,
}) => {
  const [lineName, setLineName] = useState(name || '');
  const dispatch = useDispatch();
  const orgId = useSelector((state) => state.auth?.user?.organizationId);

  const handleHideModal = () => {
    if (!isEditing) {
      setLineName('');
    }
    hideModal();
  };

  useEffect(() => {
    setLineName(name);
  }, [name]);

  const handleEditLine = (e) => {
    if (!isValid()) {
      return;
    }
    dispatch(updateLineOfBusiness(
      {
        organizationId: orgId,
        lineOfBusinessId: lob._id,
        displayName: capitalizeEachWord(lineName),
      },
      'Lines of Business saved.',
      'Error Saving Lines of Business. Please Try Again.',
    ));
  };


  const isValid = () => {
    if (lineName?.trim()?.length < 1 || lineName.length > 50) {
      return false;
    }
    return true;
  };

  const isSaveDisabled = () => {
    if (lineName?.trim()?.length < 1) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      show={show}
      showCloseButton
      title={`${isEditing ? 'Edit' : 'Create'} Line`}
      className="confirmation-modal"
      toggleModal={handleHideModal}
      backdrop
      size="lg"
    >
      <>
        <div className="edit-lob-modal-container">
          <div>
            <div className="edit-avatar-container">
              <Avatar name={lob.branding} isCustomer className="brandAvatar" />
              <label className="edit-avatar-label">{lob.branding}</label>
            </div>
            <div className="type-container">
              <p>Type</p>
              <p>{typeToLine[lob?.type]}</p>
            </div>
          </div>

          <div>
            <div className="lob-icon-container">
              <LoBIcon name={lob?.subType} />
              <h2 className="lob-icon-title">
                {capitalizeEachWord(
                  lob?.subType,
                  lob?.subType?.includes('-') ? '-' : ' ',
                )}{lob?.subType === 'workers-comp' && '.'}
              </h2>
            </div>
            <FormInputField
              label="Display Name"
              value={lineName}
              onChange={(e) => setLineName(e?.target?.value || '')}
              errorMessage={
                !isValid()
                  ? 'Line Name must be less than 50 characters.'
                  : ''
              }
              isValid={isValid()}
            />

          </div>
        </div>
        <div className="confirmation-modal-footer">
          <ButtonRow>
            <Button
              onClick={handleHideModal}
              type="outline"
              className="cancelButton"
            >
              Cancel
            </Button>
            <Button
              data-testid="delete-note-button-confirm"
              data-test="delete-note-button-confirm"
              onClick={() => {
                handleEditLine(lineName, index);
                handleHideModal();
              }}
              type="positive"
              disabled={isSaveDisabled() || !isValid()}
            >
              {isEditing ? 'Save' : 'Create'} Changes
            </Button>
          </ButtonRow>
        </div>
      </>
    </Modal>
  );
};

EditLineOfBusinessModal.propTypes = {
  isEditing: PropTypes.bool,
  show: PropTypes.bool,
  hideModal: PropTypes.func,
  name: PropTypes.string,
  index: PropTypes.string,
  handleEditLine: PropTypes.func,
  brandingMap: PropTypes.shape({ has: PropTypes.func }),
};

EditLineOfBusinessModal.defaultProps = {
  isEditing: false,
  show: false,
  hideModal: () => {},
  name: '',
  index: '',
  handleEditLine: () => {},
  brandingMap: { has: () => {} },
  lob: {},
};

export default EditLineOfBusinessModal;
