/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react'
import List from '../../../../elements/List/List'
import Menu from '../../../../elements/menus/Menu'

/**
 * Icon Menu for Scheduled Messages
 * @param   {function} setShowScheduleMessageModal triggered when selecting schedule a message
 * @param   {function} setShowScheduleMessageListModal triggered when viewing scheduled messages
* */
const ScheduleMenu = ({ setShowScheduleMessageModal, setShowScheduleMessageListModal }) => {
  const handleOnToggle = (id) => {
    if (id === 'viewSchedule') {
      setShowScheduleMessageListModal(true)
      setShowScheduleMessageModal(false)
    } else {
      setShowScheduleMessageListModal(false)
      setShowScheduleMessageModal(true)
    }
  }
  return (
    <Menu>
      <List
        items={[
          { id: 'createScheduled', label: 'Schedule Message' },
          { id: 'viewSchedule', label: 'View Scheduled Messages' },
        ]}
        onToggle={handleOnToggle}
      />
    </Menu>
  )
}

export default ScheduleMenu
