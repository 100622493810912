/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import EditableLine from '../../../../elements/EditableLine/EditableLine';
import './CaseData.less';

const ACCEPTED_DATA_TYPES = ['string', 'boolean'];
const formatValue = (dataType, value) => {
  if (!ACCEPTED_DATA_TYPES.includes(typeof value)) {
    return 'Not Reported';
  }

  if (value === 'Not Reported') {
    return 'Not Reported';
  }
  switch (dataType) {
    case 'datetime': {
      return new Date(value).toLocaleString();
    }
    case 'boolean': {
      if (value === true || value === 'Yes') {
        return 'Yes';
      }
      if (value === false || value === 'No') {
        return 'No';
      }
      return '';
    }
    default: {
      return value;
    }
  }
};

const renderCaseDataEntity = (entity) => {
  const keyName = entity[0];
  const {
    displayName,
    value,
    source,
    dataType,
  } = entity[1];
  const displayValue = formatValue(dataType, value);

  return (
    <div data-testid={`case-data-${keyName}`} key={`case-data-${keyName}`}>
      <EditableLine
        label={displayName}
        value={displayValue}
        data-testid={`case-data-field-${keyName}`}
        source={source}
      >
        {displayValue}
      </EditableLine>
    </div>
  );
};

const CaseData = ({ caseData }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isVisibleOnChatDetails = ([_, entityItem]) => entityItem.visibleTo.includes('chatDetails');

  return (
    <div className="user-details-wrapper case-data">
      { Object.entries(caseData)
        .filter(isVisibleOnChatDetails)
        .map((entity) => renderCaseDataEntity(entity))}
    </div>
  );
};

CaseData.propTypes = {
  caseData: PropTypes.shape({}).isRequired,
};

export default CaseData;
