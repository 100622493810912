import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { StateType } from '@app/types/reducer-state';

import styles from './InboxFooter.module.less';
import CancelSearchButton from '../CancelSearchButton/CancelSearchButton';

interface InboxFooterProps {
  index: number;
  setSize: (index: number, size?: number) => void;
  windowWidth?: number | string;
  style: React.CSSProperties;
}

const InboxFooter: React.FC<InboxFooterProps> = ({
  index,
  setSize,
  windowWidth,
  style,
}) => {
  const { search } = useSelector((state: StateType) => ({
    search: state.jobs?.search,
  }));

  const rowRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (rowRef.current?.firstChild) {
      const firstChild = rowRef.current.firstChild as HTMLElement;
      const { height } = firstChild.getBoundingClientRect();
      setSize(index, height + 1);
    }
  }, [setSize, index, windowWidth]);

  const endMessage = useMemo(() => {
    if (search) {
      return 'End of Search Results';
    }
    return 'There are no more chats';
  }, [search]);

  return (
    <section
      key={index}
      className={`${styles.chatScrollableContainer} ${styles.inboxFooter}`}
      data-testid="inbox-end-of-list"
      style={style}
      ref={rowRef}
    >
      <div className={styles.h3}>{endMessage}</div>
      { search ? <CancelSearchButton testId="chat-inbox-cancel-search-button-end-of-list" /> : null }
    </section>
  );
};

export default InboxFooter;
