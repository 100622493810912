/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-danger */
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import {
  Button,
  Card,
  Modal,
  Divider,
  ButtonRow,
  TextAreaInput,
  SlideToggle,
} from '@himarley/unity';
import Preview from '../OrgSettings/Preview';
import MessageInput from '../WelcomeFlowSettings/message-input';
import RadioButton from '../../elements/RadioButton/RadioButton';
import LightBulb from '../../../../images/icons/lightbulb.svg';
import { capitalizeEachWord } from '../../../helpers/format';
import {
  constructPreviewText,
  LEGAL_MESSAGE,
  NO_VALUE,
  TEMPLATE_KEYS,
  RADIO_BUTTON_VALUES,
} from './constants';

const CustomerNotOptedInModal = ({
  caseType,
  show,
  hideModal,
  welcomeMessageInitial,
  welcomeAssigned,
  welcomeMessageOptional,
  welcomeMessageOptionalEnding,
  onSave,
  isUpdatingOrgSetting,
  welcomeUnassigned,
  tokenOverrideMap,
}) => {
  const handleHide = () => {
    hideModal();
  };

  const isOptionalFollowUpEnabledDefault = !!(
    welcomeMessageOptional.trim().length > 0
    && welcomeMessageOptional !== NO_VALUE
  );

  const [showOptionalFollowUp, setShowOptionalFollowUp] = useState(
    isOptionalFollowUpEnabledDefault,
  );
  const [showEndingMessage, setShowEndingMessage] = useState(
    !!(
      welcomeMessageOptionalEnding.trim().length > 0
      && welcomeMessageOptionalEnding !== NO_VALUE
    ),
  );

  const [beginningMessage, setBeginningMessage] = useState(
    welcomeMessageInitial === NO_VALUE ? '' : welcomeMessageInitial,
  );
  const [optionalEndingMessage, setOptionalEndingMessage] = useState(
    welcomeMessageOptionalEnding === NO_VALUE
      ? ''
      : welcomeMessageOptionalEnding,
  );

  const [welcomeAssignedMessage, setWelcomeAssignedMessage] = useState(welcomeAssigned);

  const [welcomeUnassignedMessage, setWelcomeUnassignedMessage] = useState(welcomeUnassigned);

  const [optionalFollowupMessage, setOptionalFollowupMessage] = useState(
    welcomeMessageOptional === NO_VALUE ? '' : welcomeMessageOptional,
  );
  const [previewOption, setPreviewOption] = useState(RADIO_BUTTON_VALUES[0].id);

  const firstText = useMemo(
    () => (
      <div>
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(constructPreviewText(beginningMessage, tokenOverrideMap)),
          }}
        />
        <span>
          {' '}
          {LEGAL_MESSAGE}
          {' '}
        </span>
        {showEndingMessage && (
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                constructPreviewText(optionalEndingMessage, tokenOverrideMap),
              ),
            }}
          />
        )}
      </div>
    ),
    [beginningMessage, optionalEndingMessage, showEndingMessage, tokenOverrideMap],
  );

  const assignedText = useMemo(
    () => (welcomeAssignedMessage.trim().length > 0
      ? (
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              constructPreviewText(welcomeAssignedMessage, tokenOverrideMap),
            ),
          }}
        />
      )
      : null),
    [tokenOverrideMap, welcomeAssignedMessage],
  );

  const unassignedText = useMemo(
    () => (welcomeUnassignedMessage.trim().length > 0
      ? (
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              constructPreviewText(welcomeUnassignedMessage, tokenOverrideMap),
            ),
          }}
        />
      )
      : null),
    [welcomeUnassignedMessage, tokenOverrideMap],
  );

  const optionalFollowupText = useMemo(
    () => (showOptionalFollowUp && optionalFollowupMessage.trim().length > 0
      ? (
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              constructPreviewText(optionalFollowupMessage, tokenOverrideMap),
            ),
          }}
        />
      )
      : null),
    [showOptionalFollowUp, optionalFollowupMessage, tokenOverrideMap],
  );

  const hasChangedMessages = useMemo(() => {
    const optionalFollowUpConverted = welcomeMessageOptional === NO_VALUE ? '' : welcomeMessageOptional;
    const hasOptionalFollowUpChanged = showOptionalFollowUp
      ? optionalFollowupMessage.trim() !== optionalFollowUpConverted.trim()
        && optionalFollowupMessage.trim().length > 0
      : isOptionalFollowUpEnabledDefault !== showOptionalFollowUp;
    return (
      (beginningMessage.trim() !== welcomeMessageInitial.trim()
        || (showEndingMessage
          && welcomeMessageOptionalEnding.trim()
            !== optionalEndingMessage.trim())
        || welcomeAssignedMessage.trim() !== welcomeAssigned.trim()
        || welcomeUnassignedMessage.trim() !== welcomeUnassigned.trim()
        || hasOptionalFollowUpChanged)
      && welcomeAssignedMessage.trim().length > 0
      && welcomeUnassignedMessage.trim().length > 0
    );
  }, [
    isOptionalFollowUpEnabledDefault,
    welcomeMessageOptionalEnding,
    optionalEndingMessage,
    beginningMessage,
    welcomeAssignedMessage,
    optionalFollowupMessage,
    showOptionalFollowUp,
    welcomeMessageInitial,
    welcomeMessageOptional,
    welcomeUnassignedMessage,
    welcomeUnassigned,
    welcomeAssigned,
  ]);

  const handleSave = () => {
    onSave({
      [TEMPLATE_KEYS.welcomeMessageInitial]: {
        template: beginningMessage.trim().length > 0 ? beginningMessage : NO_VALUE,
        templateKey: TEMPLATE_KEYS.welcomeMessageInitial,
      },
      [TEMPLATE_KEYS.welcomeMessageOptionalEnding]: {
        template: optionalEndingMessage.trim().length > 0 ? optionalEndingMessage : NO_VALUE,
        templateKey: TEMPLATE_KEYS.welcomeMessageOptionalEnding,
      },
      [TEMPLATE_KEYS.welcomeAssigned]: {
        template: welcomeAssignedMessage.trim().length > 0 ? welcomeAssignedMessage : NO_VALUE,
        templateKey: TEMPLATE_KEYS.welcomeAssigned,
      },
      [TEMPLATE_KEYS.welcomeUnassigned]: {
        template: welcomeUnassignedMessage.trim().length > 0 ? welcomeUnassignedMessage : NO_VALUE,
        templateKey: TEMPLATE_KEYS.welcomeUnassigned,
      },
      [TEMPLATE_KEYS.welcomeMessageOptional]: {
        template: showOptionalFollowUp ? optionalFollowupMessage : NO_VALUE,
        templateKey: TEMPLATE_KEYS.welcomeMessageOptional,
      },
      [TEMPLATE_KEYS.welcomeCaseMessageInitial]: {
        template: beginningMessage.trim().length > 0 ? beginningMessage : NO_VALUE,
        templateKey: TEMPLATE_KEYS.welcomeCaseMessageInitial,
      },
      [TEMPLATE_KEYS.welcomeCaseMessageOptionalEnding]: {
        template: optionalEndingMessage.trim().length > 0 ? optionalEndingMessage : NO_VALUE,
        templateKey: TEMPLATE_KEYS.welcomeCaseMessageOptionalEnding,
      },
      [TEMPLATE_KEYS.welcomeCaseAssigned]: {
        template: welcomeAssignedMessage.trim().length > 0 ? welcomeAssignedMessage : NO_VALUE,
        templateKey: TEMPLATE_KEYS.welcomeCaseAssigned,
      },
      [TEMPLATE_KEYS.welcomeCaseUnassigned]: {
        template: welcomeUnassignedMessage.trim().length > 0 ? welcomeUnassignedMessage : NO_VALUE,
        templateKey: TEMPLATE_KEYS.welcomeCaseUnassigned,
      },
      [TEMPLATE_KEYS.welcomeCaseMessageOptional]: {
        template: showOptionalFollowUp ? optionalFollowupMessage : NO_VALUE,
        templateKey: TEMPLATE_KEYS.welcomeCaseMessageOptional,
      },
    }, false, caseType);
  };

  return (
    <Modal
      show={show}
      showCloseButton
      title="Customer Not Opted In"
      className="confirmation-modal"
      toggleModal={handleHide}
      backdrop
      size="xl"
    >
      <>
        <div className="instructionText">
          <span>Required fields labeled with an asterisk.</span>
          <RadioButton
            currentValue={previewOption}
            values={RADIO_BUTTON_VALUES}
            setCheckedValue={setPreviewOption}
            isHorizontal
          />
        </div>
        <section className="modalBody">
          <section className="topContent">
            <section className="modalLeftSection">
              <div className="numberLineCardWrap">
                <div className="numberLine">
                  <div className="number">1</div>
                  <div className="verticalLine" />
                </div>
                <Card>
                  <h2>Opt-In Request</h2>
                  Customers who have not opted in will receive this text message
                  for all new cases.
                  <MessageInput
                    label="Beginning Message Body"
                    maxLength={1000}
                    value={beginningMessage}
                    setValue={setBeginningMessage}
                    testId="beginningMessageInput"
                  />
                  <div className="tipWrap">
                    <LightBulb />
                    {' '}
                    TIP: Type the '$' symbol to insert a message
                    template variable.
                  </div>
                  <TextAreaInput
                    value={LEGAL_MESSAGE}
                    onChange={() => {}}
                    onKeyDown={() => {}}
                    autoFocus
                    label="Legal Message"
                    disabled
                    required
                    topRightLabel="Legal message is uneditable."
                  />
                  {!showEndingMessage && (
                    <button
                      className="optionalEndingBtn"
                      onClick={() => setShowEndingMessage(!showEndingMessage)}
                      onKeyDown={() => setShowEndingMessage(!showEndingMessage)}
                      type="button"
                    >
                      + Add Optional Ending Message Body
                    </button>
                  )}
                  {showEndingMessage && (
                    <>
                      <MessageInput
                        label="Ending Message Body"
                        maxLength={200}
                        value={optionalEndingMessage}
                        setValue={setOptionalEndingMessage}
                        testId="endingMessageInput"
                      />
                      <div className="tipWrap">
                        <LightBulb />
                        {' '}
                        TIP: Type the '$' symbol to insert a
                        message template variable.
                      </div>
                    </>
                  )}
                </Card>
              </div>
              <div className="numberLineCardWrap">
                <div className="numberLine">
                  <div className="number">2</div>
                  <div className="verticalLine" />
                </div>
                <Card>
                  <h2>Opt-In Confirmation</h2>
                  When customers opt-in by responding YES they will get one of
                  the following auto-reply text messages.
                  <h3>{`Assigned ${capitalizeEachWord(caseType)}s`}</h3>
                  <MessageInput
                    label="Assigned Case - Message Body"
                    maxLength={1400}
                    required
                    value={welcomeAssignedMessage}
                    setValue={setWelcomeAssignedMessage}
                    testId="assignedCaseMessageInput"
                  />
                  <div className="tipWrap">
                    <LightBulb />
                    {' '}
                    TIP: Type the '$' symbol to insert a message
                    template variable.
                  </div>
                  <h3>{`Unassigned ${capitalizeEachWord(caseType)}s`}</h3>
                  <MessageInput
                    label="Unassigned Case - Message Body"
                    maxLength={1400}
                    required
                    value={welcomeUnassignedMessage}
                    setValue={setWelcomeUnassignedMessage}
                    testId="unassignedCaseMessageInput"
                  />
                  <div className="tipWrap">
                    <LightBulb />
                    {' '}
                    TIP: Type the '$' symbol to insert a message
                    template variable.
                  </div>
                </Card>
              </div>
              <div className="numberLineCardWrap">
                <div className="numberLine">
                  <div className="number">3</div>
                </div>
                <Card>
                  <header>
                    <h2>Optional Follow Up</h2>
                    <SlideToggle
                      onToggle={() => setShowOptionalFollowUp(!showOptionalFollowUp)}
                      on={showOptionalFollowUp}
                      showLabels={false}
                    />
                  </header>
                  You can configure one additional text message to auto send
                  after the opt-in confirmation text message.
                  {showOptionalFollowUp && (
                    <>
                      <MessageInput
                        label="Message Body"
                        maxLength={1400}
                        value={optionalFollowupMessage}
                        setValue={setOptionalFollowupMessage}
                        testId="optionalFollowUpMessageInput"
                      />
                      <div className="tipWrap">
                        <LightBulb />
                        {' '}
                        TIP: Type the '$' symbol to insert a
                        message template variable.
                      </div>
                    </>
                  )}
                </Card>
              </div>
            </section>
            <Preview
              firstTextName="Lovable Insurance"
              firstTextMessage={firstText}
              secondTextMessage="YES"
              thirdTextName="Lovable Insurance"
              thirdTextMessage={
                previewOption === RADIO_BUTTON_VALUES[0].id
                  ? assignedText
                  : unassignedText
              }
              fourthTextName="Lovable Insurance"
              fourthTextMessage={optionalFollowupText}
              previewLabel=""
            />
          </section>
          <div className="messagesButtonRow">
            <Divider />
            <ButtonRow type="positive">
              <Button onClick={handleHide} type="outline">
                Cancel
              </Button>
              <Button
                disabled={isUpdatingOrgSetting === true || !hasChangedMessages}
                type="neutral"
                onClick={handleSave}
              >
                {isUpdatingOrgSetting === true ? 'Saving...' : 'Save Messages'}
              </Button>
            </ButtonRow>
          </div>
        </section>
      </>
    </Modal>
  );
};

CustomerNotOptedInModal.propTypes = {
  show: PropTypes.bool,
  hideModal: PropTypes.func,
  welcomeMessageInitial: PropTypes.string,
  welcomeAssigned: PropTypes.string,
  welcomeUnassigned: PropTypes.string,
  welcomeMessageOptional: PropTypes.string,
  welcomeMessageOptionalEnding: PropTypes.string,
  onSave: PropTypes.func,
  isUpdatingOrgSetting: PropTypes.bool,
  tokenOverrideMap: PropTypes.shape({}),
  caseType: PropTypes.string,
};

CustomerNotOptedInModal.defaultProps = {
  show: false,
  hideModal: () => {},
  welcomeMessageInitial: '',
  welcomeAssigned: '',
  welcomeUnassigned: '',
  welcomeMessageOptional: '',
  welcomeMessageOptionalEnding: '',
  onSave: () => {},
  isUpdatingOrgSetting: false,
  tokenOverrideMap: {},
  caseType: '',
};

export default CustomerNotOptedInModal;
