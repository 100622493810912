/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ChatModule from './ChatModule';

const MainChatModule = ({
  activeChatId,
  disabled,
  isInChatBotMode,
  getScheduledMessages,
  isLocked,
  updateEndUser,
  isUnlockingUser,
  chatRef,
}) => {
  useEffect(() => {
    if (getScheduledMessages && activeChatId) getScheduledMessages(activeChatId);
  }, [activeChatId, getScheduledMessages]);
  return (
    <ChatModule
      disabled={disabled}
      chatLocation="activeChat"
      isInChatBotMode={isInChatBotMode}
      isLocked={isLocked}
      updateEndUser={updateEndUser}
      isUnlockingUser={isUnlockingUser}
      chatRef={chatRef}
    />
  );
};
MainChatModule.propTypes = {
  activeChatId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  isInChatBotMode: PropTypes.bool.isRequired,
  getScheduledMessages: PropTypes.func.isRequired,
  isLocked: PropTypes.bool.isRequired,
  updateEndUser: PropTypes.func.isRequired,
  isUnlockingUser: PropTypes.bool.isRequired,
  chatRef: PropTypes.instanceOf(Object).isRequired,
};

export default MainChatModule;
