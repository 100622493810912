import _ from 'lodash'

const selector = (state) => {
  const activeCase = _.get(state, 'jobs.activeJob', {})
  const customer = _.get(state, 'jobs.activeChat.activeJob.customer', {})
  const mediaMessages = state?.jobs?.activeChat?.media?.filter(
    (media) => !media?.redactionViewedEvents?.length,
  ) || [];
  const error = _.get(state, 'users.error')
  const requestComplete = _.get(state, 'users.requestComplete')
  const rightBar = _.get(state, 'ui.rightBar')
  const caseTypes = _.get(state, 'jobs.caseTypes', null)
  const fnolData = _.get(state, 'jobs.activeJob.fnolData', {});

  return {
    hasNotesAccess: _.get(state, 'auth.permissions', []).includes('NOTES_ACCESS'),
    authId: _.get(state, 'auth.user._id'),
    customer,
    mediaMessages,
    error,
    requestComplete,
    rightBar,
    caseTypes,
    activeCaseId: _.get(activeCase, 'id'),
    notes: _.get(state, 'jobs.activeNotes', []),
    caseData: fnolData, // TODO rename to fnolData from caseData
  }
}

export default selector
