// eslint-disable-next-line import/prefer-default-export
export const formatDate = (
  timestamp,
  dateStyle = 'medium',
  timeStyle = 'long',
) => new Date(timestamp).toLocaleString('en-us', { dateStyle, timeStyle });

export const formatTime = (
  timestamp,
  timeStyle = 'short',
) => new Date(timestamp).toLocaleTimeString('en-us', { timeStyle });
