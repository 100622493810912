import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../elements/Modal/Modal';
import ViewParticipantList, { participantPropType } from './ViewParticipantList';
import './ViewParticipantsModal.less';

const ViewParticipantsModal = ({
  activeParticipants,
  pendingParticipants,
  inactiveParticipants,
  loggedInOperatorId,
  primaryEndUserId,
  onClickRemoveParticipant,
  onClose,
  onClickAddParticipant,
  onClickEditParticipant,
  onClickReinviteParticipant,
  enableAddParticipants,
}) => (
  <Modal
    size="xl"
    show
    title="Participants"
    toggleModal={onClose}
    className="view-participants-modal"
  >
    <ViewParticipantList
      activeParticipants={activeParticipants}
      pendingParticipants={pendingParticipants}
      inactiveParticipants={inactiveParticipants}
      loggedInOperatorId={loggedInOperatorId}
      primaryEndUserId={primaryEndUserId}
      onClickRemoveParticipant={onClickRemoveParticipant}
      onClose={onClose}
      onClickAddParticipant={onClickAddParticipant}
      onClickEditParticipant={onClickEditParticipant}
      onClickReinviteParticipant={onClickReinviteParticipant}
      enableAddParticipants={enableAddParticipants}
    />
  </Modal>
);

ViewParticipantsModal.propTypes = {
  activeParticipants: PropTypes.arrayOf(participantPropType).isRequired,
  pendingParticipants: PropTypes.arrayOf(participantPropType).isRequired,
  inactiveParticipants: PropTypes.arrayOf(participantPropType).isRequired,
  loggedInOperatorId: PropTypes.string.isRequired,
  primaryEndUserId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onClickRemoveParticipant: PropTypes.func.isRequired,
  onClickAddParticipant: PropTypes.func.isRequired,
  onClickEditParticipant: PropTypes.func.isRequired,
  onClickReinviteParticipant: PropTypes.func.isRequired,
  enableAddParticipants: PropTypes.bool.isRequired,
};

export default ViewParticipantsModal;
