import { combineReducers } from 'redux';
import { i18nReducer } from 'react-redux-i18n';
import analytics from './analytics';
import auth from './auth';
import jobs from './jobs';
import ui from './ui';
import users from './users';
import profile from './profile';
import templates from './templates';
import notification from './notification';
import organizations from './organizations';
import subgroups from './subgroups';
import massNotifications from './massNotifications';
import socket from './socket';
import chatLog from './chatLog';
import groups from './groups';
import operators from './operators';
import participants from './participants';
import { assistApi } from '../services/assist';
import media from './media';

const appReducer = combineReducers({
  analytics,
  auth,
  jobs,
  notification,
  operators,
  organizations,
  subgroups,
  profile,
  templates,
  ui,
  users,
  i18n: i18nReducer,
  socket,
  chatLog,
  massNotifications,
  groups,
  participants,
  media,
  [assistApi.reducerPath]: assistApi.reducer,
});

export default appReducer;
