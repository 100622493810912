/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import { clearFilter } from '../../../../actions/ui';
import { pluralize } from '../../../../helpers/common';
import BinocularsIcon from '../../../../../images/icons/binoculars.svg';
import LoadingIcon from '../../../../../images/icons/loading.svg';
import './SortTable.less';

const Row = ({ index, style, data }) => (
  <div style={style} className="tableRow" data-testid={`tableRow-${index}`}>
    {data[index]}
  </div>
);

const renderContent = (props) => {
  const {
    type,
    columns,
    rows,
    loadingItems,
    isLoading,
    increaseDataSet,
    label: labelProp,
    itemSize,
    extendedItemSize,
    className,
  } = props;
  const noneFound = (label) => `We're looking, but no ${label} found.`;
  const loading = (label) => `One sec, we're loading your ${label}...`;
  const label = type ? type.lowercasePlural() : labelProp;
  if (loadingItems || isLoading) {
    return (
      <div className="loading-notification">
        <div className="loading none-found-icon">
          <LoadingIcon />
        </div>
        <div>{loading(label)}</div>
      </div>
    );
  }
  if (rows && rows.length === 0) {
    return (
      <div className="none-found-notification">
        <div className="none-found-icon">
          <BinocularsIcon />
        </div>
        <div>{noneFound(label)}</div>
      </div>
    );
  }
  const itemCount = rows && rows.length + 1;
  const isItemLoaded = (index) => index < rows.length;
  const loadMoreData = () => {
    if (increaseDataSet) {
      increaseDataSet();
    }
  };
  return (
    <>
      <div data-testid="tableColumns" className="sort-row sort-row-header">
        {columns}
      </div>
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreData}
          >
            {({ onItemsRendered, ref }) => (
              <FixedSizeList
                itemCount={rows?.length}
                height={height - 150}
                itemSize={extendedItemSize || itemSize}
                width={width}
                itemData={rows}
                useIsScrolling
                ref={ref}
                onItemsRendered={onItemsRendered}
                className={className}
              >
                {Row}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </>
  );
};

const SortTable = (props) => {
  const { containerHeight } = props;
  return (
    <div style={{ width: '100%', height: containerHeight || '100vh' }} data-testid="sortTable">
      {renderContent(props)}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  loadingItems: get(state, `${pluralize(ownProps.id)}.loadingItem`, false),
});

SortTable.defaultProps = {
  id: 'common-list',
  label: '',
  columns: [],
  itemSize: 60,
  extendedItemSize: null,
  className: '',
  containerHeight: '',
};

Row.propTypes = {
  index: PropTypes.number,
  data: PropTypes.instanceOf(Array),
  style: PropTypes.instanceOf(Object),
};

Row.defaultProps = {
  index: 0,
  data: [],
  style: {},
};
export { SortTable };

export default connect(mapStateToProps, { clearFilter })(SortTable);
