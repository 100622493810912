import { useEffect } from 'react';
import axios from 'axios';
import {
  useInfiniteQuery, useMutation, useQueryClient,
} from '@tanstack/react-query';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { getAccessToken } from '../helpers/localStorage';
import { IN_TESTING_MODE, TEST_AUTH_TOKEN } from '../tests/constants';
import { QUERY_KEYS } from './constants';
import { GET_JOBLIST } from '../constants/endpoints';
import {
  buildPaginatedQueryString,
  buildInboxFilterObject,
  checkInboxFilterOperatorSet,
  checkPrefetchData,
} from './helpers';
import { INCREMENT_PREFETCH_CASES_COUNT } from '../constants/actions';

const headers = {
  'content-type': 'application/json',
  Accept: 'application/json',
};

// TO DO: Can buid on as part of HMB-6030 ticket
export function useCaseMutation() {
  const { tenantId } = useSelector(
    (state) => ({
      tenantId: state.auth?.user?.organizationId,
    }),
    shallowEqual,
  );
  const queryClient = useQueryClient();

  async function putCase({ caseId, body }) {
    const token = IN_TESTING_MODE ? TEST_AUTH_TOKEN : await getAccessToken();
    const payload = {
      method: 'PATCH',
      headers: new Headers({
        ...headers,
        Authorization: token,
      }),
      body: JSON.stringify(body),
    };
    return fetch(
      `/api/job/${caseId}`,
      payload,
    ).then((res) => res.json());
  }

  const query = [
    QUERY_KEYS.CASES,
    tenantId,
    {
      limit: 3,
      order: 'DESC',
      searchMessages: '',
    },
  ];
  return useMutation(
    putCase,
    {
      onSuccess: (data) => {
        console.log('success');
        console.log(data);
        // below not quite working yet
        // queryClient.setQueryData(query, (state) => state.pages.map((page) => page.map((chat) => {
        //   if (chat._id === data._id) {
        //     return data;
        //   }
        //   return chat;
        // })));
      },
      onSettled: () => {
        console.log('settled');
        queryClient.invalidateQueries(query);
      },
    },
  );
}

export function useCasesQuery(customFilters) {
  const {
    userId,
  } = useSelector(
    (state) => ({
      userId: state.auth?.user?._id,
    }),
    shallowEqual,
  );
  const filters = buildInboxFilterObject(customFilters, userId);
  async function fetchCases({ pageParam = 1 }) {
    const filterQueryString = buildPaginatedQueryString(pageParam, filters);
    const url = `${GET_JOBLIST}${
      filterQueryString ? `?${filterQueryString}` : ''
    }`;

    const token = IN_TESTING_MODE ? TEST_AUTH_TOKEN : await getAccessToken();

    return axios({
      url,
      method: 'GET',
      headers: { Authorization: token },
    }).then((response) => response.data);
  }

  return useInfiniteQuery([QUERY_KEYS.CASES, filters], fetchCases, {
    getNextPageParam: (lastPage, pages) => {
      if ((lastPage?.length || 0) < filters.limit) return;
      return (pages?.length || 0) + 1;
    },
  });
}

const prefetchCaseData = async (filters, queryClient) => {
  async function fetchCases({ pageParam = 1 }) {
    const filterQueryString = buildPaginatedQueryString(pageParam, filters);
    const url = `${GET_JOBLIST}${
      filterQueryString ? `?${filterQueryString}` : ''
    }`;

    const token = IN_TESTING_MODE ? TEST_AUTH_TOKEN : await getAccessToken();

    return axios({
      url,
      method: 'GET',
      headers: { Authorization: token },
    }).then((response) => response.data);
  }
  await queryClient.prefetchInfiniteQuery({
    queryKey: [QUERY_KEYS.CASES, filters],
    queryFn: fetchCases,
    initialPageParam: 0,
  });
};

export function usePrefetchQueries() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const {
    userId,
    inboxFilters,
    prefetchCasesCount,
  } = useSelector((state) => ({
    userId: state.auth?.user?._id,
    inboxFilters: state?.profile?.properties?.inboxFilters || {},
    prefetchCasesCount: state.jobs?.prefetchCasesCount,
  }));
  const isInboxFilterOperatorSet = checkInboxFilterOperatorSet(inboxFilters);

  const customerFilters = {
    view: 'inbox',
    inboxFilters,
  };
  const unpinnedFilters = buildInboxFilterObject(customerFilters, userId);

  useEffect(() => {
    const shouldPrefetchData = checkPrefetchData(
      isInboxFilterOperatorSet,
      prefetchCasesCount,
    );
    if (shouldPrefetchData) {
      prefetchCaseData(unpinnedFilters, queryClient);
      dispatch({ type: INCREMENT_PREFETCH_CASES_COUNT });
    }
  }, [
    prefetchCasesCount,
    isInboxFilterOperatorSet,
    queryClient,
    unpinnedFilters,
    dispatch,
  ]);
}
